<script lang="ts">
import { defineComponent } from "vue";

const DesktopHeaderComponent = defineComponent({
  name: "DesktopHeaderComponent",
});
export default DesktopHeaderComponent;
</script>

<template>
  <svg class="absolute left-0 z-0 w-full" viewBox="0 0 1200 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <mask id="mask0_291_27980" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1200" height="306">
      <rect width="1200" height="306" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_291_27980)">
      <ellipse cx="583.998" cy="-113.573" rx="771.998" ry="401.213" fill="url(#paint0_linear_291_27980)" />
      <path d="M1388 -134.818C1388 80.1207 1053.16 254.363 640.109 254.363C227.06 254.363 -107.783 80.1207 -107.783 -134.818C-107.783 -349.757 227.06 -524 640.109 -524C1053.16 -524 1388 -349.757 1388 -134.818Z" fill="url(#paint1_linear_291_27980)" />
      <rect x="509.62" y="40.9556" width="207.303" height="215.516" fill="url(#pattern0)" />
    </g>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_291_27980" transform="matrix(0.00144392 0 0 0.00138889 -0.01981 0)" />
      </pattern>
      <linearGradient id="paint0_linear_291_27980" x1="32.7745" y1="62.5691" x2="1052.48" y2="333.947" gradientUnits="userSpaceOnUse">
        <stop offset="0.332729" stop-color="#ECD51F" />
        <stop offset="1" stop-color="#CE2834" />
      </linearGradient>
      <linearGradient id="paint1_linear_291_27980" x1="478.663" y1="-109.793" x2="560.353" y2="278.406" gradientUnits="userSpaceOnUse">
        <stop offset="0.078125" stop-color="#212A83" />
        <stop offset="1" stop-color="#151B47" />
      </linearGradient>
    </defs>
  </svg>
</template>
