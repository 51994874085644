import { ENUM_FIELD_ROLE } from "./enums/enum"

export type Permission = ENUM_FIELD_ROLE[]

export const PERMISSION_ANY: Permission = [
  ENUM_FIELD_ROLE.ADMIN,
  ENUM_FIELD_ROLE.MANAGER,
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.SUPPRESSOR,
  ENUM_FIELD_ROLE.INVESTIGATOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
  ENUM_FIELD_ROLE.AGENT,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.OFFICER,
  ENUM_FIELD_ROLE.REPORTER,
]

export const PERMISSION_OVERVIEW: Permission = [
  ENUM_FIELD_ROLE.MANAGER,
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.OFFICER,
  ENUM_FIELD_ROLE.AGENT,
  ENUM_FIELD_ROLE.REPORTER,
]

export const PERMISSION_INCIDENT_MY_TASK: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.SUPPRESSOR,
  ENUM_FIELD_ROLE.INVESTIGATOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.OFFICER,
]

export const PERMISSION_INCIDENT_ALL: Permission = [
  ENUM_FIELD_ROLE.MANAGER,
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.SUPPRESSOR,
  ENUM_FIELD_ROLE.INVESTIGATOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.OFFICER,
  ENUM_FIELD_ROLE.REPORTER,
]

export const PERMISSION_INCIDENT_NEW: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.OFFICER,
]

export const PERMISSION_INCIDENT_ACCEPTED: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
]

export const PERMISSION_INCIDENT_WAITING: Permission = [
  ENUM_FIELD_ROLE.SUPPRESSOR,
  ENUM_FIELD_ROLE.INVESTIGATOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.OFFICER,
]

export const PERMISSION_INCIDENT_IN_PROGRESS: Permission = [
  ENUM_FIELD_ROLE.SUPPRESSOR,
  ENUM_FIELD_ROLE.INVESTIGATOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_EXTERNAL_DEPARTMENT: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.INVESTIGATOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
]

export const PERMISSION_INCIDENT_CURRENT: Permission = [
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.OFFICER,
]

export const PERMISSION_INCIDENT_IN_REVIEW: Permission = [
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.OFFICER,
]

export const PERMISSION_INCIDENT_REPORTED: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
]

export const PERMISSION_INCIDENT_CLOSED: Permission = [
  ENUM_FIELD_ROLE.MANAGER,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.REPORTER,
]

export const PERMISSION_INCIDENT_REJECTED: Permission = [
  ENUM_FIELD_ROLE.MANAGER,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
]

export const PERMISSION_INCIDENT_CANCELED: Permission = [
  ENUM_FIELD_ROLE.MANAGER,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_CREATE: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_ASSIGN: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_REASSIGN: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_UPDATE_REPORT: Permission = [
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_APPROVE: Permission = [
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_INCIDENT_CLOSE: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
]

export const PERMISSION_TOURIST: Permission = [
  ENUM_FIELD_ROLE.ADMIN,
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,

  // Legacy
  ENUM_FIELD_ROLE.CENTRAL_OFFICER,
  ENUM_FIELD_ROLE.OFFICER,
]

export const PERMISSION_CHAT: Permission = [
  ENUM_FIELD_ROLE.SUPERVISOR,
  ENUM_FIELD_ROLE.INTEPETOR,
  ENUM_FIELD_ROLE.DUTY_OFFICER,
]

export const PERMISSION_DASHBOARD: Permission = [
  ENUM_FIELD_ROLE.MANAGER,

  // Legacy
  ENUM_FIELD_ROLE.REPORTER,
]

export const PERMISSION_NEWS: Permission = [
  ENUM_FIELD_ROLE.ADMIN,
]

export const PERMISSION_ALERT: Permission = [
  ENUM_FIELD_ROLE.ADMIN,
]

export const PERMISSION_USER: Permission = [
  ENUM_FIELD_ROLE.ADMIN,
]
