<script lang="ts">
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { defineComponent } from "vue";
import FormRadioDefaultComponent from "@/components/Forms/Radio/FormRadioDefaultComponent.vue";
import { useSettingLanguageStore } from "./stores/useSettingLanguageStore";
import { type ENUM_FIELD_LANGUAGE } from "@/configs/enums/enum";
import type { $enum } from "@/configs/types/Shared/typeShare";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { isCommandCenterApp, setLocalStorage } from "@/configs/helpers/MainHelper";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";

export default defineComponent({
  name: "SettingLanguageView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          radio: {
            lang: {
              component: FormRadioDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingLanguageStore.error[this.settingLanguageStore.isRequired.locale.name] || "").length > 0,
                isIcon: false,
                isDisable: (this.$route.params.id && this.settingLanguageStore?.result?.responsibleID === null) || this.loading(),
                isRequired: this.settingLanguageStore.isRequired.locale.is,
                textError: this.$t(this.settingLanguageStore.isRequired.locale.placeholder),
                title: this.$t(this.settingLanguageStore.isRequired.locale.label),
                placeholder: this.$t(this.settingLanguageStore.isRequired.locale.placeholder),
                value: this.settingLanguageStore?.result?.locale,
                items: [],
                hiddenValues: [this.$enums.ENUM_INCIDENT_STATUS.REJECTED],
                name: this.settingLanguageStore.isRequired.locale.name,
              } as typeof FormRadioDefaultComponent.props,
              onUpdateValue: (value: ENUM_FIELD_LANGUAGE | $enum) => {
                this.settingLanguageStore.result.locale = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return false;
    },
    setDayJSLang() {
      switch (this.masterDataStore.lang()) {
        case "TH":
          this.$dayjs.locale("th");
          break;
        case "CN":
          this.$dayjs.locale("zh");
          break;
        case "EN":
          this.$dayjs.locale("en");
          break;
        case "JP":
          this.$dayjs.locale("ja");
          break;
        case "RU":
          this.$dayjs.locale("ru");
          break;
        case "KR":
          this.$dayjs.locale("ko");
          break;
      }
    },
    async fetch() {
      this.settingLanguageStore.loading = true;

      await Promise.all([
        (async () => {
          const fetch = new fetchAuth();
          const user = await fetch.Get();
          this.settingLanguageStore.result = user;
          this.sharedStore.locale = String(this.settingLanguageStore.result.locale).toUpperCase();
          await setLocalStorage(`${this.storeName}:locale`, String(this.settingLanguageStore.result.locale).toUpperCase());
          this.$i18n.locale = String(this.settingLanguageStore.result.locale).toLowerCase();
          this.setDayJSLang();
        })(),
      ]);
      this.settingLanguageStore.loading = false;
    },
    async setLang(e: any, value: ENUM_FIELD_LANGUAGE | $enum) {
      this.sharedStore.locale = String(value).toUpperCase();
      this.settingLanguageStore.result.locale = this.sharedStore.locale;
      await setLocalStorage(`${this.storeName}:locale`, String(value).toUpperCase());
      this.$i18n.locale = String(value).toLowerCase();
      this.settingLanguageStore.onSubmit(e);
      this.setDayJSLang();
    },
    async reset() {
      await this.settingLanguageStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const settingLanguageStore = useSettingLanguageStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const masterDataStore = useMasterDataStore();

    return {
      authStore,
      settingLanguageStore,
      sharedStore,
      storeName: storeNamePoliceTouristAndAgent,
      masterDataStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'SettingMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.SettingLanguageView.title") }}</h1>
            </div>
          </div>

          <form @submit="settingLanguageStore.onSubmit" class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <submit v-for="locale in [$enums.ENUM_FIELD_LANGUAGE.TH, $enums.ENUM_FIELD_LANGUAGE.EN, $enums.ENUM_FIELD_LANGUAGE.CN, $enums.ENUM_FIELD_LANGUAGE.JP, $enums.ENUM_FIELD_LANGUAGE.KR, $enums.ENUM_FIELD_LANGUAGE.RU]" class="flex w-full items-center cursor-pointer" @click="(e: any) => setLang(e, locale)">
              <div class="w-full text-left text-black ttp-text-sm">
                {{ locale == "EN" ? "English" : $t(`lang.${String(locale).toLowerCase()}`) }}
              </div>
              <div class="w-full text-right">
                <svg v-if="sharedStore?.locale == locale" class="ttp-icon-inside-box-03 float-right" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10.5" r="8.5" stroke="#2C3483" stroke-width="3" />
                  <circle cx="10.0003" cy="10.4998" r="5.83333" fill="#2C3483" />
                </svg>
                <svg v-else class="ttp-icon-inside-box-03 float-right" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10.5" r="8.5" stroke="#2C3483" stroke-width="3" />
                </svg>
              </div>
            </submit>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
