import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useTouristDeleteStore-${import.meta.env.VITE_APP_VERSION}`;

const errors = {
  id: "",
};
const values = {
  id: 0,
};

export const useTouristDeleteStore = defineStore(storeName, {
  state: () => ({
    isOpen: false,
    loading: false,
    values,
    errors,
    validationSchema: yup.object().shape({
      id: yup.number().required(errors.id),
    }),
  }),
  actions: {
    closeModal() {
      this.isOpen = false;
    },
    openModal() {
      this.isOpen = true;
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      this.errors = Object.fromEntries(Object.keys(this.errors).map((key) => [key, null])) as any;

      try {
        this.loading = true;
        await this.validationSchema.validate(this.values, {
          abortEarly: false,
        });
        this.values = this.values;

        this.delete();
      } catch (error: any) {
        this.loading = false;
        this.errors = Object.fromEntries(Object.keys(this.errors).map((key) => [key, error.inner.find((e: any) => e.path === key)?.message || null])) as any;
      }
      this.isOpen = false;
    },
    delete() {
      console.warn("Delete");
    },
  },
});
