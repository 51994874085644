<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { ENUM_SIZE, ENUM_COLOR } from "@/configs/enums/enum";

const PopupCautionComponent = defineComponent({
  name: "PopupCautionComponent",
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ButtonDefaultComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    textTitle: {
      type: String,
      default: "typeGetTitle",
    },
    textDetail: {
      type: String,
      default: "Detail",
    },
    functionProp: {
      type: Function,
      default: () => {},
    },
    functionCancelProp: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const isOpenLocal = ref(props.isOpen);

    watch(
      () => props.isOpen,
      (newValue) => {
        isOpenLocal.value = newValue;
      }
    );

    function toggleOpen() {
      isOpenLocal.value = !isOpenLocal.value;
      emit("updateIsOpen", isOpenLocal.value);
    }

    onMounted(() => {
      emit("sendToggleOpen", toggleOpen);
    });

    return {
      isOpen: isOpenLocal,
      ENUM_COLOR,
      ENUM_SIZE,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
      }
    },
    handleCancelClick(e: Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (typeof this.functionCancelProp === "function") {
        this.functionCancelProp(e);
      }
    },
  },
});
export default PopupCautionComponent;
</script>

<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleCancelClick" class="relative z-9999">
        <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8 ttp-fixed-tourist-margin-mobile-header">
          <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0 scale-125" enter-to="opacity-100 scale-100" leave="ease-in duration-100" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-125">
            <DialogPanel class="w-full max-w-md mx-auto flex flex-col rounded-lg shadow-md bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800">
              <div class="relative p-4">
                <div class="text-right">
                  <button @click="handleCancelClick" @touchstart="handleCancelClick" type="button" class="inline-flex items-center justify-center space-x-2 rounded-lg border border-transparent px-3 py-2 ttp-text-xs font-semibold leading-5 text-gray-800 hover:text-gray-900 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Icons/Common Action/Close">
                        <path id="Vector" d="M19.7433 3.48947C19.5452 3.49414 19.357 3.577 19.2198 3.71994L12.0001 10.9397L4.78039 3.71994C4.71048 3.64794 4.62684 3.59071 4.53441 3.55163C4.44198 3.51254 4.34265 3.4924 4.2423 3.4924C4.09304 3.49243 3.94719 3.537 3.8234 3.6204C3.69961 3.70379 3.60352 3.82223 3.54741 3.96054C3.49131 4.09886 3.47775 4.25077 3.50847 4.39683C3.53918 4.5429 3.61278 4.67648 3.71984 4.78048L10.9396 12.0002L3.71984 19.2199C3.64786 19.289 3.5904 19.3718 3.55081 19.4634C3.51122 19.555 3.49031 19.6536 3.4893 19.7534C3.48828 19.8531 3.50719 19.9521 3.5449 20.0445C3.58262 20.1369 3.63839 20.2208 3.70895 20.2914C3.77951 20.3619 3.86344 20.4177 3.95582 20.4554C4.0482 20.4931 4.14718 20.512 4.24696 20.511C4.34674 20.51 4.44531 20.4891 4.53691 20.4495C4.6285 20.4099 4.71128 20.3525 4.78039 20.2805L12.0001 13.0608L19.2198 20.2805C19.289 20.3525 19.3717 20.4099 19.4633 20.4495C19.5549 20.4891 19.6535 20.51 19.7533 20.511C19.8531 20.512 19.952 20.4931 20.0444 20.4554C20.1368 20.4177 20.2207 20.3619 20.2913 20.2914C20.3618 20.2208 20.4176 20.1369 20.4553 20.0445C20.493 19.9521 20.512 19.8531 20.5109 19.7534C20.5099 19.6536 20.489 19.555 20.4494 19.4634C20.4098 19.3718 20.3524 19.289 20.2804 19.2199L13.0607 12.0002L20.2804 4.78048C20.3895 4.6758 20.4643 4.54061 20.4952 4.39263C20.5261 4.24465 20.5116 4.09079 20.4535 3.95121C20.3955 3.81164 20.2966 3.69287 20.1698 3.61047C20.0431 3.52807 19.8944 3.4859 19.7433 3.48947Z" fill="#4C4E64" fill-opacity="0.68" />
                      </g>
                    </svg>
                  </button>
                </div>
                <div class="w-14 h-14 mx-auto flex items-center justify-center">
                  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M20.5 40.5786C31.5279 40.5786 40.5 31.6065 40.5 20.5786C40.5 9.55069 31.5279 0.578612 20.5 0.578612C9.47207 0.578611 0.500003 9.55068 0.500002 20.5786C0.500001 31.6065 9.47206 40.5786 20.5 40.5786ZM20.5 37.5786C11.0934 37.5786 3.5 29.9852 3.5 20.5786C3.5 11.172 11.0934 3.57861 20.5 3.57861C29.9066 3.57861 37.5 11.172 37.5 20.5786C37.5 29.9852 29.9066 37.5786 20.5 37.5786ZM20.5 30.5786C21.0304 30.5786 21.5391 30.3679 21.9142 29.9928C22.2893 29.6178 22.5 29.109 22.5 28.5786C22.5 28.0482 22.2893 27.5395 21.9142 27.1644C21.5391 26.7893 21.0304 26.5786 20.5 26.5786C19.9696 26.5786 19.4609 26.7893 19.0858 27.1644C18.7107 27.5395 18.5 28.0482 18.5 28.5786C18.5 29.109 18.7107 29.6178 19.0858 29.9928C19.4609 30.3679 19.9696 30.5786 20.5 30.5786ZM20.5234 23.6001C20.9209 23.5939 21.2997 23.4302 21.5765 23.1449C21.8534 22.8596 22.0057 22.4761 22 22.0786L22 11.0786C22.0028 10.8798 21.9661 10.6825 21.892 10.498C21.8178 10.3136 21.7078 10.1457 21.5682 10.0042C21.4287 9.86261 21.2624 9.7502 21.079 9.67348C20.8956 9.59676 20.6988 9.55725 20.5 9.55725C20.3012 9.55725 20.1044 9.59676 19.921 9.67348C19.7376 9.7502 19.5713 9.86261 19.4318 10.0042C19.2922 10.1457 19.1822 10.3136 19.108 10.498C19.0339 10.6825 18.9972 10.8798 19 11.0786L19 22.0786C18.9971 22.2794 19.0346 22.4787 19.1101 22.6647C19.1857 22.8507 19.2978 23.0197 19.4399 23.1616C19.582 23.3035 19.7511 23.4154 19.9372 23.4907C20.1233 23.566 20.3227 23.6032 20.5234 23.6001Z" fill="#D71A16" />
                  </svg>
                </div>
                <div class="my-5">
                  <h4 class="text-center text-gray-800 ttp-text-base font-medium leading-normal">
                    {{ textTitle }}
                  </h4>
                  <div class="text-center text-gray-500 ttp-text-xs font-normal leading-normal">
                    {{ textDetail }}
                  </div>
                </div>
              </div>
              <div class="space-x-2 bg-gray-50 px-5 py-4 text-left dark:bg-gray-700/50">
                <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true" :isDisable="false" :htmlIcon="''" :title="$t('btn.btn_submit')" :classEnumName="ENUM_COLOR.DEFAULT_1" :classTagName="'w-full'" :size="ENUM_SIZE.SMALL" @click="handleClick" @touchstart="handleClick" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
