<script lang="ts">
import { defineComponent } from "vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useAuthStore } from "./stores/useAuthStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { IonNavLink } from "@ionic/vue";
import { useForgotPasswordStore } from "./stores/useForgotPasswordStore";

export default defineComponent({
  name: "ForgotPasswordView",
  components: {
    IonNavLink,
  },
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            email: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.forgotPasswordStore.error.email || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                isDisable: this.loading(),
                componentIcon: this.$solidIcons.EnvelopeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isRequired: this.forgotPasswordStore.isRequired.email.is,
                textError: this.$t(this.forgotPasswordStore.isRequired.email.placeholder),
                title: this.$t(this.forgotPasswordStore.isRequired.email.label),
                placeholder: this.$t(this.forgotPasswordStore.isRequired.email.placeholder),
                value: this.forgotPasswordStore.result.email,
                name: this.forgotPasswordStore.isRequired.email.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: string) => {
                this.forgotPasswordStore.result.email = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_submit"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async () => {
                const result = await this.forgotPasswordStore.onSubmit();
                if (result?.ref) {
                  this.forgotPasswordStore.result.ref = result?.ref;
                  this.$router.push({ name: "ForgotPasswordOTPView" });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.forgotPasswordStore.loading;
    },
    async fetch() {
      await Promise.all([this.masterDataStore.listTitle(), this.masterDataStore.listLocale(), this.masterDataStore.listRole(), this.masterDataStore.listNationality(), this.masterDataStore.listIncidentCategory(), this.masterDataStore.listProvince(), this.masterDataStore.listCountry()]);
      if (isCommandCenterApp) await Promise.all([this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0)]);
    },
    async facebookLoginHandler() {
      await this.authStore.loginWithThirdParty("FACEBOOK");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
    async googleLoginHandler() {
      await this.authStore.loginWithThirdParty("GOOGLE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
    async appleLoginHandler() {
      await this.authStore.loginWithThirdParty("APPLE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
    async phoneLoginHandler() {
      await this.authStore.loginWithThirdParty("PHONE");
      if (this.authStore.user) {
        await this.fetch();
        this.$router.push({ name: "HomeView" });
      } else {
        this.$router.push({ name: "RegisterView" });
      }
    },
  },
  setup() {
    const forgotPasswordStore = useForgotPasswordStore();
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    return {
      forgotPasswordStore,
      authStore,
      masterDataStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.push({ name: 'LoginView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">Forgot Password</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl text-center">{{ $t("page.ForgotPasswordView.desc") }}</h1>
                <p class="text-black text-center">{{ $t("page.ForgotPasswordView.email") }}</p>
                <di>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.email.props" @updateValue="defaultFormComponents.form.input.email.onUpdateValue" />
                  </div>
                  <div class="w-full mb-2">
                    <div class="flex gap-2 w-full">
                      <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" class="m-auto" />
                    </div>
                  </div>
                  <h3 class="flex w-full items-center justify-center">
                    <span aria-hidden="true" class="h-0.5 grow rounded bg-gray-200 dark:bg-gray-700/75"></span>
                    <span class="mx-3">{{ $t("divider.or_login_with") }}</span>
                    <span aria-hidden="true" class="h-0.5 grow rounded bg-gray-200 dark:bg-gray-700/75"></span>
                  </h3>
                  <div class="flex gap-2 mt-4 items-center justify-center">
                    <IonNavLink @click="facebookLoginHandler" expand="block" class="cursor-pointer">
                      <svg class="h-12 w-12" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="44.6333" cy="44.5" r="44" stroke="#4E4E4E" stroke-opacity="0.5" />
                        <path d="M58.9754 36.713H49.8251V31.5213C49.8251 28.8424 50.0432 27.1551 53.8824 27.1551H58.7314V18.9004C56.3718 18.6564 53.9992 18.537 51.624 18.5422C44.5815 18.5422 39.4418 22.8434 39.4418 30.74V36.713H31.6543V47.0963L39.4418 47.0937V70.4588H49.8251V47.0885L57.784 47.0859L58.9754 36.713Z" fill="#1877F2" />
                      </svg>
                    </IonNavLink>
                    <IonNavLink @click="googleLoginHandler" expand="block" class="cursor-pointer">
                      <svg class="h-12 w-12" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="45.3999" cy="44.5" r="44" stroke="#4E4E4E" stroke-opacity="0.5" />
                        <g clip-path="url(#clip0_292_17186)">
                          <path d="M67.6265 44.9163C67.6265 43.0931 67.4752 41.7627 67.1475 40.3831H45.8433V48.6118H58.3484C58.0964 50.6568 56.7349 53.7364 53.7094 55.8059L53.667 56.0814L60.403 61.1807L60.8697 61.2262C65.1557 57.3581 67.6265 51.6669 67.6265 44.9163Z" fill="#4285F4" />
                          <path d="M45.8429 66.5972C51.9693 66.5972 57.1126 64.6261 60.8693 61.2263L53.709 55.8059C51.7929 57.1117 49.2212 58.0233 45.8429 58.0233C39.8424 58.0233 34.7496 54.1554 32.9342 48.8091L32.6681 48.8312L25.6639 54.1282L25.5723 54.377C29.3036 61.6203 36.9681 66.5972 45.8429 66.5972Z" fill="#34A853" />
                          <path d="M32.9346 48.8089C32.4555 47.4293 32.1783 45.9509 32.1783 44.4235C32.1783 42.8959 32.4555 41.4177 32.9094 40.038L32.8967 39.7442L25.8047 34.3621L25.5727 34.4699C24.0348 37.4757 23.1523 40.8511 23.1523 44.4235C23.1523 47.9959 24.0348 51.3711 25.5727 54.3769L32.9346 48.8089Z" fill="#FBBC05" />
                          <path d="M45.8429 30.8237C50.1037 30.8237 52.9778 32.6222 54.6167 34.1252L61.0205 28.0151C57.0875 24.4427 51.9694 22.25 45.8429 22.25C36.9681 22.25 29.3036 27.2267 25.5723 34.47L32.909 40.0381C34.7496 34.6918 39.8424 30.8237 45.8429 30.8237Z" fill="#EB4335" />
                        </g>
                        <defs>
                          <clipPath id="clip0_292_17186">
                            <rect width="44.5" height="44.5" fill="white" transform="translate(23.1499 22.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </IonNavLink>
                    <IonNavLink @click="appleLoginHandler" expand="block" class="cursor-pointer">
                      <svg class="h-12 w-12" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="45.1665" cy="44.5" r="44" stroke="#4E4E4E" stroke-opacity="0.5" />
                        <path d="M65.9953 31.9847C65.6783 32.2307 60.0809 35.3847 60.0809 42.3978C60.0809 50.5095 67.2033 53.3793 67.4165 53.4503C67.3837 53.6252 66.285 57.3805 63.6613 61.2068C61.3217 64.574 58.8784 67.9356 55.1614 67.9356C51.4444 67.9356 50.4878 65.7765 46.1969 65.7765C42.0153 65.7765 40.5285 68.0067 37.1285 68.0067C33.7286 68.0067 31.3563 64.891 28.6286 61.0647C25.4692 56.5715 22.9165 49.5912 22.9165 42.9662C22.9165 32.34 29.8257 26.7044 36.6256 26.7044C40.2388 26.7044 43.2506 29.0767 45.5191 29.0767C47.6782 29.0767 51.0454 26.5623 55.1559 26.5623C56.7138 26.5623 62.3111 26.7044 65.9953 31.9847ZM53.2045 22.0636C54.9045 20.0466 56.107 17.248 56.107 14.4493C56.107 14.0612 56.0742 13.6676 56.0032 13.3506C53.2373 13.4544 49.9467 15.1927 47.9624 17.4939C46.4046 19.265 44.9506 22.0636 44.9506 24.9006C44.9506 25.3269 45.0217 25.7533 45.0544 25.89C45.2294 25.9228 45.5136 25.961 45.7978 25.961C48.2795 25.961 51.4007 24.2993 53.2045 22.0636Z" fill="black" />
                      </svg>
                    </IonNavLink>
                    <IonNavLink @click="phoneLoginHandler" expand="block" class="cursor-pointer rounded-full border-[0.5px] border-[#a6a6a6] p-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 text-black">
                        <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                      </svg>
                    </IonNavLink>
                  </div>
                </di>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
