<script lang="ts">
import type { AllowedTypes } from "@/configs/types/Shared/typeShare";
import dayjs from "dayjs";
import { defineComponent, ref, watch, type PropType } from "vue";

const FormInputDatepickerComponent = defineComponent({
  name: "FormInputDatepickerComponent",
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isStartIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    componentIcon_2: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    classInputName: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    isFlipType: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null, Date] as PropType<AllowedTypes>,
      required: false,
      default: null,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
    isKeydownNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    isKeydownText: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    format: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    }
  },
  watch: {
    content: {
      handler(newValue) {
        this.$emit("updateValue", newValue);
      },
      deep: true,
    },
  },
  setup(props, { emit }) {
    const date = ref(props.value ? new Date(String(props.value)) : dayjs().endOf("d").toDate());
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );
    watch(
      () => props.value,
      (newValue) => {
        if (newValue) {
          date.value = new Date(String(newValue));
        }
      }
    );

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("updateValue", target.value);
    };
    const handleBlur = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("blur", target.value);
    };
    const handleChangeType = () => {
      emit("type", props.type ? "password" : "text");
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
      handleBlur,
      handleChangeType,
      date,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
    dateFormat(date: Date) {
      return dayjs(date).isValid() ? dayjs(date).format(this.format) : "Invalid date";
    },
  },
});
export default FormInputDatepickerComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label v-if="isLabel && !isSpecial" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired" class="text-ttp-error">*</span></label>
    <div class="relative">
      <div v-if="isIcon" class="absolute inset-y-0 flex items-center pointer-events-none" :class="isStartIcon ? 'start-2' : 'end-2'">
        <span v-if="htmlIcon" v-html="htmlIcon"></span>
        <component v-if="!$isObjectEmpty(componentIcon)" :is="componentIcon" :class="classIcon" />
      </div>
      <VueDatePicker :model-value="date" @update:model-value="functionProp" :format="dateFormat" class="w-full text-left"></VueDatePicker>
      <div v-if="isIcon" @click="handleChangeType" @touchstart="handleChangeType" class="absolute inset-y-0 flex items-center z-50" :class="[!isStartIcon ? 'start-2' : 'end-2', 'cursor-pointer']">
        <span v-if="htmlIcon" v-html="htmlIcon"></span>
        <component v-if="!$isObjectEmpty(componentIcon_2)" :is="componentIcon_2" :class="[classIcon]" />
      </div>
    </div>
    <small v-if="isError && isLabel" class="relative text-ttp-text-error z-0">{{ textError }}</small>
  </div>
</template>
