<script setup lang="ts">
import { fetchReport, type SimpleReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { computed, onMounted, ref } from "vue";
import { Bar } from "vue-chartjs";
import { type ChartOptions } from "chart.js";

dayjs.extend(relativeTime);

type DataSet = { label: string; data: number[]; backgroundColor?: any };

const locales = {
  TH: "ไทย",
  EN: "อังกฤษ",
  CN: "จีน",
  JP: "ญี่ปุ่น",
  KR: "เกาหลี",
  RU: "รัสเซีย",
};

const { aspectRatio } = defineProps<{
  aspectRatio?: number;
}>();

const fetch = new fetchReport();
const report = ref<SimpleReport>({});
const lastUpdate = ref<Dayjs>();

const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  aspectRatio,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const reload = async () => {
  report.value = await fetch.TouristReport(
    {
      startDate: "1970-01-01",
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    "locale"
  );
  lastUpdate.value = dayjs();
};

const chartData = computed(() => {
  const dataset: DataSet = {
    label: "Tourist",
    data: Object.keys(locales).map((k) => report.value[k] || 0),
  };
  const datasets = [dataset];

  return {
    labels: Object.values(locales),
    datasets,
  };
});

onMounted(reload);
defineExpose({ reload });
</script>

<template>
  <!-- Card Headings: Title with Subtitle -->
  <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100 h-full">
    <!-- Card Header -->
    <div class="card-title bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
      <h3 class="mb-1 font-semibold">
        <slot name="title">จำนวนผู้ใช้งานใหม่</slot>
      </h3>
    </div>
    <!-- END Card Header -->

    <!-- Card Body -->
    <div class="chart-wrap grow p-5">
      <Bar v-if="chartData.datasets.length" :data="chartData" :options="chartOptions" />
    </div>
    <!-- Card Body -->
  </div>
  <!-- END Card Headings: Title with Subtitle -->
</template>

<style scoped>
.chart-wrap {
  min-height: 0;
}
</style>
