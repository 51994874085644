<script lang="ts" setup>
import dayjs from "dayjs";
import IncidentStatus from "./IncidentStatus.vue";
import NewUser from "./NewUser.vue";
import MonthlyIncident from "./MonthlyIncident.vue";
import LocaleUser from "./LocaleUser.vue";
import IncidentLocationHeatMap from "./IncidentLocationHeatMap.vue";
import IncidentLocationTable from "./IncidentLocationTable.vue";
import IncidentCategoryTable from "./IncidentCategoryTable.vue";
import { onBeforeUpdate, onMounted, onUnmounted, ref, type ComponentPublicInstance } from "vue";

let lastUpdate = dayjs();
let to = dayjs().endOf("day");
let from = to.clone().subtract(12, "month").startOf("day");
let intervalId: NodeJS.Timeout;

const toMonth = to.clone().endOf("month");
const fromMonth = toMonth.clone().subtract(11, "month").startOf("month");
const cellAspectRation = 1920 / (1080 - 60);

const refs = ref<Array<Element | ComponentPublicInstance | null>>([]);

const aspectRatio = (col: number = 1, row: number = 1) => (cellAspectRation * (row * 4)) / (col / 8);

const components = [
  {
    component: IncidentStatus,
    title: "จำนวนเหตุการณ์ปัจจุบันที่เจ้าหน้าที่กำลังให้บริการ",
    column: [1, 3],
    row: [1, 2],
    props: {
      lastUpdate,
    },
  },
  {
    component: NewUser,
    title: "จำนวนผู้ใช้งานใหม่ แยกตามสัญชาติ",
    column: [3, 5],
    row: [1, 2],
    props: {
      from,
      to,
      lastUpdate,
      group: "nationality",
    },
  },
  {
    component: IncidentLocationHeatMap,
    column: [5, 7],
    row: [1, 3],
    title: "แผนภาพความร้อนการแจ้งเหตุในแต่ละจังหวัด",
    props: {
      from,
      to,
      lastUpdate,
    },
  },
  {
    component: NewUser,
    title: "จำนวนผู้ใช้งานใหม่ แยกตามเพศ",
    column: [1, 3],
    row: [2, 3],
    props: {
      from,
      to,
      lastUpdate,
      group: "gender",
    },
  },
  {
    component: LocaleUser,
    title: "จำนวนผู้ใช้งานทั้งหมด แยกตามภาษา",
    column: [3, 5],
    row: [2, 3],
    props: {
      lastUpdate,
    },
  },
  {
    component: MonthlyIncident,
    title: "การรับแจ้งเหตุ แยกตามเดือน (12 เดือนย้อนหลัง รวมเดือนปัจจุบัน)",
    column: [1, 3],
    row: [3, 4],
    props: {
      from: fromMonth,
      to: toMonth,
      lastUpdate,
      group: "status",
    },
  },
  {
    component: MonthlyIncident,
    title: "การรับแจ้งเหตุผ่านสายด่วน 1155 แยกตามเดือน (12 เดือนย้อนหลัง รวมเดือนปัจจุบัน)",
    column: [3, 5],
    row: [3, 4],
    props: {
      from: fromMonth,
      to: toMonth,
      lastUpdate,
      group: "status",
      channel: "1155",
    },
  },
  {
    component: MonthlyIncident,
    title: "การรับแจ้งเหตุผ่านสายด่วน 1155 แยกตามเดือน (12 เดือนย้อนหลัง รวมเดือนปัจจุบัน)",
    column: [1, 3],
    row: [4, 5],
    props: {
      from: fromMonth,
      to: toMonth,
      lastUpdate,
      group: "status",
      channel: "APPLICATION",
    },
  },
  {
    component: MonthlyIncident,
    title: "การรับแจ้งเหตุผ่านการการ Walk-in แยกตามเดือน (12 เดือนย้อนหลัง รวมเดือนปัจจุบัน)",
    column: [3, 5],
    row: [4, 5],
    props: {
      from: fromMonth,
      to: toMonth,
      lastUpdate,
      group: "status",
      channel: "WALK_IN",
    },
  },
  {
    component: IncidentLocationTable,
    title: "ตารางจำนวนการแจ้งเหตุในแต่ละจังหวัด (5 อันดับสูงสุด)",
    column: [7, 9],
    row: [1, 2],
    props: {
      from: fromMonth,
      to: toMonth,
      lastUpdate,
    },
  },
  {
    component: IncidentCategoryTable,
    title: "ตารางจำนวนการแจ้งเหตุในแต่ละประเภทเรื่อง (5 อันดับสูงสุด)",
    column: [7, 9],
    row: [2, 3],
    props: {
      from: fromMonth,
      to: toMonth,
      lastUpdate,
    },
  },
];

onMounted(() => {
  intervalId = setInterval(() => {
    refs.value.map((r) => (r as any)?.reload())
  }, 2 * 60 * 1000); // 2 mins
});

onUnmounted(() => {
  clearInterval(intervalId);
});

onBeforeUpdate(() => {
  refs.value = [];
});
</script>

<template>
  <router-link v-if="!$route?.meta.fullScreen" :to="{ name: 'VideoWallViewFullScreen' }" type="button" class="inline-flex items-center justify-center gap-2 rounded-lg border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-semibold leading-5 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90 mb-4"> เปิดแบบเต็มจอ </router-link>
  <div class="grid grid-cols-8 grid-rows-4 gap-4" :class="{ fullscreen: $route?.meta.fullScreen }">
    <div v-for="(item, index) in components" :class="[`col-start-${item.column[0]}`, `col-end-${item.column[1]}`]" :style="`grid-row-start: ${item.row[0]}; grid-row-end: ${item.row[1]};`">
      <component :is="item.component" :ref="(e) => (refs[index] = e)" v-bind="{ ...item.props, aspectRatio: aspectRatio(item.column[1] - item.column[0], item.row[1] - item.row[1]) }">
        <template #title>{{ item.title }}</template>
      </component>
    </div>
  </div>
</template>

<style scoped>
.fullscreen {
  height: 100% !important;
}
</style>
