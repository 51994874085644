import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import type { typeGetRegisterUser } from "@/configs/types/User/typeRegisterUser";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useForgotPasswordStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  email: {
    is: true,
    name: `email`,
    label: `form.email.label`,
    placeholder: `form.email.placeholder`,
    value: null,
  },
};

export const useForgotPasswordStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as typeGetRegisterUser,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      email: isRequired.email.is ? yup.string().nullable().required().email() : yup.string().nullable().notRequired().email(),
    }),
  }),
  actions: {
    async onSubmit() {
      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
        return await this.update();
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update() {
      this.loading = true;

      if (this.result?.email) {
        const auth = new fetchAuth();
        const result = await auth.ForgotPassword(this.result);

        this.loading = false;
        return result;
      } else {
        return false;
      }
    },
  },
});
