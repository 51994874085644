<script lang="ts">
import { defineComponent } from "vue";

import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useUserFormStore } from "./stores/useUserFormStore";
import { useUserDeleteStore } from "./stores/useUserDeleteStore";
import type { typeGetStation } from "@/configs/types/MasterData/typeStation";
import type { $enum, $string } from "@/configs/types/Shared/typeShare";
import { type ENUM_FIELD_STATUS } from "@/configs/enums/enum";

import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import FormCheckBoxToggleComponent from "@/components/Forms/CheckBox/FormCheckBoxToggleComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupDeleteComponent from "@/components/Popups/PopupDeleteComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import FormCheckBoxDefaultComponent from "@/components/Forms/CheckBox/FormCheckBoxDefaultComponent.vue";
import { useSharedStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

export default defineComponent({
  name: "UserManageView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      profile_image: "" as any,
      syncingGDCC: false,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            firstNameTH: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error[`content[0].firstName`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.content[0].firstName.is,
                textError: this.$t(this.userFormStore.isRequired.content[0].firstName.placeholder),
                title: `${this.$t(this.userFormStore.isRequired.content[0].firstName.label)} (TH)`,
                name: `content[0].firstName`,
                placeholder: this.$t(this.userFormStore.isRequired.content[0].firstName.placeholder),
                value: this.userFormStore.result.content[0].firstName,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.content[0].firstName = value;
              },
            },
            lastNameTH: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error[`content[0].lastName`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.content[0].lastName.is,
                textError: this.$t(this.userFormStore.isRequired.content[0].lastName.placeholder),
                title: `${this.$t(this.userFormStore.isRequired.content[0].lastName.label)} (TH)`,
                name: `content[0].lastName`,
                placeholder: this.$t(this.userFormStore.isRequired.content[0].lastName.placeholder),
                value: this.userFormStore.result.content[0].lastName,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.content[0].lastName = value;
              },
            },
            firstNameEN: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error[`content[1].firstName`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.content[1].firstName.is,
                textError: this.$t(this.userFormStore.isRequired.content[1].firstName.placeholder),
                title: `Firstname (EN)`,
                name: `content[1].firstName`,
                placeholder: this.$t(this.userFormStore.isRequired.content[1].firstName.placeholder),
                value: this.userFormStore.result.content[1].firstName,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.content[1].firstName = value;
              },
            },
            lastNameEN: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error[`content[1].lastName`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.content[1].lastName.is,
                textError: this.$t(this.userFormStore.isRequired.content[1].lastName.placeholder),
                title: `Lastname (EN)`,
                name: `content[1].lastName`,
                placeholder: this.$t(this.userFormStore.isRequired.content[1].lastName.placeholder),
                value: this.userFormStore.result.content[1].lastName,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.content[1].lastName = value;
              },
            },
            idCard: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.idCard || "").length > 0,
                isIcon: false,
                isDisable: this.$route.params.id?.length || this.loading(),
                isRequired: this.userFormStore.isRequired.idCard.is,
                textError: this.$t(this.userFormStore.isRequired.idCard.placeholder),
                title: this.$t(this.userFormStore.isRequired.idCard.label),
                placeholder: this.$t(this.userFormStore.isRequired.idCard.placeholder),
                value: this.userFormStore.result.idCard,
                name: this.userFormStore.isRequired.idCard.name,
                maxLength: 13,
                isKeydownNumber: true,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.idCard = value;
                if (!this.$route.params.id?.length) {
                  this.userFormStore.result.email = `th.touristpolice+${value}@gmail.com`;
                }
              },
            },
            password: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.password || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.password.is,
                textError: this.$t(this.userFormStore.isRequired.password.placeholder),
                title: this.$t(this.userFormStore.isRequired.password.label),
                placeholder: this.$t(this.userFormStore.isRequired.password.placeholder),
                value: this.userFormStore.result.password,
                name: "password",
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.password = value;
              },
            },
            contactNumber: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error[this.userFormStore.isRequired.contactNumber.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.contactNumber.is,
                textError: this.$t(this.userFormStore.isRequired.contactNumber.placeholder),
                title: this.$t(this.userFormStore.isRequired.contactNumber.label),
                placeholder: this.$t(this.userFormStore.isRequired.contactNumber.placeholder),
                value: this.userFormStore?.result?.contactNumber,
                name: this.userFormStore.isRequired.contactNumber.name,
                isKeydownNumber: true,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.contactNumber = value;
              },
            },
          },
          select: {
            titleCodeTH: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.titleCode || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.titleCode.is,
                textError: this.$t(this.userFormStore.isRequired.titleCode.placeholder),
                placeholder: this.$t(this.userFormStore.isRequired.titleCode.placeholder),
                value: this.userFormStore.result.titleCode,
                title: `${this.$t(this.userFormStore.isRequired.titleCode.label)} (TH)`,
                name: `content[0].titleCode`,
                items: this.masterDataStore.itemTitles(this.$enums.ENUM_FIELD_LOCALE.TH),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.titleCode = value;
              },
            },
            titleCodeEN: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.titleCode || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.titleCode.is,
                textError: this.$t(this.userFormStore.isRequired.titleCode.placeholder),
                placeholder: this.$t(this.userFormStore.isRequired.titleCode.placeholder),
                value: this.userFormStore.result.titleCode,
                title: `Prefix (EN)`,
                name: `content[1].titleCode`,
                items: this.masterDataStore.itemTitles(this.$enums.ENUM_FIELD_LOCALE.EN),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.titleCode = value;
              },
            },
            division: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.division || "").length > 0,
                isIcon: false,
                isDisable: !this.divisions.length,
                isRequired: false,
                textError: this.$t("form.division.placeholder"),
                title: this.$t("form.division.label"),
                name: "division",
                items: this.divisions,
                placeholder: this.$t("form.division.placeholder"),
                value: this.userFormStore.result.division,
                functionProp: () => {
                  this.userFormStore.result.subdivision = null;
                  this.userFormStore.result.station = null;
                  this.userFormStore.result.organizationCode = this.userFormStore.result.division;
                },
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.division = value;
              },
            },
            subdivision: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.subdivision || "").length > 0,
                isIcon: false,
                isDisable: !this.subdivisions.length,
                isRequired: false,
                textError: this.$t("form.subdivision.placeholder"),
                title: "",
                name: "subdivision",
                items: this.subdivisions,
                placeholder: this.$t("form.subdivision.placeholder"),
                value: this.userFormStore.result.subdivision,
                functionProp: async () => {
                  this.userFormStore.result.station = null;
                  this.userFormStore.result.organizationCode = this.userFormStore.result.subdivision;
                },
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.subdivision = value;
              },
            },
            station: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.station || "").length > 0,
                isIcon: false,
                isDisable: !this.stations.length,
                isRequired: false,
                textError: this.$t("form.station.placeholder"),
                title: "",
                name: "station",
                items: this.stations,
                placeholder: this.$t("form.station.placeholder"),
                value: this.userFormStore.result.station,
                functionProp: async () => {
                  this.userFormStore.result.organizationCode = this.userFormStore.result.station;
                },
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.station = value;
              },
            },
            role: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.role || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.role.is,
                textError: this.$t(this.userFormStore.isRequired.role.placeholder),
                title: this.$t(this.userFormStore.isRequired.role.label),
                placeholder: this.$t(this.userFormStore.isRequired.role.placeholder),
                value: this.userFormStore.result.role,
                items: this.masterDataStore.itemRoles(),
                name: this.userFormStore.isRequired.role.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.role = value;
              },
            },
          },
          toggle: {
            status: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.status || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isLabel: false,
                isRequired: this.userFormStore.isRequired.status.is,
                textError: this.$t(this.userFormStore.isRequired.status.placeholder),
                title: this.$t(this.userFormStore.isRequired.status.label),
                placeholder: this.$t(this.userFormStore.isRequired.status.placeholder),
                value: this.userFormStore.result.status,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.userFormStore.isRequired.status.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: (value: ENUM_FIELD_STATUS | $enum) => {
                this.userFormStore.result.status = value;
              },
            },
            isAgent: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.isOpenAgent || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userFormStore.isRequired.isAgent.is,
                textError: this.$t(this.userFormStore.isRequired.isAgent.placeholder),
                title: this.$t(this.userFormStore.isRequired.isAgent.label),
                placeholder: this.$t(this.userFormStore.isRequired.isAgent.placeholder),
                value: this.userFormStore.result.isAgent ? "true" : "false",
                classEnumName: "peer-checked:bg-ttp-primary",
                items: [{ label: "true", value: "true" }],
                name: "isOpenAgent",
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: (value: $string) => {
                this.userFormStore.result.isAgent = value === "true";
              },
            },
          },
          checkbox: {
            responsibleAreaCodes: {
              component: FormCheckBoxDefaultComponent,
              props: {
                isGrid: true,
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.responsibleAreaCodes || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isLabel: false,
                isRequired: this.userFormStore.isRequired.responsibleAreaCodes.is,
                textError: this.$t(this.userFormStore.isRequired.responsibleAreaCodes.placeholder),
                title: this.$t(this.userFormStore.isRequired.responsibleAreaCodes.label),
                placeholder: this.$t(this.userFormStore.isRequired.responsibleAreaCodes.placeholder),
                value: this.$checkNullAndConvert(this.userFormStore.result.responsibleAreaCodes),
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: this.masterDataStore.itemStations(),
                name: this.userFormStore.isRequired.responsibleAreaCodes.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: (value: any) => {
                this.userFormStore.result.responsibleAreaCodes = value;
              },
            },
            locales: {
              component: FormCheckBoxDefaultComponent,
              props: {
                isGrid: true,
                isSpecial: false,
                isVisible: true,
                isError: (this.userFormStore.error.locales || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isLabel: false,
                isRequired: this.userFormStore.isRequired.locales.is,
                textError: this.$t(this.userFormStore.isRequired.locales.placeholder),
                title: this.$t(this.userFormStore.isRequired.locales.label),
                placeholder: this.$t(this.userFormStore.isRequired.locales.placeholder),
                value: this.$checkNullAndConvert(this.userFormStore.result.locales),
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: "ไทย",
                    value: "TH",
                  },
                  {
                    label: "อังกฤษ",
                    value: "EN",
                  },
                  {
                    label: "จีน",
                    value: "CN",
                  },
                  {
                    label: "รัสเซีย",
                    value: "RU",
                  },
                  {
                    label: "ญี่ปุ่น",
                    value: "JP",
                  },
                  {
                    label: "เกาหลี",
                    value: "KR",
                  },
                ],
                name: this.userFormStore.isRequired.locales.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: (value: any) => {
                this.userFormStore.result.locales = value;
              },
            },
          },
          button: {
            btnUploadImage: {
              click: () => {
                (document?.getElementById("file") as HTMLButtonElement).click();
              },
            },
            btnDeleteImage: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.TrashIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: !this.profile_image || this.loading(),
                title: this.$t("btn.btn_delete_file"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.userFormStore.isDelete = !this.userFormStore.isDelete;
              },
            },
            btnSave: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isSubmit: true,
                title: this.$t('btn.btn_save'),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnCancel: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_cancel"),
                classEnumName: this.$enums.ENUM_COLOR.DARK,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
            },
            btnDelete: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: this.loading(),
                title: this.$t("btn.btn_delete_account"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.deleteStore.isDelete = !this.deleteStore.isDelete;
              },
            },
          },
          popup: {
            deletePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.deleteStore.isDelete,
                textTitle: this.$t("popup.error.delete_title"),
                textDetail: this.$t("popup.error.delete_detail"),
                functionProp: () => {
                  this.deleteStore.isDelete = false;
                },
                functionCancelProp: () => {
                  this.deleteStore.isDelete = false;
                },
                submitEvent: this.deleteStore.onSubmit,
              } as typeof PopupDeleteComponent.props,
            },
            deleteImagePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.userFormStore.isDelete,
                textTitle: this.$t("popup.error.delete_file_image_title"),
                textDetail: this.$t("popup.error.delete_file_image_detail"),
                functionProp: () => {
                  this.userFormStore.isDelete = false;
                },
                functionCancelProp: () => {
                  this.userFormStore.isDelete = false;
                },
                submitEvent: (e: any) => {
                  this.profile_image = "";
                  this.userFormStore.onDeleteImageSubmit(e);
                },
              } as typeof PopupDeleteComponent.props,
            },
            cancelPopup: {
              component: PopupCancelComponent,
              props: {
                isOpen: this.userFormStore.isCancel,
                textTitle: this.$t("popup.cancel.cancel_title"),
                textDetail: this.$t("popup.cancel.cancel_detail"),
                functionProp: () => {
                  this.userFormStore.isCancel = false;
                  this.$router.push({ name: "UserView" });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.userFormStore.isCancel = false;
                },
              } as typeof PopupCancelComponent.props,
            },
          },
          alert: {
            deleteSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.deleteStore.isSuccess,
                textTitle: this.$t("popup.success.delete_user_title"),
                textDetail: this.$t("popup.success.delete_user_detail"),
                functionProp: () => {
                  this.setting();
                  this.$router.push({ name: "UserView" });
                },
                functionCancelProp: () => {
                  this.deleteStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.userFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.delete_user_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.userFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            deleteImageSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.userFormStore.isSuccess,
                textTitle: this.$t("popup.success.delete_image_title"),
                textDetail: this.$t("popup.success.delete_image_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.userFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteImageCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.userFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.delete_image_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.userFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            formSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.userFormStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                functionProp: () => {
                  this.setting();
                  this.$router.push({ name: "UserView" });
                },
                functionCancelProp: () => {
                  this.userFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            formCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.userFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.duplicate_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.userFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
        },
      };
    },
    divisions() {
      return this.masterDataStore.itemOrganizations(1);
    },
    subdivisions() {
      return this.masterDataStore.itemOrganizations(2, this.userFormStore.result.division);
    },
    stations() {
      return this.masterDataStore.itemOrganizations(3, this.userFormStore.result.subdivision);
    },
  },
  methods: {
    async uploadImage(e: any) {
      let listFiles = e.target.files;

      if (!listFiles?.length) {
        return;
      }

      const files: File[] = [];
      for (const f of listFiles) {
        files.push(f);
      }

      const f = new fetchFile();
      const response = await f.Upload(files);
      this.userFormStore.result.image = response[0].id;
      this.profile_image = response[0].url;
      e.target.value = "";
      this.$configLightbox();
    },
    loading() {
      return this.userFormStore.loading || this.deleteStore.loading;
    },
    async fetch() {
      this.userFormStore.loading = true;
      if (this.$route.params.id) {
        this.deleteStore.result.id = String(this.$route.params.id);
        await Promise.all([
          (async () => {
            const result = await this.userFormStore.fetchUser.Get(this.$route.params.id as string);

            if (result.content.length < 2) {
              if (!result.content.find(({ locale }) => locale === this.$enums.ENUM_FIELD_LOCALE.TH)) {
                result.content.push({
                  locale: this.$enums.ENUM_FIELD_LOCALE.TH,
                  firstName: "",
                  lastName: "",
                });
              }
              if (!result.content.find(({ locale }) => locale === this.$enums.ENUM_FIELD_LOCALE.EN)) {
                result.content.push({
                  locale: this.$enums.ENUM_FIELD_LOCALE.EN,
                  firstName: "",
                  lastName: "",
                });
              }
            }
            this.userFormStore.result = result;
            if (result.organizationCode?.length) {
              await this.setOrganization(result.organizationCode);
            }
            if (result.responsibleAreas?.length) {
              await this.setResponsibleAreaCodes(result.responsibleAreas);
            }
            if (result.image) {
              const f = new fetchFile();
              this.profile_image = await f.GetUrl(result.image);
            }
            this.$configLightbox();
          })(),
        ]);
      }
      this.userFormStore.loading = false;
    },
    setOrganization(c: string) {
      const org = this.masterDataStore.organizations.find(({ code }) => code === c);

      switch (org?.level) {
        case 1:
          this.userFormStore.result.division = org.code;
          break;
        case 2:
          this.userFormStore.result.subdivision = org.code;
          break;
        case 3:
          this.userFormStore.result.station = org.code;
          break;
      }

      if (org?.parentCode?.length) {
        this.setOrganization(org?.parentCode);
      }
    },
    setResponsibleAreaCodes(stations: typeGetStation[]) {
      this.userFormStore.result.responsibleAreaCodes = stations.map(({ code }) => code);
    },
    async setMasterData() {
      await Promise.all([this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0)]);
    },
    async reset() {
      await this.userFormStore.$reset();
      await this.deleteStore.$reset();
      await this.userFormStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.setMasterData();
      await this.fetch();
      await this.$configLightbox();
    },
    async syncGDCC() {
      this.userFormStore.loading = true
      try {
        await this.userFormStore.fetchUser.SyncGDCC(this.$route.params.id as string);
      } catch (e) {

      } finally {
        this.fetch()
      }
    }
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const userFormStore = useUserFormStore();
    const deleteStore = useUserDeleteStore();
    const sharedStore = useSharedStore();

    return {
      masterDataStore,
      userFormStore,
      deleteStore,
      sharedStore,
    };
  },
});
</script>

<template>
  <div class="ttp-page-header-1">
    <h2>{{ $t(($route?.meta as any).i18n) }}</h2>
  </div>
  <form @submit.prevent="userFormStore.onSubmit">
    <div class="grid grid-cols-12 gap-3 p-4">
      <div class="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4">
        <div class="text-gray-800 ttp-text-xs leading-tight">รูปภาพ</div>
      </div>
      <div class="swipe-photo flex col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4">
        <div v-if="!profile_image"
          class="inline-flex ttp-icon-box-04 items-center justify-center rounded-lg bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
          <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-box-01" />
        </div>
        <div v-else class="swipe-photo flex gap-2">
          <a :href="profile_image" target="_blank" class="flex w-full">
            <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right mr-2"
              :src="profile_image" alt="" />
          </a>
        </div>
        <div class="px-4 inline-flex items-center justify-center">
          <div>
            <div class="flex gap-2">
              <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true"
                :componentIcon="$outlineIcons.PhotoIcon" :classIcon="'ttp-icon-inside-box-03 inline-block'"
                :isLoading="loading()" :title="$t('btn.btn_select')" :classEnumName="$enums.ENUM_COLOR.DEFAULT_1"
                :size="$enums.ENUM_SIZE.SMALL" @click="defaultFormComponents.form.button.btnUploadImage.click" />
              <input id="file" type="file" accept="image/*" hidden @change="uploadImage" />
              <component :is="defaultFormComponents.form.button.btnDeleteImage.component"
                v-bind="defaultFormComponents.form.button.btnDeleteImage.props"
                @click="defaultFormComponents.form.button.btnDeleteImage.click" />
            </div>
            <div class="mt-2">
              <small>{{ $t("caution.picture_1") }}</small>
            </div>
            <div>
              <small>{{ $t("caution.picture_2") }}</small>
            </div>
          </div>
        </div>
      </div>
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4"
        :is="defaultFormComponents.form.toggle.status.component" v-bind="defaultFormComponents.form.toggle.status.props"
        @updateValue="defaultFormComponents.form.toggle.status.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-3"
        :is="defaultFormComponents.form.select.titleCodeTH.component"
        v-bind="defaultFormComponents.form.select.titleCodeTH.props"
        @updateValue="defaultFormComponents.form.select.titleCodeTH.onUpdateValue" />
      <component classTagName="col-start-1 col-end-7 md:col-start-3 md:col-end-7"
        :is="defaultFormComponents.form.input.firstNameTH.component"
        v-bind="defaultFormComponents.form.input.firstNameTH.props"
        @updateValue="defaultFormComponents.form.input.firstNameTH.onUpdateValue" />
      <component classTagName="col-start-7 col-end-13 md:col-start-7 md:col-end-13"
        :is="defaultFormComponents.form.input.lastNameTH.component"
        v-bind="defaultFormComponents.form.input.lastNameTH.props"
        @updateValue="defaultFormComponents.form.input.lastNameTH.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-3"
        :is="defaultFormComponents.form.select.titleCodeEN.component"
        v-bind="defaultFormComponents.form.select.titleCodeEN.props"
        @updateValue="defaultFormComponents.form.select.titleCodeEN.onUpdateValue" />
      <component classTagName="col-start-1 col-end-7 md:col-start-3 md:col-end-7"
        :is="defaultFormComponents.form.input.firstNameEN.component"
        v-bind="defaultFormComponents.form.input.firstNameEN.props"
        @updateValue="defaultFormComponents.form.input.firstNameEN.onUpdateValue" />
      <component classTagName="col-start-7 col-end-13 md:col-start-7 md:col-end-13"
        :is="defaultFormComponents.form.input.lastNameEN.component"
        v-bind="defaultFormComponents.form.input.lastNameEN.props"
        @updateValue="defaultFormComponents.form.input.lastNameEN.onUpdateValue" />
      <component classTagName="col-start-1 col-end-7 md:col-start-1 md:col-end-7"
        :is="defaultFormComponents.form.input.contactNumber.component"
        v-bind="defaultFormComponents.form.input.contactNumber.props"
        @updateValue="defaultFormComponents.form.input.contactNumber.onUpdateValue" />
      <component classTagName="col-start-7 col-end-13 md:col-start-7 md:col-end-13"
        :is="defaultFormComponents.form.input.idCard.component" v-bind="defaultFormComponents.form.input.idCard.props"
        @updateValue="defaultFormComponents.form.input.idCard.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13"
        :is="defaultFormComponents.form.input.password.component"
        v-bind="defaultFormComponents.form.input.password.props"
        @updateValue="defaultFormComponents.form.input.password.onUpdateValue" />
      <component classTagName="col-start-1 col-end-7 md:col-start-1 md:col-end-7"
        :is="defaultFormComponents.form.select.role.component" v-bind="defaultFormComponents.form.select.role.props"
        @updateValue="defaultFormComponents.form.select.role.onUpdateValue" />
      <div v-if="$route.params.id" class="col-start-7 col-end-13 md:col-start-7 md:col-end-13 mt-2">
        <span v-if="userFormStore.result.gdccUserID?.length">{{ $t("caution.gdcc_account") }}</span>
        <ButtonDefaultComponent
          v-else
          title="สร้าง GDCC Account"
          :classEnumName="$enums.ENUM_COLOR.DEFAULT_1"
          :size="$enums.ENUM_SIZE.SMALL"
          :loading="loading()"
          @click.prevent="syncGDCC"
        />
      </div>

      <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'"
        :is="defaultFormComponents.form.checkbox.locales.component"
        v-bind="defaultFormComponents.form.checkbox.locales.props"
        @updateValue="defaultFormComponents.form.checkbox.locales.onUpdateValue" />

      <!-- group of organization -->
      <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-5'"
        :is="defaultFormComponents.form.select.division.component"
        v-bind="defaultFormComponents.form.select.division.props"
        @updateValue="defaultFormComponents.form.select.division.onUpdateValue" />
      <component :classTagName="'col-start-1 col-end-13 md:col-start-5 md:col-end-9 md:mt-5'"
        :is="defaultFormComponents.form.select.subdivision.component"
        v-bind="defaultFormComponents.form.select.subdivision.props"
        @updateValue="defaultFormComponents.form.select.subdivision.onUpdateValue" />
      <component :classTagName="'col-start-1 col-end-13 md:col-start-9 md:col-end-13 md:mt-5'"
        :is="defaultFormComponents.form.select.station.component"
        v-bind="defaultFormComponents.form.select.station.props"
        @updateValue="defaultFormComponents.form.select.station.onUpdateValue" />
      <!-- group of organization -->
      <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'"
        :is="defaultFormComponents.form.checkbox.responsibleAreaCodes.component"
        v-bind="defaultFormComponents.form.checkbox.responsibleAreaCodes.props"
        @updateValue="defaultFormComponents.form.checkbox.responsibleAreaCodes.onUpdateValue" />

      <div class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">
        <component :is="defaultFormComponents.form.toggle.isAgent.component"
          v-bind="defaultFormComponents.form.toggle.isAgent.props"
          @updateValue="defaultFormComponents.form.toggle.isAgent.onUpdateValue" />
        <small>{{ $t("caution.user_1") }}</small>
      </div>
    </div>
    <div class="ttp-between p-4 border-t-2">
      <div class="flex gap-2">
        <component :is="defaultFormComponents.form.button.btnSave.component"
          v-bind="defaultFormComponents.form.button.btnSave.props" />
        <component :is="defaultFormComponents.form.button.btnCancel.component"
          v-bind="defaultFormComponents.form.button.btnCancel.props" />
      </div>
      <div v-if="$route.params.id">
        <component :is="defaultFormComponents.form.button.btnDelete.component"
          v-bind="defaultFormComponents.form.button.btnDelete.props"
          @click="defaultFormComponents.form.button.btnDelete.click" />
      </div>
    </div>
  </form>
  <!-- Popup Delete -->
  <component :is="defaultFormComponents.form.popup.deletePopup.component"
    v-bind="defaultFormComponents.form.popup.deletePopup.props" />
  <!-- Popup Delete Image -->
  <component :is="defaultFormComponents.form.popup.deleteImagePopup.component"
    v-bind="defaultFormComponents.form.popup.deleteImagePopup.props" />
  <!-- Popup Cancel -->
  <component :is="defaultFormComponents.form.popup.cancelPopup.component"
    v-bind="defaultFormComponents.form.popup.cancelPopup.props" />

  <!-- Popup Delete Success & Caution -->
  <component v-if="deleteStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS"
    :is="defaultFormComponents.form.alert.deleteSuccess.component"
    v-bind="defaultFormComponents.form.alert.deleteSuccess.props" />
  <component v-else-if="deleteStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR"
    :is="defaultFormComponents.form.alert.deleteCaution.component"
    v-bind="defaultFormComponents.form.alert.deleteCaution.props" />

  <!-- Popup Delete Image Success & Caution -->
  <component v-if="userFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS"
    :is="defaultFormComponents.form.alert.deleteImageSuccess.component"
    v-bind="defaultFormComponents.form.alert.deleteImageSuccess.props" />
  <component v-else-if="userFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR"
    :is="defaultFormComponents.form.alert.deleteImageCaution.component"
    v-bind="defaultFormComponents.form.alert.deleteImageCaution.props" />

  <!-- Popup Form Success & Caution -->
  <component v-if="userFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS"
    :is="defaultFormComponents.form.alert.formSuccess.component"
    v-bind="defaultFormComponents.form.alert.formSuccess.props" />
  <component v-else-if="userFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR"
    :is="defaultFormComponents.form.alert.formCaution.component"
    v-bind="defaultFormComponents.form.alert.formCaution.props" />
</template>
