<script lang="ts">
import type { AllowedStringTypes } from "@/configs/types/Shared/typeShare";
import { defineComponent, ref, watch, type PropType } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const FormTextareaDefaultComponent = defineComponent({
  name: "FormTextareaEditorComponent",
  components: { QuillEditor },
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      required: false,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number, undefined] as PropType<AllowedStringTypes>,
      required: false,
      default: null,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      options: {
        modules: {
          toolbar: [["bold", "italic", "underline", "strike"], ["link"], [{ list: "ordered" }, { list: "bullet" }], ["blockquote", "align"], ["clean"]],
        },
        placeholder: this.placeholder,
        readOnly: this.isReadonly,
        theme: "snow",
      },
    };
  },
  watch: {
    content: {
      handler(newValue) {
        this.$emit("updateValue", newValue);
      },
      deep: true,
    },
  },
  setup(props) {
    const content = ref(props.value);
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    watch(
      () => props.value,
      () => {
        content.value = props.value;
      }
    );

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      content,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormTextareaDefaultComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label v-if="isLabel" :for="name" class="block mb-1 ttp-text-xs text-gray-900 dark:text-gray-50" :class="isRequired ? 'font-medium' : 'font-normal'">{{ title }} <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span></label>
    <div class="relative rounded-lg" :class="[{ 'border border-ttp-error': isError }]">
      <QuillEditor v-model:content="content" content-type="html" theme="snow" :options="options" />
    </div>
    <small v-if="isError" class="relative text-ttp-text-error">{{ textError }}</small>
  </div>
</template>
