import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues, removeEmptyValues } from "@/configs/helpers/MainHelper";

const storeName = `useDashboardSearchStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  startDate: {
    is: false,
    name: `startDate`,
    label: `form.startDate.label`,
    placeholder: `form.startDate.placeholder`,
    value: null,
  },
  endDate: {
    is: false,
    name: `endDate`,
    label: `form.endDate.label`,
    placeholder: `form.endDate.placeholder`,
    value: new Date(),
  },
  categoryCode: {
    is: false,
    name: `categoryCode`,
    label: `form.story_type.label`,
    placeholder: `form.story_type.placeholder`,
    value: null,
  },
  topicCode: {
    is: false,
    name: `topicCode`,
    label: `form.subject.label`,
    placeholder: `form.subject.placeholder`,
    value: "",
  },
  incidentCode: {
    is: false,
    name: `incidentCode`,
    label: `form.no.label`,
    placeholder: `form.no.placeholder`,
    value: null,
  },
  channel: {
    is: false,
    name: `channel`,
    label: `form.channel.label`,
    placeholder: `form.channel.placeholder`,
    value: null,
  },
};
export const useDashboardSearchStore = defineStore(storeName, {
  state: () => ({
    fetchReport: new fetchReport(),
    loading: true,
    isRequired,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    group: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    downloadTotal: {
      downloadPlayStoreTotal: 0, // แสดงจำนวนผู้ที่ download บน Play Store
      downloadAppStoreTotal: 0, // แสดงจำนวนผู้ที่ download บน App Store
    },
    showTotal: {
      arrivalTotal: 0, // แสดงจำนวนทั้งหมดที่เดินทางเข้ามา (รายวัน)
      hotlineTotal: 0, // แสดงจำนวนการรับแจ้งเหตุผ่านสายด่วน 1155 (รายวัน)
      incidentChatbotTotal: 0, // แสดงจำนวนการรับแจ้งเหตุผ่าน chatbot (Default : รายวัน)
    },
    // รายงานข้อมูลสถิติจำนวนชาวต่างชาติเดินทางเข้าประเทศไทย ที่ลงทะเบียนผ่าน TPB App
    touristNationalityResults: null as any, // สัญชาติ
    touristGenderResults: null as any, // เพศ
    // รายงานข้อมูลสถิติจำนวนการรับแจ้งเหตุผ่านสายด่วน 1155
    barIncidentNationality_Month_1155_Results: null as any, // เพศ
    barIncidentGender_Month_1155_Results: null as any, // เพศ
    incidentNationality_Month_1155_Results: null as any, // สัญชาติ
    incidentGender_Month_1155_Results: null as any, // เพศ
    barIncident_Month_1155_Results: null as any, // สัญชาติ, เพศ
    // รายงานข้อมูลสถิติจำนวนการรับแจ้งเหตุผ่าน chatbot ( 12 เดือนย้อนหลัง รวมเดือนปัจจุบัน )
    barIncident_Month_Application_Results: null as any, // สัญชาติ, เพศ
    barIncident_Day_Application_Results: null as any, // สัญชาติ, เพศ
    incidentNationality_Day_Application_Results: null as any, // สัญชาติ
    incidentGender_Day_Application_Results: null as any, // เพศ
    // รายงานแสดงจำนวนของ incident จำแนกตามประเภทการแจ้งเหตุ
    incidentProvince_Month_Application_Results: null as any, // แยกตามจังหวัด
    incidentStation_Month_Application_Results: null as any, // แยกตามหน่วยงานสถานี
    incidentSubDivision_Month_Application_Results: null as any, // แยกตามกองกำกับการ
    incidentDivision_Month_Application_Results: null as any, // แยกตามกองบังคับการ
    incidentA_Month_Application_Results: null as any, // แยกตามกองบัญชาการ
    // แสดงจำนวนของ incident จำแนกตามประเภทการแจ้งเหต ( รายวัน )
    lineIncidentDay_Application_Results: null as any,
    validationSchema: yup.object({
      startDate: isRequired.startDate.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      endDate: isRequired.endDate.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      categoryCode: isRequired.categoryCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      topicCode: isRequired.topicCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      incidentCode: isRequired.incidentCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      channel: isRequired.channel.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      this.result = removeEmptyValues(this.result, []);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        try {
          this.loading = true;

          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          return true;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
  },
});
