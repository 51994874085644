import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchTourist } from "@/configs/fetches/PoliceCommandCenter/fetchTourist";
import type { TouristSearch } from "@/configs/types/Tourist/typeTourist";
import { ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";

const storeName = `useTouristSearchStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  nationality: {
    is: false,
    name: `nationality`,
    label: `form.nationality.label`,
    placeholder: `form.nationality.placeholder`,
    value: null,
  },
  country: {
    is: false,
    name: `country`,
    label: `form.country.label`,
    placeholder: `form.country.placeholder`,
    value: null,
  },
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: null,
  },
};
export const useTouristSearchStore = defineStore(storeName, {
  state: () => ({
    fetchTourist: new fetchTourist(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    isBackgroundCheck: false,
    loading: true,
    isRequired,
    currentPage: 1,
    totalPage: 1,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    result: convertToValues(isRequired) as TouristSearch,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object({
      nationality: isRequired.nationality.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      country: isRequired.country.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      keyword: isRequired.keyword.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async gotoPage(page: number, event?: MouseEvent) {
      event?.preventDefault();
      try {
        this.loading = true;
        this.currentPage = page;
        this.tableResults = await this.keyword({
          page: this.currentPage,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async nextPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage < this.totalPage) {
        try {
          this.loading = true;
          this.currentPage++;
          this.tableResults = await this.keyword({
            page: this.currentPage,
          });
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
          throw error;
        }
      }
    },
    async prevPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage > 1) {
        try {
          this.loading = true;
          this.currentPage--;
          this.tableResults = await this.keyword({
            page: this.currentPage,
          });
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
          throw error;
        }
      }
    },
    async onUpdateCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;
        this.tableResults = await this.keyword({
          page: this.currentPage,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        try {
          this.loading = true;
          this.currentPage = 1;
          this.tableResults = await this.keyword(this.result);
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async keyword(results: TouristSearch) {
      return await this.fetchTourist.List({
        ...results,
        size: Number(import.meta.env.VITE_PAGE_SIZE),
      });
    },
  },
});
