import { defineStore } from "pinia";
import * as yup from "yup";
import { ENUM_FIELD_LOCALE, ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import type { typeGetNews } from "@/configs/types/News/typeNews";
import { fetchNews } from "@/configs/fetches/PoliceCommandCenter/fetchNews";
import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import dayjs from "dayjs";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

const storeName = `useNewsFormStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  thumbnail: {
    is: true,
    name: `thumbnail`,
    label: `form.thumbnail.label`,
    placeholder: `form.thumbnail.placeholder`,
    value: null,
  },
  images: {
    is: false,
    name: `images`,
    label: `form.images.label`,
    placeholder: `form.images.placeholder`,
    value: null,
  },
  contentAt: {
    is: true,
    name: `contentAt`,
    label: `form.contentAt.label`,
    placeholder: `form.contentAt.placeholder`,
    value: null,
  },
  publishedAt: {
    is: true,
    name: `publishedAt`,
    label: `form.publishedAt.label`,
    placeholder: `form.publishedAt.placeholder`,
    value: null,
  },
  published: {
    is: true,
    name: `published`,
    label: `form.published.label`,
    placeholder: `form.published.placeholder`,
    value: false,
  },
  isInternal: {
    is: true,
    name: `isInternal`,
    label: `form.isInternal.label`,
    placeholder: `form.isInternal.placeholder`,
    value: false,
  },
  translations: [
    {
      locale: {
        is: true,
        name: `content[0].locale`,
        label: `form.locales.label`,
        placeholder: `form.locales.placeholder`,
        value: `TH`,
      },
      title: {
        is: true,
        name: `content[0].title`,
        label: `form.title_news.label`,
        placeholder: `form.title_news.placeholder`,
        value: null,
      },
      description: {
        is: true,
        name: `content[0].description`,
        label: `form.description.label`,
        placeholder: `form.description.placeholder`,
        value: null,
      },
      detail: {
        is: true,
        name: `content[0].detail`,
        label: `form.detail.label`,
        placeholder: `form.detail.placeholder`,
        value: null,
      },
    },
    {
      locale: {
        is: true,
        name: `content[1].locale`,
        label: `form.locales.label`,
        placeholder: `form.locales.placeholder`,
        value: `EN`,
      },
      title: {
        is: true,
        name: `content[1].title`,
        label: `form.title_news.label`,
        placeholder: `form.title_news.placeholder`,
        value: null,
      },
      description: {
        is: true,
        name: `content[1].description`,
        label: `form.description.label`,
        placeholder: `form.description.placeholder`,
        value: null,
      },
      detail: {
        is: true,
        name: `content[1].detail`,
        label: `form.detail.label`,
        placeholder: `form.detail.placeholder`,
        value: null,
      },
    },
    {
      locale: {
        is: false,
        name: `content[2].locale`,
        label: `form.locales.label`,
        placeholder: `form.locales.placeholder`,
        value: `CN`,
      },
      title: {
        is: false,
        name: `content[2].title`,
        label: `form.title_news.label`,
        placeholder: `form.title_news.placeholder`,
        value: null,
      },
      description: {
        is: false,
        name: `content[2].description`,
        label: `form.description.label`,
        placeholder: `form.description.placeholder`,
        value: null,
      },
      detail: {
        is: false,
        name: `content[2].detail`,
        label: `form.detail.label`,
        placeholder: `form.detail.placeholder`,
        value: null,
      },
    },
    {
      locale: {
        is: false,
        name: `content[3].locale`,
        label: `form.locales.label`,
        placeholder: `form.locales.placeholder`,
        value: `JP`,
      },
      title: {
        is: false,
        name: `content[3].title`,
        label: `form.title_news.label`,
        placeholder: `form.title_news.placeholder`,
        value: null,
      },
      description: {
        is: false,
        name: `content[3].description`,
        label: `form.description.label`,
        placeholder: `form.description.placeholder`,
        value: null,
      },
      detail: {
        is: false,
        name: `content[3].detail`,
        label: `form.detail.label`,
        placeholder: `form.detail.placeholder`,
        value: null,
      },
    },
    {
      locale: {
        is: false,
        name: `content[4].locale`,
        label: `form.locales.label`,
        placeholder: `form.locales.placeholder`,
        value: `KR`,
      },
      title: {
        is: false,
        name: `content[4].title`,
        label: `form.title_news.label`,
        placeholder: `form.title_news.placeholder`,
        value: null,
      },
      description: {
        is: false,
        name: `content[4].description`,
        label: `form.description.label`,
        placeholder: `form.description.placeholder`,
        value: null,
      },
      detail: {
        is: false,
        name: `content[4].detail`,
        label: `form.detail.label`,
        placeholder: `form.detail.placeholder`,
        value: null,
      },
    },
    {
      locale: {
        is: false,
        name: `content[5].locale`,
        label: `form.locales.label`,
        placeholder: `form.locales.placeholder`,
        value: `RU`,
      },
      title: {
        is: false,
        name: `content[5].title`,
        label: `form.title_news.label`,
        placeholder: `form.title_news.placeholder`,
        value: null,
      },
      description: {
        is: false,
        name: `content[5].description`,
        label: `form.description.label`,
        placeholder: `form.description.placeholder`,
        value: null,
      },
      detail: {
        is: false,
        name: `content[5].detail`,
        label: `form.detail.label`,
        placeholder: `form.detail.placeholder`,
        value: null,
      },
    },
  ],
};
export const useNewsFormStore = defineStore(storeName, {
  state: () => ({
    fetchNews: new fetchNews(),
    fetchFile: new fetchFile(),
    results: [] as any,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: true,
    isRequired,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    result: convertToValues(isRequired) as typeGetNews,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object({
      thumbnail: isRequired.thumbnail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      published: isRequired.published.is ? yup.boolean().nullable().required() : yup.boolean().nullable().notRequired(),
      isInternal: isRequired.isInternal.is ? yup.boolean().nullable().required() : yup.boolean().nullable().notRequired(),
      contentAt: isRequired.contentAt.is
        ? yup
            .string()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === "" ? dayjs().tz("Asia/Bangkok").toISOString() : dayjs(value).tz("Asia/Bangkok").toISOString();
            })
            .required()
        : yup
            .string()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === "" ? dayjs().tz("Asia/Bangkok").toISOString() : dayjs(value).tz("Asia/Bangkok").toISOString();
            })
            .notRequired(),
      publishedAt: isRequired.publishedAt.is
        ? yup
            .string()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === "" ? dayjs().tz("Asia/Bangkok").toISOString() : dayjs(value).tz("Asia/Bangkok").toISOString();
            })
            .required()
        : yup
            .string()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === "" ? dayjs().tz("Asia/Bangkok").toISOString() : dayjs(value).tz("Asia/Bangkok").toISOString();
            })
            .notRequired(),
      translations: yup.array().of(
        yup.lazy((value) => {
          if (value && value.locale === ENUM_FIELD_LOCALE.TH) {
            return yup.object().shape({
              locale: isRequired.translations[0].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              title: isRequired.translations[0].title.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              description: isRequired.translations[0].description.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              detail: isRequired.translations[0].detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          } else if (value && value.locale === ENUM_FIELD_LOCALE.EN) {
            return yup.object().shape({
              locale: isRequired.translations[1].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              title: isRequired.translations[1].title.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              description: isRequired.translations[1].description.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              detail: isRequired.translations[1].detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          } else if (value && value.locale === ENUM_FIELD_LOCALE.CN) {
            return yup.object().shape({
              locale: isRequired.translations[2].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              title: isRequired.translations[2].title.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              description: isRequired.translations[2].description.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              detail: isRequired.translations[2].detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          } else if (value && value.locale === ENUM_FIELD_LOCALE.JP) {
            return yup.object().shape({
              locale: isRequired.translations[3].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              title: isRequired.translations[3].title.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              description: isRequired.translations[3].description.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              detail: isRequired.translations[3].detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          } else if (value && value.locale === ENUM_FIELD_LOCALE.KR) {
            return yup.object().shape({
              locale: isRequired.translations[4].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              title: isRequired.translations[4].title.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              description: isRequired.translations[4].description.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              detail: isRequired.translations[4].detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          } else if (value && value.locale === ENUM_FIELD_LOCALE.RU) {
            return yup.object().shape({
              locale: isRequired.translations[5].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              title: isRequired.translations[5].title.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              description: isRequired.translations[5].description.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              detail: isRequired.translations[5].detail.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          }
          return yup.object();
        })
      ),
    }),
  }),
  actions: {
    async onDeleteImageSubmit(e: Event) {
      if (e) e.preventDefault();
      this.result.thumbnail = "";
      return await this.update(this.result);
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();
      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
        if (!this.result.id?.length) {
          return await this.create(this.result);
        } else {
          return await this.update(this.result);
        }
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async create(result: typeGetNews) {
      try {
        this.loading = true;
        const data = await this.fetchNews.Create(result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
    async update(result: typeGetNews): Promise<typeGetNews> {
      try {
        this.loading = true;
        const data = await this.fetchNews.Update(result.id || "", result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
