<template>
  <div class="pt-16 md:pt-24 dark:text-gray-100" :class="{ 'pb-16': actionText.length }">
    <div class="ttp-layout container mx-auto px-4 lg:px-8 xl:max-w-7xl">
      <div class="flex items-center justify-between py-4">
        <div class="grow">
          <button class="text-black text-left" @click="router.go(-1)">
            <ChevronLeftIcon class="flex-none inline-block ttp-icon-inside-box-02" /><span class="ml-2 ttp-text-xs">{{ $t("btn.btn_back") }}</span>
          </button>
        </div>
        <button class="inline-flex items-center justify-center gap-2 rounded-lg border border-gray-200 bg-white px-2 py-1 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700" @click="() => fetch()">
          <ArrowPathIcon class="flex-none inline-block ttp-icon-inside-box-02" />
        </button>
      </div>
      <section class="header border-b mb-4">
        <div class="grid grid-cols-1 md:grid-cols-2 items-center py-4">
          <div class="text-gray-600 mb-2">
            <p>
              <IdentificationIcon class="flex-none font-medium inline-block ttp-icon-inside-box-03" /> <span class="font-medium">{{ $t("page.IncidentManageView.document_number") }}</span>
              <span class="text-gray-500 ml-1">{{ incident?.incidentNumber }}</span>
            </p>
            <p>
              <ClockIcon class="flex-none font-medium inline-block ttp-icon-inside-box-03" /> <span class="font-medium">{{ $t("page.IncidentManageView.receive_date") }}</span>
              <span class="text-gray-500 ml-1">{{ incident?.createdAt ? formatDate(incident?.createdAt, "DD/MM/YYYY HH:mm") : "-" }}</span>
            </p>
            <p>
              <ClockIcon class="flex-none font-medium inline-block ttp-icon-inside-box-03" /> <span class="font-medium">{{ $t("form.updated_date.label") }}</span>
              <span class="text-gray-500 ml-1">{{ incident?.updatedAt ? formatDate(incident?.updatedAt, "DD/MM/YYYY HH:mm") : "-" }}</span>
            </p>
          </div>
          <div class="mb-2">
            <ButtonDefaultComponent :component-icon="DocumentMagnifyingGlassIcon" :class-enum-name="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.SMALL" :is-disable="loading" class-tag-name="w-full" class-icon="ttp-icon-inside-box-03 inline-block" title="ประวัติการจัดการ" is-visible is-icon @click="openProgressTimeline = true" />
          </div>
        </div>
      </section>

      <section class="informer border-b mb-4">
        <h2 class="font-medium text-gray-900 dark:text-gray-50">ข้อมูลผู้แจ้ง</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 items-center py-4 text-gray-600">
          <template v-if="informer">
            <p class="mb-2">
              <span class="font-medium">ชื่อ:</span>
              <span class="text-gray-500 ml-1">{{ informer?.name }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">สัญชาติ:</span>
              <span class="text-gray-500 ml-1">{{ informerNationality }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">ประเทศ:</span>
              <span class="text-gray-500 ml-1">{{ informerCountry }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">เบอร์โทรศัพท์:</span>
              <span class="text-gray-500 ml-1">{{ informer?.contactNumber }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">อีเมล:</span>
              <span class="text-gray-500 ml-1">{{ informer?.email }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">เพศ:</span>
              <span class="text-gray-500 ml-1">{{ informer?.gender ? masterDataStore.getGenderName(informer?.gender) : "-" }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">เลขที่พาสปอร์ต:</span>
              <span class="text-gray-500 ml-1">{{ informer?.identificationNumber }}</span>
            </p>
            <p class="mb-2">
              <span class="font-medium">ช่องทางการรับแจ้ง:</span>
              <span class="text-gray-500 ml-1">{{ incident?.channel === "1155" ? $t("role.CENTRAL_OFFICER") : incident?.channel }}</span>
            </p>
          </template>
          <p class="mb-2" v-else>
            <span>{{ $t("layout.anonymous") }}</span>
          </p>
        </div>
      </section>

      <section class="information border-b mb-4">
        <h2 class="font-medium text-gray-900 dark:text-gray-50">รายละเอียดเหตุ</h2>
        <div class="py-4 text-gray-600">
          <div class="mb-2">
            <span class="font-medium">รูปภาพ:</span>
            <div class="swipe-photo flex gap-2 w-full">
              <template v-for="(s, index) in incident?.images">
                <a v-if="image[s]?.length" :key="index" :href="image[s]" target="_blank">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="image[s]" />
                </a>
              </template>
            </div>
          </div>
          <p class="mb-2">
            <span class="font-medium">รายละเอียดเหตุ (จากนักท่องเที่ยว):</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.detail }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">รายละเอียดเหตุ (สำหรับเจ้าหน้าที่):</span>
            <span class="text-gray-500 ml-1">{{ $findStatusDetail(incident?.progress || [], ENUM_INCIDENT_STATUS.WAITING) }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">รหัส:</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.incidentCode }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">ประเภทเรื่อง:</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.category?.content?.[0]?.title || "-" }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">หัวเรื่อง:</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.topic?.content?.[0]?.title || "-" }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">เขตพื้นที่จังหวัด:</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.province?.name?.TH || "-" }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">อำเภอ:</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.district?.name?.TH || "-" }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">รายละเอียดสถานที่:</span>
            <span class="text-gray-500 ml-1">{{ incident?.information?.location || "-" }}</span>
          </p>
          <div class="mb-2">
            <div class="flex justify-between">
              <button class="font-medium" @click="showMap">
                หมุดสถานที่:
                <span>
                  <ChevronUpIcon v-if="mapShown" class="inline-block ttp-icon-inside-box-03" />
                  <ChevronDownIcon v-else class="inline-block ttp-icon-inside-box-03" />
                </span>
              </button>
              <a :href="googleMapURL || '#'" target="_blank" class="inline-flex rounded bg-blue-200 px-2 py-1 ttp-text-xs font-semibold leading-4 text-blue-700">
                {{ $t("btn.btn_google_map") }}
              </a>
            </div>
            <GoogleMap v-show="mapShown" :api-key="googleMapAPIKey" style="height: 500px" class="-mx-4 w-screen mt-4" :center="center" :zoom="15">
              <Marker :options="{ position: center }" />
            </GoogleMap>
          </div>
        </div>
      </section>

      <section class="responsible border-b mb-4">
        <h2 class="font-medium text-gray-900 dark:text-gray-50">ผู้ดำเนินการ</h2>
        <div class="py-4 text-gray-600">
          <div class="flex gap-4 justify-start mb-2">
            <div class="swipe-photo">
              <UserIcon v-if="!incident?.responsible?.image || !image[incident?.responsible?.image]" class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500" />
              <a v-else :href="image[incident?.responsible?.image]" target="_blank" class="swipe-photo inline-flex ttp-icon-box-01 rounded-full bg-gray-100 text-gray-300 overflow-hidden">
                <img :src="image[incident?.responsible?.image]" class="w-full" />
              </a>
            </div>
            <div>
              <p class="h-4 text-gray-800 font-normal leading-tight mb-2">
                {{ incident?.responsible?.content[0]?.fullName || "-" }}
              </p>
              <p class="text-gray-500 ttp-text-xs font-normal">
                {{ incident?.responsible?.email || "-" }}
              </p>
            </div>
          </div>
          <p class="mb-2">
            <span class="font-medium">ผู้มอบหมาย:</span>
            <span class="text-gray-500 ml-1">{{ incident?.progress?.[0]?.createdBy?.content[0].shortName || "-" }}</span>
          </p>
          <p class="mb-2">
            <span class="font-medium">สถานะ:</span>
            <span class="text-gray-500 ml-1">
              <BadgeDefaultComponent :title="$formattedStatus($classStatus(incident?.status))" :classEnumName="$classStatus(incident?.status)" :size="ENUM_SIZE.NORMAL" is-visible />
            </span>
          </p>
        </div>
      </section>

      <section v-if="showReport" class="report mb-4">
        <h2 class="font-medium text-gray-900 dark:text-gray-50">รายงาน</h2>
        <div class="flex flex-col w-full mt-4 text-gray-900 dark:text-gray-50">
          <textarea v-if="editReport" v-model="report" class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          <div v-else class="mb-4 text-gray-900 bg-gray-200 -mx-4 p-4">{{ report }}</div>
        </div>
      </section>
    </div>

    <footer v-if="actionText.length" class="fixed bottom-0 w-full h-16 bg-white">
      <div class="container px-4 py-2 border-t">
        <ButtonDefaultComponent :class-enum-name="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.LARGE" :title="actionText" class-tag-name="w-full" is-visible :is-disable="loading" @click="handleActionButton" />
      </div>
    </footer>

    <Timeline :is-open="openProgressTimeline" :incident-number="incident?.incidentNumber || ''" :progress="incident?.progress" @close="openProgressTimeline = false" />
  </div>
</template>

<script setup lang="ts">
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { ENUM_COLOR, ENUM_INCIDENT_STATUS, ENUM_INFORMER_TYPE, ENUM_SIZE } from "@/configs/enums/enum";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchFile";
import { useIncidentStore } from "@/stores/PoliceTouristAndAgent/useIncidentStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { ArrowPathIcon, ChevronDownIcon, ChevronLeftIcon, ChevronUpIcon, DocumentMagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { ClockIcon, IdentificationIcon, UserIcon } from "@heroicons/vue/24/solid";
import dayjs from "dayjs";
import { computed, onMounted, ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import { configLightbox } from "@/configs/helpers/MainHelper";
import Timeline from "./Timeline.vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { stripHtml } from "string-strip-html";

const googleMapAPIKey = import.meta.env.VITE_GOOGLE_APIKEY;

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const incidentStore = useIncidentStore();
const masterDataStore = useMasterDataStore();
const file = new fetchFile();

const image = ref<{ [key: string]: string }>({});
const mapShown = ref<boolean>(false);
const openProgressTimeline = ref<boolean>(false);
const loading = ref<boolean>(false);

const incident = computed(() => incidentStore.incident);
const latestProgress = computed(() => (incident.value?.progress?.length ? incident.value?.progress[0] : null));
const toArraivedStatus = computed<boolean>((): boolean => {
  return Boolean(incident.value?.status === ENUM_INCIDENT_STATUS.IN_PROGRESS && latestProgress.value?.metadata?.["eta"]?.length);
});

const informer = computed(() => {
  switch (incident.value?.informerType) {
    case ENUM_INFORMER_TYPE.GUEST:
      return incident.value?.guest;
    case ENUM_INFORMER_TYPE.TOURIST:
      return incident.value?.tourist;
    default:
      return null;
  }
});

const informerNationality = computed(() => {
  if (!informer.value) {
    return null;
  }

  return masterDataStore.nationalities.find(({ code }) => code === informer.value?.nationality)?.content?.[0]?.name;
});

const informerCountry = computed(() => {
  if (!informer.value) {
    return null;
  }

  return masterDataStore.countries.find(({ code }) => code === informer.value?.nationality)?.content?.[0]?.name;
});

const googleMapURL = computed(() => (incident.value?.information?.latitude && incident.value?.information?.latitude ? `https://maps.google.com/?q=${incident.value?.information?.latitude},${incident.value?.information?.longitude}` : null));

const showReport = computed(() => incident.value?.status && [ENUM_INCIDENT_STATUS.COMPLETED, ENUM_INCIDENT_STATUS.IN_REVIEW, ENUM_INCIDENT_STATUS.REPORTED, ENUM_INCIDENT_STATUS.CLOSED].includes(incident.value.status));
const editReport = computed(() => incident.value?.status && [ENUM_INCIDENT_STATUS.COMPLETED].includes(incident.value.status));

const report = ref<string>();

const center = computed(() => {
  if (!incident.value?.information?.latitude || !incident.value?.information?.longitude) {
    return { lat: 13.736717, lng: 100.523186 };
  }

  return {
    lat: Number(incident.value?.information.latitude),
    lng: Number(incident.value?.information.longitude),
  };
});

const actionText = computed<string>((): string => {
  switch (incident.value?.status) {
    case ENUM_INCIDENT_STATUS.ACCEPTED:
    case ENUM_INCIDENT_STATUS.WAITING:
      return "รับเรื่อง";
    case ENUM_INCIDENT_STATUS.IN_PROGRESS:
      if (toArraivedStatus.value) {
        return "ถึงที่หมายเข้าระงับเหตุ";
      }

      return "ระงับเหตุสำเร็จ";
    case ENUM_INCIDENT_STATUS.COMPLETED:
      return "ส่งรายงาน";
    default:
      return "";
  }
});

const formatDate = (date?: string, format?: string): string => {
  if (!date) {
    return "-";
  }

  return dayjs(date).format(format);
};

const showMap = () => (mapShown.value = !mapShown.value);

const loadImages = async () => {
  const images = [...incident.value?.images, incident.value?.responsible?.image].filter((i) => i?.length);

  image.value = await images.reduce(async (carry, s) => {
    const url = await file.GetUrl(s);
    return {
      ...(await carry),
      [s]: url,
    };
  }, Promise.resolve({}) as Promise<{ [key: string]: string }>);

  configLightbox();
};

const handleActionButton = async () => {
  const { isConfirmed } = await Swal.fire({
    title: "ยืนยันการทำรายการ",
    input: incident.value?.status === ENUM_INCIDENT_STATUS.IN_PROGRESS && !toArraivedStatus.value ? "textarea" : undefined,
    showCancelButton: true,
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
    customClass: {
      confirmButton: "bg-ttp-primary w-full",
      cancelButton: "mt-4 w-full",
    },
    preConfirm: async (detail?: string) => {
      let nextStatus: ENUM_INCIDENT_STATUS;
      const metadata: { [key: string]: string } = {};

      if (typeof detail !== "string") {
        detail = undefined;
      }

      switch (incident.value?.status) {
        case ENUM_INCIDENT_STATUS.ACCEPTED:
        case ENUM_INCIDENT_STATUS.WAITING:
          nextStatus = ENUM_INCIDENT_STATUS.IN_PROGRESS;
          metadata.eta = dayjs().add(1, "hour").toISOString();
          break;
        case ENUM_INCIDENT_STATUS.IN_PROGRESS:
          if (toArraivedStatus.value) {
            nextStatus = ENUM_INCIDENT_STATUS.IN_PROGRESS;
            metadata.arrived = dayjs().toISOString();
          } else {
            nextStatus = ENUM_INCIDENT_STATUS.COMPLETED;
          }
          break;
        case ENUM_INCIDENT_STATUS.COMPLETED:
          if (!report.value?.length || !stripHtml(report.value).result.trim().length) {
            Swal.fire({
              title: "ไม่สามารถบันทึกข้อมูลได้",
              text: "กรุณากรอกรายงาน และบันทึกอีกครั้ง",
              icon: "error",
            });

            return false;
          }
          nextStatus = ENUM_INCIDENT_STATUS.IN_REVIEW;
          break;
        default:
          return false;
      }

      try {
        await incidentStore.updateProgress(incident.value.id as string, nextStatus, nextStatus === ENUM_INCIDENT_STATUS.IN_REVIEW ? report.value : detail, authStore?.user?.id ? (authStore?.user?.id as string) : undefined, metadata);

        await incidentStore.findIncident(route.params.id as string);
        return true;
      } catch (e) {
        Swal.fire({
          title: "ไม่สามารถบันทึกข้อมูลได้",
          text: "กรุณาลองอีกครั้ง",
          icon: "error",
        });

        return false;
      }
    },
  });

  if (isConfirmed) {
    Swal.fire({
      title: "บันทึกข้อมูลสำเร็จ",
      icon: "success",
    });
  }
};

const fetch = async () => {
  try {
    loading.value = true;
    await incidentStore.findIncident(route.params.id as string);
    const reports = incident.value?.progress?.filter(({ status, detail }) => status === ENUM_INCIDENT_STATUS.IN_REVIEW && detail?.trim()?.length).sort((a, b) => (dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1));
    if (reports?.length) {
      report.value = reports[0].detail;
    }
    await loadImages();
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  masterDataStore.listNationality();
  masterDataStore.listCountry();
  fetch();
});
</script>
