import { defineStore } from "pinia";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues } from "@/configs/helpers/MainHelper";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";
import { fetchNews } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchNews";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { usePopularPlaceStore } from "@/stores/PoliceTouristAndAgent/usePopularPlaceStore";

const storeName = `usePopularPlaceSearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

export const WORLD_CIRCUMFERENCE = 40075 * 1000;

const isRequired = {
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: "",
  },
};
export const usePopularPlaceSearchStore = defineStore(storeName, {
  state: () => ({
    fetchNews: new fetchNews(),
    fetchFile: new fetchFile(),
    sharedStore: useSharedStore(),
    popularPlaceStore: usePopularPlaceStore(),
    popularPlace: null as any,
    position: undefined as any,
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    loadingList: false,
    loadingScroll: false,
    loadingSearch: false,
    isRequired,
    currentPage: 0,
    totalPage: 1,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    tableListResults: null as any,
    tableScrollResults: null as any,
    tableSearchResults: null as any,
    getResult: null as any,
    NewsRole: "",
    listType: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async onUpdateCurrentPage() {
      const delay = (d: number) => new Promise((resolve) => setTimeout(resolve, d));

      const recursivePopularPlace = async (distance: number) => {
        this.loadingList = true;
        let d = 0;
        try {
          this.tableResults = await this.popularPlaceStore.list(
            {
              latitude: this.sharedStore?.position?.coords?.latitude,
              longitude: this.sharedStore?.position?.coords?.longitude,
              distance,
              page: 1,
              size: 10,
            },
            this.sharedStore?.locale?.toUpperCase()
          );
          distance = distance * 2;
          this.loadingList = false;
        } catch (e) {
          console.error("list popular place failed", e);
          d = 5 * 1000; // delay 5 seconds
          this.loadingList = false;
        } finally {
          await delay(d);
          if (!this.tableResults?.total && distance < WORLD_CIRCUMFERENCE) {
            await recursivePopularPlace(distance * 2);
            this.loadingList = false;
          }
        }
      };

      await recursivePopularPlace(5000);
    },
    async onUpdateListCurrentPage(currentPage: number) {
      this.loadingScroll = true;
      this.currentPage = currentPage;

      const results = await this.popularPlaceStore.list(
        {
          latitude: this.sharedStore?.position?.coords?.latitude,
          longitude: this.sharedStore?.position?.coords?.longitude,
          distance: 40000 * 100,
          page: this.currentPage,
          size: Number(import.meta.env.VITE_PAGE_SIZE),
        },
        this.sharedStore?.locale?.toUpperCase()
      );

      if (!results.items.length) this.loadingScroll = false;

      return results;
    },
    async onUpdateSearchCurrentPage() {
      this.loadingSearch = true;
      this.tableSearchResults = null;

      this.tableSearchResults = await this.popularPlaceStore.list(
        {
          latitude: this.sharedStore?.position?.coords?.latitude,
          longitude: this.sharedStore?.position?.coords?.longitude,
          distance: 40000 * 100,
          keyword: String(this.result?.keyword),
        },
        this.sharedStore?.locale?.toUpperCase()
      );
      this.loadingSearch = false;
    },
  },
});
