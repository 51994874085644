<script setup lang="ts">
import { fetchReport, type TouristReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { computed, onMounted, ref } from "vue";
import { Bar } from "vue-chartjs";
import { Chart, Colors, type ChartOptions } from "chart.js";

Chart.register(Colors);

dayjs.extend(relativeTime);

type DataSet = { label: string; data: number[]; backgroundColor?: any };

const { from, to, group, aspectRatio } = defineProps<{
  from: Dayjs;
  to: Dayjs;
  group: "nationality" | "gender";
  aspectRatio?: number;
}>();

const fetch = new fetchReport();
const report = ref<{ [key: string]: TouristReport }>({});
const lastUpdate = ref<Dayjs>();

const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  aspectRatio,
  scales: {
    x: { stacked: true },
    y: { stacked: true },
  },
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const reload = async () => {
  report.value = await fetch.TouristDailyReport(
    {
      startDate: from.format("YYYY-MM-DD"),
      endDate: to.format("YYYY-MM-DD"),
    },
    group
  );

  lastUpdate.value = dayjs();
};

const chartData = computed(() => {
  const dates = Object.keys(report.value)
    .map((d) => dayjs(d).startOf("day"))
    .sort((a, b) => a.diff(b));
  const datasets = Object.keys(report.value).reduce<DataSet[]>((x, d) => {
    return Object.keys(report.value[d]).reduce<DataSet[]>((y, n) => {
      let data = y.find((i) => i.label === n);
      if (!data) {
        data = { label: n, data: new Array(Object.keys(report.value).length).fill(0) };
        y.push(data);
      }

      const index = Object.keys(report.value).findIndex((i) => i === d);
      data.data[index] = report.value[d][n];

      return y;
    }, x);
  }, [] as DataSet[]);

  return {
    labels: dates.map((d) => d.format("D MMM YYYY")),
    datasets,
  };
});

onMounted(reload);
defineExpose({ reload });
</script>

<template>
  <!-- Card Headings: Title with Subtitle -->
  <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100 h-full">
    <!-- Card Header -->
    <div class="card-title bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
      <h3 class="mb-1 font-semibold">
        <slot name="title">จำนวนผู้ใช้งานใหม่</slot>
      </h3>
    </div>
    <!-- END Card Header -->

    <!-- Card Body -->
    <div class="chart-wrap grow p-5">
      <Bar v-if="chartData.datasets.length" :data="chartData" :options="chartOptions" />
    </div>
    <!-- Card Body -->
  </div>
  <!-- END Card Headings: Title with Subtitle -->
</template>

<style scoped>
.chart-wrap {
  min-height: 0;
}
</style>
