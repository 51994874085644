import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetGuest } from "@/configs/types/Guest/typeGuest";

export class fetchGuest {
  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get("/v1/guests", {
      params,
    });
    return data;
  }
  async Get(id: string): Promise<typeGetGuest> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetGuest>(`/v1/guests/${id}`);
    return data;
  }
  async ListIncident(id: string, params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get(`/v1/guests/${id}/incidents`, {
      params,
    });
    return data;
  }
}
