import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import { Capacitor } from "@capacitor/core";
import { NativeAudio } from "@capacitor-community/native-audio";
import { useAudioStore } from "@/stores/Shared/useAudioStore";
import { useAuthStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { PushNotifications, type ActionPerformed, type PushNotificationSchema, type RegistrationError, type Token } from "@capacitor/push-notifications";

export const storeName = `useSharedStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;
export const useSharedStore = defineStore(storeName, {
  state: () => ({
    audioStore: useAudioStore(),
    authStore: useAuthStore(),
    mode: "true" as string,
    mobileSidebarOpen: false,
    desktopSidebarOpen: true,
    submenuOpen: "Dashboard" as string,
    count: 0,
    getProjectType: ENUM_APP_CATEGORY_NAME.TOURIST as any,
    locale: "EN" as string,
    isAlert: false,
    position: null as any,
    alertResult: null as any,
    is_track_location: `${storeName}:is_track_location`,
    is_microphone: `${storeName}:is_microphone`,
    is_push_notification: `${storeName}:is_push_notification`,
    is_camera: `${storeName}:is_camera`,
    is_photo: `${storeName}:is_photo`,
    isShowPermission: false,
    isLoadingPermission: false,
    permissionResults: [] as string[],
    onCall: false,
  }),
  actions: {
    appVersion() {
      return import.meta.env.VITE_APP_VERSION;
    },
    displayName() {
      return import.meta.env.VITE_DISPLAY_NAME;
    },
    async setSubmenuOpen(name: any) {
      this.submenuOpen = name;
      await setLocalStorage(`${storeName}:submenuOpen`, name);
    },
    async toggleMode() {
      this.mode = this.mode == "true" ? "false" : "true";
      await setLocalStorage(`${storeName}:mode`, this.mode);
    },
    increment() {
      this.count++;
    },
    decrement() {
      this.count--;
    },
    initNotificationHandler() {
      Promise.all([
        PushNotifications.addListener('registration', this.onPushNotificationRegistration),
        PushNotifications.addListener('registrationError', this.onPushNotificationRegistrationError),
        PushNotifications.addListener('pushNotificationReceived', this.onPushNotificationReceived),
        PushNotifications.addListener('pushNotificationActionPerformed', this.onPushNotificationActionPerformed),
      ]).then(([
        registrationResponse,
        registrationErrorResponse,
        pushNotificationReceivedResponse,
        pushNotificationActionPerformedResponse,
      ]) => {
        console.log({
          registrationResponse,
          registrationErrorResponse,
          pushNotificationReceivedResponse,
          pushNotificationActionPerformedResponse,
        })
      })
    },
    async registerNotification() {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        await setLocalStorage(this.is_push_notification, "denied");
        if (!this.permissionResults.includes('Notification')) {
          this.permissionResults.push('Notification')
        }
      } else {
        await setLocalStorage(this.is_push_notification, "granted");
        this.permissionResults = this.permissionResults.filter((p) => p !== 'Notification')
      }

      await PushNotifications.register();
    },
    onPushNotificationRegistration(token: Token) {
      if (!this.authStore.user?.devices?.find((d) => d.deviceToken === token.value)) {
        this.authStore.addToken(token.value)
      }
    },
    onPushNotificationRegistrationError(err: RegistrationError) {
      alert(JSON.stringify({ err }))
    },
    onPushNotificationReceived(notification: PushNotificationSchema) {
      alert(JSON.stringify({ notification }))
    },
    onPushNotificationActionPerformed(notification: ActionPerformed) {
      alert(JSON.stringify({ notification }))
    },
    async startCall() {
      this.onCall = true;
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.loop({
          assetId: "call",
        });
      } else {
        await this.audioStore.playCall();
      }
    },
    async waitingCall() {
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.play({
          assetId: "waiting",
        });
      } else {
        await this.audioStore.playWaiting();
      }
    },
    async endCall() {
      this.onCall = false;
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.stop({
          assetId: "waiting",
        });
        await NativeAudio.stop({
          assetId: "call",
        });
        await NativeAudio.play({
          assetId: "hang_up",
        });
      } else {
        await this.audioStore.stopCall();
        await this.audioStore.stopWaiting();
        await this.audioStore.playHangUp();
      }
    },
    async stopCall() {
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.stop({
          assetId: "call",
        });
      } else {
        await this.audioStore.stopCall();
      }
    },
    async stopWaitingCall() {
      if (!Capacitor.isNativePlatform()) {
        await NativeAudio.stop({
          assetId: "waiting",
        });
      } else {
        await this.audioStore.stopWaiting();
      }
    },
  },
});
