<script lang="ts">
import { defineComponent } from "vue";

const PaginationComponent = defineComponent({
  name: "PaginationComponent",
  components: {},
  props: {
    query: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    totalPage: {
      type: Number,
      required: false,
      default: 1,
    },
    gotoPage: {
      type: Function,
      required: false,
      default: () => {},
    },
    nextPage: {
      type: Function,
      required: false,
      default: () => {},
    },
    prevPage: {
      type: Function,
      required: false,
      default: () => {},
    },
    onUpdateCurrentPage: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {};
  },
  setup() {},
  methods: {
    async _gotoPage(page: number, e?: MouseEvent) {
      if (e) e.preventDefault();
      if (typeof this.gotoPage === "function") {
        this.gotoPage(page, e);
      }
    },
    async _nextPage(e: any) {
      if (e) e.preventDefault();
      if (typeof this.nextPage === "function") {
        this.nextPage(e);
      }
    },
    async _prevPage(e: any) {
      if (e) e.preventDefault();
      if (typeof this.prevPage === "function") {
        this.prevPage(e);
      }
    },
  },
  computed: {
    pagesToShow() {
      const pageCount = 5;
      const startPage = Math.max(1, this.currentPage - pageCount);
      const endPage = Math.min(this.totalPage, this.currentPage + pageCount);
      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
});
export default PaginationComponent;
</script>

<template>
  <div class="ttp-pagination">
    <div>
      <div>
        <nav>
          <div class="flex justify-start items-center mr-2 w-full">
            <div v-if="totalPage" class="flex gap-2">
              <span>Page</span>
              <span class="font-normal">{{ currentPage }}</span>
              <span>of</span>
              <span class="font-normal">{{ totalPage }}</span>
            </div>
          </div>
          <div class="flex justify-end items-center w-full">
            <RouterLink v-if="currentPage > 1" class="nav-left" :to="{ query: { ...query, page: currentPage - 1 } }">
              <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03" />
            </RouterLink>
            <a v-else class="nav-left" href="#">
              <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03" />
            </a>
            <RouterLink v-if="currentPage < totalPage" class="nav-right" :to="{ query: { ...query, page: currentPage + 1 } }">
              <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" />
            </RouterLink>
            <a v-else class="nav-right" href="#">
              <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" />
            </a>
          </div>
        </nav>
        <div class="flex">
          <div v-if="totalPage" class="flex gap-2">
            <span>Page</span>
            <span class="font-normal">{{ currentPage }}</span>
            <span>of</span>
            <span class="font-normal">{{ totalPage }}</span>
          </div>
          <nav>
            <RouterLink v-if="currentPage > 1" class="nav-left" :to="{ query: { ...query, page: currentPage - 1 } }">
              <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03" />
            </RouterLink>
            <a v-else class="nav-left" href="#">
              <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03" />
            </a>
            <RouterLink
              v-if="currentPage < 1"
              :to="{ query: { ...query, page: 1 } }"
              :class="{
                'nav-a': currentPage !== 1,
                'nav-a-active': currentPage === 1,
              }"
            >
              1
            </RouterLink>
            <div v-if="currentPage > 4" class="nav-dot">...</div>
            <RouterLink
              v-for="n in pagesToShow"
              :key="n"
              :to="{ query: { ...query, page: n } }"
              :class="{
                'nav-a': currentPage !== n,
                'nav-a-active': currentPage === n,
              }"
            >
              {{ n }}
            </RouterLink>
            <div class="nav-dot" v-if="currentPage < totalPage - 7">...</div>
            <RouterLink v-if="currentPage < totalPage" class="nav-right" :to="{ query: { ...query, page: currentPage + 1 } }">
              <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" />
            </RouterLink>
            <a v-else class="nav-right" href="#">
              <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
