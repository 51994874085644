<script lang="ts">
import { defineComponent } from "vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useRegisterEmergencyStore } from "./stores/useRegisterEmergencyStore";
import { useRegisterStore } from "./stores/useRegisterStore";
import { useRegisterInformationStore } from "./stores/useRegisterInformationStore";

export default defineComponent({
  name: "RegisterEmergencyView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            emergencyContact: {
              name: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.registerEmergencyStore.error[this.registerEmergencyStore.isRequired.emergencyContact.name.name] || "").length > 0,
                  isIcon: false,
                  isDisable: this.loading(),
                  isRequired: this.registerEmergencyStore.isRequired.emergencyContact.name.is,
                  textError: this.$t(this.registerEmergencyStore.isRequired.emergencyContact.name.placeholder),
                  title: this.$t(this.registerEmergencyStore.isRequired.emergencyContact.name.label),
                  placeholder: this.$t(this.registerEmergencyStore.isRequired.emergencyContact.name.placeholder),
                  value: this.registerEmergencyStore?.result?.emergencyContact?.name,
                  name: this.registerEmergencyStore.isRequired.emergencyContact.name.name,
                  isKeydownText: true,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.registerEmergencyStore.result.emergencyContact.name = value;
                },
              },
              contactNumber: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.registerEmergencyStore.error[this.registerEmergencyStore.isRequired.emergencyContact.contactNumber.name] || "").length > 0,
                  isIcon: false,
                  isDisable: this.loading(),
                  isRequired: this.registerEmergencyStore.isRequired.emergencyContact?.contactNumber.is,
                  textError: this.$t(this.registerEmergencyStore.isRequired.emergencyContact?.contactNumber.placeholder),
                  title: this.$t(this.registerEmergencyStore.isRequired.emergencyContact?.contactNumber.label),
                  placeholder: this.$t(this.registerEmergencyStore.isRequired.emergencyContact?.contactNumber.placeholder),
                  value: this.registerEmergencyStore?.result?.emergencyContact?.contactNumber,
                  name: this.registerEmergencyStore.isRequired.emergencyContact?.contactNumber.name,
                  isKeydownNumber: true,
                  maxLength: 25,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.registerEmergencyStore.result.emergencyContact.contactNumber = value;
                },
              },
              identificationNumber: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: this.registerInformationStore.hiddenFields.identificationNumber,
                  isError: (this.registerEmergencyStore.error[this.registerEmergencyStore.isRequired.emergencyContact.identificationNumber.name] || "").length > 0,
                  isIcon: false,
                  isDisable: this.loading(),
                  isRequired: this.registerInformationStore.hiddenFields.identificationNumber,
                  textError: this.$t(this.registerEmergencyStore.isRequired.emergencyContact?.identificationNumber.placeholder),
                  title: this.$t(this.registerEmergencyStore.isRequired.emergencyContact?.identificationNumber.label),
                  placeholder: this.$t(this.registerEmergencyStore.isRequired.emergencyContact?.identificationNumber.placeholder),
                  value: this.registerEmergencyStore?.result?.emergencyContact?.identificationNumber,
                  name: this.registerEmergencyStore.isRequired.emergencyContact?.identificationNumber.name,
                  maxLength: 25,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.registerEmergencyStore.result.emergencyContact.identificationNumber = value;
                },
              },
            },
          },
          select: {
            guest: {},
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_submit"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                if (e) e.preventDefault();
                const result = await this.registerEmergencyStore.onSubmit(e);
                if (result) this.$router.push({ name: "TermConditionsView" });
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.registerEmergencyStore.loading;
    },
  },
  async created() {
    if (!this.registerStore.result.email) this.$router.push({ name: "RegisterView" });
  },
  setup() {
    const registerEmergencyStore = useRegisterEmergencyStore();
    const registerStore = useRegisterStore();
    const registerInformationStore = useRegisterInformationStore();

    registerInformationStore.loading = false;

    // Hidden
    registerInformationStore.getHiddenFields();

    return {
      registerEmergencyStore,
      registerStore,
      registerInformationStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">{{ $t("page.RegisterView.title") }}</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl">{{ $t("page.RegisterEmergencyView.title") }}</h1>
                <p class="text-black">{{ $t("page.RegisterEmergencyView.detail") }}</p>
                <div>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-7'" :is="defaultFormComponents.form.input.emergencyContact.name.component" v-bind="defaultFormComponents.form.input.emergencyContact.name.props" @updateValue="defaultFormComponents.form.input.emergencyContact.name.onUpdateValue" />
                    <component :classTagName="'col-start-1 col-end-13 md:col-start-7 md:col-end-13'" :is="defaultFormComponents.form.input.emergencyContact.contactNumber.component" v-bind="defaultFormComponents.form.input.emergencyContact.contactNumber.props" @updateValue="defaultFormComponents.form.input.emergencyContact.contactNumber.onUpdateValue" />
                    <component :classTagName="'col-start-1 col-end-13'" :is="defaultFormComponents.form.input.emergencyContact.identificationNumber.component" v-bind="defaultFormComponents.form.input.emergencyContact.identificationNumber.props" @updateValue="defaultFormComponents.form.input.emergencyContact.identificationNumber.onUpdateValue" />
                  </div>
                  <div class="ttp-between border-t-2 pt-4">
                    <div class="flex gap-2 w-full">
                      <RouterLink :to="{ name: 'TermConditionsView' }" class="w-full m-auto">
                        <button type="submit" class="text-[#2C3483] bg-[#E9F0FF] font-medium disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled hover:bg-ttp-primary-20 focus:bg-ttp-primary active:bg-ttp-primary-20 rounded-lg w-full ttp-text-sm px-5 py-2.5 z-0">
                          <div class="flex gap-1 justify-center items-center">
                            <span></span><span class="w-full ttp-text-xs">{{ $t("btn.btn_skip") }}</span>
                          </div>
                        </button>
                      </RouterLink>
                    </div>
                    <div class="flex gap-2 w-full">
                      <RouterLink v-if="false" :to="{ name: 'TermConditionsView' }" class="w-full m-auto">
                        <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" />
                      </RouterLink>
                      <component v-else :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
