<script lang="ts">
import { defineComponent } from "vue";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "PageFooterComponent",
  setup() {
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    return {
      sharedStore,
    };
  },
  computed: {},
});
</script>

<template>
  <footer v-if="false" id="page-footer" class="flex flex-none items-center bg-gray-100 dark:bg-gray-800 dark:bg-opacity-100">
    <div class="text-center flex flex-col md:text-left md:flex-row md:justify-between ttp-text-xs container xl:max-w-7xl mx-auto px-4 lg:px-8 w-full">
      <div class="pt-4 pb-1 md:pb-4">
        <a href="#" target="_blank" class="font-medium text-teal-600 hover:text-teal-400 dark:text-teal-400 dark:hover:text-teal-300">{{ sharedStore.displayName() }} ©</a>
      </div>
    </div>
  </footer>
</template>
