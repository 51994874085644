import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchIncident } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchIncident";
import { ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { convertToValues, extractMetadata } from "@/configs/helpers/MainHelper";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";

const storeName = `useHistorySearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {};
export const useHistorySearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    fetchFile: new fetchFile(),
    loading: true,
    isWaitingLoading: true,
    isInProgressloading: true,
    isClosedLoading: true,
    tableResults: null as any,
    tableWaitingResults: null as any,
    tableInProgressResults: null as any,
    tableClosedResults: null as any,
    result: convertToValues(isRequired) as any,
    validationSchema: yup.object({}),
    error: {} as { [key: string]: string | null },
    isBackgroundCheck: false,
  }),
  actions: {
    async onUpdateCurrentPage(status?: ENUM_INCIDENT_STATUS) {
      try {
        if (status == ENUM_INCIDENT_STATUS.NEW) {
          this.isWaitingLoading = true;
          this.tableWaitingResults = await this.keyword([ENUM_INCIDENT_STATUS.NEW, ENUM_INCIDENT_STATUS.ACCEPTED, ENUM_INCIDENT_STATUS.WAITING]);

          this.isWaitingLoading = false;
        } else if (status == ENUM_INCIDENT_STATUS.IN_PROGRESS) {
          this.isInProgressloading = true;
          this.tableInProgressResults = await this.keyword([ENUM_INCIDENT_STATUS.IN_PROGRESS, ENUM_INCIDENT_STATUS.FORWARDED]);

          if (this.tableInProgressResults?.items) {
            this.tableInProgressResults?.items?.reduce((acc: any, item: any, _index: any) => {
              item.metadata = {} as any;
              if (item?.progress) {
                item.metadata = extractMetadata(item?.progress);
                acc.push(item);
                return acc;
              }
              return acc;
            }, []);
          }

          this.isInProgressloading = false;
        } else if (status == ENUM_INCIDENT_STATUS.CLOSED) {
          this.isClosedLoading = true;
          this.tableClosedResults = await this.keyword([ENUM_INCIDENT_STATUS.COMPLETED, ENUM_INCIDENT_STATUS.IN_REVIEW, ENUM_INCIDENT_STATUS.REPORTED, ENUM_INCIDENT_STATUS.CLOSED, ENUM_INCIDENT_STATUS.REJECTED]);
          this.isClosedLoading = false;
        } else {
          this.loading = true;
          this.tableResults = await this.keyword();
          this.loading = false;
        }
      } catch (error: any) {
        this.isWaitingLoading = false;
        this.isInProgressloading = false;
        this.isClosedLoading = false;
        this.loading = false;
      }
    },
    async keyword(status?: ENUM_INCIDENT_STATUS[]) {
      return await this.fetchIncident.ListIncident(status);
    },
  },
});
