import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetFile } from "@/configs/types/Shared/typeFile";

export class fetchFile {
  async Get(ids: string[]) {
    try {
      const responses = await Promise.all(
        await ids.map(async (id) => {
          const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).get<typeGetFile>(`/v1/files/${id}`);
          return data;
        })
      );

      return await responses;
    } catch (error) {
      return [];
    }
  }
  async GetUrl(id: string) {
    try {
      const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).get<typeGetFile>(`/v1/files/${id}`);

      return data?.url?.length ? data?.url as string : '';
    } catch (error) {
      return null;
    }
  }
  async Upload(files: File[]): Promise<typeGetFile[] | any> {
    if (files.length) {
      const formDataArray = Array.from(files).map((fileItem) => {
        const formData = new FormData();
        formData.append("file", fileItem);
        formData.append("accept", "image/*");
        return formData;
      });

      try {
        const responses = await Promise.all(formDataArray.map((formData) => axiosClient({ isFile: true }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).post<typeGetFile>("/v1/files", formData)));

        const responseDataArray = await responses.map((response) => response.data);
        return responseDataArray;
      } catch (error) {
        console.error("Error sending files:", error);
        throw error;
      }
    }
  }
}
