import type { typeGetUser } from "@/configs/types/User/typeUser";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";

export class fetchAuth {
  async Get(): Promise<typeGetUser> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetUser>("/v1/me");
    return data;
  }
  async GetRegisterForm(): Promise<{ [key: string]: boolean }> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<{ [key: string]: boolean }>("/v1/register");
    return data;
  }
  async UpdateProfile(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).put(`/v1/me`, body);
    return data;
  }
  async AddDevice(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).post(`/v1/me/devices`, body);
    return data;
  }
  async DeleteDevice(id: string, body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).post(`/v1/me/devices/${id}`, body);
    return data;
  }
  async Register(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).post<any>("/v1/register", body);
    return data;
  }
  async Hidden() {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any>("/v1/register");
    return data;
  }
  async ForgotPassword(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).post<any>("/v1/forgot-password", body);
    return data;
  }
  async LoginWithOTP(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).post<any>("/v1/login-with-otp", body);
    return data;
  }
  async PasswordUpdate(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).patch(`/v1/me:password`, body);
    return data;
  }
}
