<script lang="ts">
import { defineComponent } from "vue";
import { useRegisterOTPStore } from "./stores/useRegisterOTPStore";
import FormInputLargeComponent from "@/components/Forms/Input/FormInputLargeComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useRegisterEmergencyStore } from "./stores/useRegisterEmergencyStore";
import { useRegisterStore } from "./stores/useRegisterStore";
import { useRegisterInformationStore } from "./stores/useRegisterInformationStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useSettingLanguageStore } from "../Tourist/Setting/stores/useSettingLanguageStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";

export default defineComponent({
  name: "RegisterOTPView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            number_1: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerOTPStore.error.number_1 || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.registerOTPStore.isRequired.number_1.is,
                textError: this.$t(this.registerOTPStore.isRequired.number_1.placeholder),
                title: this.$t(this.registerOTPStore.isRequired.number_1.label),
                placeholder: this.$t(this.registerOTPStore.isRequired.number_1.placeholder),
                value: this.registerOTPStore.result.number_1,
                name: this.registerOTPStore.isRequired.number_1.name,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerOTPStore.result.number_1 = value;
              },
            },
            number_2: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerOTPStore.error.number_2 || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.registerOTPStore.isRequired.number_2.is,
                textError: this.$t(this.registerOTPStore.isRequired.number_2.placeholder),
                title: this.$t(this.registerOTPStore.isRequired.number_2.label),
                placeholder: this.$t(this.registerOTPStore.isRequired.number_2.placeholder),
                value: this.registerOTPStore.result.number_2,
                name: this.registerOTPStore.isRequired.number_2.name,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerOTPStore.result.number_2 = value;
              },
            },
            number_3: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerOTPStore.error.number_3 || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.registerOTPStore.isRequired.number_3.is,
                textError: this.$t(this.registerOTPStore.isRequired.number_3.placeholder),
                title: this.$t(this.registerOTPStore.isRequired.number_3.label),
                placeholder: this.$t(this.registerOTPStore.isRequired.number_3.placeholder),
                value: this.registerOTPStore.result.number_3,
                name: this.registerOTPStore.isRequired.number_3.name,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerOTPStore.result.number_3 = value;
              },
            },
            number_4: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerOTPStore.error.number_4 || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.registerOTPStore.isRequired.number_4.is,
                textError: this.$t(this.registerOTPStore.isRequired.number_4.placeholder),
                title: this.$t(this.registerOTPStore.isRequired.number_4.label),
                placeholder: this.$t(this.registerOTPStore.isRequired.number_4.placeholder),
                value: this.registerOTPStore.result.number_4,
                name: this.registerOTPStore.isRequired.number_4.name,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerOTPStore.result.number_4 = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: "Verify",
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                if (e) e.preventDefault();
                const result = await this.registerOTPStore.onSubmit(e);
                if (result) {
                  await setLocalStorage(`${this.storeName}:locale`, String(this.sharedStore.locale).toUpperCase());
                  this.settingLanguageStore.result.locale = this.sharedStore.locale;
                  this.$i18n.locale = String(this.sharedStore.locale).toLowerCase();
                  const register = await this.registerStore.fetchAuth.Register({
                    ...this.registerStore.result,
                    ...this.registerEmergencyStore.result,
                    ...this.registerInformationStore.result,
                    contactNumberCountryCode: this.$enums.ENUM_FIELD_LOCALE.TH,
                    identificationType: this.$enums.ENUM_IDENTIFICATION_TYPE.PASSPORT,
                    locale: this.sharedStore.locale,
                  });
                  if (register) this.$router.push({ name: "RegisterSuccessView" });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.registerOTPStore.loading;
    },
  },
  async created() {
    if (!this.registerStore.result.email) this.$router.push({ name: "RegisterView" });
  },
  setup() {
    const registerOTPStore = useRegisterOTPStore();
    const registerEmergencyStore = useRegisterEmergencyStore();
    const registerStore = useRegisterStore();
    const registerInformationStore = useRegisterInformationStore();
    const masterDataStore = useMasterDataStore();
    const settingLanguageStore = useSettingLanguageStore();
    const sharedStore = useSharedStore();

    registerEmergencyStore.loading = false;

    return {
      registerOTPStore,
      registerEmergencyStore,
      registerStore,
      registerInformationStore,
      masterDataStore,
      settingLanguageStore,
      sharedStore,
      storeName: storeNamePoliceTouristAndAgent,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">OTP Code Verification</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl text-center my-4">Register OTP</h1>
                <p class="text-black text-center">{{ $t("page.ForgotPasswordView.otp_send_to") }}</p>
                <p class="text-black text-center text-ttp-primary-10 font-medium mb-4">{{ registerStore?.result?.email }}</p>
                <div>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component classTagName="col-start-3 col-end-5 md:col-start-5 md:col-end-6" :is="defaultFormComponents.form.input.number_1.component" v-bind="defaultFormComponents.form.input.number_1.props" @updateValue="defaultFormComponents.form.input.number_1.onUpdateValue" />
                    <component classTagName="col-start-5 col-end-7 md:col-start-6 md:col-end-7" :is="defaultFormComponents.form.input.number_2.component" v-bind="defaultFormComponents.form.input.number_2.props" @updateValue="defaultFormComponents.form.input.number_2.onUpdateValue" />
                    <component classTagName="col-start-7 col-end-9 md:col-start-7 md:col-end-8" :is="defaultFormComponents.form.input.number_3.component" v-bind="defaultFormComponents.form.input.number_3.props" @updateValue="defaultFormComponents.form.input.number_3.onUpdateValue" />
                    <component classTagName="col-start-9 col-end-11 md:col-start-8 md:col-end-9" :is="defaultFormComponents.form.input.number_4.component" v-bind="defaultFormComponents.form.input.number_4.props" @updateValue="defaultFormComponents.form.input.number_4.onUpdateValue" />
                  </div>
                  <p v-if="false" class="text-black text-center mt-4">{{ $t("page.ForgotPasswordView.otp_resend") }} 55 วินาที</p>
                  <p v-else class="text-black text-center text-ttp-primary-10 font-medium">{{ $t("btn.btn_resend_code") }}</p>
                  <div class="ttp-between mt-4">
                    <div class="flex gap-2 w-full">
                      <RouterLink v-if="false" :to="{ name: 'RegisterSuccessView' }" class="w-full m-auto">
                        <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" />
                      </RouterLink>
                      <component v-else :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
