<script lang="ts" setup>
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";

type TableHeader = {
  label: string;
  key?: string;
  className?: string;
  parser?: (...args: any[]) => any;
}

withDefaults(defineProps<{
  currentPage?: number;
  headers: TableHeader[];
  content?: any[];
  loading: boolean;
  loadingMessage?: string;
  counter: boolean;
  counterLabel?: string;
  counterInital?: number;
}>(), {
  currentPage: 0,
  content: <any>[],
  loading: false,
  loadingMessage: '',
  counter: false,
  counterLabel: '',
  counterInital: 0,
})

const pageSize = Number(import.meta.env.VITE_PAGE_SIZE)
</script>

<template>
  <div class="ttp-table">
    <table>
      <thead v-if="headers">
        <slot name="head">
          <tr>
            <th v-if="counter">{{ counterLabel }}</th>
            <th v-for="(header, index) in headers" :key="index">
              {{ header.label }}
            </th>
          </tr>
        </slot>
      </thead>
      <tbody>
        <slot name="body">
          <tr v-if="loading">
            <td :colspan="counter ? headers.length + 1 : headers.length" class="text-center py-10">
              <LoadingFormComponent />
              <div class="py-1">{{ $t("layout.loading") }}</div>
            </td>
          </tr>
          <tr v-else-if="!content?.length">
            <td :colspan="counter ? headers.length + 1 : headers.length" class="text-center py-10">
              <svg class="hi-mini hi-archive-box-x-mark inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M2 3a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1H2z" />
                <path fill-rule="evenodd" d="M2 7.5h16l-.811 7.71a2 2 0 01-1.99 1.79H4.802a2 2 0 01-1.99-1.79L2 7.5zm5.22 1.72a.75.75 0 011.06 0L10 10.94l1.72-1.72a.75.75 0 111.06 1.06L11.06 12l1.72 1.72a.75.75 0 11-1.06 1.06L10 13.06l-1.72 1.72a.75.75 0 01-1.06-1.06L8.94 12l-1.72-1.72a.75.75 0 010-1.06z" clip-rule="evenodd" />
              </svg>
              <div>{{ $t("layout.not_found") }}</div>
            </td>
          </tr>
          <tr v-else v-for="(item, itemIdx) in content as any[]" :key="`item-${itemIdx}`">
            <td v-if="counter">{{ (counterInital ?? 0) + itemIdx + 1 + Number($inArray(Number($route?.query?.page), [1, NaN]) ? 0 : pageSize * (Number($route?.query?.page) - 1)) }}</td>
            <td v-for="(header, headerIdx) in headers as TableHeader[]" :key="`item-${headerIdx}`" :class="[header.className]">
              <slot :name="`body-${header.key}`" :value="header.key ? item[header.key] : ''" :item="item">
                {{ header.parser ? header.parser(header.key ? item[header.key] : "") : header.key ? item[header.key] : "" }}
              </slot>
            </td>
          </tr>
        </slot>
      </tbody>
    </table>
  </div>
</template>
