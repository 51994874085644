<script setup lang="ts">
import { onMounted, ref } from "vue";
import type { Dayjs } from "dayjs";
import { fetchReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import { fetchMasterData } from "@/configs/fetches/PoliceCommandCenter/fetchMasterData";
import type { typeGetIncidentCategory } from "@/configs/types/Incident/typeIncidentCategory";
import { ENUM_FIELD_LOCALE } from "@/configs/enums/enum";
import dayjs from "dayjs";

type Report = { title: string; total: number };

const { from, to } = defineProps<{
  from: Dayjs;
  to: Dayjs;
}>();

const fetch = new fetchReport();
const fetchMaster = new fetchMasterData();
const report = ref<Report[]>([]);
const categories = ref<typeGetIncidentCategory[]>([]);
const lastUpdate = ref<Dayjs>();

const reload = async () => {
  const result = await fetch.IncidentReport(
    {
      startDate: from.format("YYYY-MM-DD"),
      endDate: to.format("YYYY-MM-DD"),
    },
    "category_code",
    "month"
  );

  lastUpdate.value = dayjs();

  report.value = Object.values(result)
    .reduce(
      (x, item) =>
        Object.keys(item).reduce((y, k) => {
          let i = y.find(([key, _]) => key === k);
          if (!i) {
            i = [k, 0];
            y.push(i);
          }

          i[1] += item[k];
          return y;
        }, x),
      [] as Array<[string, number]>
    )
    .sort(([, a], [, b]) => b - a)
    .reduce((item, [key, total]) => {
      item.push({
        title: getCategoryTitle(key),
        total,
      });
      return item;
    }, [] as Report[])
    .slice(0, 5);
};

const getCategoryTitle = (code: string) => {
  return categories.value.find((c) => c.code === code)?.content?.find(({ locale }) => locale === ENUM_FIELD_LOCALE.TH)?.title || code;
};

onMounted(async () => {
  categories.value = await fetchMaster.listIncidentCategory();
  reload();
});

defineExpose({ reload });
</script>

<template>
  <!-- Card Headings: Title with Subtitle -->
  <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100 h-full">
    <!-- Card Header -->
    <div class="card-title bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
      <h3 class="mb-1 font-semibold">
        <slot name="title">จำนวนผู้ใช้งานใหม่</slot>
      </h3>
    </div>
    <!-- END Card Header -->

    <!-- Card Body -->
    <div class="chart-wrap grow p-5">
      <table class="table-auto min-w-full whitespace-nowrap align-middle text-sm">
        <thead>
          <tr class="border-b border-gray-100 dark:border-gray-700/50">
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">ประเภทเรื่อง</th>
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in report" class="border-b border-gray-100 dark:border-gray-700/50">
            <td class="p-3 text-wrap">{{ item.title }}</td>
            <td class="p-3">{{ item.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Card Body -->
  </div>
  <!-- END Card Headings: Title with Subtitle -->
</template>

<style scoped>
.chart-wrap {
  min-height: 0;
}
</style>
