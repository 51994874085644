import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";

import "dayjs/locale/th";
import "dayjs/locale/zh";
import "dayjs/locale/en";
import "dayjs/locale/ja";
import "dayjs/locale/ru";
import "dayjs/locale/ko";

import App from "@/App.vue";
import router, { addDeviceToken } from "@/routers";
import VueDayjs from "./configs/plugins/dayjs";
import dayjs from "dayjs";

import translatePoliceCommandCenterTH from "@/configs/locales/th";
import translatePoliceCommandCenterEN from "@/configs/locales/en";
import translatePoliceCommandCenterJP from "@/configs/locales/jp";
import translatePoliceCommandCenterKR from "@/configs/locales/kr";
import translatePoliceCommandCenterRU from "@/configs/locales/ru";
import translatePoliceCommandCenterCN from "@/configs/locales/cn";

import { outlineIcons, solidIcons } from "@/configs/icons/icon";
import { findTranslationIndexByLocale, popUnique, pushUnique, getContentByLocale, replaceAll, calculateMinutesDifference, calculateTimeColor, removeById, findByIdAndRemove, isPast, calculateDate, extractMetadata, addMinutesToCurrentTime, getColorStatus, isEqual, isOpenNow, filterNewsByLocale, groupNewsByContentDate, substr, saveDataToLocalStorage, getDataFromLocalStorage, findStatusDetail, configLightbox, calculateTimeDifference, searchIncidentByIDOrTouristName, removeHtmlTags, handleKeydown, findIndexesOfLocations, findIndexesOfImages, isArrayOfStringsOrObjects, findPlaceholder, findIndexById, hasNameAndValue, checkNullAndConvert, classStatus, convertEnumToArray, convertNumber, convertString, formattedStatus, inArray, isObjectEmpty, removeEmptyValues, replaceString, transformData, transformUserData, isCommandCenterApp } from "@/configs/helpers/MainHelper";
import GoogleMapsApiLoader from "google-maps-api-loader";
import { enums } from "./configs/enums/enum";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "swiper/swiper-bundle.css";
import "photoswipe/dist/photoswipe.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, Chart, ArcElement, BarElement, LineElement, PointElement, Decimation, Filler, Legend, SubTitle, Title, Tooltip, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale } from "chart.js";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import FormCheckBoxToggleComponent from "@/components/Forms/CheckBox/FormCheckBoxToggleComponent.vue";
import FormCheckBoxDefaultComponent from "@/components/Forms/CheckBox/FormCheckBoxDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormRadioDefaultComponent from "@/components/Forms/Radio/FormRadioDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import FormTextareaDefaultComponent from "@/components/Forms/Textarea/FormTextareaDefaultComponent.vue";
import PopupAssignTextareaComponent from "@/components/Popups/PopupAssignTextareaComponent.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupDeleteComponent from "@/components/Popups/PopupDeleteComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import TableComponent from "./components/Tables/TableComponent.vue";
import PaginationComponent from "./components/Paginations/PaginationComponent.vue";
import DesktopHeaderComponent from "./components/Headers/DesktopHeaderComponent.vue";
import MobileHeaderComponent from "./components/Headers/MobileHeaderComponent.vue";
import ProfileComponent from "./components/Headers/ProfileComponent.vue";
import { registerPlugin } from "@capacitor/core";
import { io } from "socket.io-client";
import { getProjectType } from "@/routers";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const TTPPlugin = registerPlugin("TTPPlugin");
const PermissionsPlugin = registerPlugin("PermissionsPlugin");
const AudioRoutePlugin = registerPlugin("AudioRoutePlugin");

Chart.register(BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, ArcElement, BarElement, LineElement, PointElement, Decimation, Filler, Legend, SubTitle, Title, Tooltip, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale);

const socketConfig = {
  autoConnect: false,
  secure: true,
  transports: ["websocket", "polling"],
  forceNew: true,
  upgrade: true,
};

export const chatSocket = io(import.meta.env.VITE_SOCKET_URL, Object.assign({ path: "/socket.io" }, socketConfig));
export const voiceSocket = io(import.meta.env.VITE_VOICE_SOCKET_URL, Object.assign({ path: "/voice" }, socketConfig));

chatSocket.on("connect", () => console.log("chat connected"));
voiceSocket.on("connect", () => console.log("voice connected"));
chatSocket.on("disconnect", () => console.log("chat disconnected"));
voiceSocket.on("disconnect", () => console.log("voice disconnected"));

export const i18n = createI18n({
  legacy: false,
  locale: isCommandCenterApp ? "th" : "en",
  fallbackLocale: isCommandCenterApp ? "th" : "en",
  messages: {
    th: translatePoliceCommandCenterTH,
    en: translatePoliceCommandCenterEN,
    jp: translatePoliceCommandCenterJP,
    kr: translatePoliceCommandCenterKR,
    ru: translatePoliceCommandCenterRU,
    cn: translatePoliceCommandCenterCN,
  },
});

const pinia = createPinia();
const app = createApp(App);
app.use(i18n);
app.use(VueDayjs, { locale: isCommandCenterApp ? enums.ENUM_FIELD_LOCALE.TH : enums.ENUM_FIELD_LOCALE.EN });
app.use(pinia);
app.use(router);
app.use(VueSweetalert2);

app.component("Menu", Menu);
app.component("MenuButton", MenuButton);
app.component("MenuItems", MenuItems);
app.component("MenuItem", MenuItem);
app.component("TableComponent", TableComponent);
app.component("PaginationComponent", PaginationComponent);
app.component("VueDatePicker", VueDatePicker);
app.component("LoadingFormComponent", LoadingFormComponent);
app.component("BadgeDefaultComponent", BadgeDefaultComponent);
app.component("ButtonDefaultComponent", ButtonDefaultComponent);
app.component("ButtonStyle2Component", ButtonStyle2Component);
app.component("FormCheckBoxToggleComponent", FormCheckBoxToggleComponent);
app.component("FormCheckBoxDefaultComponent", FormCheckBoxDefaultComponent);
app.component("FormInputDefaultComponent", FormInputDefaultComponent);
app.component("FormRadioDefaultComponent", FormRadioDefaultComponent);
app.component("FormSelectBoxDefaultComponent", FormSelectBoxDefaultComponent);
app.component("FormTextareaDefaultComponent", FormTextareaDefaultComponent);
app.component("PopupAssignTextareaComponent", PopupAssignTextareaComponent);
app.component("PopupCancelComponent", PopupCancelComponent);
app.component("PopupCautionComponent", PopupCautionComponent);
app.component("PopupDeleteComponent", PopupDeleteComponent);
app.component("PopupSuccessComponent", PopupSuccessComponent);
app.component("DesktopHeaderComponent", DesktopHeaderComponent);
app.component("MobileHeaderComponent", MobileHeaderComponent);
app.component("ProfileComponent", ProfileComponent);
app.component("QuillEditor", QuillEditor);

app.config.globalProperties.$Chart = Chart;
app.config.globalProperties.$window = window;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$enums = enums;
app.config.globalProperties.$solidIcons = solidIcons;
app.config.globalProperties.$outlineIcons = outlineIcons;
app.config.globalProperties.$convertString = convertString;
app.config.globalProperties.$convertNumber = convertNumber;
app.config.globalProperties.$convertEnumToArray = convertEnumToArray;
app.config.globalProperties.$classStatus = classStatus;
app.config.globalProperties.$formattedStatus = formattedStatus;
app.config.globalProperties.$inArray = inArray;
app.config.globalProperties.$transformData = transformData;
app.config.globalProperties.$transformUserData = transformUserData;
app.config.globalProperties.$isObjectEmpty = isObjectEmpty;
app.config.globalProperties.$removeEmptyValues = removeEmptyValues;
app.config.globalProperties.$checkNullAndConvert = checkNullAndConvert;
app.config.globalProperties.$replaceString = replaceString;
app.config.globalProperties.$hasNameAndValue = hasNameAndValue;
app.config.globalProperties.$findIndexById = findIndexById;
app.config.globalProperties.$findPlaceholder = findPlaceholder;
app.config.globalProperties.$isArrayOfStringsOrObjects = isArrayOfStringsOrObjects;
app.config.globalProperties.$findIndexesOfImages = findIndexesOfImages;
app.config.globalProperties.$findIndexesOfLocations = findIndexesOfLocations;
app.config.globalProperties.$handleKeydown = handleKeydown;
app.config.globalProperties.$removeHtmlTags = removeHtmlTags;
app.config.globalProperties.$searchIncidentByIDOrTouristName = searchIncidentByIDOrTouristName;
app.config.globalProperties.$calculateTimeDifference = calculateTimeDifference;
app.config.globalProperties.$configLightbox = configLightbox;
app.config.globalProperties.$findStatusDetail = findStatusDetail;
app.config.globalProperties.$saveDataToLocalStorage = saveDataToLocalStorage;
app.config.globalProperties.$getDataFromLocalStorage = getDataFromLocalStorage;
app.config.globalProperties.$substr = substr;
app.config.globalProperties.$isCommandCenterApp = isCommandCenterApp;
app.config.globalProperties.$isEqual = isEqual;
app.config.globalProperties.$TTPPlugin = TTPPlugin;
app.config.globalProperties.$PermissionsPlugin = PermissionsPlugin;
app.config.globalProperties.$AudioRoutePlugin = AudioRoutePlugin;
app.config.globalProperties.$chatSocket = chatSocket;
app.config.globalProperties.$voiceSocket = voiceSocket;
app.config.globalProperties.$getProjectType = getProjectType;
app.config.globalProperties.$addDeviceToken = addDeviceToken;
app.config.globalProperties.$isOpenNow = isOpenNow;
app.config.globalProperties.$filterNewsByLocale = filterNewsByLocale;
app.config.globalProperties.$groupNewsByContentDate = groupNewsByContentDate;
app.config.globalProperties.$getColorStatus = getColorStatus;
app.config.globalProperties.$addMinutesToCurrentTime = addMinutesToCurrentTime;
app.config.globalProperties.$extractMetadata = extractMetadata;
app.config.globalProperties.$calculateDate = calculateDate;
app.config.globalProperties.$isPast = isPast;
app.config.globalProperties.$findByIdAndRemove = findByIdAndRemove;
app.config.globalProperties.$removeById = removeById;
app.config.globalProperties.$calculateTimeColor = calculateTimeColor;
app.config.globalProperties.$calculateMinutesDifference = calculateMinutesDifference;
app.config.globalProperties.$replaceAll = replaceAll;
app.config.globalProperties.$getContentByLocale = getContentByLocale;
app.config.globalProperties.$pushUnique = pushUnique;
app.config.globalProperties.$popUnique = popUnique;
app.config.globalProperties.$findTranslationIndexByLocale = findTranslationIndexByLocale;

app.config.globalProperties.$asyncGoogleMapsLoader = async () => {
  const google = await GoogleMapsApiLoader({
    apiKey: import.meta.env.VITE_GOOGLE_APIKEY,
    version: "weekly",
    libraries: ["places"],
  });

  return google;
};

router.isReady().then(() => {
  app.mount("#app");
});
