import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchIncident } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchIncident";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { convertToValues, removeEmptyValues } from "@/configs/helpers/MainHelper";

const storeName = `useHistorySearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {};
export const useHistorySearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isReject: false,
    isDelete: false,
    isMap: false,
    isMapGenerate: false,
    isCopyPopup: false,
    loading: true,
    isRequired,
    currentPage: 1,
    totalPage: 1,
    metadata: { eta: undefined, arrived: undefined, createdAt: undefined } as any,
    calculate: {
      arrived: { remainingMinutes: 0, colorSegment: "Green" },
      now: { remainingMinutes: 0, colorSegment: "Green" },
    } as any,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    tablePendingResults: null as any,
    tableAcceptedResults: null as any,
    tableForwardedResults: null as any,
    tableInProgressResults: null as any,
    tableCompletedResults: null as any,
    tableCanceledResults: null as any,
    result: convertToValues(isRequired) as any,
    validationSchema: yup.object({}),
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async gotoPage(page: number, e?: MouseEvent) {
      e?.preventDefault();
      try {
        this.loading = true;
        this.currentPage = page;
        this.tableResults = await this.keyword();
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async nextPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage < this.totalPage) {
        try {
          this.loading = true;
          this.currentPage++;
          this.tableResults = await this.keyword();
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      }
    },
    async prevPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage > 1) {
        try {
          this.loading = true;
          this.currentPage--;
          this.tableResults = await this.keyword();
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      }
    },
    async onUpdateCurrentPage(currentPage: number, status?: ENUM_INCIDENT_STATUS) {
      try {
        this.loading = true;
        this.currentPage = currentPage;
        switch (status) {
          case ENUM_INCIDENT_STATUS.PENDING:
            this.tablePendingResults = await this.keyword(ENUM_INCIDENT_STATUS.PENDING);
            break;
          case ENUM_INCIDENT_STATUS.ACCEPTED:
            this.tableAcceptedResults = await this.keyword(ENUM_INCIDENT_STATUS.ACCEPTED);
            break;
          case ENUM_INCIDENT_STATUS.WAITING:
            this.tableAcceptedResults = await this.keyword(ENUM_INCIDENT_STATUS.WAITING);
            break;
          case ENUM_INCIDENT_STATUS.FORWARDED:
            this.tableForwardedResults = await this.keyword(ENUM_INCIDENT_STATUS.FORWARDED);
            break;
          case ENUM_INCIDENT_STATUS.IN_PROGRESS:
            this.tableInProgressResults = await this.keyword(ENUM_INCIDENT_STATUS.IN_PROGRESS);
            break;
          case ENUM_INCIDENT_STATUS.COMPLETED:
            this.tableCompletedResults = await this.keyword(ENUM_INCIDENT_STATUS.COMPLETED);
            break;
          case ENUM_INCIDENT_STATUS.REJECTED:
            this.tableCanceledResults = await this.keyword(ENUM_INCIDENT_STATUS.REJECTED);
            break;
          default:
            this.tableCanceledResults = await this.keyword();
            break;
        }

        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      this.result = removeEmptyValues(this.result, []);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        try {
          this.loading = true;
          this.currentPage = 1;
          this.tableResults = await this.keyword();
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async keyword(status?: ENUM_INCIDENT_STATUS) {
      return await this.fetchIncident.ListIncident(status ? [status] : []);
    },
  },
});
