import type { typeGetUser } from "@/configs/types/User/typeUser";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { ENUM_APP_NAME } from "@/configs/enums/enum";

export class fetchAuth {
  async Get(): Promise<typeGetUser> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetUser>("/v1/me");
    return data;
  }

  async AddDevice(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).post(`/v1/me/devices`, body);
    return data;
  }
  async PasswordUpdate(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).patch(`/v1/me:password`, body);
    return data;
  }
}
