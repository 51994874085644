import { createRouter, createWebHistory } from "vue-router";

import { ENUM_APP_CATEGORY_NAME, ENUM_FIELD_ROLE } from "@/configs/enums/enum";
import { routePoliceCommandCenter } from "./routePoliceCommandCenter";
import { routePoliceTouristAndAgent } from "./routePoliceTouristAndAgent";
import { useAuthStore as useAuthPoliceCommandCenterStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useAuthStore as useAuthPoliceTouristAndAgentStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { getMessaging } from "@/configs/plugins/firebase";
import { getToken } from "firebase/messaging";
import { inArray, getLocalStorage, isCommandCenterApp, setLocalStorage } from "@/configs/helpers/MainHelper";
import { AUTH_PROFILE_KEY as PTAA_AUTH_PROFILE_KEY, AUTH_TOKEN_KEY as PTAA_AUTH_TOKEN_KEY, AUTH_USER_KEY as PTAA_AUTH_USER_KEY } from "../views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { AUTH_PROFILE_KEY as PCC_AUTH_PROFILE_KEY, AUTH_TOKEN_KEY as PCC_AUTH_TOKEN_KEY, AUTH_USER_KEY as PCC_AUTH_USER_KEY } from "../views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";

// Route Setting
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: isCommandCenterApp ? routePoliceCommandCenter : routePoliceTouristAndAgent,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { top: 0, to, from };
  },
});

export const getProjectType = (fullPath?: string): ENUM_APP_CATEGORY_NAME => {
  if ((fullPath || window.location.pathname).includes("/agent/")) {
    return ENUM_APP_CATEGORY_NAME.AGENT
  }

  if (isCommandCenterApp) {
    return ENUM_APP_CATEGORY_NAME.COMMAND
  }

  return ENUM_APP_CATEGORY_NAME.TOURIST
};

export const addDeviceToken = async (authStore: any, messaging: any, storeName: any) => {
  return getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_KEY_PAIR }).then(async (token: any) => {
    if (!authStore.user?.devices.some(({ deviceToken }: any) => deviceToken === token)) {
      authStore.addToken(token);
    }
    await setLocalStorage(`${storeName}:deviceToken`, token);
  });
};

// Middleware
router.beforeEach(async (to, _from, next) => {
  document.title = String(to.meta.title);
  const storeName = inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent;
  const sharedStore = inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
  const authStore = inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? useAuthPoliceCommandCenterStore() : useAuthPoliceTouristAndAgentStore();

  const storageTokenName = inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? PCC_AUTH_TOKEN_KEY : PTAA_AUTH_TOKEN_KEY;
  const storageUserName = inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? PCC_AUTH_USER_KEY : PTAA_AUTH_USER_KEY;
  const storageProfileName = inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? PCC_AUTH_PROFILE_KEY : PTAA_AUTH_PROFILE_KEY;

  if (await getLocalStorage(`${storeName}:mode`)) sharedStore.mode = String(await getLocalStorage(`${storeName}:mode`));
  if (await getLocalStorage(`${storeName}:submenuOpen`)) sharedStore.submenuOpen = String(await getLocalStorage(`${storeName}:submenuOpen`));
  if (isCommandCenterApp) await setLocalStorage(`${storeName}:getProjectType`, ENUM_APP_CATEGORY_NAME.COMMAND);
  if (await getLocalStorage(`${storeName}:getProjectType`)) sharedStore.getProjectType = String(await getLocalStorage(`${storeName}:getProjectType`));
  if (await getLocalStorage(`${storeName}:locale`)) sharedStore.locale = String(await getLocalStorage(`${storeName}:locale`));
  if (await getLocalStorage(`${storeName}:deviceToken`)) authStore.deviceToken = String(await getLocalStorage(`${storeName}:deviceToken`));
  if (await getLocalStorage(storageTokenName)) authStore.token = String(await getLocalStorage(storageTokenName));
  if (await getLocalStorage(storageUserName)) authStore.user = JSON.parse(String(await getLocalStorage(storageUserName)));
  if (await getLocalStorage(storageProfileName)) authStore.registerProfile = JSON.parse(String(await getLocalStorage(storageProfileName)));

  // Check Authentication
  if (authStore.token && !authStore.user) {
    try {
      await authStore.get();
    } catch (e) {}
  } else {
  }

  // Notification
  const messaging = await getMessaging();
  if (messaging && authStore.user) {
    await addDeviceToken(authStore, messaging, storeName);
  }

  // Public Route
  if (to.matched.some((record) => inArray(record.name, ["PrivacyPolicyView"]))) {
    next();
  }

  // Auth Role
  if (to.matched.some((record) => record.meta.role)) {
    if (authStore?.user && authStore?.token)
      if (inArray(authStore?.user?.role, to?.matched[1]?.meta?.role as any[])) {
        next();
        return;
      }

      if (getProjectType(to.fullPath) === ENUM_APP_CATEGORY_NAME.AGENT) {
        next('/agent/incidents');
        return
      }

      if (getProjectType(to.fullPath) === ENUM_APP_CATEGORY_NAME.AGENT) {
        next('/tourist/home');
        return
      }

      switch (authStore?.user?.role) {
        case ENUM_FIELD_ROLE.ADMIN:
          next('/news-management');
          break
        case ENUM_FIELD_ROLE.MANAGER:
          next('/overview');
          break
        case ENUM_FIELD_ROLE.INTEPETOR:
          next('/overview');
          break
        case ENUM_FIELD_ROLE.SUPPRESSOR:
          next('/incident-management/my-incident');
          break
        case ENUM_FIELD_ROLE.INVESTIGATOR:
          next('/incident-management/my-incident');
          break
        case ENUM_FIELD_ROLE.DUTY_OFFICER:
          next('/incident-management/waiting');
          break
        case ENUM_FIELD_ROLE.AGENT:
          next('/incident-management/my-incident');
          break

        // Legacy
        case ENUM_FIELD_ROLE.CENTRAL_OFFICER:
          next('/overview');
          break;
        case ENUM_FIELD_ROLE.OFFICER:
          next('/overview');
          break;
        case ENUM_FIELD_ROLE.REPORTER:
          next('/dashboard');
          break;
      }
  }

  // Auth Route
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authStore.user) {
      if (sharedStore.getProjectType !== getProjectType(to.fullPath)) {
        window.location.href = inArray(sharedStore.getProjectType, [ENUM_APP_CATEGORY_NAME.AGENT]) ? `${window.location.origin}/agent/incidents` : `${window.location.origin}/tourist/home`;
      } else {
        next();
      }
    } else {
      next("/");
    }
  }

  // Guest Route
  if (to.matched.some((record) => record.meta.guestOnly)) {
    if (authStore.user) {
      next(inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? "/overview" : inArray(getProjectType(to.fullPath), [ENUM_APP_CATEGORY_NAME.AGENT]) ? "/agent/incidents" : "/tourist/home");
    } else {
      next();
    }
  }
});

export default router;
