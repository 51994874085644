import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import type { typeIncidentAssignee } from "@/configs/types/Incident/typeIncidentAssignee";
import type { typeGetPagination } from "@/configs/types/Shared/typePagination";

export class fetchIncident {
  async ProgressList(id: string): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get(`/v1/incidents/${id}/progress`);
    return data;
  }
  async ProgressUpdate(id: string, body: object): Promise<typeIncidentAssignee> {
    const { data } = await axiosClient(
      {
        isFile: false,
      },
      ENUM_APP_NAME.POLICE_COMMAND_CENTER
    ).post<typeIncidentAssignee>(`/v1/incidents/${id}/progress`, body);

    return data;
  }

  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetPagination<typeGetIncident>>("/v1/incidents", { params });

    return data;
  }

  async ListNew(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetPagination<typeGetIncident>>("/v1/incidents:new", { params });

    return data;
  }

  async AllList(params: object): Promise<typeGetPagination<typeGetIncident>> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetPagination<typeGetIncident>>("/v1/incidents:all", { params });

    return data;
  }

  async MyList(params: object): Promise<typeGetPagination<typeGetIncident>> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetPagination<typeGetIncident>>("/v1/incidents:me", { params });

    return data;
  }

  async Get(id: string): Promise<typeGetIncident> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetIncident>(`/v1/incidents/${id}`);
    return data;
  }

  async Create(body: object): Promise<typeGetIncident> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).post<typeGetIncident>("/v1/incidents", body);
    return data;
  }
  async Update(id: string, body: object): Promise<typeGetIncident> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).put<typeGetIncident>(`/v1/incidents/${id}`, body);
    return data;
  }

  async SelfAssign(id: string): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).patch(`/v1/incidents/${id}/responsible`);
    return data;
  }

  async SetResponsible(id: string, responsibleID: string): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).patch(`/v1/incidents/${id}/assign`, { responsibleID });
    return data;
  }

  async GetListIncidentChat(id: string, locale = "EN"): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<any>(`/v1/incidents/${id}/chats`, { headers: { "Accept-Language": locale } });
    return data;
  }
  async GetListIncidentChatByUser(params: object, locale = "EN"): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<any>(`/v1/chats`, { params, headers: { "Accept-Language": locale } });
    return data;
  }
}
