<script lang="ts">
import { defineComponent } from "vue";
import { useProfileEmergencyContactStore } from "./stores/useProfileEmergencyContactStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";

export default defineComponent({
  name: "ProfileEmergencyContactView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          popup: {
            success: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.profileEmergencyContactStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.confirm_detail"),
                functionProp: () => {
                  this.profileEmergencyContactStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.profileEmergencyContactStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
          },
          input: {
            emergencyContact: {
              name: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.profileEmergencyContactStore.error[this.profileEmergencyContactStore.isRequired.emergencyContact.name.name] || "").length > 0,
                  isIcon: false,
                  isLoading: this.loading(),
                  isDisable: this.loading(),
                  isRequired: this.profileEmergencyContactStore.isRequired.emergencyContact.name.is,
                  textError: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact.name.placeholder),
                  title: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact.name.label),
                  placeholder: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact.name.placeholder),
                  value: this.profileEmergencyContactStore?.result?.emergencyContact?.name,
                  name: this.profileEmergencyContactStore.isRequired.emergencyContact.name.name,
                  isKeydownText: true,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.profileEmergencyContactStore.result.emergencyContact.name = value;
                },
              },
              contactNumber: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.profileEmergencyContactStore.error[this.profileEmergencyContactStore.isRequired.emergencyContact.contactNumber.name] || "").length > 0,
                  isIcon: false,
                  isLoading: this.loading(),
                  isDisable: this.loading(),
                  isRequired: this.profileEmergencyContactStore.isRequired.emergencyContact?.contactNumber.is,
                  textError: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact?.contactNumber.placeholder),
                  title: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact?.contactNumber.label),
                  placeholder: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact?.contactNumber.placeholder),
                  value: this.profileEmergencyContactStore?.result?.emergencyContact?.contactNumber,
                  name: this.profileEmergencyContactStore.isRequired.emergencyContact?.contactNumber.name,
                  isKeydownNumber: true,
                  maxLength: 25,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.profileEmergencyContactStore.result.emergencyContact.contactNumber = value;
                },
              },
              identificationNumber: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.profileEmergencyContactStore.error[this.profileEmergencyContactStore.isRequired.emergencyContact.identificationNumber.name] || "").length > 0,
                  isIcon: false,
                  isLoading: this.loading(),
                  isDisable: this.loading(),
                  isRequired: this.profileEmergencyContactStore.isRequired.emergencyContact?.identificationNumber.is,
                  textError: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact?.identificationNumber.placeholder),
                  title: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact?.identificationNumber.label),
                  placeholder: this.$t(this.profileEmergencyContactStore.isRequired.emergencyContact?.identificationNumber.placeholder),
                  value: this.profileEmergencyContactStore?.result?.emergencyContact?.identificationNumber,
                  name: this.profileEmergencyContactStore.isRequired.emergencyContact?.identificationNumber.name,
                  isKeydownNumber: true,
                  maxLength: 25,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.profileEmergencyContactStore.result.emergencyContact.identificationNumber = value;
                },
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: (e: Event) => {
                this.profileEmergencyContactStore.onSubmit(e);
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.profileEmergencyContactStore.loading;
    },
    async fetch() {
      this.profileEmergencyContactStore.loading = true;

      await Promise.all([
        (async () => {
          const fetch = new fetchAuth();
          const user = await fetch.Get();
          this.profileEmergencyContactStore.result = user;
        })(),
      ]);
      this.profileEmergencyContactStore.loading = false;
    },
    async reset() {
      await this.profileEmergencyContactStore.$reset();
      await this.authStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const profileEmergencyContactStore = useProfileEmergencyContactStore();
    const authStore = useAuthStore();
    return {
      profileEmergencyContactStore,
      authStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'ProfileMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.ProfileEmergencyContactView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="w-full mt-0 p-4">
              <div>
                <div class="grid grid-cols-12 gap-2 py-4">
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.emergencyContact.name.component" v-bind="defaultFormComponents.form.input.emergencyContact.name.props" @updateValue="defaultFormComponents.form.input.emergencyContact.name.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.emergencyContact.contactNumber.component" v-bind="defaultFormComponents.form.input.emergencyContact.contactNumber.props" @updateValue="defaultFormComponents.form.input.emergencyContact.contactNumber.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.emergencyContact.identificationNumber.component" v-bind="defaultFormComponents.form.input.emergencyContact.identificationNumber.props" @updateValue="defaultFormComponents.form.input.emergencyContact.identificationNumber.onUpdateValue" />
                </div>
                <div class="ttp-between border-t-2 pt-4">
                  <div class="flex gap-2 w-full">
                    <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="profileEmergencyContactStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.popup.success.component" v-bind="defaultFormComponents.form.popup.success.props" />
</template>

<style style="scss"></style>
