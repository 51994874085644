import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchGuest } from "@/configs/fetches/PoliceCommandCenter/fetchGuest";
import { fetchTourist } from "@/configs/fetches/PoliceCommandCenter/fetchTourist";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { useIncidentFormStore } from "./useIncidentFormStore";
import { ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { convertToValues, removeEmptyValues } from "@/configs/helpers/MainHelper";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";

const storeName = `useIncidentShareSearchStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: null,
  },
};
export const useIncidentShareSearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    fetchGuest: new fetchGuest(),
    fetchTourist: new fetchTourist(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: true,
    isRequired,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    currentPage: 1,
    totalPage: 1,
    tableResults: null as any,
    result: convertToValues(isRequired) as typeGetQuery,
    validationSchema: yup.object({
      keyword: isRequired.keyword.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async gotoPage(page: number, event?: MouseEvent) {
      event?.preventDefault();
      try {
        this.loading = true;
        this.currentPage = page;
        this.tableResults = await this.keyword({
          page: this.currentPage,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async nextPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage < this.totalPage) {
        try {
          this.loading = true;
          this.currentPage++;
          this.tableResults = await this.keyword({
            page: this.currentPage,
          });
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      }
    },
    async prevPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage > 1) {
        try {
          this.loading = true;
          this.currentPage--;
          this.tableResults = await this.keyword({
            page: this.currentPage,
          });
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      }
    },
    async onUpdateCurrentPage(currentPage: number, params: typeGetQuery) {
      try {
        this.loading = true;
        this.currentPage = currentPage;
        this.tableResults = await this.keyword({
          page: this.currentPage,
          ...params,
        } as typeGetQuery);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      this.result = removeEmptyValues(this.result, []);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        this.loading = true;
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        await this.keyword(this.result);
        this.loading = false;
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
          this.loading = false;
        });
      }
    },
    async keyword(results: typeGetQuery) {
      const formStore = useIncidentFormStore();
      const data = formStore.fetchIncident.List({
        ...results,
        size: Number(import.meta.env.VITE_PAGE_SIZE),
      });

      return data;
    },
  },
});
