<script lang="ts">
import { defineComponent } from "vue";
import { useRegisterStore } from "./stores/useRegisterStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useAuthStore } from "./stores/useAuthStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "RegisterView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            email: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerStore.error.email || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                isDisable: this.loading(),
                componentIcon: this.$solidIcons.EnvelopeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isRequired: this.registerStore.isRequired.email.is,
                textError: this.$t(this.registerStore.isRequired.email.placeholder),
                title: this.$t(this.registerStore.isRequired.email.label),
                placeholder: this.$t(this.registerStore.isRequired.email.placeholder),
                value: this.registerStore.result.email,
                name: this.registerStore.isRequired.email.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: string) => {
                this.registerStore.result.email = value;
              },
            },
            contactNumber: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerStore.error.contactNumber || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                isDisable: this.loading(),
                componentIcon: this.$solidIcons.PhoneIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                textError: this.$t(this.registerStore.isRequired.contactNumber.placeholder),
                title: this.$t(this.registerStore.isRequired.contactNumber.label),
                placeholder: this.$t(this.registerStore.isRequired.contactNumber.placeholder),
                value: this.registerStore.result.contactNumber,
                name: this.registerStore.isRequired.contactNumber.name,
                isKeydownNumber: true,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerStore.result.contactNumber = value;
              },
            },
            newPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerStore.error.newPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isDisable: this.loading(),
                isRequired: this.registerStore.isRequired.newPassword.is,
                textError: this.$t(this.registerStore.isRequired.newPassword.placeholder),
                title: this.$t(this.registerStore.isRequired.newPassword.label),
                placeholder: this.$t(this.registerStore.isRequired.newPassword.placeholder),
                value: this.registerStore.result.newPassword,
                name: this.registerStore.isRequired.newPassword.name,
                type: this.registerStore.isRequired.newPassword.type,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.registerStore.result.newPassword = value;
              },
              onType: () => {
                this.registerStore.isRequired.newPassword.type = this.registerStore.isRequired.newPassword.type == "password" ? "text" : "password";
              },
            },
            repeatPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.registerStore.error.repeatPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                isDisable: this.loading(),
                isRequired: this.registerStore.isRequired.repeatPassword.is,
                textError: this.$t(this.registerStore.isRequired.repeatPassword.placeholder),
                title: this.$t(this.registerStore.isRequired.repeatPassword.label),
                placeholder: this.$t(this.registerStore.isRequired.repeatPassword.placeholder),
                value: this.registerStore.result.repeatPassword,
                name: this.registerStore.isRequired.repeatPassword.name,
                type: this.registerStore.isRequired.repeatPassword.type,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: string) => {
                this.registerStore.result.repeatPassword = value;
              },
              onType: () => {
                this.registerStore.isRequired.repeatPassword.type = this.registerStore.isRequired.repeatPassword.type == "password" ? "text" : "password";
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_next"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                if (e) e.preventDefault();
                await this.registerStore
                  .onSubmit(e)
                  .then(async (result: any) => {
                    if (result.status) {
                      if (!result.isError) {
                        this.$router.push({ name: "RegisterInformationView" });
                      }
                    } else {
                      if (!result.isError) {
                        this.registerStore.logout();
                      }
                    }
                  })
                  .catch((error) => {
                    console.error("error", error);
                  });
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.registerStore.loading;
    },
    async fetch() {
      await Promise.all([this.masterDataStore.listTitle(), this.masterDataStore.listLocale(), this.masterDataStore.listRole(), this.masterDataStore.listNationality(), this.masterDataStore.listIncidentCategory(), this.masterDataStore.listProvince(), this.masterDataStore.listCountry()]);
      if (isCommandCenterApp) await Promise.all([this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0)]);
    },
  },
  setup() {
    const authStore = useAuthStore();
    const registerStore = useRegisterStore();
    const masterDataStore = useMasterDataStore();
    const sharedStore = useSharedStore();
    return {
      authStore,
      registerStore,
      masterDataStore,
      sharedStore,
    };
  },
  async created() {
    await this.masterDataStore.listNationality();

    if (this.authStore.registerProfile && this.authStore.registerProfile.provider !== "EMAIL") {
      this.registerStore.provider = this.authStore.registerProfile.provider;
      this.registerStore.uid = this.authStore.registerProfile.uid;
      this.registerStore.result.email = this.registerStore.result.email || this.authStore.registerProfile.email;
      this.registerStore.result.contactNumber = this.registerStore.result.contactNumber || this.authStore.registerProfile.phoneNumber;
      this.registerStore.result.name = this.registerStore.result.name || this.authStore.registerProfile.displayName;
    }
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.push({ name: 'HomeView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="text-white font-bold text-center ttp-text-2xl">{{ $t("page.RegisterView.title") }}</h1>
              <p class="text-white text-center">{{ $t("page.RegisterView.desc") }}</p>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl">{{ $t("page.RegisterView.create") }}</h1>
                <p class="text-black">{{ $t("page.RegisterView.login") }} <RouterLink :to="{ name: 'LoginView' }" class="w-full m-auto">Login</RouterLink></p>
                <div>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.email.props" @updateValue="defaultFormComponents.form.input.email.onUpdateValue" />
                    <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ $t("caution.email_1") }}</small>
                    <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.contactNumber.props" @updateValue="defaultFormComponents.form.input.contactNumber.onUpdateValue" />
                    <template v-if="!authStore.registerProfile || !authStore.registerProfile?.provider?.length || authStore.registerProfile?.provider === 'EMAIL'">
                      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.newPassword.component" v-bind="defaultFormComponents.form.input.newPassword.props" @updateValue="defaultFormComponents.form.input.newPassword.onUpdateValue" @type="defaultFormComponents.form.input.newPassword.onType" />
                      <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ $t("caution.password_1") }}</small>
                      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.repeatPassword.component" v-bind="defaultFormComponents.form.input.repeatPassword.props" @updateValue="defaultFormComponents.form.input.repeatPassword.onUpdateValue" @type="defaultFormComponents.form.input.repeatPassword.onType" />
                    </template>
                  </div>
                  <div class="ttp-between border-t-2 pt-4">
                    <div class="flex gap-2 w-full">
                      <RouterLink v-if="false" :to="{ name: 'RegisterInformationView' }" class="w-full m-auto">
                        <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" />
                      </RouterLink>
                      <component v-else :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
