<script lang="ts">
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import PageSidebarComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageSidebarComponent.vue";
import PageHeaderComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageHeaderComponent.vue";
import PageContentComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageContentComponent.vue";
import PageFooterComponent from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/PageFooterComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";
import { NativeAudio } from "@capacitor-community/native-audio";
import { Capacitor } from "@capacitor/core";
import { useRoute } from "vue-router";

declare var Media: any;

export default defineComponent({
  name: "AdminLayoutView",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    PageSidebarComponent,
    PageHeaderComponent,
    PageContentComponent,
    PageFooterComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  methods: {
    async fetch() {
      const processes = [this.masterDataStore.listTitle(), this.masterDataStore.listLocale(), this.masterDataStore.listRole(), this.masterDataStore.listNationality(), this.masterDataStore.listIncidentFriendlyCategory(), this.masterDataStore.listIncidentCategory(), this.masterDataStore.listProvince()];

      if (isCommandCenterApp) {
        processes.push(this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0));
      }

      await Promise.all(processes);
    },
  },
  async mounted() {
    if (this.$route.meta.requiresAuth) {
      await this.fetch();
    }
    if (!Capacitor.isNativePlatform()) {
      await NativeAudio.preload({
        assetId: "call",
        assetPath: new URL(`@/assets/sounds/phone-outgoing-call-72202.mp3`, import.meta.url).href,
        isUrl: true,
        volume: 1,
      });
      await NativeAudio.preload({
        assetId: "hang_up",
        assetPath: new URL(`@/assets/sounds/mobile_phone_hanging_up-94525.mp3`, import.meta.url).href,
        isUrl: true,
        volume: 1,
      });
      await NativeAudio.preload({
        assetId: "waiting",
        assetPath: new URL(`@/assets/sounds/ringtone-126505.mp3`, import.meta.url).href,
        isUrl: true,
        volume: 1,
      });
    }
  },
  unmounted() {},
  setup() {
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const masterDataStore = useMasterDataStore();

    return {
      sharedStore,
      masterDataStore,
      route: useRoute(),
    };
  },
});
</script>

<template>
  <div
    id="page-container"
    class="flex flex-col mx-auto w-full min-h-screen min-w-[320px] bg-gray-100 dark:text-gray-100 dark:bg-gray-900"
    :class="{
      'lg:pl-64': sharedStore.desktopSidebarOpen && !route.meta?.fullScreen,
      dark: sharedStore.mode == 'true' ? false : true,
    }"
  >
    <PageSidebarComponent v-if="!route.meta?.fullScreen" />
    <PageHeaderComponent v-if="!route.meta?.fullScreen" />
    <PageContentComponent />
    <PageFooterComponent v-if="!route.meta?.fullScreen" />
  </div>
</template>
