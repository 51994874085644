import { ENUM_APP_CATEGORY_NAME, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";
import { convertToValues, convertToNumber, inArray } from "@/configs/helpers/MainHelper";
import type { typeGetResetPassword, typeGetUser } from "@/configs/types/User/typeUser";
import { defineStore } from "pinia";
import * as yup from "yup";
import YupPassword from "yup-password";
import { getProjectType } from "@/routers";
import { fetchAuth as fetchCommandAuth } from "@/configs/fetches/PoliceCommandCenter/fetchAuth";
import { fetchAuth as fetchAgentAuth } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchAuth";

YupPassword(yup);

const storeName = `usePasswordSettingStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  // nowPassword: {
  //   is: true,
  //   name: `nowPassword`,
  //   label: `form.nowPassword.label`,
  //   placeholder: `form.nowPassword.placeholder`,
  //   value: null,
  // },
  newPassword: {
    is: true,
    name: `newPassword`,
    label: `form.newPassword.label`,
    placeholder: `form.newPassword.placeholder`,
    value: null,
  },
  repeatPassword: {
    is: true,
    name: `repeatPassword`,
    label: `form.repeatPassword.label`,
    placeholder: `form.repeatPassword.placeholder`,
    value: null,
  },
};

export const usePasswordSettingStore = defineStore(storeName, {
  state: () => ({
    fetchUser: new fetchUser(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as typeGetResetPassword,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      // nowPassword: yup.string().min(8, "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร"),
      newPassword: yup.string().min(8, "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร").minLowercase(1).minUppercase(1).minNumbers(1),
      repeatPassword: yup.string().when(["newPassword"], (newPassword, schema) => {
        return newPassword[0] !== undefined ? schema.oneOf([yup.ref("newPassword")], "รหัสผ่านไม่ตรงกัน").required("กรุณายืนยันรหัสผ่าน") : schema.notRequired();
      }),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
        return await this.update(this.result);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update(result: typeGetResetPassword): Promise<typeGetUser> {
      try {
        this.loading = true;
        const fetch = inArray(getProjectType(), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? new fetchCommandAuth() : new fetchAgentAuth();
        const data = await fetch.PasswordUpdate({
          password: result?.newPassword,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;
        this.isSuccess = true;

        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
