<script setup lang="ts">
import { fetchReport, type IncidentReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { computed, onMounted, ref } from "vue";
import { Bar } from "vue-chartjs";
import { Chart, Colors, type ChartOptions } from "chart.js";

Chart.register(Colors);

dayjs.extend(relativeTime);

type DataSet = { label: string; data: number[]; backgroundColor?: any };

const { from, to, channel, group, aspectRatio } = defineProps<{
  from: Dayjs;
  to: Dayjs;
  channel?: string;
  group: "status";
  aspectRatio?: number;
}>();

const fetch = new fetchReport();
const report = ref<{ [key: string]: IncidentReport }>({});
const lastUpdate = ref<Dayjs>();

const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  aspectRatio,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const reload = async () => {
  report.value = await fetch.IncidentReport(
    {
      startDate: from.format("YYYY-MM-DD"),
      endDate: to.format("YYYY-MM-DD"),
      channel,
    },
    group,
    "month"
  );

  lastUpdate.value = dayjs();
};

const chartData = computed(() => {
  const totalMonth = to.diff(from, "month") + 1;
  const labels = new Array(totalMonth).fill(from).map((v: Dayjs, i) => v.clone().add(i, "month").format("MMM YYYY"));

  const keys = Object.keys(report.value).map((d) => dayjs(d).format("MMM YYYY"));

  const dataset: DataSet = {
    label: group,
    data: labels.map((d) => {
      const index = keys.findIndex((v) => v === d);
      if (index === -1) {
        return 0;
      }

      return Object.values(Object.values(report.value)[index]).reduce((s, v) => s + v, 0);
    }),
  };
  const datasets = [dataset];

  return {
    labels,
    datasets,
  };
});

onMounted(reload);
defineExpose({ reload });
</script>

<template>
  <!-- Card Headings: Title with Subtitle -->
  <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100 h-full">
    <!-- Card Header -->
    <div class="card-title bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
      <h3 class="mb-1 font-semibold">
        <slot name="title">จำนวนผู้ใช้งานใหม่</slot>
      </h3>
    </div>
    <!-- END Card Header -->

    <!-- Card Body -->
    <div class="chart-wrap grow p-5">
      <Bar v-if="chartData.datasets.length" :data="chartData" :options="chartOptions" />
    </div>
    <!-- Card Body -->
  </div>
  <!-- END Card Headings: Title with Subtitle -->
</template>

<style scoped>
.chart-wrap {
  min-height: 0;
}
</style>
