import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useSettingSecurityPrivacyStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  is_track_location: {
    is: false,
    name: `form.is_track_location.name`,
    label: `form.is_track_location.label`,
    placeholder: `form.is_track_location.placeholder`,
    value: null,
  },
  is_camera: {
    is: false,
    name: `form.is_camera.name`,
    label: `form.is_camera.label`,
    placeholder: `form.is_camera.placeholder`,
    value: null,
  },
  is_photo: {
    is: false,
    name: `form.is_photo.name`,
    label: `form.is_photo.label`,
    placeholder: `form.is_photo.placeholder`,
    value: null,
  },
  is_microphone: {
    is: false,
    name: `form.is_microphone.name`,
    label: `form.is_microphone.label`,
    placeholder: `form.is_microphone.placeholder`,
    value: null,
  },
};

export const useSettingSecurityPrivacyStore = defineStore(storeName, {
  state: () => ({
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as any,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({}),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
  },
});
