import type { $string } from "@/configs/types/Shared/typeShare";

type SelectItem = { label: $string; value: $string };

export class FormatDateHelper {
  static date(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  }
}
export class FormatIntegerHelper {}
export class FormatFloatHelper {}
export class FormatStringtHelper {
  static capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  static truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }
}
export class FormatNounHelper {
  static formatCurrency(amount: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }
}

export const contentToSelect = (list: any, locale: string, key: 'fullName' | 'shortName' = 'fullName'): SelectItem[] => {
  return list
    .map((item: { content: any[]; code: any }) => {
      const label = item.content.find((content: { locale: string }) => content.locale === locale)?.[key] || item.content.find((content: { locale: string }) => content.locale === "EN")?.name || ""; // Fallback to an empty string if neither locale nor English content is found
      return { label, value: item.code };
    })
    .sort((a: SelectItem, b: SelectItem) => (a.label || "").localeCompare(b.label || ""));
};

export const contentToSelectNationality = (list: any, locale: string): SelectItem[] => {
  return list
    .map((item: { content: any[]; code: any }) => {
      const label = item.content.find((content: { locale: string }) => content.locale === locale)?.name || item.content.find((content: { locale: string }) => content.locale === "EN")?.name || "";
      return { label, value: item.code };
    })
    .sort((a: SelectItem, b: SelectItem) => (a.label || "").localeCompare(b.label || ""));
};

export const contentToSelectExternalDepartments = (list: any, locale: string): SelectItem[] => {
  return list
    .map((item: { content: any[]; id: any }) => {
      const label = item.content.find((content: { locale: string }) => content.locale === locale)?.name || item.content.find((content: { locale: string }) => content.locale === "EN")?.name || "";
      return { label, value: item.id };
    })
    .sort((a: SelectItem, b: SelectItem) => (a.label || "").localeCompare(b.label || ""));
};

export const contentToSelectCountry = (list: any, locale: string): SelectItem[] => {
  return list
    .map((item: { content: any[]; code: any }) => {
      const label = item.content.find((content: { locale: string }) => content.locale === locale)?.name || item.content.find((content: { locale: string }) => content.locale === "EN")?.name || "";
      return { label, value: item.code };
    })
    .sort((a: SelectItem, b: SelectItem) => (a.label || "").localeCompare(b.label || ""));
};

export const contentToSelectIncident = (list: any, locale: string): SelectItem[] => {
  return list
    .map((item: { content: any[]; code: any }) => {
      const label = item.content.find((content: { locale: string }) => content.locale === locale)?.title || item.content.find((content: { locale: string }) => content.locale === "EN")?.name || "";
      return { label, value: item.code };
    })
    .sort((a: SelectItem, b: SelectItem) => (a.label || "").localeCompare(b.label || ""));
};
