<template>
  <div v-show="callStatus !== CALL_STATUS.FREE" class="call-dialog fixed top-20 right-10">
    <audio ref="localAudio" autoplay />
    <audio ref="remoteAudio" autoplay />

    <div class="rounded bg-blue-100 p-4 text-blue-700 dark:bg-blue-900/75 dark:text-blue-100 md:p-5">
      <h4 class="mb-1 font-semibold">มีสายเรียกเข้าจากนักท่องเที่ยว</h4>
      <p class="mb-5 text-gray-600 dark:text-gray-400">
        {{ incident?.tourist.name }} ({{ nationality?.name }})<br />

        <router-link :to="{ name: 'IncidentManageView', params: { id: incident?.id } }"
          class="text-blue-600 underline hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300">
          คลิกเพื่อเปิดหน้าจัดการเหตุการณ์ </router-link>
      </p>
      <div class="flex flex-wrap items-center gap-2">
        <a v-if="callStatus === CALL_STATUS.RINGING"
          class="flex-1 inline-flex items-center justify-center gap-2 rounded-lg border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-semibold leading-5 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90"
          @click.prevent="pickup">
          <svg class="hi-mini hi-check inline-block size-4 opacity-50" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
              clip-rule="evenodd" />
          </svg>
          <span>รับสาย</span>
        </a>
        <span v-if="callStatus === CALL_STATUS.CONNECTING"
          class="flex-1 inline-flex items-center justify-center gap-2 rounded-lg border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-semibold leading-5 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90">
          กำลังเชื่อมต่อ </span>
        <span v-else-if="callStatus === CALL_STATUS.ON_CALL"
          class="flex-1 inline-flex items-center justify-center gap-2 rounded-lg border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-semibold leading-5 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400/50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400/90">
          {{ timer }}
        </span>
        <a class="flex-1 inline-flex items-center justify-center gap-2 rounded-lg border border-transparent bg-white px-3 py-2 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-transparent dark:bg-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700"
          @click.prevent="hangup">
          <span>วางสาย</span>
        </a>
      </div>
      <div class="mt-2">
        <select name="select"
          class="block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:focus:border-blue-500" v-model="audioInput" @change="onChangeAudioInput">
          <option v-for="a in audioInputs" :value="a.deviceId">{{ a.label }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import { useSharedStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useIncidentFormStore } from "@/views/PoliceCommandCenter/IncidentManagement/stores/useIncidentFormStore";
import { io } from "socket.io-client";
import { computed } from "vue";
import { onMounted, onBeforeUnmount, ref } from "vue";

const authStore = useAuthStore();
const incidetStore = useIncidentFormStore();
const masterStore = useMasterDataStore();
const sharedStore = useSharedStore();

enum CALL_STATUS {
  FREE,
  RINGING,
  CONNECTING,
  ON_CALL,
}

const socket = io(import.meta.env.VITE_VOICE_SOCKET_URL, {
  path: "/voice",
  secure: true,
  transports: ["websocket", "polling"],
  forceNew: true,
  upgrade: true,
});

const callStatus = ref<CALL_STATUS>(CALL_STATUS.FREE);
const roomId = ref<string | null>(null);
const incident = ref<typeGetIncident | null>(null);
const localStream = ref<MediaStream | null>(null);
const remoteStream = ref<MediaStream | null>(null);
const localAudio = ref<HTMLMediaElement>();
const remoteAudio = ref<HTMLMediaElement>();
const counter = ref<Number>(0);
const candidateQueue: RTCIceCandidate[] = [];
const audioInputs = ref<MediaDeviceInfo[]>([])
const audioInput = ref<string>('default')
let hasRemote: boolean = false;

let pc: RTCPeerConnection | null;
const counterIntervalId = ref<NodeJS.Timeout | undefined>();

const nationality = computed(() => masterStore.nationalities.find((n) => n.code === incident.value?.tourist?.nationality)?.content?.find((c) => c.locale === "TH"));
const timer = computed((): string => {
  const h: number = Math.floor(counter.value.valueOf() / 3600);
  const m: number = Math.floor((counter.value.valueOf() % 3600) / 60);
  const s: number = counter.value.valueOf() % 60;
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")} น.`;
});

const intervalCounter = () => {
  counter.value = counter.value.valueOf() + 1;
};

const createPeerConnection = (iceServers: RTCIceServer[]) => {
  console.log(`[${new Date().toISOString()}] createPeerConnection`);
  pc = new RTCPeerConnection({
    iceServers,
    rtcpMuxPolicy: "require",
    iceCandidatePoolSize: 2,
  });
  pc.onicecandidate = (e: RTCPeerConnectionIceEvent) => {
    console.log("onicecandidate", e);
    if (e.candidate) {
      socket.emit("candidate", {
        type: "candidate",
        candidate: e.candidate,
        sendTo: roomId.value,
      });
    }
  };

  pc.onicecandidateerror = (e: RTCPeerConnectionIceErrorEvent) => {
    console.log(`[${new Date().toISOString()}] onicecandidateerror`, e);
  };

  pc.onicegatheringstatechange = (e: Event) => {
    console.log(`[${new Date().toISOString()}] onicegatheringstatechange`, e, { iceGatheringState: pc?.iceGatheringState });
    if (pc?.iceGatheringState === "complete") {
      callStatus.value = CALL_STATUS.ON_CALL;
      counterIntervalId.value = setInterval(intervalCounter, 1000);
    }
  };

  pc.ontrack = (event: RTCTrackEvent) => {
    const [stream] = event.streams;
    remoteStream.value = stream;

    if (localStream.value && localAudio.value) {
      console.log(`[${new Date().toISOString()}] addLocalStream`, localStream.value);

      localAudio.value.srcObject = localStream.value as MediaStream;
      localAudio.value.volume = 0;
    }

    if (remoteStream.value && remoteAudio.value) {
      console.log(`[${new Date().toISOString()}] onAddRemoteStream`, remoteStream.value);
      remoteAudio.value.srcObject = remoteStream.value as MediaStream;
      remoteAudio.value.volume = 1;
    }
  };
};

const pickup = async () => {
  console.log(`[${new Date().toISOString()}] pickup`);
  callStatus.value = CALL_STATUS.CONNECTING;

  localStream.value = await navigator.mediaDevices.getUserMedia({
    audio: {
      noiseSuppression: true,
      echoCancellation: true,
      deviceId: { exact: audioInput.value }
    },
  });

  if (localStream.value && localAudio.value) {
    console.log(`[${new Date().toISOString()}] addLocalStream`, localStream.value);
    localAudio.value.srcObject = localStream.value as MediaStream;
    localAudio.value.volume = 0;
  }

  console.log(`[${new Date().toISOString()}] join room`);
  console.log(`[${new Date().toISOString()}] incident`, incident.value);
  socket.emit("join", { code: roomId.value, incidentId: incident.value?.id });
};

const hangup = async () => {
  onHang({
    incidentId: incident.value ? (incident.value?.id as string) : undefined,
  });
  // emit
  socket.emit("hangup", { code: roomId.value });
};

const onConnected = () => {
  console.log("connected on voice dialog");
};

const onRinging = async (data: { incidentId: string; roomId: string }) => {
  console.log(`[${new Date().toISOString()}] ringing`);
  const call = await incidetStore.fetchIncident.Get(data.incidentId);
  if (authStore.user?.locales.includes(call.tourist?.locale || "") && callStatus.value !== CALL_STATUS.RINGING) {
    incident.value = call;
    roomId.value = data.roomId;
    callStatus.value = CALL_STATUS.RINGING;

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      console.log({ devices })
      audioInputs.value = devices.filter(({ kind }) => kind === 'audioinput')
    });
    sharedStore.stopWaitingCall();
  }
};

const onHang = ({ incidentId }: { incidentId?: string }) => {
  if (incidentId?.length && incidentId !== incident.value?.id) {
    console.log("still on call");
    return;
  }

  console.log("hangup");

  incident.value = null;
  callStatus.value = CALL_STATUS.FREE;

  // unset local stream
  localStream.value?.getAudioTracks()?.forEach((track) => (track.enabled = false));
  localStream.value?.getTracks()?.forEach((track) => track.stop());
  localStream.value = null;

  // unset remote stream
  if (remoteAudio.value) {
    remoteAudio.value?.pause();
    remoteAudio.value.srcObject = null;
  }

  // unset RTC connector
  pc?.close();
  pc = null;
  hasRemote = false;

  // clean up counter
  if (counterIntervalId.value) {
    clearInterval(counterIntervalId.value);
  }
  counter.value = 0;

  sharedStore.endCall();
};

const onOffer = async (param: any) => {
  console.log(`[${new Date().toISOString()}] on offer`, param);
  roomId.value = param.caller;

  await createPeerConnection(param.iceServers);

  await pc?.setRemoteDescription(param.description);
  hasRemote = true;
  console.log(`[${new Date().toISOString()}] addLocalTrack`, localStream.value);
  localStream.value?.getAudioTracks().forEach((track) => {
    pc?.addTrack(track, localStream.value as MediaStream);
  });

  const sdp = await pc?.createAnswer();
  await pc?.setLocalDescription(sdp);

  console.log(`[${new Date().toISOString()}] answering`, sdp);
  socket.emit("answer", {
    type: "answer",
    description: pc?.localDescription,
    caller: roomId.value,
  });
};

const onCandidate = async (event: any) => {
  console.log(`[${new Date().toISOString()}] on candidate, ${hasRemote ? "adding" : "queueing"}`);
  candidateQueue.push(event.candidate);

  if (hasRemote) {
    manageCandidates();
  }
};

const manageCandidates = async () => {
  if (candidateQueue.length > 0) {
    const candidate = candidateQueue.shift();
    try {
      console.log(`[${new Date().toISOString()}] adding candidate`, candidate);
      await pc?.addIceCandidate(new RTCIceCandidate(candidate));
    } catch (e) {
      console.log(`[${new Date().toISOString()}] failed to add candidate`, e);
    }

    await manageCandidates();
  }
};

const onChangeAudioInput = async () => {
  console.log('onChangeAudioInput')
  const constraints = {
    audio: { deviceId: { exact: audioInput.value }}
  }

  const stream = await navigator.mediaDevices.getUserMedia(constraints)
  const track = stream.getAudioTracks().find((t) => t.kind === 'audio')
  const senders = pc?.getSenders()
  const sender = senders?.find((s) => s.track?.kind === 'audio')

  console.log({ track, senders, sender })
  if (sender && track) {
    console.log('replacing track')
    sender.replaceTrack(track)
  }
}

onMounted(() => {
  socket.connect();

  socket.on("connect", onConnected);
  socket.on("ringing", onRinging);
  socket.on("hangup", onHang);
  socket.on("candidate", onCandidate);
  socket.on("offer", onOffer);
});

onBeforeUnmount(() => {
  socket.off("connect");
  socket.off("ringing");
  socket.off("hangup");
  socket.off("candidate");
  socket.off("offer");

  socket.disconnect();
});
</script>

<style scoped>
.call-dialog {
  z-index: 20;
}
</style>
