<script lang="ts">
import { defineComponent, ref } from "vue";
import { useForgotPasswordOTPStore } from "./stores/useForgotPasswordOTPStore";
import FormInputLargeComponent from "@/components/Forms/Input/FormInputLargeComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useForgotPasswordStore } from "./stores/useForgotPasswordStore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { isCommandCenterApp, setLocalStorage } from "@/configs/helpers/MainHelper";
import { AUTH_PROFILE_KEY, AUTH_TOKEN_KEY, useAuthStore } from "./stores/useAuthStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";

export default defineComponent({
  name: "ForgotPasswordOTPView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      sharedStoreName: this.$inArray(this.$getProjectType(), [this.$enums.ENUM_APP_CATEGORY_NAME.COMMAND]) ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            number_1: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.forgotPasswordOTPStore.error.number_1 || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.forgotPasswordOTPStore.isRequired.number_1.is,
                textError: this.$t(this.forgotPasswordOTPStore.isRequired.number_1.placeholder),
                title: this.$t(this.forgotPasswordOTPStore.isRequired.number_1.label),
                placeholder: this.$t(this.forgotPasswordOTPStore.isRequired.number_1.placeholder),
                value: this.forgotPasswordOTPStore.result.number_1,
                name: this.forgotPasswordOTPStore.isRequired.number_1.name,
                maxLength: 1,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: any) => {
                this.forgotPasswordOTPStore.result.number_1 = value;
                const nextInputEl = document.querySelector(`[el=number_2]`);
                if (nextInputEl) (nextInputEl as any).focus();
              },
            },
            number_2: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.forgotPasswordOTPStore.error.number_2 || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.forgotPasswordOTPStore.isRequired.number_2.is,
                textError: this.$t(this.forgotPasswordOTPStore.isRequired.number_2.placeholder),
                title: this.$t(this.forgotPasswordOTPStore.isRequired.number_2.label),
                placeholder: this.$t(this.forgotPasswordOTPStore.isRequired.number_2.placeholder),
                value: this.forgotPasswordOTPStore.result.number_2,
                name: this.forgotPasswordOTPStore.isRequired.number_2.name,
                maxLength: 1,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: any) => {
                this.forgotPasswordOTPStore.result.number_2 = value;
                const nextInputEl = document.querySelector(`[el=number_3]`);
                if (nextInputEl) (nextInputEl as any).focus();
              },
            },
            number_3: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.forgotPasswordOTPStore.error.number_3 || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.forgotPasswordOTPStore.isRequired.number_3.is,
                textError: this.$t(this.forgotPasswordOTPStore.isRequired.number_3.placeholder),
                title: this.$t(this.forgotPasswordOTPStore.isRequired.number_3.label),
                placeholder: this.$t(this.forgotPasswordOTPStore.isRequired.number_3.placeholder),
                value: this.forgotPasswordOTPStore.result.number_3,
                name: this.forgotPasswordOTPStore.isRequired.number_3.name,
                maxLength: 1,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: any) => {
                this.forgotPasswordOTPStore.result.number_3 = value;
                const nextInputEl = document.querySelector(`[el=number_4]`);
                if (nextInputEl) (nextInputEl as any).focus();
              },
            },
            number_4: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.forgotPasswordOTPStore.error.number_4 || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.forgotPasswordOTPStore.isRequired.number_4.is,
                textError: this.$t(this.forgotPasswordOTPStore.isRequired.number_4.placeholder),
                title: this.$t(this.forgotPasswordOTPStore.isRequired.number_4.label),
                placeholder: this.$t(this.forgotPasswordOTPStore.isRequired.number_4.placeholder),
                value: this.forgotPasswordOTPStore.result.number_4,
                name: this.forgotPasswordOTPStore.isRequired.number_4.name,
                maxLength: 1,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.forgotPasswordOTPStore.result.number_4 = value;
                const nextInputEl = document.querySelector(`[el=number_5]`);
                if (nextInputEl) (nextInputEl as any).focus();
              },
            },
            number_5: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.forgotPasswordOTPStore.error.number_5 || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.forgotPasswordOTPStore.isRequired.number_5.is,
                textError: this.$t(this.forgotPasswordOTPStore.isRequired.number_5.placeholder),
                title: this.$t(this.forgotPasswordOTPStore.isRequired.number_5.label),
                placeholder: this.$t(this.forgotPasswordOTPStore.isRequired.number_5.placeholder),
                value: this.forgotPasswordOTPStore.result.number_5,
                name: this.forgotPasswordOTPStore.isRequired.number_5.name,
                maxLength: 1,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.forgotPasswordOTPStore.result.number_5 = value;
                const nextInputEl = document.querySelector(`[el=number_6]`);
                if (nextInputEl) (nextInputEl as any).focus();
              },
            },
            number_6: {
              component: FormInputLargeComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.forgotPasswordOTPStore.error.number_6 || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.forgotPasswordOTPStore.isRequired.number_6.is,
                textError: this.$t(this.forgotPasswordOTPStore.isRequired.number_6.placeholder),
                title: this.$t(this.forgotPasswordOTPStore.isRequired.number_6.label),
                placeholder: this.$t(this.forgotPasswordOTPStore.isRequired.number_6.placeholder),
                value: this.forgotPasswordOTPStore.result.number_6,
                name: this.forgotPasswordOTPStore.isRequired.number_6.name,
                maxLength: 1,
              } as typeof FormInputLargeComponent.props,
              onUpdateValue: (value: $string) => {
                this.forgotPasswordOTPStore.result.number_6 = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_submit"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
                classTagName: "w-full",
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                const result = await this.forgotPasswordOTPStore.onSubmit(e);
                if (result?.token) {
                  const auth = getAuth();
                  signInWithCustomToken(auth, result?.token).then(async (userCredential) => {
                    const user = userCredential.user;
                    this.authStore.token = result?.token;
                    await setLocalStorage(AUTH_TOKEN_KEY, result?.token);
                    await setLocalStorage(`${this.sharedStoreName}:getProjectType`, this.$getProjectType());
                    await setLocalStorage(
                      AUTH_PROFILE_KEY,
                      JSON.stringify({
                        email: user?.email,
                        phoneNumber: user?.phoneNumber,
                        displayName: user?.displayName,
                        provider: "EMAIL",
                      })
                    );

                    this.$router.push({ name: "ForgotPasswordNewPasswordView" });
                  });
                } else {
                  this.$router.push({ name: "ForgotPasswordOTPView" });
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.forgotPasswordOTPStore.loading;
    },
  },
  async created() {
    if (!this.forgotPasswordStore.result.email) this.$router.push({ name: "ForgotPasswordView" });
  },
  setup() {
    const forgotPasswordOTPStore = useForgotPasswordOTPStore();
    const forgotPasswordStore = useForgotPasswordStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const authStore = useAuthStore();

    const countDown = ref(60);
    const countDownVisible = ref(false);
    const intervalId = ref();

    const startCountDown = async () => {
      countDownVisible.value = true;

      const result = await forgotPasswordStore.onSubmit();
      if (result?.ref) forgotPasswordStore.result.ref = result?.ref;

      intervalId.value = setInterval(() => {
        countDown.value--;

        if (countDown.value === 0) {
          clearInterval(intervalId.value);
          countDownVisible.value = false;
        }
      }, 1000);
    };

    return {
      forgotPasswordOTPStore,
      forgotPasswordStore,
      authStore,
      sharedStore,
      countDown,
      countDownVisible,
      startCountDown,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left"
              @click="
                () => {
                  $router.push({ name: 'ForgotPasswordView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">Forgot Password</h1>
            </div>
            <div class="w-10"></div>
          </div>
          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h1 class="text-black font-bold ttp-text-2xl text-center my-4">{{ $t("page.ForgotPasswordView.otp") }}</h1>
                <p class="text-black text-center">{{ $t("page.ForgotPasswordView.otp_send_to") }}</p>
                <p class="text-black text-center text-ttp-primary-10 font-medium mb-4">{{ $convertString(forgotPasswordStore.result.email) }}</p>
                <div class="flex flex-col">
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <component classTagName="col-start-3 col-end-5 md:col-start-4 md:col-end-5" :is="defaultFormComponents.form.input.number_1.component" v-bind="defaultFormComponents.form.input.number_1.props" @updateValue="defaultFormComponents.form.input.number_1.onUpdateValue" />
                    <component classTagName="col-start-5 col-end-7 md:col-start-5 md:col-end-6" :is="defaultFormComponents.form.input.number_2.component" v-bind="defaultFormComponents.form.input.number_2.props" @updateValue="defaultFormComponents.form.input.number_2.onUpdateValue" />
                    <component classTagName="col-start-7 col-end-9 md:col-start-6 md:col-end-7" :is="defaultFormComponents.form.input.number_3.component" v-bind="defaultFormComponents.form.input.number_3.props" @updateValue="defaultFormComponents.form.input.number_3.onUpdateValue" />
                    <component classTagName="col-start-9 col-end-11 md:col-start-7 md:col-end-8" :is="defaultFormComponents.form.input.number_4.component" v-bind="defaultFormComponents.form.input.number_4.props" @updateValue="defaultFormComponents.form.input.number_4.onUpdateValue" />
                    <component classTagName="col-start-9 col-end-11 md:col-start-8 md:col-end-9" :is="defaultFormComponents.form.input.number_5.component" v-bind="defaultFormComponents.form.input.number_5.props" @updateValue="defaultFormComponents.form.input.number_5.onUpdateValue" />
                    <component classTagName="col-start-9 col-end-11 md:col-start-9 md:col-end-10" :is="defaultFormComponents.form.input.number_6.component" v-bind="defaultFormComponents.form.input.number_6.props" @updateValue="defaultFormComponents.form.input.number_6.onUpdateValue" />
                  </div>
                  <p v-if="countDownVisible" class="text-black text-center">{{ $t("page.ForgotPasswordView.otp_resend") }} {{ countDown }} วินาที</p>
                  <a v-if="!countDownVisible" href="#" @click="startCountDown" class="text-black text-center text-ttp-primary-10 font-medium m-auto">{{ $t("btn.btn_resend_code") }}</a>
                  <div class="ttp-between mt-4">
                    <div class="flex gap-2 w-full">
                      <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
