<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="map.viewBox" :aria-label="map.label" class="svg-map">
    <slot name="before" />
    <path v-for="(location, index) in map.locations" :id="location.id" :key="location.id" :name="location.name"
      :d="location.path" class="svg-map__location"
      :class="isLocationClassFunction ? (locationClass as Function)(location, index) : locationClass"
      :tabindex="isLocationTabindexFunction ? (locationTabindex as Function)(location, index) : locationTabindex"
      :role="locationRole" :aria-label="location.name"
      :aria-checked="isLocationSelected && isLocationSelected(location, index)"
      v-bind="getLocationCustomProperties(location)" />
    <slot name="after" />
  </svg>
</template>

<style scoped>
svg {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

svg path {
  fill: lightgray;
  stroke: rgb(186, 185, 185);
}

svg path.level-1 {
  fill: #fff33b;
}
svg path.level-2 {
  fill: #fdc70c;
}
svg path.level-3 {
  fill: #f3903f;
}
svg path.level-4 {
  fill: #ed683c;
}
svg path.level-5 {
  fill: #e93e3a;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';

const LOCATION_DEFAULT_PROPERTIES = ['id', 'name', 'path']

export type Location = {
  name: string;
  id: string;
  path: string;
}

export type Map = {
  label: string;
  viewBox: string;
  locations: Location[]
}

const { locationClass, locationTabindex } = defineProps<{
  map: Map,
  locationClass?: string | Function
  locationTabindex?: string | Function
  locationRole?: string
  isLocationSelected?: Function
}>()

const isLocationClassFunction = computed<boolean>(() => typeof locationClass === 'function')
const isLocationTabindexFunction = computed<boolean>(() => typeof locationTabindex === 'function')

const getLocationCustomProperties = (location: any) => Object.fromEntries(
  Object.entries(location).filter(([key]) => !LOCATION_DEFAULT_PROPERTIES.includes(key))
)
</script>
