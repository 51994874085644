<script lang="ts">
// @ts-nocheck
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { defineComponent, reactive } from "vue";

import type { $string } from "@/configs/types/Shared/typeShare";
import loadImage from "blueimp-load-image";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Geolocation } from "@capacitor/geolocation";
import { useSharedStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { TransitionRoot, Dialog, DialogPanel } from "@headlessui/vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import { isCommandCenterApp, setLocalStorage } from "@/configs/helpers/MainHelper";
import { useInfiniteScroll } from "@vueuse/core";
import _ from "lodash";
import { useChatFormStore } from "./stores/useChatFormStore";
import { useHistorySearchStore } from "./stores/useHistorySearchStore";
import { useIncidentFormStore } from "./stores/useIncidentFormStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";

const ChatDialogComponent = defineComponent({
  name: "ChatDialog",
  components: {
    TransitionRoot,
    Dialog,
    DialogPanel,
    LoadingFormComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
      policeProfileImage: new URL(`@/assets/images/police-profile.svg`, import.meta.url).href,
      isMobile: false,
      selectedItem: null,
      isVisible: false,
      caller: "",
      incidentID: null as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            message: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.chatFormStore.error[`message`] || "").length > 0,
                isIcon: false,
                isDisable: this.chatFormStore.loading,
                isRequired: this.chatFormStore.isRequired.message.is,
                textError: "",
                title: this.$t(this.chatFormStore.isRequired.message.label),
                name: `message`,
                placeholder: this.$t(this.chatFormStore.isRequired.message.placeholder),
                value: this.chatFormStore.result.message,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.chatFormStore.result.message = value;
                this.chatFormStore.message = value;
              },
            },
            name: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: true,
                isVisible: true,
                isError: (this.chatFormStore.locationError[this.chatFormStore.isRequiredLocation.name.name] || "").length > 0,
                isIcon: false,
                isDisable: false,
                isRequired: this.chatFormStore.isRequiredLocation.name.is,
                textError: this.$t(this.chatFormStore.isRequiredLocation.name.placeholder),
                title: this.$t(this.chatFormStore.isRequiredLocation.name.label),
                placeholder: this.$t(this.chatFormStore.isRequiredLocation.name.placeholder),
                value: this.chatFormStore?.resultLocation?.name,
                name: this.chatFormStore.isRequiredLocation.name.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.chatFormStore.resultLocation.name = value;
              },
            },
          },
          button: {
            btnManage: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_manage"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    async generateAfterSubmit() {
      setTimeout(async () => {
        await this.$configLightbox();
      }, 10);
      this.scrollBottom(100);
    },
    async changeFile(e: any) {
      await this.chatFormStore.handleFileChange(e);
      await this.generateAfterSubmit();
    },
    async checkVoiceCall() {
      this.sharedStore.permissionResults = [];
      this.sharedStore.isLoadingPermission = true;
      Promise.all([
        (async () => {
          return await this.getMicrophonePermission();
        })(),
        (async () => {
          return await this.getLocationPermission();
        })(),
        (async () => {
          return await this.getPhotoLibraryPermission();
        })(),
      ]).then(([resultA, resultB, resultC]) => {
        if (resultA === true && resultB === true && resultC === true) {
          // this.$router.push({ name: "VoiceCallView", params: { id: this.$route.params.id ? this.$route.params.id : this.incidentID }, query: { type: this.$enums.ENUM_VOICE_TYPE.TOURIST_MAKE_A_CALL_CREATE, roomId: "0", permission: this.$getProjectType() } });

          this.sharedStore.isShowPermission = false;
        } else {
          // POPUP
          this.sharedStore.isShowPermission = true;
        }
        this.sharedStore.isLoadingPermission = false;
      });
    },
    async openAppSetting() {
      if (Capacitor.isNativePlatform()) {
        await (this.$PermissionsPlugin as any).openAppSettings();
      }
    },
    async geolocationToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        try {
          await navigator.geolocation.getCurrentPosition(
            async () => {
              await setLocalStorage(this.sharedStore.is_track_location, "granted");
            },
            async () => {
              await setLocalStorage(this.sharedStore.is_track_location, "denied");
            }
          );
        } catch (error) {
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
        }
      }
    },
    async microphoneToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach((track) => track.stop());
          await setLocalStorage(this.sharedStore.is_microphone, "granted");
        } catch (error) {
          this.openAppSetting();
          await setLocalStorage(this.sharedStore.is_microphone, "denied");
        }
      }
    },
    async socketVoiceOnReceived() {
      await this.$voiceSocket.on("message.received", (payload: any) => {
        if (this.$window.location.href.indexOf("chat") !== -1) {
          switch (payload?.type) {
            case this.$enums.ENUM_VOICE_TYPE.TOURIST_MAKE_A_CALL_CREATE:
              // this.$router.push({
              //   name: "VoiceCallView",
              //   params: { id: this.$route.params.id ? this.$route.params.id : this.incidentID },
              //   query: { type: this.$enums.ENUM_VOICE_TYPE.TOURIST_WAITING, roomId: payload?.roomId, permission: this.$getProjectType() },
              // });
              break;
          }
        } else if (payload?.permission == "command") {
          switch (payload?.type) {
            case this.$enums.ENUM_VOICE_TYPE.COMMAND_CENTER_MAKE_A_CALL_CREATE:
              // this.$router.push({
              //   name: "VoiceCallView",
              //   params: { id: this.$route.params.id ? this.$route.params.id : this.incidentID },
              //   query: { type: this.$enums.ENUM_VOICE_TYPE.TOURIST_WAITING, roomId: payload?.roomId, permission: this.$getProjectType() },
              // });
              break;
          }
        }
      });
    },
    async connectChat() {
      if (this.$chatSocket) {
        await this.chatFormStore.setIncident(String(this.$route.params.id ? this.$route.params.id : this.incidentID), this.$enums.ENUM_CHAT_SENDER.USER, this.authStore?.user?.id);
        await this.$chatSocket.connect();
      }
    },
    async connectVoice() {
      if (this.$voiceSocket) {
        this.$voiceSocket.auth = {
          incidentID: this.$route.params.id ? this.$route.params.id : this.incidentID,
          userType: isCommandCenterApp ? this.$enums.ENUM_CHAT_SENDER.USER : this.$enums.ENUM_CHAT_SENDER.TOURIST,
          userID: this.authStore?.user?.id,
        };
        await this.$voiceSocket.connect();
      }
    },
    async socketChatOnReceived() {
      await this.$chatSocket.on("message.received", async (payload: any) => {
        if (payload?.message?.type !== this.$enums.ENUM_CHAT_TYPE.MAKE_A_CALL) {
          this.chatFormStore.roomId = payload?.message?.content?.roomId;
          this.chatFormStore.startAt = payload?.message?.content?.startAt;
          this.chatFormStore.endAt = payload?.message?.content?.endAt;
          this.chatFormStore.replyID = payload?.refID;
          await setLocalStorage(`roomId`, payload?.message?.content?.roomId);

          // Get Image
          if (payload?.message?.type == this.$enums.ENUM_CHAT_TYPE.IMAGES) {
            if (payload?.message?.content?.length) {
              payload.message.content = await Promise.all(await this.chatFormStore.fetchFile.Get(payload?.message?.content));
              await this.generateAfterSubmit();
            }
          }

          await this.chatFormStore.messages.push(payload);
          this.scrollBottom(100);
        }
      });
    },
    closeCamera() {
      if (this.chatFormStore.stream) {
        this.chatFormStore.stream.getTracks().forEach((track: any) => track.stop());
        this.chatFormStore.stream = null;
      }
      this.chatFormStore.isCamera = !this.chatFormStore.isCamera;
    },
    async startCamera() {
      try {
        if (Capacitor.isNativePlatform()) {
          this.chatFormStore.photo = null;

          const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
          });

          const response = await fetch(capturedPhoto.webPath!);
          const blob = await response.blob();

          const currentDate = new Date();
          const dateString = currentDate.toISOString().replace(/[:.]/g, "-");
          const fileName = `custom_${dateString}.jpg`;

          const file = new File([blob], fileName, { type: "image/jpeg" });

          const results = await this.chatFormStore.fetchFile.Upload([file]);
          this.chatFormStore.imageIds = await results.map((item: any) => item.id);
          this.chatFormStore.imageUrls = await this.chatFormStore.fetchFile.Get(this.chatFormStore.imageIds);

          this.chatFormStore.update(this.$enums.ENUM_CHAT_TYPE.IMAGES, this.chatFormStore.imageIds);
          this.chatFormStore.messages[this.$findIndexById(this.chatFormStore.messages, this.chatFormStore.id)].child = "Hide";
          await this.generateAfterSubmit();
        } else {
          this.chatFormStore.photo = null;
          this.chatFormStore.isCamera = !this.chatFormStore.isCamera;
          this.chatFormStore.stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              facingMode: "environment",
            },
          });

          (this.$refs as any).video.srcObject = this.chatFormStore.stream;

          (this.$refs as any).video.onloadedmetadata = () => {
            this.chatFormStore.ready = true;
          };

          (this.$refs as any).video.onended = () => {
            this.chatFormStore.ready = false;
            this.chatFormStore.stream = null;
          };
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    },
    capturePhoto() {
      let video = (this.$refs as any).video;

      let videoCanvas = document.createElement("canvas");
      videoCanvas.height = video.videoHeight;
      videoCanvas.width = video.videoWidth;
      let videoContext = videoCanvas.getContext("2d") as any;

      videoContext.drawImage(video, 0, 0);

      this.chatFormStore.photo = loadImage.scale(videoCanvas, {});

      this.uploadPhoto();

      this.chatFormStore.stream.getTracks().forEach((track: any) => track.stop());
      this.chatFormStore.stream = null;
    },
    uploadPhoto() {
      this.chatFormStore.photo.toBlob(async (blob: any) => {
        const currentDate = new Date();
        const dateString = currentDate.toISOString().replace(/[:.]/g, "-");
        const fileName = `custom_${dateString}.jpg`;

        const file = new File([blob], fileName, { type: "image/jpeg" });

        const results = await this.chatFormStore.fetchFile.Upload([file]);
        this.chatFormStore.imageIds = await results.map((item: any) => item.id);
        this.chatFormStore.imageUrls = await this.chatFormStore.fetchFile.Get(this.chatFormStore.imageIds);

        this.chatFormStore.update(this.$enums.ENUM_CHAT_TYPE.IMAGES, this.chatFormStore.imageIds);
        this.chatFormStore.messages[this.$findIndexById(this.chatFormStore.messages, this.chatFormStore.id)].child = "Hide";
        await this.generateAfterSubmit();
      }, "image/jpeg");
    },
    toggleSelect() {
      this.isMobile = !this.isMobile;
    },
    async setting() {
      await this.fetch();
      await this.fetchScroll();

      if (this.$route.params.id ? this.$route.params.id : this.incidentID) await this.getMessageRoom();
      await this.generateAfterSubmit();

      await setLocalStorage("roomId", "");
    },
    async fetch() {
      if (this.$route.params.id ? this.$route.params.id : this.incidentID) {
        await Promise.all([
          (async () => {
            // Get Incident
            this.incidentFormStore.result = await this.incidentFormStore.fetchIncident.Get(String(this.$route.params.id ? this.$route.params.id : this.incidentID));

            // Get Image
            if (this?.incidentFormStore?.result?.images?.length) {
              this.incidentFormStore.result.images = await Promise.all(await this.incidentFormStore.fetchFile.Get(this?.incidentFormStore?.result?.images));
            }
            await this.generateAfterSubmit();
          })(),
        ]);
      }
    },
    async fetchScroll() {
      if (!this.historySearchStore.loadingScroll) {
        this.historySearchStore.currentPage = this.historySearchStore.currentPage + 1;

        let results = (await this.historySearchStore.onUpdateUnlimitedScrollCurrentPage(this.historySearchStore.currentPage)) as any;

        if (!this.historySearchStore.filterTableResults) {
          this.historySearchStore.tableResults = results;
          this.historySearchStore.filterTableResults = _.cloneDeep(results);
          this.historySearchStore.totalPage = results?.totalPages;
        } else {
          this.historySearchStore.tableResults.items.push(...results?.items);
          this.historySearchStore.tableResults.totalPage = this.historySearchStore.tableResults.totalPages;
          results = this.historySearchStore.tableResults;
        }
        this.historySearchStore.filterTableResults.items = results.items;
        this.historySearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
        this.historySearchStore.loadingScroll = false;

        await this.scrollBottom(100);
      }
    },
    async getMessageRoom() {
      this.chatFormStore.messages = await this.chatFormStore.fetchIncident.GetListIncidentChat(String(this.$route.params.id ? this.$route.params.id : this.incidentID), this.masterDataStore.lang());

      // Last Auto Reply
      if (this.chatFormStore.messages.length) {
        // Get Image
        if (this.$findIndexesOfImages(this.chatFormStore.messages).length) {
          await Promise.all(
            this.$findIndexesOfImages(this.chatFormStore.messages).map(async (index) => {
              this.chatFormStore.messages[index].message.content = await this.chatFormStore.fetchFile.Get(this.chatFormStore.messages[index]?.message?.content);
            })
          );
          await this.generateAfterSubmit();
        }

        // Get Image From Google Map
        if (this.$findIndexesOfLocations(this.chatFormStore.messages).length) {
          await Promise.all(
            this.$findIndexesOfLocations(this.chatFormStore.messages).map(async (index) => {
              const data = this.chatFormStore.messages[index]?.message?.content;
              const result = Object.values({
                latitude: data?.latitude,
                longitude: data?.longitude,
              }).join(",");

              this.chatFormStore.messages[index].message.content = {
                ...this.chatFormStore.messages[index]?.message?.content,
                url: await this.chatFormStore.getStaticMap(result),
              };
            })
          );
        }

        // Last Auto Reply
        const lastIndex = await this.chatFormStore.messages.map((obj: any) => obj.sender).lastIndexOf(this.$enums.ENUM_CHAT_SENDER.SYSTEM);
        this.chatFormStore.replyID = this.chatFormStore.messages[lastIndex].id;
      }

      await this.scrollBottom(100);
    },
    loading() {
      return false;
    },
    async reset() {
      await this.chatFormStore.$reset();
      await this.incidentFormStore.$reset();
      await setLocalStorage("roomId", "");
    },
    scrollBottom(number: number) {
      setTimeout(() => {
        if (this.$refs.scrollContainer) {
          const container = this.$refs.scrollContainer as HTMLElement;

          container.scrollTop = container.scrollHeight;
        }
        this.chatFormStore.loading = false;
      }, number);
    },
    settingMap() {
      this.chatFormStore.resultLocation.latitude = 13.736717;
      this.chatFormStore.resultLocation.longitude = 100.523186;
      this.initializeMap();
      this.scrollBottom(100);
    },
    async initializeMap() {
      const google = await this.$asyncGoogleMapsLoader();
      const { Map } = (await google.maps.importLibrary("maps")) as any;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as any;

      var thailandCoordinates = { lat: 13.736717, lng: 100.523186 };

      const map = new Map(document.getElementById("map_chat") as HTMLElement, {
        center: thailandCoordinates,
        zoom: 14,
        mapId: "4504f8b37365c3d0",
        mapTypeId: "roadmap",
      });

      const draggableMarker = new AdvancedMarkerElement({
        map,
        position: thailandCoordinates,
        gmpDraggable: true,
        title: "This marker is draggable.",
      });

      if (navigator.geolocation) {
        const position = await Geolocation.getCurrentPosition();
        var selfCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.chatFormStore.resultLocation.latitude = position.coords.latitude;
        this.chatFormStore.resultLocation.longitude = position.coords.longitude;

        map.setCenter(selfCoordinates);
        draggableMarker.position = selfCoordinates;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: selfCoordinates },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("name") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.chatFormStore.resultLocation.name = result.results[0].formatted_address;
      }

      draggableMarker.addListener("dragend", async (e: any) => {
        const position = draggableMarker.position as any;
        this.chatFormStore.resultLocation.latitude = position.lat;
        this.chatFormStore.resultLocation.longitude = position.lng;

        const newPosition = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        } as any;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: newPosition },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("name") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.chatFormStore.resultLocation.name = result.results[0].formatted_address;
      });

      const input = document.getElementById("name") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as any);
      });

      let markers: any[] = [];
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) return;
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place: any) => {
          if (!place.geometry || !place.geometry.location) return;

          draggableMarker.position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          this.chatFormStore.resultLocation.latitude = place.geometry.location.lat();
          this.chatFormStore.resultLocation.longitude = place.geometry.location.lng();
          this.chatFormStore.resultLocation.name = `${place.name}, ${place.formatted_address}`;

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
    },
    async scrollFetch() {
      useInfiniteScroll(
        this.el,
        async () => {
          if (this.historySearchStore.totalPage > this.historySearchStore.currentPage) await this.fetchScroll();
        },
        { distance: 10 }
      );
    },
    async connect() {
      await this.connectChat();
      await this.socketChatOnReceived();
      //   await this.connectVoice();
      //   await this.socketVoiceOnReceived();
    },
    async disconnect() {
      await this.$chatSocket.off("message.received");
      await this.$chatSocket.disconnect();

      this.$voiceSocket.off("ready");
      await this.$voiceSocket.off("candidate");
      await this.$voiceSocket.off("offer");
      await this.$voiceSocket.off("answer");
      await this.$voiceSocket.off("message.received");
      await this.$voiceSocket.disconnect();
    },
    async getPhotoLibraryPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          try {
            await this.$PermissionsPlugin.requestPhoto();
          } catch (_error) {}

          const result = await this.$PermissionsPlugin
            .checkPermissionPhoto()
            .then(async (_result: any) => {
              console.log("PhotoLibrary permission:", _result.value);
              await setLocalStorage(this.sharedStore.is_photo, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Photo");
              else this.$pushUnique(this.sharedStore.permissionResults, "Photo");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              console.log("PhotoLibrary permission denied", _error);
              await setLocalStorage(this.sharedStore.is_photo, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Photo");
              return false;
            });

          return result;
        } catch (_error) {
          console.log("PhotoLibrary permission denied", _error);
          await setLocalStorage(this.sharedStore.is_photo, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Photo");
          return false;
        }
      } else {
        console.log("PhotoLibrary permission:");
        await setLocalStorage(this.sharedStore.is_photo, "granted");
        this.$popUnique(this.sharedStore.permissionResults, "Photo");
        return true;
      }
    },
    async getCameraPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          await this.$PermissionsPlugin.requestCamera();

          const result = await this.$PermissionsPlugin
            .checkPermissionCamera()
            .then(async (_result: any) => {
              console.log("Camera permission:", _result.value);
              await setLocalStorage(this.sharedStore.is_camera, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Camera");
              else this.$pushUnique(this.sharedStore.permissionResults, "Camera");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              console.log("Camera permission denied", _error);
              await setLocalStorage(this.sharedStore.is_camera, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Camera");
              return false;
            });
          return result;
        } catch (_error) {
          console.log("Camera permission denied", _error);
          await setLocalStorage(this.sharedStore.is_camera, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Camera");
          return false;
        }
      } else {
        try {
          const permission = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
          if (permission) permission.getTracks().forEach((track) => track.stop());
          console.log("Camera permission:");
          await setLocalStorage(this.sharedStore.is_camera, "granted");
          this.$popUnique(this.sharedStore.permissionResults, "Camera");
          return true;
        } catch (_error) {
          console.log("Camera permission denied");
          await setLocalStorage(this.sharedStore.is_camera, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Camera");
          return false;
        }
      }
    },
    async getMicrophonePermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          await this.$PermissionsPlugin.requestMicrophone();

          const result = await this.$PermissionsPlugin
            .checkPermissionMicrophone()
            .then(async (_result: any) => {
              console.log("Microphone permission:", _result.value);
              await setLocalStorage(this.sharedStore.is_microphone, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Microphone");
              else this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              console.log("Microphone permission denied", _error);
              await setLocalStorage(this.sharedStore.is_microphone, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
              return false;
            });
          return result;
        } catch (_error) {
          console.log("Microphone permission denied", _error);
          await setLocalStorage(this.sharedStore.is_microphone, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
          return false;
        }
      } else {
        try {
          const permission = await navigator.mediaDevices.getUserMedia({ audio: true });
          permission.getTracks().forEach((track) => track.stop());
          console.log("Microphone permission:");
          await setLocalStorage(this.sharedStore.is_microphone, "granted");
          this.$popUnique(this.sharedStore.permissionResults, "Microphone");
          return true;
        } catch (_error) {
          console.log("Microphone permission denied");
          await setLocalStorage(this.sharedStore.is_microphone, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Microphone");
          return false;
        }
      }
    },
    async getLocationPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          // const permission = await this.$PermissionsPlugin.requestLocation();
          await Geolocation.getCurrentPosition().then((permission: any) => {
            this.sharedStore.position = permission;
          });

          const result = await this.$PermissionsPlugin
            .checkPermissionLocation()
            .then(async (_result: any) => {
              console.log("Location permission:", _result.value);
              await setLocalStorage(this.sharedStore.is_track_location, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Location");
              else this.$pushUnique(this.sharedStore.permissionResults, "Location");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              console.log("Location permission denied");
              await setLocalStorage(this.sharedStore.is_track_location, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Location");
              return false;
            });

          return result;
        } catch (_error) {
          console.log("Location permission denied", _error);
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Location");
          return false;
        }
      } else {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              async (permission) => {
                this.sharedStore.position = permission;

                console.log("Location permission:");
                await setLocalStorage(this.sharedStore.is_track_location, "granted");
                this.$popUnique(this.sharedStore.permissionResults, "Location");
                resolve(true);
              },
              async (_error) => {
                console.log("Location permission denied");
                await setLocalStorage(this.sharedStore.is_track_location, "denied");
                this.$pushUnique(this.sharedStore.permissionResults, "Location");
                reject(false);
              }
            );
          });
          return position;
        } catch (_error) {
          console.log("Location permission denied");
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Location");
          return false;
        }
      }
    },
    async getNotificationsPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          const permission = await this.$PermissionsPlugin.requestNotification();
          console.log("Notifications permission:", permission);

          const result = await this.$PermissionsPlugin
            .checkPermissionNotification()
            .then(async (_result: any) => {
              console.log("Notifications permission:", _result.value);
              await setLocalStorage(this.sharedStore.is_push_notification, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Notification");
              else this.$pushUnique(this.sharedStore.permissionResults, "Notification");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              console.log("Notifications permission denied");
              await setLocalStorage(this.sharedStore.is_push_notification, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Notification");
              return false;
            });
          return result;
        } catch (_error) {
          console.log("Notifications permission denied");
          await setLocalStorage(this.sharedStore.is_push_notification, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Notification");
          return false;
        }
      } else {
        try {
          const permission = (await PushNotifications.requestPermissions()) as any;
          if (permission === "granted") {
            console.log("Notifications permission:");
            await setLocalStorage(this.sharedStore.is_push_notification, "granted");
            this.$popUnique(this.sharedStore.permissionResults, "Notification");
            return true;
          } else {
            console.log("Notifications permission denied");
            await setLocalStorage(this.sharedStore.is_push_notification, "denied");
            this.$pushUnique(this.sharedStore.permissionResults, "Notification");
            return false;
          }
        } catch (_error) {
          console.log("Notifications permission denied");
          await setLocalStorage(this.sharedStore.is_push_notification, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Notification");
          return false;
        }
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) this.incidentID = this.$route.params.id;

    await this.connect();
    await this.setting();
    await this.scrollFetch();
  },
  async unmounted() {
    await this.reset();
    await this.disconnect();
  },
  setup() {
    const authStore = useAuthStore();
    const chatFormStore = useChatFormStore();
    const sharedStore = useSharedStore();
    const historySearchStore = useHistorySearchStore();
    const incidentFormStore = useIncidentFormStore();
    const masterDataStore = useMasterDataStore();

    var state = reactive({
      el: null,
    });

    return { authStore, chatFormStore, sharedStore, historySearchStore, incidentFormStore, masterDataStore, el: state.el, state };
  },
});
export default ChatDialogComponent;
</script>

<template>
  <div class="flex w-full h-[520px] bg-white">
    <div class="flex flex-col w-full h-full border-l-[1px]">
      <div class="flex flex-col w-full border-b-[1px] p-4 bg-ttp-chat-right">
        <div class="flex gap-2 items-center justify-center w-full">
          <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="inline-block ttp-icon-inside-box-03"><path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path></svg>
          </div>
          <div class="w-full">
            <LoadingFormComponent v-if="chatFormStore.loading" />
            <div v-else class="ttp-text-md text-white">{{ incidentFormStore?.result?.tourist?.name }} ({{ $t("form.case_number.label") }}: {{ incidentFormStore?.result?.incidentNumber }})</div>
            <div v-if="false" class="ttp-text-xs text-[#64748B]">{{ $t("page.ChatView.reply_message") }}</div>
          </div>
          <div>
            <button @click="checkVoiceCall" v-if="true && !$inArray(historySearchStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.COMPLETED, $enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REJECTED])">
              <component :is="$solidIcons.PhoneIcon" class="flex-none inline-block ttp-icon-inside-box-03 mx-1 text-white" />
            </button>
          </div>
          <div>
            <button @click="incidentFormStore.isChat = !incidentFormStore.isChat"><component :is="incidentFormStore.isChat ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="flex-none inline-block ttp-icon-inside-box-03 mx-1 text-white" /></button>
          </div>
        </div>
      </div>
      <div ref="scrollContainer" class="flex flex-col gap-2 h-full border-b-[1px] p-4 overflow-auto">
        <LoadingFormComponent v-if="chatFormStore.loading" class="m-auto" />
        <template v-else v-for="item in chatFormStore.messages" :key="item.refID">
          <div v-if="item.sender == $enums.ENUM_CHAT_SENDER.TOURIST" class="ttp-tourist-chat flex flex-col gap-2">
            <span class="text-[#64748B] text-[12px]">{{ historySearchStore?.result?.tourist?.name }}</span>
            <div class="flex w-full">
              <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.TEXT">
                <div class="flex bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                  <div class="relative pb-5">
                    <div class="text-black ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.SELECTED">
                <div class="bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                  <div class="relative pb-5">
                    <div class="text-black ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.IMAGES">
                <div v-if="item?.message?.content" class="w-full">
                  <div class="bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                    <div class="relative pb-5">
                      <div class="text-black ttp-text-xs">{{ $t("layout.select_picture") }}</div>
                    </div>
                  </div>
                  <div class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                    <div class="relative pb-5">
                      <div class="swipe-photo flex gap-2 w-full items-start justify-start">
                        <LoadingFormComponent v-if="$isArrayOfStringsOrObjects(item?.message?.content)" />
                        <a :href="_item?.url ? _item?.url : '#'" target="_blank" v-else v-for="(_item, index) in item?.message?.content" :key="index">
                          <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-left mr-2" :src="_item?.url" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="bg-ttp-chat-left border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                  <div class="relative pb-5">
                    <div class="text-black ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.LOCATION">
                <a v-if="item?.message?.content?.latitude" :href="`https://maps.google.com/?q=${item?.message?.content?.latitude},${item?.message?.content?.longitude}`" target="_blank" class="bg-ttp-chat-left border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full pb-2 overflow-hidden">
                  <div class="relative">
                    <div class="w-full mb-2">
                      <img :src="item?.message?.content?.url" class="object-cover object-center w-full h-36 max-w-full" />
                    </div>
                    <div class="px-3">
                      <div class="text-black ttp-text-xs" v-html="item?.message?.content?.name"></div>
                      <hr class="my-1 md:my-2" />
                      <div class="flex gap-1 items-center">
                        <div><component :is="$solidIcons.MapPinIcon" class="inline-block m-auto text-[#7B61FF] ttp-icon-inside-box-03 cursor-pointer" /></div>
                        <div class="text-[#7B61FF] ttp-text-xs pt-1">Location</div>
                        <div class="w-full text-right"><component :is="$solidIcons.ChevronRightIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-[#7B61FF]" /></div>
                      </div>
                    </div>
                  </div>
                </a>
                <div v-else class="bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                  <div class="relative pb-5">
                    <div class="text-black ttp-text-xs">{{ $t("btn.btn_non") }}</div>
                  </div>
                </div>
              </template>
              <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.MAKE_A_CALL">
                <div class="flex bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                  <div class="flex flex-col gap-3">
                    <div class="flex items-center gap-3">
                      <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-[#2C3483]"><component :is="$solidIcons.PhoneIcon" class="flex-none inline-block ttp-icon-inside-box-03 mx-1 text-white" /></div>
                      <div class="text-black ttp-text-xs">{{ $t("page.ChatView.make_a_call") }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.HANG_UP">
                <div class="flex bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                  <div class="flex flex-col gap-3">
                    <div class="flex items-center gap-3">
                      <div class="text-black ttp-text-xs">{{ $t("page.ChatView.hang_up") }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.ANSWER_A_CALL">
                <div class="flex bg-ttp-chat-left border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg w-full p-3">
                  <div class="flex flex-col gap-3">
                    <div class="flex items-center gap-3">
                      <div class="text-black ttp-text-xs">{{ $t("page.ChatView.answer_a_call") }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="w-0 lg:w-20"></div>
            </div>
            <span class="text-[#64748B] text-[12px]">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }} {{ $t("page.ChatView.response_voice_call") }}</span>
          </div>

          <div v-else-if="$inArray(item.sender, [$enums.ENUM_CHAT_SENDER.SYSTEM])" class="ttp-police-chat flex flex-col gap-2">
            <div class="flex w-full">
              <div class="w-12 lg:w-20"></div>
              <div class="bg-ttp-chat-right flex flex-col border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3 gap-1">
                <div class="text-white relative">
                  <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.TEXT">
                    <div class="ttp-text-xs mb-2" v-html="$replaceString(item?.message?.content?.message, '%name%', $convertString(authStore?.user?.name))"></div>
                  </template>
                  <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.SELECT">
                    <div class="relative">
                      <div class="ttp-text-xs mb-2" v-html="item?.message?.content?.message"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="relative text-ttp-primary ttp-text-sm">
              <div class="text-[12px] text-[#64748B] text-right">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }} {{ $t("page.ChatView.response_bot") }}</div>
            </div>
          </div>

          <div v-else-if="$inArray(item.sender, [$enums.ENUM_CHAT_SENDER.USER])" class="ttp-police-chat flex flex-col gap-2">
            <div class="flex w-full">
              <div class="w-12 lg:w-20"></div>
              <div class="bg-ttp-chat-right flex flex-col border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3 gap-1">
                <div class="text-white relative">
                  <template v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.TEXT">
                    <div class="ttp-text-xs mb-2">{{ item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item) }}</div>
                  </template>
                  <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.IMAGES">
                    <div v-if="item?.message?.content" class="w-full">
                      <div class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                        <div class="relative pb-5">
                          <div class="swipe-photo flex gap-2 w-full items-end justify-end">
                            <LoadingFormComponent v-if="$isArrayOfStringsOrObjects(item?.message?.content)" />
                            <a :href="_item?.url ? _item?.url : '#'" target="_blank" v-else v-for="(_item, index) in item?.message?.content" :key="index">
                              <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-left mr-2" :src="_item?.url" alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="bg-ttp-chat-left border rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none w-full p-3">
                      <div class="relative pb-5">
                        <div class="text-black ttp-text-xs" v-html="item?.content ? item?.content : $hasNameAndValue(item?.message) ? (item?.message?.content.name ? item?.message?.content.name : $findPlaceholder(item)) : item?.message?.content ? item?.message?.content : $findPlaceholder(item)"></div>
                      </div>
                    </div>
                  </template>
                  <template div v-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.MAKE_A_CALL">
                    <div class="flex items-center gap-3">
                      <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-white"><component :is="$solidIcons.PhoneIcon" class="flex-none inline-block ttp-icon-inside-box-03 mx-1 text-ttp-primary" /></div>
                      <div class="ttp-text-xs mb-2">{{ $t("page.ChatView.make_a_call") }}</div>
                    </div>
                  </template>
                  <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.HANG_UP">
                    <div class="flex items-center gap-3">
                      <div class="ttp-text-xs mb-2">{{ $t("page.ChatView.hang_up") }}</div>
                    </div>
                  </template>
                  <template v-else-if="item?.message?.type == $enums.ENUM_CHAT_TYPE.ANSWER_A_CALL">
                    <div class="flex items-center gap-3">
                      <div class="ttp-text-xs mb-2">{{ $t("page.ChatView.answer_a_call") }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="relative text-ttp-primary ttp-text-sm">
              <div class="text-[12px] text-[#64748B] text-right">{{ $dayjs(item?.datetime ? item?.datetime : item?.createdAt).format("HH:mm") }} {{ $t("page.ChatView.response_police") }}</div>
            </div>
          </div>
        </template>
      </div>
      <form
        @submit.prevent="
          async (e: any) => {
            await chatFormStore.onSubmit(e);
            scrollBottom(100);
          }
        "
        class="flex gap-2 w-full h-[80px] p-4 items-center justify-center relative"
      >
        <div class="absolute -mt-[4px] right-[70px] z-[9999]">
          <label for="file-upload" class="icon-container relative">
            <component :is="$solidIcons.PaperClipIcon" class="inline-block m-auto text-ttp-primary ttp-icon-inside-box-03 cursor-pointer" />
            <input type="file" ref="fileInput" @change="changeFile" id="file-upload" class="hidden" accept="image/*" multiple />
          </label>
        </div>
        <div class="w-full"><component :classTagName="'col-start-1 col-end-7 md:col-start-3 md:col-end-7'" :is="defaultFormComponents.form.input.message.component" v-bind="defaultFormComponents.form.input.message.props" @updateValue="defaultFormComponents.form.input.message.onUpdateValue" /></div>
        <div class="">
          <button :disabled="chatFormStore.loading" class="disabled:cursor-not-allowed disabled:opacity-50 inline-flex ttp-icon-box-03 items-center justify-center rounded bg-[#212A83] text-gray-300 dark:bg-gray-700 dark:text-gray-500">
            <svg class="ttp-icon-inside-box-03" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.4354 2.58198C20.9352 2.0686 20.1949 1.87734 19.5046 2.07866L3.408 6.75952C2.6797 6.96186 2.16349 7.54269 2.02443 8.28055C1.88237 9.0315 2.37858 9.98479 3.02684 10.3834L8.0599 13.4768C8.57611 13.7939 9.24238 13.7144 9.66956 13.2835L15.4329 7.4843C15.723 7.18231 16.2032 7.18231 16.4934 7.4843C16.7835 7.77623 16.7835 8.24935 16.4934 8.55134L10.72 14.3516C10.2918 14.7814 10.2118 15.4508 10.5269 15.9702L13.6022 21.0538C13.9623 21.6577 14.5826 22 15.2628 22C15.3429 22 15.4329 22 15.513 21.9899C16.2933 21.8893 16.9135 21.3558 17.1436 20.6008L21.9156 4.52479C22.1257 3.84028 21.9356 3.09537 21.4354 2.58198Z" fill="white" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
