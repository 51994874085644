<script lang="ts">
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "PageHeaderComponent",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  setup() {
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    return {
      sharedStore,
      authStore,
      masterDataStore,
      profile_image: "" as any,
    };
  },
  computed: {},
  methods: {
    async setting() {
      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }
    },
  },
  async mounted() {
    if (this.$route.meta.requiresAuth) await this.authStore.get();
    await this.setting();
  },
  unmounted() {},
});
</script>

<template>
  <header
    id="page-header"
    class="flex flex-none items-center h-16 bg-white shadow-md fixed top-0 right-0 left-0 z-30 dark:bg-gray-800"
    :class="{
      'lg:pl-64': sharedStore.desktopSidebarOpen,
    }"
  >
    <div class="flex justify-between container xl:max-w-7xl mx-auto px-4 lg:pl-8 lg:pr-0 w-full">
      <div class="flex items-center space-x-2">
        <div class="">
          <button
            @click="
              () => {
                sharedStore.mobileSidebarOpen = !sharedStore.mobileSidebarOpen;
              }
            "
            type="button"
            class="lg:hidden inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 ttp-text-xs border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
          >
            <component :is="$solidIcons.Bars3CenterLeftIcon" class="inline-block ttp-icon-inside-box-03" />
          </button>
        </div>
      </div>
      <div class="flex items-center w-full px-4 font-medium">
        {{ $route?.meta?.isSpecial ? (!$route.params.id ? $t("layout.create") + $t(($route?.meta as any).i18n) : $t("layout.update") + $t(($route?.meta as any).i18n)) : $t(($route?.meta as any).i18n) }}
      </div>
      <div class="flex items-center space-x-2">
        <div class="hidden lg:block">
          <a v-if="false" class="group inline-flex items-center justify-center space-x-2 rounded-md border font-semibold transition focus:outline-none focus:ring-2 border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/50 dark:active:border-gray-700 p-2 ttp-text-xs leading-5 sm:px-3" href="#"> v{{ sharedStore.appVersion() }}</a>
        </div>
        <button
          v-if="false"
          @click="
            () => {
              sharedStore.toggleMode();
            }
          "
          type="button"
          :class="sharedStore.mode == 'true' ? 'group items-center rounded-md border p-2 ttp-text-xs font-semibold leading-5 transition border-blue-100 bg-blue-50 text-blue-500 dark:border-blue-800 dark:bg-blue-800/30 dark:text-blue-200 inline-flex' : 'group items-center rounded-md border p-2 ttp-text-xs font-semibold leading-5 transition border-transparent text-gray-500 hover:border-gray-200 hover:text-blue-500 active:border-blue-300 dark:text-gray-400 dark:hover:border-gray-700 dark:hover:text-blue-400 dark:active:border-blue-800 inline-flex'"
        >
          <component :is="$solidIcons.MoonIcon" class="inline-block ttp-icon-inside-box-03" />
        </button>
        <a v-if="false" href="#" class="inline-flex items-center justify-center space-x-2 rounded-lg border border-gray-200 bg-white px-3 py-2 ttp-text-xs font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
          <component :is="$solidIcons.BellIcon" class="inline-block ttp-icon-inside-box-03" />
        </a>
        <Menu as="div" class="hidden sm:inline-block">
          <MenuButton class="inline-flex justify-center items-center space-x-2 rounded-lg px-3 py-2 leading-5 ttp-text-xs bg-white text-gray-800">
            <div class="flex-1 min-w-0 hidden lg:block">
              <div class="text-sm text-gray-900 truncate dark:text-white font-semibold">
                {{ authStore.displayName }}
              </div>
            </div>
            <div>
              <img v-if="false" src="https://cdn.tailkit.com/media/placeholders/avatar-iFgRcqHznqg-160x160.jpg" alt="User Avatar" class="inline-flex h-10 w-10 items-center justify-center rounded-full" />
              <div v-if="true" class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
                <img v-else class="object-cover object-center w-10 h-10 rounded-full" :src="profile_image" alt="" />
              </div>
            </div>
            <component :is="$solidIcons.ChevronDownIcon" class="inline-block ttp-icon-inside-box-03 opacity-40" />
          </MenuButton>
          <Transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 scale-90" enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-90">
            <MenuItems class="absolute right-1 origin-top-right z-10 mt-2 w-4/4 shadow-md rounded-lg dark:shadow-gray-900 focus:outline-none">
              <div class="bg-white ring-1 ring-black ring-opacity-5 rounded-lg divide-y divide-gray-100 dark:bg-gray-800 dark:divide-gray-700 dark:ring-gray-700">
                <div class="px-5 py-3 flex items-center space-x-3">
                  <img v-if="false" src="https://cdn.tailkit.com/media/placeholders/avatar-iFgRcqHznqg-160x160.jpg" alt="User Avatar" class="inline-flex ttp-icon-inside-box-02 items-center justify-center rounded-full" />
                  <div v-if="true" class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                    <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
                    <img v-else class="object-cover object-center w-10 h-10 rounded-full" :src="profile_image" alt="" />
                  </div>
                  <div class="grow ttp-text-xs">
                    <a href="javascript:void(0)" class="font-semibold text-gray-600 hover:text-gray-500 dark:text-gray-300 dark:hovertext-gray-400">
                      {{ authStore.displayName }}
                    </a>
                  </div>
                </div>
                <div class="p-2.5 space-y-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'ProfileEditView' });
                        }
                      "
                      href="#"
                      class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                      :class="{
                        'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                        'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                      }"
                    >
                      <component :is="$solidIcons.UserCircleIcon" class="inline-block ttp-icon-inside-box-03 opacity-25 group-hover:opacity-50" />
                      <span class="grow">{{ $t("layout.menu.ProfileView") }}</span>
                    </a>
                  </MenuItem>
                </div>
                <div class="p-2.5 space-y-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'PasswordSettingView' });
                        }
                      "
                      href="#"
                      class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                      :class="{
                        'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                        'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                      }"
                    >
                      <component :is="$solidIcons.UserCircleIcon" class="inline-block ttp-icon-inside-box-03 opacity-25 group-hover:opacity-50" />
                      <span class="grow">{{ $t("layout.menu.PasswordSettingView") }}</span>
                    </a>
                  </MenuItem>
                </div>
                <div class="p-2.5 space-y-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="
                        async () => {
                          await authStore.logout();
                          $window.location.href = $router.resolve({ name: 'LoginPoliceCommandCenterView' }).href;
                        }
                      "
                      href="#"
                      class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                      :class="{
                        'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                        'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                      }"
                    >
                      <component :is="$solidIcons.LockClosedIcon" class="inline-block ttp-icon-inside-box-03 opacity-25 group-hover:opacity-50" />
                      <span class="grow">{{ $t("layout.menu.LogoutView") }}</span>
                    </a>
                  </MenuItem>
                </div>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  </header>
</template>
