<script lang="ts">
import { defineComponent, ref, watch, type PropType } from "vue";
import { ENUM_SIZE } from "@/configs/enums/enum";
import type { $string, AllowedTypes } from "@/configs/types/Shared/typeShare";

const FormCheckBoxToggleComponent = defineComponent({
  name: "FormCheckBoxToggleComponent",
  components: {},
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    textError: {
      type: String,
      required: false,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array as PropType<{ label: $string; value: $string }[]>,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null] as PropType<AllowedTypes>,
      required: false,
      default: null,
    },
    classEnumName: {
      type: String,
      required: false,
      default: "peer-checked:bg-ttp-primary",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: ENUM_SIZE.NORMAL,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("updateValue", target.checked ? target.value : "");
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
      ENUM_SIZE,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormCheckBoxToggleComponent;
</script>

<template>
  <div v-if="isVisible" :class="`w-full mb-2 ${classTagName}`">
    <label v-if="!isLabel" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span></label>
    <label :for="name" class="relative inline-flex items-center cursor-pointer">
      <input v-for="(item, index) in items" :key="index" :id="name" :name="name" :value="item.value" @input="handleInput" :disabled="isDisable" :readonly="isReadonly" :checked="value === item.value" type="checkbox" class="sr-only peer" />
      <div
        :class="[
          `${classEnumName}`,
          {
            'w-9 h-5 after:h-4 after:w-4': size == ENUM_SIZE.SMALL,
            'w-11 h-6 after:h-5 after:w-5': size == ENUM_SIZE.NORMAL,
            'w-14 h-7 after:h-6 after:w-6': size == ENUM_SIZE.LARGE,
          },
        ]"
        class="bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-ttp-ring peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600"
      ></div>
      <div v-if="isLabel" class="ms-3 ttp-text-xs text-ttp-secondary dark:text-gray-400">
        {{ title }}
        <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span>
      </div>
    </label>
    <div>
      <small v-if="isError" class="relative text-ttp-text-error">{{ textError }}</small>
    </div>
  </div>
</template>
