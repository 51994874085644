import { defineStore } from "pinia";
import * as yup from "yup";
import { convertToValues } from "@/configs/helpers/MainHelper";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import type { ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";

const storeName = `useHistorySearchStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: null,
  },
};
export const useHistorySearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    masterDataStore: useMasterDataStore(),
    loading: true,
    isWaitingLoading: true,
    isInProgressloading: true,
    isClosedLoading: true,
    currentPage: 0,
    totalPage: 1,
    loadingList: false,
    loadingScroll: false,
    loadingSearch: false,
    tableResults: null as any,
    tableListResults: null as any,
    tableScrollResults: null as any,
    tableSearchResults: null as any,
    filterTableResults: null as any,
    tableWaitingResults: null as any,
    tableInProgressResults: null as any,
    tableClosedResults: null as any,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    result: convertToValues(isRequired) as any,
    validationSchema: yup.object({}),
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async onUpdateUnlimitedScrollCurrentPage(currentPage: number) {
      try {
        this.loadingScroll = true;
        this.currentPage = currentPage;

        const results = (await this.keywordUnlimitedScroll({
          ...this.result,
          page: this.currentPage,
          size: Number(import.meta.env.VITE_PAGE_SIZE),
        })) as any;

        if (!results?.items?.length) this.loadingScroll = false;

        return results;
      } catch (error: any) {}
    },
    async keywordUnlimitedScroll(results: typeGetQuery) {
      const payload = {
        ...results,
        page: this.currentPage,
        size: Number(import.meta.env.VITE_PAGE_SIZE),
      };
      return await this.fetchIncident.GetListIncidentChatByUser(payload, this.masterDataStore.lang());
    },
    async keyword() {
      return await this.fetchIncident.GetListIncidentChatByUser({}, this.masterDataStore.lang());
    },
  },
});
