<script lang="ts">
import { defineComponent, watch } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useAlertSearchStore } from "./stores/useAlertSearchStore";

export default defineComponent({
  name: "AlertDetailView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  methods: {
    async fetch() {
      this.alertSearchStore.loading = true;
      if (this.$route.params.id) {
        await Promise.all([
          (async () => {
            const result = await this.alertSearchStore.fetchAlert.GetById(this.$route.params.id as string);
            this.alertSearchStore.getResult = result;
          })(),
        ]);
      }
      this.alertSearchStore.loading = false;
    },
    loading() {
      return this.alertSearchStore.loading;
    },
    async reset() {
      await this.alertSearchStore.$reset();
    },
    async setting() {
      await this.fetch();
    },
  },
  created() {
    watch(
      () => this.$route.params.id,
      () => {
        this.fetch();
      }
    );
  },
  async mounted() {
    await this.reset();
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const alertSearchStore = useAlertSearchStore();

    return {
      authStore,
      alertSearchStore,
      masterDataStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-full text-white text-left z-[11]"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-10 text-white text-right">
              <router-link :to="{ name: 'MessageView' }"
                ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                </svg>
              </router-link>
            </div>
            <div class="w-10 text-white text-right">
              <router-link :to="{ name: 'NotificationView' }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
              </router-link>
            </div>
          </div>

          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto pb-20">
            <div class="flex flex-col w-full">
              <time class="w-full ttp-text-xs"><span class="w-full ttp-text-xs font-medium">Date</span>: {{ $dayjs(new Date(alertSearchStore?.getResult?.createdAt)).format("DD MMMM YYYY") }}</time>
              <h1 class="w-full ttp-text-lg font-bold">{{ $convertString($filterNewsByLocale(alertSearchStore?.getResult?.translations, masterDataStore.lang())?.title) }}</h1>
            </div>
            <hr />
            <p class="ttp-text-xs" v-html="$convertString($filterNewsByLocale(alertSearchStore?.getResult?.translations, masterDataStore?.lang())?.description)"></p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
