<script lang="ts">
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingContactUsView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    components() {
      return {};
    },
  },
  methods: {
    loading() {
      return false;
    },
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'SettingMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.SettingContactUsView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <a href="https://www.touristpolice.go.th/" target="_blank" class="flex w-full gap-4 rounded-lg shadow-lg p-4">
              <div>
                <svg class="ttp-icon-inside-box-03" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2C3483" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8 12C8 17.5228 9.79086 22 12 22C14.2091 22 16 17.5228 16 12C16 6.47715 14.2091 2 12 2C9.79086 2 8 6.47715 8 12Z" stroke="#2C3483" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2 12H22" stroke="#2C3483" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div class="text-[#212121] font-bold">Website</div>
            </a>
            <a href="https://www.facebook.com/1155TPB" target="_blank" class="flex w-full gap-4 rounded-lg shadow-lg p-4">
              <div>
                <svg class="ttp-icon-inside-box-03" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.001 2.00195C6.47895 2.00195 2.00195 6.47895 2.00195 12.001C2.00195 16.991 5.65795 21.127 10.439 21.88V14.892H7.89895V12.001H10.439V9.79795C10.439 7.28995 11.932 5.90695 14.215 5.90695C15.309 5.90695 16.455 6.10195 16.455 6.10195V8.56095H15.191C13.951 8.56095 13.563 9.33295 13.563 10.124V11.999H16.334L15.891 14.89H13.563V21.878C18.344 21.129 22 16.992 22 12.001C22 6.47895 17.523 2.00195 12.001 2.00195Z" fill="#2C3483" />
                </svg>
              </div>
              <div class="text-[#212121] font-bold">Facebook</div>
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
