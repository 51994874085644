import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetIncidentCategory } from "@/configs/types/Incident/typeIncidentCategory";
import type { typeGetIncidentTopic } from "@/configs/types/Incident/typeIncidentTopic";
import type { typeGetInformer } from "@/configs/types/Informer/typeInformer";
import type { typeGetLocale } from "@/configs/types/MasterData/typeLocale";
import type { typeGetNationality } from "@/configs/types/MasterData/typeNationality";
import type { typeGetOrganization } from "@/configs/types/MasterData/typeOrganization";
import type { typeGetStation } from "@/configs/types/MasterData/typeStation";
import type { typeGetRole } from "@/configs/types/MasterData/typeRole";
import type { typeGetTitle } from "@/configs/types/MasterData/typeTitle";
import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import type { typeGetIncidentFriendlyCategory } from "@/configs/types/Incident/typeIncidentFriendlyCategory";
import type { typeGetExternalDepartment } from "@/configs/types/MasterData/typeExternalDepartment";

export class fetchMasterData {
  async listTitle(): Promise<typeGetTitle[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetTitle[]>("/v1/master-data/titles");
    return data;
  }
  async listStations(provinceId: number = 0, districtId: number = 0): Promise<typeGetStation[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetStation[]>("/v1/master-data/stations", {
      params: { provinceId, districtId },
    });
    return data.sort((a, b) => {
      const [, aSD, aPT] = a.code?.split("_") || [];
      const [, bSD, bPT] = b.code?.split("_") || [];

      if (aPT === bPT) {
        if (aSD === bSD) {
          return a.code?.localeCompare(b.code || "") || 0;
        }

        return aSD.localeCompare(bSD);
      }

      return aPT.localeCompare(bPT);
    });
  }
  async listLocale(): Promise<typeGetLocale[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetLocale[]>("/v1/master-data/locales");
    return data;
  }
  async listRole(): Promise<typeGetRole[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetRole[]>("/v1/master-data/roles");
    return data;
  }
  async listNationality(): Promise<typeGetNationality[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetNationality[]>("/v1/master-data/nationalities");
    return data;
  }
  async listExternalDepartments(provinceId: number = 0): Promise<typeGetExternalDepartment[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetExternalDepartment[]>("/v1/master-data/external-departments", { params: { provinceId } });
    return data;
  }
  async listIncidentFriendlyCategory(): Promise<typeGetIncidentFriendlyCategory[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetIncidentFriendlyCategory[]>("/v1/master-data/incident/friendly-categories");
    return data;
  }
  async listIncidentCategory(): Promise<typeGetIncidentCategory[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetIncidentCategory[]>("/v1/master-data/incident/categories");
    return data;
  }
  async listIncidentTopic(): Promise<typeGetIncidentTopic[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetIncidentTopic[]>("/v1/master-data/incident/topics");
    return data;
  }
  async listInformer(params: object): Promise<typeGetInformer[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetInformer[]>("/v1/informers", {
      params,
    });
    return data;
  }
  async listProvince(): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>("/v1/master-data/address/provinces");
    return data;
  }
  async listCountry(): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>("/v1/master-data/countries");
    return data;
  }
  async getProvince(province_id: number): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>(`/v1/master-data/address/provinces/${province_id}`);
    return data;
  }
  async listDistrict(province_id: number): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>(`/v1/master-data/address/provinces/${province_id}/districts`);
    return data;
  }
  async getDistrict(province_id: number, district_id: number): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>(`/v1/master-data/address/provinces/${province_id}/districts/${district_id}`);
    return data;
  }
  async listSubDistrict(province_id: number, district_id: number): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>(`/v1/master-data/address/provinces/${province_id}/districts/${district_id}/sub-districts`);
    return data;
  }
  async getSubDistrict(province_id: number, district_id: number, sub_district_id: number): Promise<any[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<any[]>(`/v1/master-data/address/provinces/${province_id}/districts/${district_id}/sub-districts/${sub_district_id}`);
    return data;
  }
  async listOrganizations(): Promise<typeGetOrganization[]> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetOrganization[]>("/v1/master-data/organizations");
    return data;
  }
}
