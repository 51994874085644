import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import { ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { wrapConvertValues } from "@/configs/helpers/MainHelper";
import type { typeIncidentAssignee } from "@/configs/types/Incident/typeIncidentAssignee";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";

const storeName = `useIncidentAssigneeFormStore-${import.meta.env.VITE_APP_VERSION}`;

const pick = {
  validate: {
    is: false,
    name: `validate`,
    label: `form.validate.label`,
    placeholder: `form.validate.placeholder`,
    value: false,
  },
  routeName: {
    is: false,
    name: `route_name`,
    label: `form.route_name.label`,
    placeholder: `form.route_name.placeholder`,
    value: null,
  },
};

const isRequired = {
  ...pick,
  assigneeID: {
    is: false,
    name: `assigneeID`,
    label: `form.responsible_person.label`,
    placeholder: `form.responsible_person.placeholder`,
    value: null,
  },
  detail: {
    is: true,
    name: `detail`,
    label: `form.officerDetail.label`,
    placeholder: `form.officerDetail.placeholder`,
    value: null,
  },
  metadata: {
    is: false,
    name: `metadata`,
    label: `form.metadata.label`,
    placeholder: `form.metadata.placeholder`,
    value: null,
  },
};
export const useIncidentAssigneeFormStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    fetchUser: new fetchUser(),
    routeName: null,
    isOpenDefault: false,
    isOpenComplete: false,
    isSuccess: false,
    isProgressSuccess: false,
    isResponsibleSuccess: false,
    isAssign: false,
    isEditAssign: false,
    isRequired,
    isShowTime: false,
    isETA: false,
    isArrive: false,
    metadata: { eta: undefined, arrived: undefined, createdAt: undefined } as any,
    calculate: {
      arrived: { remainingMinutes: 0, colorSegment: "Green" },
      now: { remainingMinutes: 0, colorSegment: "Green" },
    } as any,
    loading: false,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    results: [] as any,
    result: {} as typeIncidentAssignee,
    sendResult: {} as typeIncidentAssignee,
    error: {} as { [key: string]: string | null | any },
    validationSchema: yup.object({
      assigneeID: isRequired.assigneeID.is
        ? yup.string().required()
        : yup
            .string()
            .notRequired()
            .test(function (value) {
              if (Boolean((this.options as any).from[0].value.routeName == "IncidentManageView")) {
                return true;
              } else {
                return Boolean(value);
              }
            }),
      detail: isRequired.detail.is
        ? yup.string().test(function (value) {
            return Boolean(!(this.options as any).from[0].value.validate ? true : value);
          })
        : yup.string().notRequired(),
    }),
    validationCompletedSchema: yup.object({
      detail: isRequired.detail.is ? yup.string().required() : yup.string().notRequired(),
    }),
    ignoreEmptyField: ["detail"],
    convertNumberField: [],
  }),
  actions: {
    settingResult() {
      this.sendResult = wrapConvertValues(this.result, this.ignoreEmptyField, this.convertNumberField);
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      await this.settingResult();

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        return true;
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return false;
      }
    },
    async updateProgress(id: string, result: typeIncidentAssignee): Promise<typeIncidentAssignee> {
      try {
        this.loading = true;
        const data = await this.fetchIncident.ProgressUpdate(id, result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isProgressSuccess = true;

        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isProgressSuccess = true;
        throw error;
      }
    },
    async updateResponsible(id: string) {
      try {
        this.loading = true;
        const data = await this.fetchIncident.SelfAssign(String(id));
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isResponsibleSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isResponsibleSuccess = true;
        throw error;
      }
    },
    async onCompletedSubmit(e: Event) {
      if (e) e.preventDefault();

      await this.settingResult();

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationCompletedSchema.validate(this.result, {
          abortEarly: false,
        });

        return true;
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return false;
      }
    },
  },
});
