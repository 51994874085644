import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetTourist } from "@/configs/types/Tourist/typeTourist";

export class fetchTourist {
  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get("/v1/tourists", {
      params,
    });
    return data;
  }
  async Get(id: string): Promise<typeGetTourist> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetTourist>(`/v1/tourists/${id}`);
    return data;
  }
  async ListIncident(id: string, params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get(`/v1/tourists/${id}/incidents`, {
      params,
    });
    return data;
  }
}
