<script lang="ts">
// @ts-nocheck
import FormCheckBoxToggleComponent from "@/components/Forms/CheckBox/FormCheckBoxToggleComponent.vue";
import { defineComponent } from "vue";
import { useSettingNotificationStore } from "./stores/useSettingNotificationStore";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { getLocalStorage, setLocalStorage } from "@/configs/helpers/MainHelper";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  name: "SettingNotificationView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          toggle: {
            is_push_notification: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingNotificationStore.error.is_push_notification || "").length > 0,
                isIcon: false,
                isDisable: true,
                isLabel: true,
                isRequired: this.settingNotificationStore.isRequired.is_push_notification.is,
                textError: this.$t(this.settingNotificationStore.isRequired.is_push_notification.placeholder),
                title: "",
                placeholder: this.$t(this.settingNotificationStore.isRequired.is_push_notification.placeholder),
                value: this.settingNotificationStore.result.is_push_notification === "granted" ? this.$enums.ENUM_FIELD_STATUS.ACTIVE : this.$enums.ENUM_FIELD_STATUS.INACTIVE,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.settingNotificationStore.isRequired.is_push_notification.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: () => {
                // this.settingNotificationStore.result.is_push_notification = value;
              },
            },
            is_chat: {
              component: FormCheckBoxToggleComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.settingNotificationStore.error.is_chat || "").length > 0,
                isIcon: false,
                isDisable: true,
                isLabel: true,
                isRequired: this.settingNotificationStore.isRequired.is_chat.is,
                textError: this.$t(this.settingNotificationStore.isRequired.is_chat.placeholder),
                title: "",
                placeholder: this.$t(this.settingNotificationStore.isRequired.is_chat.placeholder),
                value: this.settingNotificationStore.result.is_chat === "granted" ? this.$enums.ENUM_FIELD_STATUS.ACTIVE : this.$enums.ENUM_FIELD_STATUS.INACTIVE,
                classEnumName: this.$enums.ENUM_TOGGLE_CLASS.SUCCESS,
                items: [
                  {
                    label: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                    value: this.$enums.ENUM_FIELD_STATUS.ACTIVE,
                  },
                ],
                name: this.settingNotificationStore.isRequired.is_chat.name,
              } as typeof FormCheckBoxToggleComponent.props,
              onUpdateValue: () => {
                // this.settingNotificationStore.result.is_chat = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return false;
    },
    async notificationToggle() {
      if (Capacitor.isNativePlatform()) {
        this.openAppSetting();
      } else {
        Notification.requestPermission()
          .then(async (result) => {
            await setLocalStorage(this.sharedStore.is_push_notification, result);
            this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
          })
          .catch(async (_error) => {
            await setLocalStorage(this.sharedStore.is_push_notification, "denied");
            this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
          });
      }
    },
    async notificationPermission() {
      if (Capacitor.isNativePlatform()) {
        this.$PermissionsPlugin
          .checkPermissionNotification()
          .then(async (result: any) => {
            await setLocalStorage(this.sharedStore.is_push_notification, result.value);
            this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
          })
          .catch(async (_error: any) => {
            await setLocalStorage(this.sharedStore.is_push_notification, "denied");
            this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
          });
      } else {
        navigator.permissions
          .query({ name: "notifications" })
          .then(async (result) => {
            await setLocalStorage(this.sharedStore.is_push_notification, await result?.state);
            this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
          })
          .catch(async (_error) => {
            await setLocalStorage(this.sharedStore.is_push_notification, "denied");
            this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
          });
      }
    },
    async chatToggle() {
      const toggle = await getLocalStorage(this.sharedStore.is_chat);
      if (this.$inArray(toggle, ["granted"])) {
        await setLocalStorage(this.sharedStore.is_chat, "denied");
      } else {
        await setLocalStorage(this.sharedStore.is_chat, "granted");
      }
      this.settingNotificationStore.result.is_chat = await getLocalStorage(this.sharedStore.is_chat);
    },
    async chatPermission() {
      this.settingNotificationStore.result.is_chat = await getLocalStorage(this.sharedStore.is_chat);
    },
    async openAppSetting() {
      if (Capacitor.isNativePlatform()) {
        await this.$PermissionsPlugin.openAppSettings();
      }
    },
  },
  async mounted() {
    // Listener
    if (Capacitor.isNativePlatform()) {
      await this.$PermissionsPlugin.addListener("notificationPermissionChange", async (data: any) => {
        await setLocalStorage(this.sharedStore.is_push_notification, data.status);
        this.settingNotificationStore.result.is_push_notification = await getLocalStorage(this.sharedStore.is_push_notification);
      });
    }
  },
  unmounted() {
    if (Capacitor.isNativePlatform()) {
      this.$PermissionsPlugin.removeListener("notificationPermissionChange");
    }
  },
  async created() {
    this.notificationPermission();
    this.chatPermission();
  },
  setup() {
    const settingNotificationStore = useSettingNotificationStore();
    const authStore = useAuthStore();
    const sharedStore = useSharedStore();
    return {
      settingNotificationStore,
      authStore,
      sharedStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'SettingMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.SettingNotificationView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="flex w-full items-center">
              <div class="w-full text-black ttp-text-sm">{{ $t("page.SettingNotificationView.push_notifications_txt") }}</div>
              <div class="w-full text-right">
                <component @click="notificationToggle" :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.toggle.is_push_notification.component" v-bind="defaultFormComponents.form.toggle.is_push_notification.props" @updateValue="defaultFormComponents.form.toggle.is_push_notification.onUpdateValue" />
              </div>
            </div>
            <div class="flex w-full items-center">
              <div class="w-full text-black ttp-text-sm">{{ $t("page.SettingNotificationView.chat_txt") }}</div>
              <div class="w-full text-right">
                <component @click="chatToggle" :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.toggle.is_chat.component" v-bind="defaultFormComponents.form.toggle.is_chat.props" @updateValue="defaultFormComponents.form.toggle.is_chat.onUpdateValue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
