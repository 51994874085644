<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { ENUM_SIZE, ENUM_COLOR } from "@/configs/enums/enum";

const PopupSuccessComponent = defineComponent({
  name: "PopupSuccessComponent",
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ButtonDefaultComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    textTitle: {
      type: String,
      default: "typeGetTitle",
    },
    textDetail: {
      type: String,
      default: "Detail",
    },
    functionProp: {
      type: Function,
      default: () => {},
    },
    functionCancelProp: {
      type: Function,
      default: () => {},
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    class: {
      type: String,
      required: false,
      default: "",
    },
    classEnumName: {
      type: String,
      required: false,
      default: ENUM_COLOR.NEW,
    },
    hasCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const isOpenLocal = ref(props.isOpen);

    watch(
      () => props.isOpen,
      (newValue) => {
        isOpenLocal.value = newValue;
      }
    );

    function toggleOpen() {
      isOpenLocal.value = !isOpenLocal.value;
      emit("updateIsOpen", isOpenLocal.value);
    }

    onMounted(() => {
      emit("sendToggleOpen", toggleOpen);
    });

    return {
      isOpen: isOpenLocal,
      ENUM_COLOR,
      ENUM_SIZE,
      props,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
      }
    },
    handleCancelClick(e: Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (typeof this.functionCancelProp === "function") {
        this.functionCancelProp(e);
      }
    },
  },
});
export default PopupSuccessComponent;
</script>

<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleCancelClick" class="relative z-9999">
        <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm"></div>
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8 ttp-fixed-tourist-margin-mobile-header">
          <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0 scale-125" enter-to="opacity-100 scale-100" leave="ease-in duration-100" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-125">
            <DialogPanel class="w-full max-w-md mx-auto flex flex-col rounded-lg shadow-md bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800">
              <div class="relative p-4">
                <div v-if="hasCancel" class="text-right">
                  <button @click="handleCancelClick" @touchstart="handleCancelClick" type="button" class="inline-flex items-center justify-center space-x-2 rounded-lg border border-transparent px-3 py-2 ttp-text-xs font-semibold leading-5 text-gray-800 hover:text-gray-900 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
                    <svg class="ttp-icon-inside-box-03" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Icons/Common Action/Close">
                        <path id="Vector" d="M19.7433 3.48947C19.5452 3.49414 19.357 3.577 19.2198 3.71994L12.0001 10.9397L4.78039 3.71994C4.71048 3.64794 4.62684 3.59071 4.53441 3.55163C4.44198 3.51254 4.34265 3.4924 4.2423 3.4924C4.09304 3.49243 3.94719 3.537 3.8234 3.6204C3.69961 3.70379 3.60352 3.82223 3.54741 3.96054C3.49131 4.09886 3.47775 4.25077 3.50847 4.39683C3.53918 4.5429 3.61278 4.67648 3.71984 4.78048L10.9396 12.0002L3.71984 19.2199C3.64786 19.289 3.5904 19.3718 3.55081 19.4634C3.51122 19.555 3.49031 19.6536 3.4893 19.7534C3.48828 19.8531 3.50719 19.9521 3.5449 20.0445C3.58262 20.1369 3.63839 20.2208 3.70895 20.2914C3.77951 20.3619 3.86344 20.4177 3.95582 20.4554C4.0482 20.4931 4.14718 20.512 4.24696 20.511C4.34674 20.51 4.44531 20.4891 4.53691 20.4495C4.6285 20.4099 4.71128 20.3525 4.78039 20.2805L12.0001 13.0608L19.2198 20.2805C19.289 20.3525 19.3717 20.4099 19.4633 20.4495C19.5549 20.4891 19.6535 20.51 19.7533 20.511C19.8531 20.512 19.952 20.4931 20.0444 20.4554C20.1368 20.4177 20.2207 20.3619 20.2913 20.2914C20.3618 20.2208 20.4176 20.1369 20.4553 20.0445C20.493 19.9521 20.512 19.8531 20.5109 19.7534C20.5099 19.6536 20.489 19.555 20.4494 19.4634C20.4098 19.3718 20.3524 19.289 20.2804 19.2199L13.0607 12.0002L20.2804 4.78048C20.3895 4.6758 20.4643 4.54061 20.4952 4.39263C20.5261 4.24465 20.5116 4.09079 20.4535 3.95121C20.3955 3.81164 20.2966 3.69287 20.1698 3.61047C20.0431 3.52807 19.8944 3.4859 19.7433 3.48947Z" fill="#4C4E64" fill-opacity="0.68" />
                      </g>
                    </svg>
                  </button>
                </div>
                <div class="w-14 h-14 mx-auto flex items-center justify-center">
                  <svg v-if="!htmlIcon" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M20.5 0C9.47207 0 0.5 8.97207 0.5 20C0.5 31.0279 9.47207 40 20.5 40C31.5279 40 40.5 31.0279 40.5 20C40.5 8.97207 31.5279 0 20.5 0ZM20.5 3C29.9066 3 37.5 10.5934 37.5 20C37.5 29.4066 29.9066 37 20.5 37C11.0934 37 3.5 29.4066 3.5 20C3.5 10.5934 11.0934 3 20.5 3ZM27.9707 13.9863C27.5811 13.9977 27.2113 14.1602 26.9395 14.4395L18 23.3789L14.0605 19.4395C13.9223 19.2955 13.7568 19.1806 13.5736 19.1014C13.3904 19.0222 13.1932 18.9804 12.9937 18.9784C12.7941 18.9763 12.5962 19.0141 12.4114 19.0896C12.2266 19.165 12.0588 19.2766 11.9177 19.4177C11.7766 19.5588 11.665 19.7266 11.5896 19.9114C11.5141 20.0962 11.4763 20.2941 11.4784 20.4937C11.4804 20.6932 11.5222 20.8904 11.6014 21.0736C11.6806 21.2568 11.7955 21.4223 11.9395 21.5605L16.9395 26.5605C17.2208 26.8417 17.6022 26.9997 18 26.9997C18.3978 26.9997 18.7792 26.8417 19.0605 26.5605L29.0605 16.5605C29.2769 16.3498 29.4247 16.0786 29.4844 15.7825C29.5442 15.4864 29.5131 15.1792 29.3953 14.901C29.2776 14.6228 29.0786 14.3867 28.8244 14.2235C28.5702 14.0603 28.2726 13.9776 27.9707 13.9863Z" fill="#00A860" />
                  </svg>
                  <span v-else-if="htmlIcon && isIcon" v-html="htmlIcon" :class="class"></span>
                </div>
                <div class="my-5">
                  <h4 class="text-center text-gray-800 ttp-text-base font-medium leading-normal">
                    {{ textTitle }}
                  </h4>
                  <div class="text-center text-gray-500 ttp-text-xs font-normal leading-normal">
                    {{ textDetail }}
                  </div>
                </div>
              </div>
              <div class="space-x-2 bg-gray-50 px-5 py-4 text-left dark:bg-gray-700/50">
                <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true" :isDisable="false" :htmlIcon="''" :title="$t('btn.btn_submit')" :classEnumName="classEnumName ? classEnumName : ENUM_COLOR.GREEN" :classTagName="'w-full'" :size="ENUM_SIZE.SMALL" @click="handleClick" @touchstart="handleClick" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
