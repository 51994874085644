<script lang="ts">
// @ts-nocheck
import { defineComponent, reactive } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import { usePopularPlaceSearchStore } from "./stores/usePopularPlaceSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import dayjs from "dayjs";
import type { $string } from "@/configs/types/Shared/typeShare";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import { useInfiniteScroll } from "@vueuse/core";
import _ from "lodash";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { Capacitor } from "@capacitor/core";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { setLocalStorage } from "@/configs/helpers/MainHelper";
import { Geolocation } from "@capacitor/geolocation";
import { useActivitySearchStore } from "./stores/useActivitySearchStore";

export default defineComponent({
  name: "PopularPlaceView",
  components: { TabGroup, TabList, Tab, TabPanels, TabPanel, LoadingFormComponent, Pagination, Navigation, Swiper, SwiperSlide },
  data() {
    return {
      componentName: this.$options.name,
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href,
      profile_image: "" as any,
      isVisibleSearch: false,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                htmlIcon: "",
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.popularPlaceSearchStore.error.keyword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                classIcon: "ttp-icon-inside-box-03 text-[#2C3483]",
                componentIcon: this.$solidIcons.MapPinIcon,
                isDisable: this.loading(),
                isRequired: this.popularPlaceSearchStore.isRequired.keyword.is,
                textError: this.$t(this.popularPlaceSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.popularPlaceSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.popularPlaceSearchStore.isRequired.keyword.placeholder),
                value: this.popularPlaceSearchStore.result.keyword,
                name: this.popularPlaceSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: async (value: $string) => {
                this.popularPlaceSearchStore.result.keyword = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async popularPlaceFetch() {
      if (!this.popularPlaceSearchStore.loadingList) await this.popularPlaceSearchStore.onUpdateCurrentPage();
    },
    async popularPlaceListFetch() {
      if (!this.popularPlaceSearchStore.loadingScroll) {
        this.popularPlaceSearchStore.currentPage = this.popularPlaceSearchStore.currentPage + 1;

        const ids = this.popularPlaceSearchStore?.tableResults?.items?.map((item: any) => item.id);
        let results = (await this.popularPlaceSearchStore.onUpdateListCurrentPage(this.popularPlaceSearchStore.currentPage)) as any;
        results.items = results?.items?.filter((item: any) => !ids.includes(item.id));

        if (!this.popularPlaceSearchStore.tableScrollResults) {
          this.popularPlaceSearchStore.tableListResults = results;
          this.popularPlaceSearchStore.tableScrollResults = _.cloneDeep(results);
          this.popularPlaceSearchStore.totalPage = results?.totalPages;
        } else {
          this.popularPlaceSearchStore.tableListResults.items.push(...results?.items);
          this.popularPlaceSearchStore.tableListResults.totalPage = this.popularPlaceSearchStore.tableListResults.totalPages;
          results = this.popularPlaceSearchStore.tableListResults;
        }

        await results?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.popularPlaceSearchStore?.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);

        this.popularPlaceSearchStore.tableScrollResults.items = results.items;
        this.popularPlaceSearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
        this.popularPlaceSearchStore.loadingScroll = false;
      }
    },
    async activityListFetch() {
      if (!this.activitySearchStore.loadingScroll) {
        this.activitySearchStore.currentPage = this.activitySearchStore.currentPage + 1;

        let results = (await this.activitySearchStore.onUpdateListCurrentPage(this.activitySearchStore.currentPage)) as any;

        if (!this.activitySearchStore.tableScrollResults) {
          this.activitySearchStore.tableListResults = results;
          this.activitySearchStore.tableScrollResults = _.cloneDeep(results);
          this.activitySearchStore.totalPage = results?.totalPages;
        } else {
          this.activitySearchStore.tableListResults.items.push(...results?.items);
          this.activitySearchStore.tableListResults.totalPage = this.activitySearchStore.tableListResults.totalPages;
          results = this.activitySearchStore.tableListResults;
        }

        await results?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.activitySearchStore?.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);

        this.activitySearchStore.tableScrollResults.items = results.items;
        this.activitySearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
        this.activitySearchStore.loadingScroll = false;
      }
    },
    async popularPlaceSearchFetch() {
      if (!this.popularPlaceSearchStore.loadingSearch) this.popularPlaceSearchStore.onUpdateSearchCurrentPage();
    },
    isOpen(openingHours: []) {
      return this.$isOpenNow(openingHours) ? "Open" : "Close";
    },
    async popularPlaceScrollFetch() {
      useInfiniteScroll(
        this.el,
        () => {
          if (this.popularPlaceSearchStore.totalPage > this.popularPlaceSearchStore.currentPage) this.popularPlaceListFetch();
        },
        { distance: 10 }
      );
    },
    async activityScrollFetch() {
      useInfiniteScroll(
        this.el,
        () => {
          if (this.activitySearchStore.totalPage > this.activitySearchStore.currentPage) this.activityListFetch();
        },
        { distance: 10 }
      );
    },
    async getLocationPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          // const permission = await this.$PermissionsPlugin.requestLocation();
          await Geolocation.getCurrentPosition()
            .then(async (permission: any) => {
              this.sharedStore.position = permission;
              await this.popularPlaceFetch();
              await this.popularPlaceListFetch();
              await this.popularPlaceScrollFetch();

              await this.activityListFetch();
              await this.activityScrollFetch();
            })
            .catch(async () => {
              this.sharedStore.position = null;
              await this.popularPlaceFetch();
              await this.popularPlaceListFetch();
              await this.popularPlaceScrollFetch();

              await this.activityListFetch();
              await this.activityScrollFetch();
            });

          const result = await this.$PermissionsPlugin
            .checkPermissionLocation()
            .then(async (_result: any) => {
              console.log("Location permission:", _result.value);
              await setLocalStorage(this.sharedStore.is_track_location, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Location");
              else this.$pushUnique(this.sharedStore.permissionResults, "Location");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              console.log("Location permission denied");
              await setLocalStorage(this.sharedStore.is_track_location, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Location");
              return false;
            });

          return result;
        } catch (_error) {
          console.log("Location permission denied", _error);
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Location");
          return false;
        }
      } else {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              async (permission) => {
                this.sharedStore.position = permission;

                console.log("Location permission:");
                await setLocalStorage(this.sharedStore.is_track_location, "granted");
                this.$popUnique(this.sharedStore.permissionResults, "Location");
                await this.popularPlaceFetch();
                await this.popularPlaceListFetch();
                await this.popularPlaceScrollFetch();

                await this.activityListFetch();
                await this.activityScrollFetch();
                resolve(true);
              },
              async (_error) => {
                this.sharedStore.position = null;
                console.log("Location permission denied");
                await setLocalStorage(this.sharedStore.is_track_location, "denied");
                this.$pushUnique(this.sharedStore.permissionResults, "Location");
                await this.popularPlaceFetch();
                await this.popularPlaceListFetch();
                await this.popularPlaceScrollFetch();

                await this.activityListFetch();
                await this.activityScrollFetch();
                reject(false);
              }
            );
          });
          return position;
        } catch (_error) {
          this.sharedStore.position = null;
          console.log("Location permission denied");
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Location");
          await this.popularPlaceFetch();
          await this.popularPlaceListFetch();
          await this.popularPlaceScrollFetch();

          await this.activityListFetch();
          await this.activityScrollFetch();
          return false;
        }
      }
    },
    loading() {
      return this.popularPlaceSearchStore.loadingSearch;
    },
    async reset() {
      await this.popularPlaceSearchStore.$reset();
    },
    async setting() {
      // await this.popularPlaceFetch();
      await this.getLocationPermission();
      await this.$configLightbox();

      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }
    },
  },
  async mounted() {
    await this.reset();
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const popularPlaceSearchStore = usePopularPlaceSearchStore();
    const activitySearchStore = useActivitySearchStore();
    const sharedStore = useSharedStore();
    dayjs.locale(masterDataStore.lang());

    var state = reactive({
      el: null,
    });

    return {
      sharedStore,
      authStore,
      masterDataStore,
      popularPlaceSearchStore,
      activitySearchStore,
      modules: [Pagination, Navigation],
      el: state.el,
      state
    };
  },
});
</script>

<template>
  <div
    v-if="isVisibleSearch"
    @click="
      () => {
        isVisibleSearch = !isVisibleSearch;
      }
    "
    class="absolute top-0 left-0 bg-black flex w-full h-full z-[99999] opacity-50"
  ></div>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 mt-5">
            <button
              class="w-10 text-white text-left z-[99999]"
              @click="
                () => {
                  $router.push({ name: 'HomeView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>

            <div v-if="isVisibleSearch" class="relative w-full text-white text-right z-[99999]">
              <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
              <div v-if="popularPlaceSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full h-60 overflow-auto rounded-lg p-3 z-[99999]">
                <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: data?.id } }" v-for="(data, key) in popularPlaceSearchStore?.tableSearchResults?.items" :key="key" class="flex gap-2 mb-2 w-full text-black">
                  <div class="inline-flex w-10 h-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 overflow-hidden">
                    <img v-if="data.images" :src="data?.images[0]" loading="lazy" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                    <img v-else="image_url" :src="image_url" loading="lazy" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                  </div>
                  <div class="flex flex-col w-full">
                    <div class="ttp-text-md text-black text-left font-bold">{{ $convertString(data?.name) }}</div>
                    <time class="ttp-text-xs text-black text-left">{{ $dayjs(new Date(data?.refCreatedAt)).format("DD MMMM YYYY") }}</time>
                  </div>
                </router-link>
              </div>
              <div v-else-if="loading()" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <div class="m-auto text-center"><LoadingFormComponent class="text-black" /></div>
                <div class="ttp-text-xs text-black text-center">{{ $t("layout.loading") }}</div>
              </div>
              <div v-else-if="popularPlaceSearchStore?.tableSearchResults != null && !popularPlaceSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <div class="ttp-text-xs text-black text-center">{{ $t("layout.not_found") }}</div>
              </div>
            </div>
            <div v-if="!isVisibleSearch" class="w-full"></div>
            <button
              @click="
                () => {
                  if (!isVisibleSearch) isVisibleSearch = !isVisibleSearch;
                  else popularPlaceSearchFetch();
                }
              "
              class="w-10 text-white text-right text-right z-[99999]"
            >
              <div>
                <component :is="$solidIcons.MagnifyingGlassIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
              </div>
            </button>
            <div v-if="!isVisibleSearch" class="w-10 text-white text-right">
              <router-link :to="{ name: 'MessageView' }"
                ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                </svg>
              </router-link>
            </div>
            <div v-if="!isVisibleSearch" class="w-10 text-white text-right">
              <router-link :to="{ name: 'NotificationView' }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="flex my-4">
            <div class="w-full">
              <div class="flex w-full items-center gap-6 ml-4">
                <div class="swipe-photo inline-flex ttp-icon-box-01 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500 overflow-hidden">
                  <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
                  <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center rounded-full float-right w-16 h-16 lg:w-24 lg:h-24" :src="profile_image" loading="lazy" alt="" /></a>
                </div>
                <div class="flex-col justify-start items-start gap-0 inline-flex">
                  <div class="ttp-text-md sm:ttp-text-xl text-white font-extralight">
                    {{ $t("page.HomeView.hello_txt") }}, <span class="text-white-300">{{ $t("page.HomeView.welcome_txt") }}</span>
                  </div>
                  <div class="text-white font-medium ttp-text-3xl">
                    <div>{{ authStore?.user?.name }}</div>
                    <div class="ttp-text-sm sm:ttp-text-xl text-white-300">{{ $t("page.HomeView.welcome_detail_txt") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto">
            <div class="grid grid-cols-1">
              <component
                :classTagName="'w-full text-left'"
                :is="defaultFormComponents.form.input.keyword.component"
                v-bind="defaultFormComponents.form.input.keyword.props"
                @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue"
                @click="
                  () => {
                    isVisibleSearch = !isVisibleSearch;
                  }
                "
              />
            </div>
            <div class="swiper-container w-full">
              <div v-if="popularPlaceSearchStore.loadingList" class="flex flex-col justify-center items-center w-full">
                <div><LoadingFormComponent /></div>
                <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
              </div>
              <Swiper v-else :slidesPerView="2.75" :loop="true" :spaceBetween="5" :modules="modules" class="swiper-wrapper">
                <SwiperSlide v-for="(result, number) in popularPlaceSearchStore?.tableResults?.items" :key="number" class="swiper-slide">
                  <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: result?.id } }" class="relative flex gap-2 h-36 rounded-[21px] overflow-hidden">
                    <div class="relative w-full">
                      <div class="absolute w-full h-36 inset-0">
                        <img v-if="result.images" :src="result?.images[0]" loading="lazy" alt="รูปภาพ" class="object-cover w-full h-full" />
                      </div>
                    </div>
                    <div class="absolute w-full text-white bottom-0 p-4 ttp-text-md leading-none font-bold">{{ $convertString(result?.name) }}</div>
                  </router-link>
                </SwiperSlide>
              </Swiper>
            </div>

            <TabGroup>
              <TabList class="flex items-center border-b border-gray-200/75 ttp-text-sm bg-white">
                <Tab as="template" v-slot="{ selected }">
                  <button
                    class="-mb-px flex grow items-center justify-center space-x-2 border-b-4 py-3 font-medium"
                    :class="{
                      'ttp-text-sm font-bold border-ttp-primary text-ttp-primary dark:border-ttp-primary dark:text-ttp-primary': selected,
                      'ttp-text-sm font-bold border-transparent text-gray-600 hover:border-gray-200 hover:text-ttp-primary active:text-ttp-primary dark:text-ttp-primary dark:hover:border-gray-600 dark:hover:text-ttp-primary': !selected,
                    }"
                  >
                    Attraction
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    class="-mb-px flex grow items-center justify-center space-x-2 border-b-4 py-3 font-medium"
                    :class="{
                      'ttp-text-sm font-bold border-ttp-primary text-ttp-primary dark:border-ttp-primary dark:text-ttp-primary': selected,
                      'ttp-text-sm font-bold border-transparent text-gray-600 hover:border-gray-200 hover:text-ttp-primary active:text-ttp-primary dark:text-ttp-primary dark:hover:border-gray-600 dark:hover:text-ttp-primary': !selected,
                    }"
                  >
                    Activity
                  </button>
                </Tab>
              </TabList>

              <TabPanels ref="el" class="flex w-full h-full overflow-auto">
                <TabPanel class="flex flex-col w-full h-full items-center mx-0">
                  <div v-if="popularPlaceSearchStore?.tableScrollResults?.items?.length" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4">
                    <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: result?.id } }" v-for="(result, number) in popularPlaceSearchStore?.tableScrollResults?.items" :key="number" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                      <div class="relative w-full overflow-hidden">
                        <div class="absolute w-full h-36 inset-0">
                          <img v-if="result.images" :src="result?.images[0]" loading="lazy" alt="รูปภาพ" class="object-cover w-full h-full" />
                        </div>
                      </div>
                      <div class="flex flex-col w-full justify-between py-2">
                        <h2 class="ttp-text-lg font-bold text-[#2C3483]">{{ $convertString(result.name) }}</h2>
                        <div class="flex gap-2 grow -items-center -justify-center">
                          <span><component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto text-black ttp-icon-inside-box-03 cursor-pointer" /></span>
                          <span class="ttp-text-sm">{{ $convertString(result?.address) }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div v-else-if="!popularPlaceSearchStore?.tableListResults?.items?.length && popularPlaceSearchStore.currentPage == 1 && !popularPlaceSearchStore.loadingScroll" class="flex flex-col h-full justify-center items-center">
                    <div class="text-center">
                      <component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                      <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.PopularPlaceView.not_found") }}</div>
                    </div>
                  </div>
                  <div v-if="popularPlaceSearchStore.loadingScroll" class="flex flex-col justify-center items-center w-full">
                    <div><LoadingFormComponent /></div>
                    <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
                  </div>
                </TabPanel>
                <TabPanel class="flex flex-col w-full h-full items-center mx-0">
                  <div v-if="activitySearchStore?.tableScrollResults?.items?.length" class="w-full grid md:grid-cols-2 grid-cols-1 gap-4">
                    <router-link :to="{ name: 'ActivityDetailView', params: { id: result?.id } }" v-for="(result, number) in activitySearchStore?.tableScrollResults?.items" :key="number" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                      <div class="relative w-full overflow-hidden">
                        <div class="absolute w-full h-36 inset-0">
                          <img v-if="result.images" :src="result?.images[0]" loading="lazy" alt="รูปภาพ" class="object-cover w-full h-full" />
                        </div>
                      </div>
                      <div class="flex flex-col w-full justify-between py-2">
                        <h2 class="ttp-text-lg font-bold text-[#2C3483]">{{ $convertString(result.name) }}</h2>
                        <div class="flex gap-2 grow -items-center -justify-center">
                          <span><component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto text-black ttp-icon-inside-box-03 cursor-pointer" /></span>
                          <span class="ttp-text-sm">{{ $convertString(result?.address) }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div v-else-if="!activitySearchStore?.tableListResults?.items?.length && activitySearchStore.currentPage == 1 && !activitySearchStore.loadingScroll" class="flex flex-col h-full justify-center items-center">
                    <div class="text-center">
                      <component :is="$outlineIcons.MapPinIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                      <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.PopularPlaceView.not_found") }}</div>
                    </div>
                  </div>
                  <div v-if="activitySearchStore.loadingScroll" class="flex flex-col justify-center items-center w-full">
                    <div><LoadingFormComponent /></div>
                    <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
