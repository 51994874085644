import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { defineStore } from "pinia";

export type ListFilter = {
  distance?: number;
  latitude?: number;
  longitude?: number;
  keyword?: string;
  page?: number;
  size?: number;
};

export type Attraction = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  refCreatedAt: string;
  refUpdatedAt: string;
  locale: string;
  name: string;
  description: string;
  type: string[];
  images: string[];
  videos: string[];
  touristTypes: string[];
  maximumCapacity: number;
  announcements: string[];
  address: string;
  proviceID?: number;
  latitude?: number;
  longitude?: number;
  contactNumber: string;
  email: string;
  contactChannels: { name: string; url: string }[];
  openingHours: {
    dayOfWeek: string;
    open: string;
    close: string;
    validFrom: string;
    validThrough: string;
  }[];
  isFreeAccessible: boolean;
  prices: {
    price: number;
    priceCurrency: string;
    type: string;
  }[];
  additionalProperties: string[];
  distance: number;
};

export type Paginate<T> = {
  items: T[];
  page: number;
  size: number;
  totalPages: number;
  total: number;
};

export const storeName = `usePopularPlaceStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;
export const usePopularPlaceStore = defineStore(storeName, {
  state: () => ({}),
  actions: {
    async list(params: ListFilter, locale = "EN"): Promise<Paginate<Attraction>> {
      const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<Paginate<Attraction>>("/v1/attractions", { params: { ...params }, headers: { "Accept-Language": locale } });

      return data;
    },
    async get(id: string, locale = "EN"): Promise<Attraction> {
      const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<Attraction>(`/v1/attractions/${id}`, { headers: { "Accept-Language": locale } });

      return data;
    },
  },
});
