<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";

import { Swiper, SwiperSlide } from "swiper/vue";
import { useNewsSearchStore } from "./stores/useNewsSearchStore";
import type { $string } from "@/configs/types/Shared/typeShare";

export default defineComponent({
  name: "NewsDetailView",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      componentName: this.$options.name,
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href,
      isVisibleSearch: false,
      result: null as any,
      image_urls: [] as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.newsSearchStore.error.keyword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                classIcon: "ttp-icon-inside-box-03 text-[#2C3483]",
                componentIcon: this.$solidIcons.NewspaperIcon,
                isDisable: this.loading(),
                isRequired: this.newsSearchStore.isRequired.keyword.is,
                textError: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.newsSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                value: this.newsSearchStore.result.keyword,
                name: this.newsSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: async (value: $string) => {
                this.newsSearchStore.result.keyword = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async fetch() {
      this.newsSearchStore.loading = true;
      if (this.$route.params.id) {
        await Promise.all([
          (async () => {
            const result = await this.newsSearchStore.fetchNews.Get(this.$route.params.id as string);
            this.newsSearchStore.getResult = result;

            // Get Thumbnail
            if (this?.newsSearchStore?.getResult?.thumbnail) {
              this.newsSearchStore.getResult.thumbnail = await Promise.all(await this.newsSearchStore?.fetchFile?.Get([this?.newsSearchStore?.getResult?.thumbnail]));
            }

            // Get Images
            if (this?.newsSearchStore?.getResult?.images?.length) {
              this.newsSearchStore.getResult.images = await Promise.all(await this.newsSearchStore?.fetchFile?.Get(this?.newsSearchStore?.getResult?.images));
            }

            this.$configLightbox();
          })(),
        ]);
      }
      this.newsSearchStore.loading = false;
    },
    async newsSearchFetch() {
      if (!this.newsSearchStore.loadingSearch) this.newsSearchStore.onUpdateSearchCurrentPage(Number(this.$route.query.page) || 1);
    },
    loading() {
      return this.newsSearchStore.loading;
    },
    async reset() {
      await this.newsSearchStore.$reset();
    },
    async setting() {
      await this.fetch();
      await this.$configLightbox();
    },
  },
  created() {
    watch(
      () => this.$route.params.id,
      () => {
        this.fetch();
      }
    );
  },
  async mounted() {
    await this.reset();
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const newsSearchStore = useNewsSearchStore();

    return {
      authStore,
      newsSearchStore,
      masterDataStore,
      swiperOptions: ref({
        navigation: true,
        pagination: true,
        breakpoints: {
          320: {
            slidesPerView: 4,
          },
          425: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 4,
          },
          1440: {
            slidesPerView: 6,
          },
        },
      }),
    };
  },
});
</script>

<template>
  <div
    v-if="isVisibleSearch"
    @click="
      () => {
        isVisibleSearch = !isVisibleSearch;
      }
    "
    class="absolute top-0 left-0 bg-black flex w-full h-full z-[10] opacity-50"
  ></div>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left z-[11]"
              @click="
                () => {
                  $router.go(-1);
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div v-if="isVisibleSearch" class="relative w-full text-white text-right z-[99999]">
              <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
              <div v-if="newsSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <router-link
                  :to="{ name: 'NewsDetailView', params: { id: data?.id } }"
                  @click="
                    () => {
                      isVisibleSearch = !isVisibleSearch;
                    }
                  "
                  v-for="(data, key) in newsSearchStore?.tableSearchResults?.items"
                  :key="key"
                  class="flex gap-2 mb-2 w-full"
                >
                  <div class="inline-flex w-10 h-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 overflow-hidden">
                    <img v-if="data?.thumbnail[0]" :src="data?.thumbnail[0]?.url" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                    <img v-else="image_url" :src="image_url" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                  </div>
                  <div class="flex flex-col w-full">
                    <div class="ttp-text-md text-black text-left font-bold">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.title) }}</div>
                    <time class="ttp-text-xs text-black text-left">{{ $dayjs(new Date(data?.contentAt)).format("DD MMMM YYYY") }}</time>
                  </div>
                </router-link>
              </div>
              <div v-else-if="loading()" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <div class="m-auto text-center"><LoadingFormComponent class="text-black" /></div>
                <div class="ttp-text-xs text-black text-center">{{ $t("layout.loading") }}</div>
              </div>
              <div v-else-if="newsSearchStore?.tableSearchResults != null && !newsSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <div class="ttp-text-xs text-black text-center">{{ $t("layout.not_found") }}</div>
              </div>
            </div>
            <div v-if="!isVisibleSearch" class="w-full"></div>
            <button
              @click="
                () => {
                  if (!isVisibleSearch) isVisibleSearch = !isVisibleSearch;
                  else newsSearchFetch();
                }
              "
              class="w-10 text-white text-right z-[99999]"
            >
              <div>
                <component :is="$solidIcons.MagnifyingGlassIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
              </div>
            </button>
            <div v-if="!isVisibleSearch" class="w-10 text-white text-right">
              <router-link :to="{ name: 'MessageView' }"
                ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                </svg>
              </router-link>
            </div>
            <div v-if="!isVisibleSearch" class="w-10 text-white text-right">
              <router-link :to="{ name: 'NotificationView' }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
              </router-link>
            </div>
          </div>

          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto pb-20">
            <div class="flex flex-col w-full">
              <time class="w-full ttp-text-xs"><span class="w-full ttp-text-xs font-medium">Date</span>: {{ $dayjs(new Date(newsSearchStore?.getResult?.contentAt)).format("DD MMMM YYYY") }}</time>
              <h1 class="w-full ttp-text-lg font-bold">{{ $convertString($filterNewsByLocale(newsSearchStore?.getResult?.translations, masterDataStore?.lang())?.title) }}</h1>
            </div>
            <hr />
            <p class="ttp-text-xs" v-html="$convertString($filterNewsByLocale(newsSearchStore?.getResult?.translations, masterDataStore?.lang())?.detail)"></p>
            <hr />
            <h3 class="w-full ttp-text-md font-bold">{{ $t("page.NewsInternalView.gallery") }}</h3>
            <div class="swipe-photo flex gap-2 w-full">
              <div v-if="$isArrayOfStringsOrObjects(newsSearchStore?.getResult?.images) || loading()" class="flex flex-col justify-center items-center w-full">
                <div><LoadingFormComponent /></div>
                <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
              </div>
              <template v-else>
                <a v-if="newsSearchStore?.getResult?.images[0]" :href="newsSearchStore?.getResult?.images[0]?.url" target="_blank">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="newsSearchStore?.getResult?.images[0].url" alt="" />
                </a>
                <a v-if="newsSearchStore?.getResult?.images[1]" :href="newsSearchStore?.getResult?.images[1]?.url" target="_blank">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="newsSearchStore?.getResult?.images[1].url" alt="" />
                </a>
                <a v-if="newsSearchStore?.getResult?.images[2]" :href="newsSearchStore?.getResult?.images[2]?.url" target="_blank">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="newsSearchStore?.getResult?.images[2].url" alt="" />
                </a>
                <a v-if="newsSearchStore?.getResult?.images[3]" :href="newsSearchStore?.getResult?.images[3]?.url" target="_blank" class="relative">
                  <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="newsSearchStore?.getResult?.images[3].url" alt="" />
                  <div class="absolute top-0 left-0 bg-black opacity-50 w-full h-full"></div>
                  <div class="absolute flex top-0 left-0 w-full h-full text-white justify-center items-center ttp-text-xl font-bold">{{ newsSearchStore?.getResult?.images.length - 3 }}+</div>
                </a>
                <template v-if="newsSearchStore?.getResult?.images?.length" v-for="(data, index) in newsSearchStore?.getResult?.images" :key="index">
                  <a :href="data?.url" v-if="!$inArray(index, [0, 1, 2, 3])" target="_blank" class="hidden">
                    <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="data?.url" alt="" />
                  </a>
                </template>
                <div v-else class="flex flex-col justify-center items-center w-full">
                  <div class="ttp-text-xs">{{ $t("layout.not_found_image") }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss">
.pswp img {
  max-width: none;
  object-fit: contain;
}
.pswp__img--placeholder {
  display: none !important;
}
</style>
