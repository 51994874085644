import { defineStore } from "pinia";
import * as yup from "yup";
import { ENUM_CHAT_SENDER, ENUM_CHAT_TYPE, ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";
import { convertToValues, convertToNumber, findIndexById, configLightbox, getLocalStorage } from "@/configs/helpers/MainHelper";
import { v4 as uuidv4 } from "uuid";
import type { $string } from "@/configs/types/Shared/typeShare";
import dayjs from "dayjs";
import { ref, getCurrentInstance } from "vue";
import type { typeMap } from "@/configs/types/Incident/typeIncident";
import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

const storeName = `useChatFormStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  message: {
    is: true,
    name: `message`,
    label: `form.message.label`,
    placeholder: `form.message.placeholder`,
    value: null,
  },
};
const isRequiredLocation = {
  name: {
    is: true,
    name: `name`,
    label: `form.location_detail.label`,
    placeholder: `form.location_detail.label`,
    value: null,
  },
  latitude: {
    is: true,
    name: `latitude`,
    label: `form.latitude.label`,
    placeholder: `form.latitude.placeholder`,
    value: null,
  },
  longitude: {
    is: true,
    name: `longitude`,
    label: `form.longitude.label`,
    placeholder: `form.longitude.placeholder`,
    value: null,
  },
};
export const useChatFormStore = defineStore(storeName, {
  state: () => ({
    chatSocket: getCurrentInstance()?.appContext.config.globalProperties?.$chatSocket,
    fetchUser: new fetchUser(),
    fetchIncident: new fetchIncident(),
    fetchFile: new fetchFile(),
    results: [] as any,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    isToggle: false,
    isCamera: false,
    isMap: false,
    loading: false,
    currentPage: 0,
    totalPage: 1,
    roomId: getLocalStorage(`roomId`) as any,
    startAt: null,
    endAt: null,
    isRequired,
    isRequiredLocation,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    result: convertToValues(isRequired) as typeGetUser,
    resultLocation: convertToValues(isRequiredLocation) as typeMap,
    id: null as any,
    replyID: null as any,
    selectedTopic: null as any,
    selectedImages: null as any,
    selectValue: null as any,
    selectId: null as any,
    buttonText_1: null as any,
    buttonText_2: null as any,
    imageUrls: [] as any[],
    imageIds: [] as any[],
    stream: null as any,
    ready: false as any,
    photo: null as any,
    error: {} as { [key: string]: string | null | any },
    locationError: {} as { [key: string]: string | null | any },
    validationSchema: yup.object({
      message: isRequired.message.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
    validationSchemaLocation: yup.object({
      name: isRequiredLocation.name.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      latitude: isRequiredLocation.latitude.is
        ? yup.number().nullable().required()
        : yup
            .number()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === "" ? null : value;
            })
            .notRequired(),
      longitude: isRequiredLocation.longitude.is
        ? yup.number().nullable().required()
        : yup
            .number()
            .nullable()
            .transform((value, originalValue) => {
              return originalValue === "" ? null : value;
            })
            .notRequired(),
    }),
    currentIncident: false as any,
    message: null as any,
    messages: [] as any,
    messageContainer: ref(0) as any,
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        return this.update(ENUM_CHAT_TYPE.TEXT, this.replyID);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async onSubmitLocation(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.resultLocation = convertToNumber(this.resultLocation, keysToConvert);

      Object.keys(this.locationError).forEach((key) => {
        this.locationError[key] = null;
      });

      try {
        await this.validationSchemaLocation.validate(this.resultLocation, {
          abortEarly: false,
        });

        return this.update(ENUM_CHAT_TYPE.LOCATION, this.resultLocation);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.locationError[inner.path] = inner.message;
          }
        });
      }
    },
    async update(type: ENUM_CHAT_TYPE, content?: any) {
      try {
        this.loading = true;
        var payload = {};

        if (type == ENUM_CHAT_TYPE.TEXT) {
          payload = {
            content: this.message,
            type: ENUM_CHAT_TYPE.TEXT,
            to: this.currentIncident,
            refID: uuidv4(),
            datetime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          };
        } else if (type == ENUM_CHAT_TYPE.IMAGES) {
          payload = {
            refID: uuidv4(),
            to: this.currentIncident,
            content: content,
            type: ENUM_CHAT_TYPE.IMAGES,
          };
        } else if (type == ENUM_CHAT_TYPE.LOCATION) {
          payload = {
            refID: uuidv4(),
            to: this.currentIncident,
            content: content,
            type: ENUM_CHAT_TYPE.LOCATION,
          };
        } else if (type == ENUM_CHAT_TYPE.SELECTED) {
          payload = {
            refID: uuidv4(),
            to: this.currentIncident,
            content: content,
            type: ENUM_CHAT_TYPE.SELECTED,
          };
        }

        if (this.chatSocket) {
          this.chatSocket.emit("message.sent", payload);
          if (type == ENUM_CHAT_TYPE.IMAGES) {
            payload = {
              ...payload,
              content: this.imageUrls,
            };
          } else if (type == ENUM_CHAT_TYPE.LOCATION) {
            const data = (payload as any).content;
            const result = Object.values({
              latitude: data.latitude,
              longitude: data.longitude,
            }).join(",");

            payload = {
              ...payload,
              content: {
                ...(payload as any).content,
                url: await this.getStaticMap(result),
              },
            };
          }

          await this.messages.push({ sender: ENUM_CHAT_SENDER.USER, message: payload });
          console.log("pushed", { payload });

          configLightbox();

          if (type == ENUM_CHAT_TYPE.LOCATION) {
            if (this.messages[findIndexById(this.messages, this.id)] !== undefined) this.messages[findIndexById(this.messages, this.id)].child = "Hide";
          }

          this.result.message = null;
          this.message = null;

          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loading = false;

          this.isSuccess = true;

          this.isMap = false;
        }
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }

      return;
    },
    async setIncident(incidentID: $string, userType: ENUM_CHAT_SENDER, userID: $string) {
      if (this.chatSocket) {
        this.chatSocket.auth = {
          incidentID,
          userType,
          userID,
        };
      }
      this.currentIncident = incidentID;
    },
    chooseFiles: function () {
      (document.getElementById("file-upload") as HTMLElement).click();
    },
    async handleFileChange(e: any) {
      const files = e.target.files;
      if (files && files.length > 0) {
        if (e.target.files.length > 0) {
          const results = await this.fetchFile.Upload(files);
          this.imageIds = await results.map((item: any) => item.id);
          this.imageUrls = await this.fetchFile.Get(this.imageIds);

          this.update(ENUM_CHAT_TYPE.IMAGES, this.imageIds);
          if (this.messages[findIndexById(this.messages, this.id)] !== undefined) this.messages[findIndexById(this.messages, this.id)].child = "Hide";
        } else {
          if (Capacitor.isNativePlatform()) {
            this.photo = null;

            const capturedPhoto = await Camera.getPhoto({
              resultType: CameraResultType.Uri,
              source: CameraSource.Camera,
              quality: 100,
            });

            this.photo = await fetch(capturedPhoto.webPath!);
            this.uploadPhoto();
          }
        }
      }
    },
    uploadPhoto() {
      this.photo.toBlob(async (blob: any) => {
        const currentDate = new Date();
        const dateString = currentDate.toISOString().replace(/[:.]/g, "-");
        const fileName = `custom_${dateString}.jpg`;

        const file = new File([blob], fileName, { type: "image/jpeg" });

        const results = await this.fetchFile.Upload([file]);
        this.imageIds = await results.map((item: any) => item.id);
        this.imageUrls = await this.fetchFile.Get(this.imageIds);

        this.update(ENUM_CHAT_TYPE.IMAGES, this.imageIds);
        this.messages[findIndexById(this.messages, this.id)].child = "Hide";
      }, "image/jpeg");
    },
    async getStaticMap(location: $string) {
      const base_url = "https://maps.googleapis.com/maps/api/staticmap";
      const params = {
        center: location,
        zoom: 15,
        size: "1024x768",
        markers: `color:red|label:A|${location}`,
        key: import.meta.env.VITE_GOOGLE_APIKEY,
      };

      return await axios
        .get(base_url, { params, responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          return url;
        })
        .catch((error) => {
          console.error("Error fetching static map:", error);
        });
    },
  },
});
