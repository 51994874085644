import type { App } from "vue";
import dayjs from "dayjs";
import type { ENUM_FIELD_LOCALE } from "@/configs/enums/enum";

type Options = {
  locale?: ENUM_FIELD_LOCALE;
};

export default {
  install: (app: App, options: Options) => {
    if (options.locale) {
      dayjs.locale(options.locale);
    }
    app.config.globalProperties.$dayjs = dayjs;
  },
};
