<script lang="ts">
import { defineComponent } from "vue";

const ProfileComponent = defineComponent({
  name: "ProfileComponent",
  data() {
    return {
      bgImage: new URL(`@/assets/images/bg.png`, import.meta.url).href,
    };
  },
});
export default ProfileComponent;
</script>

<template>
  <img class="absolute top-0 left-0 w-full md:-mt-20 lg:-mt-40" :src="bgImage" />
  <div class="relative overflow-hidden items-center justify-center mt-6 mb-44">
    <div class="flex">
      <div class="w-full">
        <div class="flex w-full items-center gap-6 ml-4">
          <div class="inline-flex h-36 w-36 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
            <component :is="$solidIcons.UserIcon" class="inline-block h-20 w-20" />
          </div>
          <div class="flex-col justify-start items-start gap-2 inline-flex">
            <div class="text-white text-4xl font-normal">Hello,</div>
            <div class="text-white ttp-text-lg font-bold">Somchai!</div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex gap-4 justify-center items-center mr-4">
          <router-link :to="{ name: 'MessageView' }"
            ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
            </svg>
          </router-link>
          <router-link :to="{ name: 'NotificationView' }">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
