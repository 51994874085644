import { ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { convertToNumber, convertToValues } from "@/configs/helpers/MainHelper";
import type { typeGetInformer } from "@/configs/types/Informer/typeInformer";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useProfileEmergencyContactStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  emergencyContact: {
    name: {
      is: true,
      name: `emergencyContact.name`,
      label: `form.name.label`,
      placeholder: `form.name.placeholder`,
      value: null,
    },
    contactNumber: {
      is: true,
      name: `emergencyContact.contactNumber`,
      label: `form.phone.label`,
      placeholder: `form.phone.placeholder`,
      value: null,
    },
    identificationNumber: {
      is: true,
      name: `emergencyContact.identificationNumber`,
      label: `form.passport_number.label`,
      placeholder: `form.passport_number.placeholder`,
      value: null,
    },
  },
};

export const useProfileEmergencyContactStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    isRequired,
    result: convertToValues(isRequired) as any,
    error: {} as { [key: string]: string | null | any },
    validationSchema: yup.object().shape({
      emergencyContact: yup.object().shape({
        name: isRequired.emergencyContact.name.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        contactNumber: isRequired.emergencyContact.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        identificationNumber: isRequired.emergencyContact.identificationNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      }),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        await this.update(this.result);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update(result: typeGetInformer): Promise<typeGetInformer> {
      try {
        this.loading = true;
        const data = await this.fetchAuth.UpdateProfile({
          ...result,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
