import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import type { typeRegisteEmergencyUser } from "@/configs/types/User/typeRegisteEmergencyUser";
import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";

const storeName = `useRegisterEmergencyStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  emergencyContact: {
    name: {
      is: false,
      name: `guest.emergencyContact.name`,
      label: `form.name.label`,
      placeholder: `form.name.placeholder`,
      value: null,
    },
    contactNumber: {
      is: false,
      name: `guest.emergencyContact.contactNumber`,
      label: `form.phone.label`,
      placeholder: `form.phone.placeholder`,
      value: null,
    },
    identificationNumber: {
      is: false,
      name: `guest.emergencyContact.identificationNumber`,
      label: `form.passport_number.label`,
      placeholder: `form.passport_number.placeholder`,
      value: null,
    },
  },
};

export const useRegisterEmergencyStore = defineStore(storeName, {
  state: () => ({
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    termConditionsLoading: false,
    isRequired,
    result: convertToValues(isRequired) as typeRegisteEmergencyUser,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      emergencyContact: yup.object().shape({
        name: isRequired.emergencyContact.name.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        contactNumber: isRequired.emergencyContact.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        // identificationNumber: isRequired.emergencyContact.identificationNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        identificationNumber: isRequired.emergencyContact.identificationNumber.is
          ? yup
              .string()
              .nullable()
              .test((value: any) => {
                const fetch = new fetchAuth();
                return fetch.Hidden().then((results) => {
                  const resolvedResults = results;
                  return resolvedResults.emergencyContact.identificationNumber ? value : true;
                });
              })
          : yup.string().nullable().notRequired(),
      }),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        return await this.update();
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return false;
      }
    },
    async update() {
      this.loading = true;
      return true;
    },
  },
});
