import { defineStore } from "pinia";
import { Capacitor } from "@capacitor/core";

declare var Media: any;

const storeName = `useAudioStore-${import.meta.env.VITE_APP_VERSION}`;

export const useAudioStore = defineStore(storeName, {
  state: () => ({
    call: null as any,
    hang_up: null as any,
    waiting: null as any,
    isCallPlaying: false,
    isHangUpPlaying: false,
    isWaitingPlaying: false,
  }),
  actions: {
    async initializeCall() {
      if (this.call) {
        this.call.stop();
        this.call.release();
      }

      this.call = await new Media(`${Capacitor.getPlatform() == "android" ? "/android_asset/public" : ""}/assets/sounds/phone-outgoing-call-72202.mp3`, () => {
        this.isCallPlaying = false;
      });
    },
    async playCall() {
      try {
        if (!this.call) {
          await this.initializeCall();
        }
        this.call.play({ numberOfLoops: 1000 });
        this.call.setVolume("1.0");
        this.isCallPlaying = true;
      } catch (error) {
        alert(error);
      }
    },
    stopCall() {
      if (this.call && this.isCallPlaying) {
        this.call.stop();
        this.isCallPlaying = false;
      }
    },
    releaseCall() {
      if (this.call) {
        this.call.release();
        this.call = null;
        this.isCallPlaying = false;
      }
    },
    async initializeHangUp() {
      if (this.hang_up) {
        this.hang_up.stop();
        this.hang_up.release();
      }
      this.hang_up = await new Media(`${Capacitor.getPlatform() == "android" ? "/android_asset/public" : ""}/assets/sounds/mobile_phone_hanging_up-94525.mp3`, () => {
        this.isHangUpPlaying = false;
      });
    },
    playHangUp() {
      if (!this.hang_up) {
        this.initializeHangUp();
      }
      this.hang_up.play();
      this.hang_up.setVolume("1.0");
      this.isHangUpPlaying = true;
    },
    stopHangUp() {
      if (this.hang_up && this.isHangUpPlaying) {
        this.hang_up.stop();
        this.isHangUpPlaying = false;
      }
    },
    releaseHangUp() {
      if (this.hang_up) {
        this.hang_up.release();
        this.hang_up = null;
        this.isHangUpPlaying = false;
      }
    },
    async initializeWaiting() {
      if (this.waiting) {
        this.waiting.stop();
        this.waiting.release();
      }
      this.waiting = await new Media(`${Capacitor.getPlatform() == "android" ? "/android_asset/public" : ""}/assets/sounds/ringtone-126505.mp3`, () => {
        this.isWaitingPlaying = false;
      });
    },
    playWaiting() {
      if (!this.waiting) {
        this.initializeWaiting();
      }
      this.waiting.play({ numberOfLoops: 1000 });
      this.waiting.setVolume("1.0");
      this.isWaitingPlaying = true;
    },
    stopWaiting() {
      if (this.waiting && this.isWaitingPlaying) {
        this.waiting.stop();
        this.isWaitingPlaying = false;
      }
    },
    releaseWaiting() {
      if (this.waiting) {
        this.waiting.release();
        this.waiting = null;
        this.isWaitingPlaying = false;
      }
    },
  },
});
