<script lang="ts">
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { defineComponent } from "vue";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";
export default defineComponent({
  name: "PageSidebarMenuComponent",
  methods: {
    async logout() {
      await Promise.all([await this.authStore.logout()]);
      this.$window.location.href = this.$router.resolve({ name: "LoginPoliceCommandCenterView" }).href;
    },
  },
  setup() {
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const authStore = useAuthStore();
    return {
      sharedStore,
      authStore,
    };
  },
});
</script>

<template>
  <div class="space-y-1" v-for="route in $router.options.routes" :key="route.name">
    <!-- Main Menu / Dropdown is true -->
    <button
      type="button"
      v-if="route?.meta?.isMenu && route?.meta?.isDropdown"
      :to="route?.path"
      :active="route?.name === $route?.matched[0]?.name"
      @click.stop="
        () => {
          if (sharedStore.submenuOpen !== String(route.name)) {
            sharedStore.setSubmenuOpen(route.name);
          } else {
            sharedStore.setSubmenuOpen(null);
          }
        }
      "
      class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white hover:bg-ttp-error focus:bg-ttp-error-20 rounded-md"
      :class="route?.name !== $route.matched[0].name ? '' : 'bg-ttp-error'"
    >
      <span v-if="route?.meta?.isIcon" v-html="route?.meta?.icon" class="flex-none flex items-center"></span>
      <span v-if="(route?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs">{{ $t((route?.meta as any).i18n) }}</span>
      <span
        v-if="route.children?.length"
        class="flex-none opacity-75"
        :class="{
          'rotate-180': sharedStore.submenuOpen == String(route.name),
        }"
      >
        <svg class="hi-mini hi-chevron-down inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>
    <!-- Main Menu / Dropdown is  false -->
    <div
      v-if="route?.meta?.isMenu && !route?.meta?.isDropdown && $inArray(authStore?.user?.role, route?.meta?.role ? route?.meta?.role : [] as any)"
      :to="route?.path"
      :active="route?.name === $route?.matched[0]?.name"
      @click.stop="
        () => {
          if (sharedStore.submenuOpen !== String(route.name)) {
            sharedStore.setSubmenuOpen(route.name);
          } else {
            sharedStore.setSubmenuOpen(null);
          }
        }
      "
      class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white rounded-md"
    >
      <span v-if="route?.meta?.isIcon" v-html="route?.meta?.icon" class="flex-none flex items-center"></span>
      <span v-if="(route?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs">{{ $t((route?.meta as any).i18n) }}</span>
      <span
        v-if="route.children?.length"
        class="flex-none opacity-75"
        :class="{
          'rotate-180': sharedStore.submenuOpen == String(route.name),
        }"
      >
      </span>
    </div>
    <!-- Sub Menu / Dropdown is true -->
    <Transition v-if="route?.meta?.isDropdown" enter-active-class="transition ease-out duration-100" enter-from-class="-translate-y-5 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition ease-in duration-100" leave-from-class="translate-y-0 opacity-100" leave-to-class="-translate-y-5 opacity-0" v-for="sub_route in route.children" :key="sub_route.name">
      <router-link
        v-if="sub_route?.meta?.isMenu"
        :to="`${route.path}${sub_route.path ? '/' + sub_route.path : ''}`"
        @click="
          () => {
            sharedStore.mobileSidebarOpen = !sharedStore.mobileSidebarOpen;
          }
        "
      >
        <div v-if="sharedStore.submenuOpen == String(route.name)" class="relative z-0 ml-7 mt-1">
          <button type="button" class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white hover:bg-ttp-error focus:bg-ttp-error-20 rounded-md" :class="sub_route?.name !== $route.name ? '' : 'bg-ttp-error'">
            <span v-if="sub_route?.meta?.isMenu && route?.meta?.isIcon" v-html="sub_route?.meta?.icon" class="flex-none flex items-center" />
            <span v-if="sub_route?.meta?.isMenu && (sub_route?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs"> {{ $t((sub_route?.meta as any).i18n) }}</span>
          </button>
        </div>
      </router-link>
    </Transition>
    <!-- Sub Menu / Dropdown is  false -->
    <Transition v-if="!route?.meta?.isDropdown" enter-active-class="transition ease-out duration-100" enter-from-class="-translate-y-5 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition ease-in duration-100" leave-from-class="translate-y-0 opacity-100" leave-to-class="-translate-y-5 opacity-0" v-for="sub_route in route.children" :key="sub_route.name">
      <router-link
        v-if="sub_route?.meta?.isMenu"
        :to="`${route.path}${sub_route.path ? '/' + sub_route.path : ''}`"
        @click="
          () => {
            sharedStore.mobileSidebarOpen = false;
          }
        "
      >
        <template v-if="Boolean(sub_route?.meta?.role)">
          <div v-if="$inArray(authStore?.user?.role, sub_route?.meta?.role as any[])" class="relative z-0 mt-1">
            <button type="button" class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white hover:bg-ttp-error focus:bg-ttp-error-20 rounded-md" :class="!$inArray($route.name, (sub_route?.meta as any)?.activeMenu) ? '' : 'bg-ttp-error'">
              <span v-if="sub_route?.meta?.isMenu && sub_route?.meta?.isIcon" v-html="sub_route?.meta?.icon" class="flex-none flex items-center" />
              <span v-if="sub_route?.meta?.isMenu && (sub_route?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs"> {{ $t((sub_route?.meta as any).i18n) }}</span>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="relative z-0 mt-1">
            <button type="button" class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white hover:bg-ttp-error focus:bg-ttp-error-20 rounded-md" :class="!$inArray($route.name, (sub_route?.meta as any)?.activeMenu) ? '' : 'bg-ttp-error'">
              <span v-if="sub_route?.meta?.isMenu && sub_route?.meta?.isIcon" v-html="sub_route?.meta?.icon" class="flex-none flex items-center" />
              <span v-if="sub_route?.meta?.isMenu && (sub_route?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs"> {{ $t((sub_route?.meta as any).i18n) }}</span>
            </button>
          </div>
        </template>
      </router-link>
    </Transition>
  </div>
  <a class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white hover:bg-ttp-error focus:bg-ttp-error-20 rounded-md" href="https://meeting.ntconference.com/" target="_blank">
    <span class="flex-none flex items-center">
      <component :is="$outlineIcons.UserGroupIcon" class="inline-block ttp-icon-inside-box-03" />
    </span>
    <span class="py-2 grow text-left ttp-text-xs">NT Conference</span>
  </a>
  <button @click="logout" class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs text-white hover:bg-ttp-error focus:bg-ttp-error-20 rounded-md">
    <span class="flex-none flex items-center">
      <component :is="$solidIcons.LockClosedIcon" class="inline-block ttp-icon-inside-box-03" />
    </span>
    <span class="py-2 grow text-left ttp-text-xs">{{ $t("layout.menu.LogoutView") }}</span>
  </button>
</template>
