import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import type { typeGetForgotPasswordUser } from "@/configs/types/User/typeForgotPasswordUser";
import { defineStore } from "pinia";
import * as yup from "yup";
import { useForgotPasswordStore } from "./useForgotPasswordStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";

const storeName = `useForgotPasswordOTPStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  number_1: {
    is: true,
    name: `number_1`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_2: {
    is: true,
    name: `number_2`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_3: {
    is: true,
    name: `number_3`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_4: {
    is: true,
    name: `number_4`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_5: {
    is: true,
    name: `number_5`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_6: {
    is: true,
    name: `number_6`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
};

export const useForgotPasswordOTPStore = defineStore(storeName, {
  state: () => ({
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as typeGetForgotPasswordUser,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      number_1: isRequired.number_1.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_2: isRequired.number_2.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_3: isRequired.number_3.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_4: isRequired.number_4.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_5: isRequired.number_5.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_6: isRequired.number_6.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
        return await this.update();
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update() {
      this.loading = true;
      const forgotPasswordStore = useForgotPasswordStore();

      try {
        if (this.result) {
          const auth = new fetchAuth();
          const result = auth.LoginWithOTP({
            otp: `${this.result.number_1}${this.result.number_2}${this.result.number_3}${this.result.number_4}${this.result.number_5}${this.result.number_6}`,
            email: forgotPasswordStore?.result?.email,
            ref: forgotPasswordStore?.result?.ref,
          });
          this.loading = false;
          return result;
        } else {
          return false;
        }
      } catch (e) {
        return true;
      }
      return true;
    },
  },
});
