<script lang="ts">
// @ts-nocheck
import { defineComponent } from "vue";
import { useAuthStore as useAuthPoliceCommandCenterStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useAuthStore as useAuthPoliceTouristAndAgentStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "./stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "./stores/PoliceTouristAndAgent/useSharedStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";
import { Capacitor } from "@capacitor/core";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";
import { App, type URLOpenListenerEvent } from "@capacitor/app";

export default defineComponent({
  name: "AppView",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {
    urlOpenListenerEvent() {
      if (Capacitor.isNativePlatform()) {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
          const url = new URL(event.url);
          const view = url?.searchParams?.get("view");
          const param = url?.searchParams?.get("param");

          if (param) {
            this.$router.push({ name: view, params: JSON.parse(param) });
          } else {
            this.$router.push({ name: view });
          }
        });
      } else {
        const url = new URL(window.location.href);
        const view = url?.searchParams?.get("view");
        const param = url?.searchParams?.get("param");

        if (param) {
          this.$router.push({ name: view, params: JSON.parse(param) });
        } else {
          this.$router.push({ name: view });
        }
      }
    },
  },
  async mounted() {
    const authStore = isCommandCenterApp ? useAuthPoliceCommandCenterStore() : useAuthPoliceTouristAndAgentStore();
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const storeName = this.$inArray(this.$getProjectType(), [this.$enums.ENUM_APP_CATEGORY_NAME.COMMAND]) ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent;
    this.$i18n.locale = isCommandCenterApp ? sharedStore.locale?.toLowerCase() : sharedStore.locale?.toLowerCase();

    this.urlOpenListenerEvent();

    return {
      storeName,
      sharedStore,
      authStore,
    };
  },
  unmounted() {},
});
</script>

<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
