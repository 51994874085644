import { ENUM_APP_NAME, ENUM_CHAT_TYPE, ENUM_COLOR, ENUM_FIELD_LOCALE, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import _ from "lodash";
import { i18n } from "../../main";
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { Preferences } from "@capacitor/preferences";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export class MainHelper { }

export const appVersion = async () => {
  const packageJsonUrl = new URL("/package.json", import.meta.url);
  return await fetch(packageJsonUrl)
    .then((response) => response.json())
    .then((packageJson) => packageJson.version);
};

export const classStatus = (status: any) => {
  return status ? status : ENUM_COLOR.NEW;
};

export const inArray = (value: any, array: any[]) => {
  return array.includes(value);
};

export const convertEnumToArray = (enums: any) => {
  const enumArray = Object.keys(enums).map((key) => enums[key as keyof typeof enums]);
  enumArray.unshift("");
  return enumArray;
};

export const transformData = (data: any) => {
  return data
    .map((item: any) => ({
      label: item?.name?.TH,
      value: item?.id,
    }))
    .sort((a: any, b: any) => a.label?.trim().localeCompare((b.label || "").trim()));
};

export const transformUserData = (data: any) => {
  return data.items.map((item: any) => ({
    label: item.content[0]?.shortName,
    value: item.id,
  }));
};

export const convertToValues = (data: any): any => {
  if (typeof data === "object" && data !== null && !Array.isArray(data)) {
    const result: any = {};

    for (const key in data) {
      if (typeof data[key] === "object" && data[key] !== null && !Array.isArray(data[key])) {
        if ("value" in data[key]) {
          result[key] = data[key]["value"];
        } else {
          result[key] = convertToValues(data[key]);
        }
      } else if (Array.isArray(data[key])) {
        result[key] = data[key].map((item: any) => convertToValues(item));
      } else {
        result[key] = data[key];
      }
    }
    return result;
  } else {
    return data;
  }
};

export const convertString = (data: any) => {
  return data !== null && data !== undefined && data !== "" ? (data !== "\n" ? data : "-") : "-";
};
export const convertNumber = (data: any) => {
  return data ? data : "0";
};

export const formattedStatus = (status: any) => {
  return status.replace(/_/g, " ").replace(/\b\w/g, (c: any) => c.toUpperCase());
};

export const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const removeEmptyValues = (obj: any, keysToConvert: string[]): any => {
  const clonedObj = _.cloneDeep(obj);

  function removeEmpty(obj: any) {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value)) {
        if (!keysToConvert.includes(key)) {
          removeEmpty(value);
        }
      } else if (_.isEmpty(value) && !_.isNumber(value)) {
        // Remove only if the value is empty and not a number
        _.unset(obj, key);
      }
    });
  }

  removeEmpty(clonedObj);
  return clonedObj;
};

export const removeEmptyObjects = (obj: any): any => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  for (let key in obj) {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      if (obj[key] !== null && Object.keys(obj[key]).length === 0) {
        delete obj[key];
      } else {
        removeEmptyObjects(obj[key]);
        if (obj[key] !== null && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }
  }
  return obj;
};

export const convertToNumber = (obj: any, keysToConvert: string[]): any => {
  const clonedObj = JSON.parse(JSON.stringify(obj));

  keysToConvert.forEach((key) => {
    const keyPath = key.split(".");
    let currentObj = clonedObj;
    for (const k of keyPath) {
      if (currentObj[k] !== undefined && currentObj[k] !== null) {
        if (typeof currentObj[k] === "string" && !isNaN(Number(currentObj[k]))) {
          currentObj[k] = Number(currentObj[k]);
        }
        currentObj = currentObj[k];
      } else {
        break;
      }
    }
  });

  return clonedObj;
};

export const deleteKeys = (obj: any, keysToDelete: string[]) => {
  keysToDelete.forEach((key) => {
    const parts = key.split(".");
    let nestedObj: Record<string, any> | undefined = obj;

    for (let i = 0; i < parts.length - 1; i++) {
      if (!nestedObj || typeof nestedObj !== "object") return;
      nestedObj = nestedObj[parts[i]];
    }

    if (nestedObj) {
      const lastKey = parts[parts.length - 1];
      delete nestedObj[lastKey];
    }
  });

  return obj;
};

export const assignValues = (dataObj: any, keysArray: string[], value: any = "") => {
  let current = dataObj;
  for (let i = 0; i < keysArray.length; i++) {
    const keys: string[] = keysArray[i].split(".");
    for (let j = 0; j < keys.length - 1; j++) {
      const key: string = keys[j];
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    }
    const lastKey: string = keys[keys.length - 1];
    if (current[lastKey] === undefined || current[lastKey] === null) {
      current[lastKey] = value !== undefined ? value : "";
    }
    current = dataObj;
  }
  return dataObj;
};

export const deleteKeysFromObject = (obj: any, keysToDelete: string[]) => {
  keysToDelete.forEach((key) => {
    const keyParts = key.split(".");
    let currentObj = obj;

    for (let i = 0; i < keyParts.length - 1; i++) {
      currentObj = currentObj[keyParts[i]];
      if (!currentObj) return;
    }

    delete currentObj[keyParts[keyParts.length - 1]];
  });

  return obj;
};

export const wrapConvertValues = (obj: any, ignoreEmptyField: any[], convertNumberField: any[]) => {
  obj = removeEmptyValues(obj, ignoreEmptyField);
  obj = removeEmptyObjects(obj);
  obj = convertToNumber(obj, convertNumberField);
  return obj;
};

export const renameKeys = (obj: any, renameList: { name: string; rename: string }[]): any => {
  renameList.forEach((item) => {
    const { name, rename } = item;
    if (name !== rename && obj.hasOwnProperty(name)) {
      obj[rename] = obj[name];
      delete obj[name];
    }
  });
  return obj;
};

export const intersectJSON = (obj1: any, obj2: any) => {
  const result: any = {};
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object" && obj1[key] !== null && obj2[key] !== null) {
        result[key] = intersectJSON(obj1[key], obj2[key]);
      } else if (obj1[key] === obj2[key]) {
        result[key] = obj1[key];
      } else {
        result[key] = obj1[key];
      }
    }
  }
  return result;
};

export const checkNullAndConvert = (input: any) => {
  return input ? input : "";
};
export const mergeArrays = <T>(array1: T[], array2: T[]): T[] => {
  return [...array1, ...array2];
};

export const replaceString = (originalString: string, placeholder: string, newName: string): string => {
  return originalString?.replace(placeholder, newName);
};

export const containsString = (text: string, placeholder: string): boolean => {
  return text.toLowerCase().includes(placeholder);
};

export const hasNameAndValue = (obj: any) => {
  return obj.content && obj.content.hasOwnProperty("name") && obj.content.hasOwnProperty("value");
};

export const findIndexById = (array: any, targetId: string) => {
  return array.findIndex((item: any) => item.id === targetId);
};

export const findPlaceholder = (item: any) => {
  if (item?.message?.type == ENUM_CHAT_TYPE.LOCATION) return i18n.global.t("layout.non_location");
  else if (item?.message?.type == ENUM_CHAT_TYPE.IMAGES) return i18n.global.t("layout.non_image");
  else return i18n.global.t("layout.non");
};

export const isArrayOfStringsOrObjects = (arr: (string | object)[]): boolean => {
  return Array.isArray(arr) && arr.length > 0 && arr.every((item) => typeof item === "string");
};

export const findIndexesOfImages = (arr: any[]): number[] => {
  return arr
    .map((item, index) => ({ messageIndex: index, type: item.message.type }))
    .filter(({ type }) => type === ENUM_CHAT_TYPE.IMAGES)
    .map(({ messageIndex }) => messageIndex);
};
export const findIndexesOfLocations = (arr: any[]): number[] => {
  return arr
    .map((item, index) => ({ messageIndex: index, type: item.message.type }))
    .filter(({ type }) => type === ENUM_CHAT_TYPE.LOCATION)
    .map(({ messageIndex }) => messageIndex);
};

export const handleKeydown = (event: any, type: any) => {
  if (((event.metaKey || event.ctrlKey) && event.key === "a") || ((event.metaKey || event.ctrlKey) && event.key === "c") || ((event.metaKey || event.ctrlKey) && event.key === "w") || ((event.metaKey || event.ctrlKey) && event.key === "v") || ((event.metaKey || event.ctrlKey) && event.key === "r")) {
    return
  }

  switch (type) {
    case 'text':
      var notAllowedKeys = ["฿", "!", '"', "#", "$", "%", "&", "'", "(", ")", "*", "+", ",", "-", ".", "/", ":", ";", "<", "=", ">", "?", "@", "[", "\\", "]", "^", "_", "`", "{", "|", "}", "~", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

      if (notAllowedKeys.includes(event.key)) {
        event.preventDefault();
      }
      break;
    case 'english':
      if (!/[A-Za-z\s]/.test(event.key)) {
        event.preventDefault();
      }
      break;
    case 'decimal':
      var allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End", "Tab"];
      if (!allowedKeys.includes(event.key) || (event.key === "." && event.target.value.includes(".")) || ((event.metaKey || event.ctrlKey) && event.key === "a")) {
        event.preventDefault();
      }
      break
    case 'number':
      var allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End", "Tab"];
      if (!allowedKeys.includes(event.key) || ((event.metaKey || event.ctrlKey) && event.key === "a")) {
        event.preventDefault();
      }
      break
  }
};

export const checkValue = (event: any, number: number) => {
  if (event.target.value >= number) event.target.value = number;
};

export const removeHtmlTags = (htmlString: string): string => {
  return htmlString ? htmlString.replace(/<[^>]*>?/gm, " ") : "";
};

export const searchIncidentByIDOrTouristName = (data: any, searchTerm: string) => {
  const byIncidentID = data.filter((item: any) => item.incidentID === searchTerm);
  const byTouristName = data.filter((item: any) => item.incident && item.incident.tourist && item.incident.tourist.name && item.incident.tourist.name.toLowerCase().includes(searchTerm.toLowerCase()));
  return [...byIncidentID, ...byTouristName];
};

export const calculateTimeDifference = (start_date: Date, end_date: Date, lang: string): string => {
  const timeDiffInSeconds = Math.ceil(Math.abs(end_date.getTime() - start_date.getTime()) / 1000);

  const days = Math.floor(timeDiffInSeconds / (3600 * 24));
  const hours = Math.floor((timeDiffInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
  const seconds = Math.ceil(timeDiffInSeconds % 60);

  let formattedTime = "";

  if (days > 0) {
    formattedTime += days.toString() + " ";
    switch (lang) {
      case ENUM_FIELD_LOCALE.EN:
        formattedTime += days === 1 ? "day" : "days";
        break;
      case ENUM_FIELD_LOCALE.TH:
        formattedTime += "วัน";
        break;
      case ENUM_FIELD_LOCALE.JP:
        formattedTime += days === 1 ? "日" : "日々";
        break;
      case ENUM_FIELD_LOCALE.KR:
        formattedTime += days === 1 ? "일" : "일";
        break;
      case ENUM_FIELD_LOCALE.RU:
        formattedTime += days === 1 ? "день" : "дней";
        break;
      case ENUM_FIELD_LOCALE.CN:
        formattedTime += days === 1 ? "天" : "天";
        break;
      default:
        formattedTime += "days";
        break;
    }
    formattedTime += " ";
  }

  if (hours > 0 || days > 0) {
    formattedTime += hours.toString() + " ";
    switch (lang) {
      case ENUM_FIELD_LOCALE.EN:
        formattedTime += hours === 1 ? "hour" : "hours";
        break;
      case ENUM_FIELD_LOCALE.TH:
        formattedTime += "ชั่วโมง";
        break;
      case ENUM_FIELD_LOCALE.JP:
        formattedTime += hours === 1 ? "時間" : "時間";
        break;
      case ENUM_FIELD_LOCALE.KR:
        formattedTime += hours === 1 ? "시간" : "시간";
        break;
      case ENUM_FIELD_LOCALE.RU:
        formattedTime += hours === 1 ? "час" : "часов";
        break;
      case ENUM_FIELD_LOCALE.CN:
        formattedTime += hours === 1 ? "小时" : "小时";
        break;
      default:
        formattedTime += "hours";
        break;
    }
    formattedTime += " ";
  }

  if (minutes > 0 || hours > 0 || days > 0) {
    formattedTime += minutes.toString() + " ";
    switch (lang) {
      case ENUM_FIELD_LOCALE.EN:
        formattedTime += minutes === 1 ? "minute" : "minutes";
        break;
      case ENUM_FIELD_LOCALE.TH:
        formattedTime += "นาที";
        break;
      case ENUM_FIELD_LOCALE.JP:
        formattedTime += minutes === 1 ? "分" : "分";
        break;
      case ENUM_FIELD_LOCALE.KR:
        formattedTime += minutes === 1 ? "분" : "분";
        break;
      case ENUM_FIELD_LOCALE.RU:
        formattedTime += minutes === 1 ? "минута" : "минут";
        break;
      case ENUM_FIELD_LOCALE.CN:
        formattedTime += minutes === 1 ? "分钟" : "分钟";
        break;
      default:
        formattedTime += "minutes";
        break;
    }
    formattedTime += " ";
  }

  formattedTime += seconds.toString() + " ";
  switch (lang) {
    case ENUM_FIELD_LOCALE.EN:
      formattedTime += seconds === 1 ? "second" : "seconds";
      break;
    case ENUM_FIELD_LOCALE.TH:
      formattedTime += "วินาที";
      break;
    case ENUM_FIELD_LOCALE.JP:
      formattedTime += seconds === 1 ? "秒" : "秒";
      break;
    case ENUM_FIELD_LOCALE.KR:
      formattedTime += seconds === 1 ? "초" : "초";
      break;
    case ENUM_FIELD_LOCALE.RU:
      formattedTime += seconds === 1 ? "секунда" : "секунд";
      break;
    case ENUM_FIELD_LOCALE.CN:
      formattedTime += seconds === 1 ? "秒钟" : "秒钟";
      break;
    default:
      formattedTime += "seconds";
      break;
  }

  return formattedTime;
};

export const configLightbox = () => {
  const lightbox = new PhotoSwipeLightbox({
    gallery: ".swipe-photo",
    children: "a",
    pswpModule: PhotoSwipe,
    hideAnimationDuration: 0,
    showAnimationDuration: 0,
    zoomAnimationDuration: 0,
  });
  lightbox.init();
};

export const findStatusDetail = (incidents: any[], status: ENUM_INCIDENT_STATUS): string | null => {
  if (incidents) {
    for (const incident of incidents) {
      if (incident.status === status) {
        return incident.detail;
      }
    }
  }
  return null;
};

export const saveDataToLocalStorage = (key: string, data: any) => {
  setLocalStorage(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key: string): any => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

export const substr = (s: string, length: number): string => {
  if (s.length <= length) {
    return s;
  }
  //
  return s.substring(0, length) + "...";
};

export const setLocalStorage = async (key: string, value: string) => {
  await Preferences.set({
    key: key,
    value: value,
  });
};

export const getLocalStorage = async (key: string) => {
  const { value } = await Preferences.get({ key });
  return value;
};

export const removeLocalStorage = async (key: string) => {
  await Preferences.remove({ key });
};

export const saveDataToPreferences = async (key: string, data: any) => {
  await Preferences.set({
    key: key,
    value: JSON.stringify(data),
  });
};

export const getDataFromPreferences = async (key: string): Promise<any | null> => {
  const { value } = await Preferences.get({ key: key });
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const isCommandCenterApp = import.meta.env.VITE_APP_NAME == ENUM_APP_NAME.POLICE_COMMAND_CENTER;

export const isEqual = <T extends object>(obj1: T, obj2: T): boolean => {
  const keys1 = Object.keys(obj1) as (keyof T)[];
  const keys2 = Object.keys(obj2) as (keyof T)[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const isOpenNow = (hours: any[]): boolean => {
  if (hours) {
    const today = new Date();
    const dayOfWeek = today.toLocaleDateString("en-US", { weekday: "long" });

    const todayHours = hours.find((hour) => hour.dayOfWeek === dayOfWeek);

    if (!todayHours) return false;
    const todayDate = today.toISOString().slice(0, 10);

    const openDateTime = new Date(`${todayDate}T${todayHours.open}`);
    const closeDateTime = new Date(`${todayDate}T${todayHours.close}`);
    const currentDateTime = today.getTime();

    return currentDateTime >= openDateTime.getTime() && currentDateTime <= closeDateTime.getTime();
  } else {
    return false;
  }
};

export const filterNewsByLocale = (newsArray: any[], locale: any) => {
  if (newsArray) return newsArray.find((news) => news.locale === locale);
  return "EN";
};

export const findTranslationIndexByLocale = (translations: any[], locale: any) => {
  return translations.findIndex((translation) => translation.locale === locale);
};

export const groupNewsByContentDate = (news: any[]) =>
  news.reduce((acc, item) => {
    const date = dayjs.tz(dayjs(item.contentAt)).format("YYYY-MM-DD");
    if (!Object.keys(acc).includes(date)) {
      acc[date] = [];
    }

    acc[date].push(item);

    return acc;
  }, {});

// SAVE ETC
export const addMinutesToCurrentTime = (minutes: number) => {
  var currentTime = new Date();
  currentTime.setMinutes(currentTime.getMinutes() + minutes);
  return currentTime.toISOString();
};

export const extractMetadata = (data: any[]) => {
  let eta;
  let arrived;
  let createdAt;

  for (const item of data) {
    if (item.metadata) {
      if (item.metadata.eta !== undefined && eta === undefined) {
        eta = item.metadata.eta;
        createdAt = item.createdAt;
      }
      if (item.metadata.arrived !== undefined) {
        arrived = item.metadata.arrived;
      }
    }
  }

  return { eta, arrived, createdAt };
};

// ETA(CREATED_AT), ETA_DAT, ARRVIED_AT(OR NOW)
export const getColorStatus = (created_at: Date, eta_at: Date, arrived_at: Date, lang: string) => {
  const diffMinutes = (eta_at.getTime() - created_at.getTime()) / (1000 * 60);
  const interval = diffMinutes / 3;
  const yellowStart = new Date(created_at.getTime() + interval * 1 * 60 * 1000);
  const redStart = new Date(created_at.getTime() + interval * 2 * 60 * 1000);

  const arrived = calculateTimeDifference(created_at, arrived_at, lang);
  const now_at = calculateTimeDifference(created_at, new Date(), lang);
  const now = new Date(arrived_at ? arrived_at : new Date());
  if (now < yellowStart) {
    // Green
    return {
      color: "#65a30d",
      datetime: arrived,
      now: now_at,
    };
  } else if (now < redStart) {
    // Yellow
    return {
      color: "#facc15",
      datetime: arrived,
      now: now_at,
    };
  } else {
    // Red
    return {
      color: "#b91c1c",
      datetime: arrived,
      now: now_at,
    };
  }
};

export const calculateDate = (startDate: Date, endDate: Date) => {
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    return false;
  }

  const differenceInTime = endDate.getTime() - startDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  if (differenceInDays < 0) {
    return false;
  }

  return differenceInDays;
};

export const isPast = (startDate: Date) => {
  const currentDate = new Date();
  return startDate < currentDate;
};

export const getContentByLocale = (content: Required<{ locale: string }>[], locale: string): Required<{ locale: string }> => {
  return content.find((c) => c.locale === locale) || content[0];
};

export const findByIdAndRemove = (arr: any[], id: string) => {
  const index = arr.findIndex((item) => item.id === id);
  if (index !== -1) {
    return arr.splice(index, 1)[0];
  }
  return null;
};

export const removeById = (arr: any[], id: string) => {
  const index = arr.indexOf(id);
  if (index !== -1) {
    arr.splice(index, 1);
    return true;
  }
  return false;
};

export const calculateTimeColor = (now: any, arrived: any, createdAt: any, eta: any) => {
  const createdAtDate = new Date(createdAt) as any;
  const deadline = new Date(createdAtDate.getTime() + eta * 60000) as any;
  const nowDate = new Date(now) as any;
  const arrivedDate = new Date(arrived) as any;

  const totalDuration = eta * 60000;
  const firstSegmentEnd = new Date(createdAtDate.getTime() + totalDuration / 3);
  const secondSegmentEnd = new Date(createdAtDate.getTime() + (2 * totalDuration) / 3);

  const remainingTimeNow = deadline - nowDate;
  const remainingMinutesNow = remainingTimeNow > 0 ? Math.ceil(remainingTimeNow / 60000) : 0;
  let colorSegmentNow;
  if (nowDate <= firstSegmentEnd) {
    colorSegmentNow = "Green";
  } else if (nowDate <= secondSegmentEnd) {
    colorSegmentNow = "Yellow";
  } else if (nowDate <= deadline) {
    colorSegmentNow = "Red";
  } else {
    colorSegmentNow = "Red";
  }

  const remainingTimeArrived = deadline - arrivedDate;
  const remainingMinutesArrived = remainingTimeArrived > 0 ? Math.ceil(remainingTimeArrived / 60000) : 0;
  let colorSegmentArrived;
  if (arrivedDate <= firstSegmentEnd) {
    colorSegmentArrived = "Green";
  } else if (arrivedDate <= secondSegmentEnd) {
    colorSegmentArrived = "Yellow";
  } else if (arrivedDate <= deadline) {
    colorSegmentArrived = "Red";
  } else {
    colorSegmentArrived = "Red";
  }

  return {
    now: {
      remainingMinutes: remainingMinutesNow,
      colorSegment: colorSegmentNow,
    },
    arrived: {
      remainingMinutes: remainingMinutesArrived,
      colorSegment: colorSegmentArrived,
    },
  };
};

export const calculateMinutesDifference = (startTime: any, endTime: any) => {
  const startDate = new Date(startTime) as any;
  const endDate = new Date(endTime) as any;

  const differenceInMilliseconds = endDate - startDate;
  const differenceInMinutes = Math.ceil(differenceInMilliseconds / 60000);

  return differenceInMinutes;
};

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, "g"), replace);
};

export const pushUnique = (arr: any[], value: any) => {
  if (Array.isArray(arr) && value !== undefined && !arr.includes(value)) {
    arr.push(value);
  }
};

export const popUnique = (arr: any[], value: any) => {
  if (Array.isArray(arr) && value !== undefined) {
    const index = arr.indexOf(value);
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }
};
