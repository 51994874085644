<script lang="ts">
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileMenuView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    components() {
      return {};
    },
  },
  methods: {
    loading() {
      return false;
    },
  },
  setup() {
    const sharedStore = useAuthStore();
    return {
      sharedStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'SettingMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.ProfileMenuView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="flex flex-col w-full gap-6 py-4">
              <router-link :to="{ name: 'ProfileEditTouristView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.ProfileEditTouristView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <router-link :to="{ name: 'ProfileEmergencyContactView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.BellIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.ProfileEmergencyContactView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
              <router-link :to="{ name: 'ProfileSocialMediaAccountView' }" class="flex w-full gap-4 items-center justify-center">
                <div class="text-black"><component :is="$outlineIcons.ShieldCheckIcon" class="inline-block ttp-icon-inside-box-03" /></div>
                <div class="text-black w-full ttp-text-sm">{{ $t("page.ProfileSocialMediaAccountView.title") }}</div>
                <div class="text-black"><component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
