<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { ENUM_SIZE, ENUM_COLOR } from "@/configs/enums/enum";

const BadgeDefaultComponent = defineComponent({
  name: "BadgeDefaultComponent",
  components: {},
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    classEnumName: {
      type: String,
      required: false,
      default: ENUM_COLOR.NEW,
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: ENUM_SIZE.NORMAL,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const classEnumName = computed(() => {
      const c = ["disabled:cursor-not-allowed", "disabled:bg-ttp-disabled", "disabled:cursor-not-allowed", "disabled:text-ttp-text-disabled", "rounded-full"];
      switch (props.classEnumName) {
        case ENUM_COLOR.DEFAULT_1:
          c.push("text-white", "bg-ttp-primary", "hover:bg-ttp-primary-20", "focus:bg-ttp-primary", "active:bg-ttp-primary-20");
          break;
        case ENUM_COLOR.DEFAULT_2:
          c.push("text-white", "bg-ttp-info", "hover:bg-ttp-info-20", "focus:bg-ttp-info", "active:bg-ttp-info-20");
          break;
        case ENUM_COLOR.DARK:
          c.push("text-white", "bg-ttp-secondary", "hover:bg-ttp-secondary-20", "focus:bg-ttp-secondary", "active:bg-ttp-secondary-20");
          break;
        case ENUM_COLOR.GREEN:
          c.push("text-white", "bg-ttp-success", "hover:bg-ttp-success-20", "focus:bg-ttp-success", "active:bg-ttp-success-20");
          break;
        case ENUM_COLOR.RED:
          c.push("text-white", "bg-ttp-error", "hover:bg-ttp-error-20", "focus:bg-ttp-error", "active:bg-ttp-error-20");
          break;
        case ENUM_COLOR.YELLOW:
          c.push("text-white", "bg-ttp-warning", "hover:bg-ttp-warning-20", "focus:bg-ttp-warning", "active:bg-ttp-warning-20");
          break;
        case ENUM_COLOR.GRAY:
          c.push("text-ttp-text-secondary", "bg-ttp-new", "hover:bg-ttp-new-20", "focus:bg-ttp-new", "active:bg-ttp-new-20");
          break;
        case ENUM_COLOR.ACCEPTED:
          c.push("text-white", "bg-ttp-primary", "hover:bg-ttp-primary-20", "focus:bg-ttp-primary", "active:bg-ttp-primary-20");
          break;
        case ENUM_COLOR.WAITING:
          c.push("text-white", "bg-ttp-primary", "hover:bg-ttp-primary-20", "focus:bg-ttp-primary", "active:bg-ttp-primary-20");
          break;
        case ENUM_COLOR.FORWARDED:
        c.push("text-white", "bg-ttp-warning", "hover:bg-ttp-warning-20", "focus:bg-ttp-warning", "active:bg-ttp-warning-20");
          break;
        case ENUM_COLOR.COMPLETED:
          c.push("text-white", "bg-ttp-success", "hover:bg-ttp-success-20", "focus:bg-ttp-success", "active:bg-ttp-success-20");
          break;
        case ENUM_COLOR.CLOSED:
          c.push("text-white", "bg-ttp-close", "hover:bg-ttp-close-20", "focus:bg-ttp-close", "active:bg-ttp-close-20");
          break;
        case ENUM_COLOR.IN_PROGRESS:
          c.push("text-white", "bg-ttp-warning", "hover:bg-ttp-warning-20", "focus:bg-ttp-warning", "active:bg-ttp-warning-20");
          break;
        case ENUM_COLOR.REJECTED:
          c.push("text-white", "bg-ttp-error", "hover:bg-ttp-error-20", "focus:bg-ttp-error", "active:bg-ttp-error-20");
          break;
        case ENUM_COLOR.IN_REVIEW:
          c.push("text-white", "bg-ttp-in-review", "hover:bg-ttp-in-review-20", "focus:bg-ttp-in-review", "active:bg-ttp-in-review-20");
          break;
        case ENUM_COLOR.REPORTED:
          c.push("text-white", "bg-ttp-reported", "hover:bg-ttp-reported-20", "focus:bg-ttp-reported", "active:bg-ttp-reported-20");
          break;
        case ENUM_COLOR.NEW:
          c.push("text-white", "bg-ttp-new", "hover:bg-ttp-new-20", "focus:bg-ttp-new", "active:bg-ttp-new-20");
          break;
        default:
          c.push("text-white", "bg-ttp-primary", "hover:bg-ttp-primary-20", "focus:bg-ttp-primary", "active:bg-ttp-primary-20");
          break;
      }

      return c.join(" ");
    });

    const size = computed(() => {
      switch (props.size) {
        case ENUM_SIZE.SMALL:
          return "ttp-text-xs px-2.5 py-0.5";
        case ENUM_SIZE.NORMAL:
          return "ttp-text-xs px-3 py-1";
        case ENUM_SIZE.LARGE:
          return "ttp-text-xs px-3 py-1.5";
        default:
          return "ttp-text-xs px-2.5 py-0.5";
      }
    });

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      classEnumName,
      size,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        // this.toggleVisible();
      }
    },
  },
});
export default BadgeDefaultComponent;
</script>

<template>
  <button v-if="isVisible" type="button" :disabled="isDisable" :class="`${[classEnumName, classTagName, size].join(' ')}`" @click="handleClick" @touchstart="handleClick">
    <span v-if="htmlIcon && isIcon" v-html="htmlIcon"></span>
    <component v-if="!$isObjectEmpty(componentIcon) && isIcon" :is="componentIcon" :class="classIcon" />
    <span>{{ title }}</span>
  </button>
</template>
