<script lang="ts">
import { defineComponent, ref, watch, type PropType } from "vue";
import { ENUM_SIZE } from "@/configs/enums/enum";
import type { $string, AllowedTypes } from "@/configs/types/Shared/typeShare";

const FormCheckBoxDefaultComponent = defineComponent({
  name: "FormCheckBoxDefaultComponent",
  components: {},
  props: {
    isGrid: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRightPlaceholder: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      required: false,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    items: {
      type: Array as PropType<{ label: $string; value: $string }[]>,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null] as PropType<AllowedTypes>,
      required: false,
      default: null,
    },
    classEnumName: {
      type: String,
      required: false,
      default: "text-ttp-primary",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: ENUM_SIZE.NORMAL,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    let values = [] as any[];
    const handleInput = (event: Event, _values?: any) => {
      if (_values) values = _values;
      const target = event.target as HTMLInputElement;
      if (!values.includes(target.value)) values.push(target.value);

      if (!target.checked) values = values.filter((item: any) => item !== target.value);

      emit("updateValue", values);
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
      ENUM_SIZE,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormCheckBoxDefaultComponent;
</script>

<template>
  <div v-if="isVisible" :class="`w-full mb-2 ${classTagName}`">
    <label v-if="!isRightPlaceholder" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span></label>
    <div :class="{ relative: !isGrid, 'grid grid-cols-12': isGrid }">
      <div v-for="(item, index) in items" :key="index" :class="{ 'flex items-center mb-2': !isGrid, 'col-start-1 col-end-13 xl:col-start-1 xl:col-end-7 mb-2': isGrid && index % 2 === 0, 'col-start-1 col-end-13 xl:col-start-7 xl:col-end-13 mb-2': isGrid && index % 2 !== 0 }">
        <input
          v-if="typeof value != 'object'"
          type="checkbox"
          :id="`${name}-${item.label}`"
          :name="`${name}[]`"
          :value="item.value"
          :checked="$inArray(value, [item.value])"
          @input="
            (e) => {
              handleInput(e);
            }
          "
          :disabled="isDisable"
          :readonly="isReadonly"
          :class="`${[classEnumName].join(' ')}`"
          class="w-4 h-4 bg-gray-100 focus:ring-4 focus:ring-ttp-ring disabled:cursor-not-allowed disabled:bg-ttp-text-disabled rounded"
        />
        <input
          v-else
          type="checkbox"
          :id="`${name}-${item.label}`"
          :name="`${name}[]`"
          :value="item.value"
          :checked="$inArray(item.value, value as any)"
          @input="
            (e) => {
              handleInput(e, value as any);
            }
          "
          :disabled="isDisable"
          :readonly="isReadonly"
          :class="`${[classEnumName].join(' ')}`"
          class="w-4 h-4 bg-gray-100 focus:ring-4 focus:ring-ttp-ring disabled:cursor-not-allowed disabled:bg-ttp-text-disabled rounded"
        />
        <label v-if="!isRightPlaceholder" :for="`${name}-${item.label}`" class="ms-2 ttp-text-xs text-ttp-secondary dark:text-gray-400">{{ item.label }}</label>
        <label v-else :for="`${name}-${item.label}`" class="ms-2 ttp-text-xs text-ttp-secondary dark:text-gray-400">{{ title }}</label>
      </div>
      <small v-if="isError" class="relative text-ttp-text-error">{{ textError }}</small>
    </div>
  </div>
</template>
