<script lang="ts">
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import { defineComponent, onMounted, ref, type Ref } from "vue";
import { useDashboardSearchStore } from "./stores/useDashboardSearchStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import type { $enum, $string } from "@/configs/types/Shared/typeShare";
import { ENUM_CHANNEL } from "@/configs/enums/enum";

export default defineComponent({
  name: "DashboardView",
  components: {},
  data() {
    const today = dayjs();

    return {
      componentName: this.$options.name,
      date: ref([today.subtract(1, "month").tz("Asia/Bangkok").format("YYYY-MM-DD"), today.tz("Asia/Bangkok").format("YYYY-MM-DD")]) as Ref<string[]>,
      dailyChannel: {
        "1155": 0,
        application: 0,
        tourist: 0,
      },
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          select: {
            categoryCode: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.dashboardSearchStore.error[this.dashboardSearchStore.isRequired.categoryCode.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.dashboardSearchStore.isRequired.categoryCode.is,
                textError: this.$t(this.dashboardSearchStore.isRequired.categoryCode.placeholder),
                title: this.$t(this.dashboardSearchStore.isRequired.categoryCode.label),
                placeholder: this.$t(this.dashboardSearchStore.isRequired.categoryCode.placeholder),
                value: this.dashboardSearchStore?.result?.categoryCode,
                items: this.masterDataStore.itemIncidentCategories(),
                name: this.dashboardSearchStore.isRequired.categoryCode.name,
                functionProp: async (e: any) => {
                  this.masterDataStore.loadingTopicCode = true;
                  this.masterDataStore.incidentTopics = [];
                  this.dashboardSearchStore.result.topicCode = "";
                  this.masterDataStore.categoryCode = e?.target?.value;
                  await this.masterDataStore.listIncidentTopic(e?.target?.value);
                  this.masterDataStore.loadingTopicCode = false;
                },
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.dashboardSearchStore.result.categoryCode = value;
              },
            },
            topicCode: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.dashboardSearchStore.error[this.dashboardSearchStore.isRequired.topicCode.name] || "").length > 0,
                isIcon: false,
                isDisable: !this.dashboardSearchStore?.result?.categoryCode || this.loading(),
                isRequired: this.dashboardSearchStore.isRequired.topicCode.is,
                textError: this.$t(this.dashboardSearchStore.isRequired.topicCode.placeholder),
                title: this.$t(this.dashboardSearchStore.isRequired.topicCode.label),
                placeholder: this.$t(this.dashboardSearchStore.isRequired.topicCode.placeholder),
                value: this.dashboardSearchStore?.result?.topicCode,
                items: this.masterDataStore.itemIncidentTopics(),
                name: this.dashboardSearchStore.isRequired.topicCode.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.dashboardSearchStore.result.topicCode = value;
              },
            },
            channel: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: false,
                isError: (this.dashboardSearchStore.error[this.dashboardSearchStore.isRequired.channel.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.dashboardSearchStore.isRequired.channel.is,
                textError: this.$t(this.dashboardSearchStore.isRequired.channel.placeholder),
                title: this.$t(this.dashboardSearchStore.isRequired.channel.label),
                placeholder: this.$t(this.dashboardSearchStore.isRequired.channel.placeholder),
                value: this.dashboardSearchStore?.result?.channel,
                items: this.masterDataStore.itemChannels(),
                name: this.dashboardSearchStore.isRequired.channel.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: ENUM_CHANNEL | $enum) => {
                this.dashboardSearchStore.result.channel = value;
              },
            },
          },
          button: {
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: any) => {
                const validate = await this.dashboardSearchStore.onSubmit(e);
                if (validate) {
                  await this.fetch();
                  await this.generateCharts();
                }
              },
            },
            btnReset: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_reset"),
                classEnumName: this.$enums.ENUM_COLOR.YELLOW,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.dashboardSearchStore.result.categoryCode = null;
                this.dashboardSearchStore.result.topicCode = null;
                this.dashboardSearchStore.result.channel = null;
                this.resetDate();
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.dashboardSearchStore.loading;
    },
    async setting() {
      this.$configLightbox();
    },
    resetDate() {
      const today = dayjs();
      this.date = [today.subtract(1, "month").tz("Asia/Bangkok").format("YYYY-MM-DD"), today.tz("Asia/Bangkok").format("YYYY-MM-DD")];
    },
    handleDate([start, end]: string[]) {
      const endDay = end ? dayjs(end) : dayjs();
      const startDay = start ? dayjs(end) : endDay.subtract(1, "month");
      this.date = [startDay.tz("Asia/Bangkok").format("YYYY-MM-DD"), endDay.tz("Asia/Bangkok").format("YYYY-MM-DD")];
    },
    dateRangeFormat(dates: Date[]) {
      const [start, end] = dates;

      const startString = !start || !dayjs(start).isValid() ? "เริ่มต้น" : dayjs(start).format("YYYY-MM-DD");
      const endString = !end || !dayjs(end).isValid() ? "ปัจจุบัน" : dayjs(end).format("YYYY-MM-DD");

      return `${startString} - ${endString}`;
    },
  },
  async mounted() {
    await this.setting();

    this.dailyChannel["1155"] = await (async () => {
      const result = await this.dashboardSearchStore.fetchReport.IncidentReport(
        {
          startDate: dayjs().format("YYYY-MM-DD"),
          endDate: dayjs().format("YYYY-MM-DD"),
          channel: "1155",
        },
        "gender",
        "day"
      );

      return Object.keys(result).reduce((total, key): number => total + Object.keys(result[key]).reduce((subTotal, subKey): number => subTotal + result[key][subKey], 0), 0);
    })();

    this.dailyChannel.application = await (async () => {
      const result = await this.dashboardSearchStore.fetchReport.IncidentReport(
        {
          startDate: dayjs().format("YYYY-MM-DD"),
          endDate: dayjs().format("YYYY-MM-DD"),
          channel: "APPLICATION",
        },
        "gender",
        "day"
      );

      return Object.keys(result).reduce((total, key): number => total + Object.keys(result[key]).reduce((subTotal, subKey): number => subTotal + result[key][subKey], 0), 0);
    })();

    this.dailyChannel.tourist = await (async () => {
      const result = await this.dashboardSearchStore.fetchReport.TouristReport(
        {
          startDate: dayjs().format("YYYY-MM-DD"),
          endDate: dayjs().format("YYYY-MM-DD"),
          channel: "APPLICATION",
        },
        "gender"
      );

      return Object.keys(result).reduce((total, key): number => total + result[key], 0);
    })();
  },
  setup() {
    const dashboardSearchStore = useDashboardSearchStore();
    const masterDataStore = useMasterDataStore();

    Chart.register(...registerables);

    const touristNationalityResults = ref<HTMLCanvasElement | null>(null);
    const touristGenderResults = ref<HTMLCanvasElement | null>(null);
    const barIncidentGender_Month_1155_Results = ref<HTMLCanvasElement | null>(null);
    const barIncidentNationality_Month_1155_Results = ref<HTMLCanvasElement | null>(null);
    const incidentNationality_Month_1155_Results = ref<HTMLCanvasElement | null>(null);
    const incidentGender_Month_1155_Results = ref<HTMLCanvasElement | null>(null);
    const barIncident_Month_1155_Results = ref<HTMLCanvasElement | null>(null);
    const barIncident_Month_Application_Results = ref<HTMLCanvasElement | null>(null);
    const barIncident_Day_Application_Results = ref<HTMLCanvasElement | null>(null);
    const incidentNationality_Day_Application_Results = ref<HTMLCanvasElement | null>(null);
    const incidentGender_Day_Application_Results = ref<HTMLCanvasElement | null>(null);

    onMounted(async () => {
      dashboardSearchStore.$reset();
      if (dashboardSearchStore.result) {
        const today = dayjs();
        (dashboardSearchStore.result.startDate = today.subtract(1, "month").tz("Asia/Bangkok").format("YYYY-MM-DD")), (dashboardSearchStore.result.endDate = today.tz("Asia/Bangkok").format("YYYY-MM-DD"));

        await fetch();
        await generateCharts();
      }

      setInterval(async () => {
        await fetch();
        await updateCharts()
      }, 1000 * 30)
    });

    async function fetch() {
      dashboardSearchStore.result.channel = ENUM_CHANNEL.APPLICATION;
      const touristNationalityResults = await dashboardSearchStore.fetchReport.TouristReport(await dashboardSearchStore.result, "nationality");
      const touristGenderResults = await dashboardSearchStore.fetchReport.TouristReport(await dashboardSearchStore.result, "gender");

      dashboardSearchStore.result.channel = ENUM_CHANNEL._1155;
      const incidentNationality_Month_1155_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "nationality", "month");
      const incidentGender_Month_1155_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "gender", "month");

      // Monthly
      const startDate = dayjs(dashboardSearchStore.result.endDate).startOf("month").subtract(1, "year");
      const endDate = dayjs(dashboardSearchStore.result.endDate).startOf("month");
      const barIncident_Month_1155_Results = await dashboardSearchStore.fetchReport.IncidentReport(
        {
          channel: dashboardSearchStore.result.channel,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
        "status",
        "month"
      );

      dashboardSearchStore.result.channel = ENUM_CHANNEL.APPLICATION;
      const barIncident_Month_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(
        {
          channel: dashboardSearchStore.result.channel,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
        "status",
        "month"
      );
      const barIncident_Day_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "status", "day");
      const incidentNationality_Day_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "nationality", "day");
      const incidentGender_Day_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "gender", "day");

      dashboardSearchStore.loading = false;

      dashboardSearchStore.touristNationalityResults = await masterDataStore.transformTouristReportNationalities(touristNationalityResults);
      dashboardSearchStore.touristGenderResults = await masterDataStore.transformTouristReportGenders(touristGenderResults);
      dashboardSearchStore.barIncidentGender_Month_1155_Results = await masterDataStore.transformCombineReport(incidentNationality_Month_1155_Results, incidentGender_Month_1155_Results);
      dashboardSearchStore.incidentNationality_Month_1155_Results = await masterDataStore.transformIncidentReportNationalities(incidentNationality_Month_1155_Results);
      dashboardSearchStore.incidentGender_Month_1155_Results = await masterDataStore.transformIncidentReportGenders(incidentGender_Month_1155_Results);
      dashboardSearchStore.barIncident_Month_1155_Results = await masterDataStore.transformIncidentReportDate(barIncident_Month_1155_Results, "month");
      dashboardSearchStore.barIncident_Month_Application_Results = await masterDataStore.transformIncidentReportDate(barIncident_Month_Application_Results, "month");
      dashboardSearchStore.barIncident_Day_Application_Results = await masterDataStore.transformIncidentReportStatus(barIncident_Day_Application_Results);
      dashboardSearchStore.incidentNationality_Day_Application_Results = await masterDataStore.transformIncidentReportNationalities(incidentNationality_Day_Application_Results);
      dashboardSearchStore.incidentGender_Day_Application_Results = await masterDataStore.transformIncidentReportGenders(incidentGender_Day_Application_Results);
    }

    let Chart_touristNationalityResults: Chart<"pie", number[], string> | undefined;
    let Chart_touristGenderResults: Chart<"pie", number[], string> | undefined;
    let Chart_barIncidentGender_Month_1155_Results: Chart<"bar", number[], string> | undefined;
    let Chart_barIncidentNationality_Month_1155_Results: Chart<"bar", number[], string> | undefined;
    let Chart_incidentNationality_Month_1155_Results: Chart<"pie", number[], string> | undefined;
    let Chart_incidentGender_Month_1155_Results: Chart<"pie", number[], string> | undefined;
    let Chart_barIncident_Month_1155_Results: Chart<"bar", number[], string> | undefined;
    let Chart_barIncident_Month_Application_Results: Chart<"bar", number[], string> | undefined;
    let Chart_barIncident_Day_Application_Results: Chart<"bar", number[], string> | undefined;
    let Chart_incidentNationality_Day_Application_Results: Chart<"pie", number[], string> | undefined;
    let Chart_incidentGender_Day_Application_Results: Chart<"pie", number[], string> | undefined;

    function generateChartData(dashboardData: any): { labels: any[], datasets: any[] } {
      const labels = new Set();
      const datasets = [];

      for (const [date, data] of Object.entries(dashboardData) as any) {
        data.nationalities.label.forEach((label: any) => labels.add(label));
        const dataset = {
          label: `จำนวนผู้คน วันที่ ${date}`,
          data: new Array(data.nationalities.label.length).fill(0),
          backgroundColor: generateRandomColors(data.nationalities.label.length),
        };

        data.nationalities.label.forEach((label: any, index: any) => {
          const labelIndex = [...labels].indexOf(label);
          dataset.data[labelIndex] = data.nationalities.value[index];
        });

        datasets.push(dataset);
      }

      return {
        labels: [...labels],
        datasets,
      };
    }

    // ฟังก์ชั่นสร้างสีสุ่ม
    function generateRandomColors(count: any) {
      return Array.from({ length: count }, () => `#${Math.floor(Math.random() * 16777215).toString(16)}`);
    }

    async function generateCharts() {
      if (Chart_touristNationalityResults) {
        Chart_touristNationalityResults.destroy();
        Chart_touristNationalityResults = undefined;
      }
      if (Chart_touristGenderResults) {
        Chart_touristGenderResults.destroy();
        Chart_touristGenderResults = undefined;
      }
      if (Chart_barIncidentGender_Month_1155_Results) {
        Chart_barIncidentGender_Month_1155_Results.destroy();
        Chart_barIncidentGender_Month_1155_Results = undefined;
      }
      if (Chart_barIncidentNationality_Month_1155_Results) {
        Chart_barIncidentNationality_Month_1155_Results.destroy();
        Chart_barIncidentNationality_Month_1155_Results = undefined;
      }
      if (Chart_incidentNationality_Month_1155_Results) {
        Chart_incidentNationality_Month_1155_Results.destroy();
        Chart_incidentNationality_Month_1155_Results = undefined;
      }
      if (Chart_incidentGender_Month_1155_Results) {
        Chart_incidentGender_Month_1155_Results.destroy();
        Chart_incidentGender_Month_1155_Results = undefined;
      }
      if (Chart_barIncident_Month_1155_Results) {
        Chart_barIncident_Month_1155_Results.destroy();
        Chart_barIncident_Month_1155_Results = undefined;
      }
      if (Chart_barIncident_Month_Application_Results) {
        Chart_barIncident_Month_Application_Results.destroy();
        Chart_barIncident_Month_Application_Results = undefined;
      }
      if (Chart_barIncident_Day_Application_Results) {
        Chart_barIncident_Day_Application_Results.destroy();
        Chart_barIncident_Day_Application_Results = undefined;
      }
      if (Chart_incidentNationality_Day_Application_Results) {
        Chart_incidentNationality_Day_Application_Results.destroy();
        Chart_incidentNationality_Day_Application_Results = undefined;
      }
      if (Chart_incidentGender_Day_Application_Results) {
        Chart_incidentGender_Day_Application_Results.destroy();
        Chart_incidentGender_Day_Application_Results = undefined;
      }

      if (touristNationalityResults.value) {
        const touristNationalityResults_Context = touristNationalityResults.value.getContext("2d");
        if (touristNationalityResults_Context) {
          Chart_touristNationalityResults = new Chart(touristNationalityResults_Context, {
            type: "pie",
            data: {
              labels: await dashboardSearchStore?.touristNationalityResults?.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: await dashboardSearchStore?.touristNationalityResults?.value,
                },
              ],
            },
          });
        }
      }
      if (touristGenderResults.value) {
        const touristGenderResults_Context = touristGenderResults.value.getContext("2d");
        if (touristGenderResults_Context) {
          Chart_touristGenderResults = new Chart(touristGenderResults_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.touristGenderResults.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.touristGenderResults.value,
                },
              ],
            },
          });
        }
      }
      if (barIncidentGender_Month_1155_Results.value) {
        const barIncidentGender_Month_1155_Results_Context = barIncidentGender_Month_1155_Results.value.getContext("2d");
        if (barIncidentGender_Month_1155_Results_Context) {
          var maleData = [] as any;
          var femaleData = [] as any;
          dashboardSearchStore.barIncidentGender_Month_1155_Results.date.forEach(function (date: any) {
            maleData.push(dashboardSearchStore.barIncidentGender_Month_1155_Results.data[date].genders.value[0]);
            femaleData.push(dashboardSearchStore.barIncidentGender_Month_1155_Results.data[date].genders.value[1]);
          });

          Chart_barIncidentGender_Month_1155_Results = new Chart(barIncidentGender_Month_1155_Results_Context, {
            type: "bar",
            data: {
              labels: dashboardSearchStore.barIncidentGender_Month_1155_Results.date,
              datasets: [
                {
                  type: "bar",
                  label: "เพศชาย",
                  data: maleData,
                  backgroundColor: "blue",
                },
                {
                  type: "bar",
                  label: "เพศหญิง",
                  data: femaleData,
                  backgroundColor: "pink",
                },
              ],
            },
          });
        }
      }
      if (barIncidentNationality_Month_1155_Results.value) {
        const barIncidentNationality_Month_1155_Results_Context = barIncidentNationality_Month_1155_Results.value.getContext("2d");
        if (barIncidentNationality_Month_1155_Results_Context) {
          const chartData = generateChartData(dashboardSearchStore.barIncidentGender_Month_1155_Results.data);

          Chart_barIncidentNationality_Month_1155_Results = new Chart(barIncidentNationality_Month_1155_Results_Context, {
            type: "bar",
            data: chartData,
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          });
        }
      }
      if (incidentNationality_Month_1155_Results.value) {
        const incidentNationality_Month_1155_Results_Context = incidentNationality_Month_1155_Results.value.getContext("2d");
        if (incidentNationality_Month_1155_Results_Context) {
          Chart_incidentNationality_Month_1155_Results = new Chart(incidentNationality_Month_1155_Results_Context, {
            type: "pie",
            data: {
              labels: await dashboardSearchStore.incidentNationality_Month_1155_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: await dashboardSearchStore.incidentNationality_Month_1155_Results.value,
                },
              ],
            },
          });
        }
      }
      if (incidentGender_Month_1155_Results.value) {
        const incidentGender_Month_1155_Results_Context = incidentGender_Month_1155_Results.value.getContext("2d");
        if (incidentGender_Month_1155_Results_Context) {
          Chart_incidentGender_Month_1155_Results = new Chart(incidentGender_Month_1155_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentGender_Month_1155_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.incidentGender_Month_1155_Results.value,
                },
              ],
            },
          });
        }
      }
      if (barIncident_Month_1155_Results.value) {
        const barIncident_Month_1155_Results_Context = barIncident_Month_1155_Results.value.getContext("2d");
        if (barIncident_Month_1155_Results_Context) {
          Chart_barIncident_Month_1155_Results = new Chart(barIncident_Month_1155_Results_Context, {
            type: "bar",
            data: dashboardSearchStore.barIncident_Month_1155_Results,
          });
        }
      }
      if (barIncident_Month_Application_Results.value) {
        const barIncident_Month_Application_Results_Context = barIncident_Month_Application_Results.value.getContext("2d");
        if (barIncident_Month_Application_Results_Context) {
          Chart_barIncident_Month_Application_Results = new Chart(barIncident_Month_Application_Results_Context, {
            type: "bar",
            data: dashboardSearchStore.barIncident_Month_Application_Results,
          });
        }
      }
      if (barIncident_Day_Application_Results.value) {
        const barIncident_Day_Application_Results_Context = barIncident_Day_Application_Results.value.getContext("2d");
        if (barIncident_Day_Application_Results_Context) {
          Chart_barIncident_Day_Application_Results = new Chart(barIncident_Day_Application_Results_Context, {
            type: "bar",
            data: dashboardSearchStore.barIncident_Day_Application_Results,
          });
        }
      }
      if (incidentNationality_Day_Application_Results.value) {
        const incidentNationality_Day_Application_Results_Context = incidentNationality_Day_Application_Results.value.getContext("2d");
        if (incidentNationality_Day_Application_Results_Context) {
          Chart_incidentNationality_Day_Application_Results = new Chart(incidentNationality_Day_Application_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentNationality_Day_Application_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: [65, 59, 80, 81, 56, 55],
                },
              ],
            },
          });
        }
      }
      if (incidentGender_Day_Application_Results.value) {
        const incidentGender_Day_Application_Results_Context = incidentGender_Day_Application_Results.value.getContext("2d");
        if (incidentGender_Day_Application_Results_Context) {
          Chart_incidentGender_Day_Application_Results = new Chart(incidentGender_Day_Application_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentGender_Day_Application_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.incidentGender_Day_Application_Results.value,
                },
              ],
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              responsive: true,
            },
          });
        }
      }
    }

    const updateCharts = () => {
      if (Chart_touristNationalityResults) {
        Chart_touristNationalityResults.data.labels = dashboardSearchStore?.touristNationalityResults?.label
        Chart_touristNationalityResults.data.datasets[0].data = dashboardSearchStore?.touristNationalityResults?.value
        Chart_touristNationalityResults.update()
      }

      if (Chart_touristGenderResults) {
        Chart_touristGenderResults.data.labels = dashboardSearchStore.touristGenderResults.label
        Chart_touristGenderResults.data.datasets[0].data = dashboardSearchStore.touristGenderResults.value
        Chart_touristGenderResults.update()
      }

      if (Chart_barIncidentGender_Month_1155_Results) {
        var maleData = [] as any;
        var femaleData = [] as any;
        dashboardSearchStore.barIncidentGender_Month_1155_Results.date.forEach(function (date: any) {
          maleData.push(dashboardSearchStore.barIncidentGender_Month_1155_Results.data[date].genders.value[0]);
          femaleData.push(dashboardSearchStore.barIncidentGender_Month_1155_Results.data[date].genders.value[1]);
        });

        Chart_barIncidentGender_Month_1155_Results.data.labels = dashboardSearchStore.barIncidentGender_Month_1155_Results.date
        Chart_barIncidentGender_Month_1155_Results.data.datasets[0].data = maleData
        Chart_barIncidentGender_Month_1155_Results.data.datasets[1].data = femaleData
        Chart_barIncidentGender_Month_1155_Results.update()
      }

      if (Chart_barIncidentNationality_Month_1155_Results) {
        Chart_barIncidentNationality_Month_1155_Results.data = generateChartData(dashboardSearchStore.barIncidentGender_Month_1155_Results.data)
        Chart_barIncidentNationality_Month_1155_Results.update()
      }

      if (Chart_incidentNationality_Month_1155_Results) {
        Chart_incidentNationality_Month_1155_Results.data.labels = dashboardSearchStore.incidentNationality_Month_1155_Results.label
        Chart_incidentNationality_Month_1155_Results.data.datasets[0].data = dashboardSearchStore.incidentNationality_Month_1155_Results.value
        Chart_incidentNationality_Month_1155_Results.update()
      }

      if (Chart_incidentGender_Month_1155_Results) {
        Chart_incidentGender_Month_1155_Results.data.labels = dashboardSearchStore.incidentGender_Month_1155_Results.label
        Chart_incidentGender_Month_1155_Results.data.datasets[0].data = dashboardSearchStore.incidentGender_Month_1155_Results.value
        Chart_incidentGender_Month_1155_Results.update()
      }

      if (Chart_barIncident_Month_1155_Results) {
        Chart_barIncident_Month_1155_Results.data = dashboardSearchStore.barIncident_Month_1155_Results
        Chart_barIncident_Month_1155_Results.update()
      }

      if (Chart_barIncident_Month_Application_Results) {
        Chart_barIncident_Month_Application_Results.data = dashboardSearchStore.barIncident_Month_Application_Results
        Chart_barIncident_Month_Application_Results.update()
      }

      if (Chart_barIncident_Day_Application_Results) {
        Chart_barIncident_Day_Application_Results.data = dashboardSearchStore.barIncident_Day_Application_Results
        Chart_barIncident_Day_Application_Results.update()
      }
      // Chart_incidentNationality_Day_Application_Results
      if (Chart_incidentGender_Day_Application_Results) {
        Chart_incidentGender_Day_Application_Results.data.labels = dashboardSearchStore.incidentGender_Day_Application_Results.label
        Chart_incidentGender_Day_Application_Results.data.datasets[0].data = dashboardSearchStore.incidentGender_Day_Application_Results.value
        Chart_incidentGender_Day_Application_Results.update()
      }
    }

    return {
      dashboardSearchStore,
      masterDataStore,
      generateCharts,
      updateCharts,
      fetch,
      touristNationalityResults,
      touristGenderResults,
      barIncidentGender_Month_1155_Results,
      barIncidentNationality_Month_1155_Results,
      incidentNationality_Month_1155_Results,
      incidentGender_Month_1155_Results,
      barIncident_Month_1155_Results,
      barIncident_Month_Application_Results,
      barIncident_Day_Application_Results,
      incidentNationality_Day_Application_Results,
      incidentGender_Day_Application_Results,

      generateChartData,
      generateRandomColors,
    };
  },
});
</script>

<template>
  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">0</div>
        <div class="ttp-text-xs">จำนวนผู้ที่ Download</div>
        <div class="ttp-text-xs">(Play Store)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">0</div>
        <div class="ttp-text-xs">จำนวนผู้ที่ Download</div>
        <div class="ttp-text-xs">(App Store)</div>
      </div>
    </div>
  </div>
  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">{{ dailyChannel.tourist }}</div>
        <div class="ttp-text-xs">จำนวนทั้งหมดที่เดินทางเข้ามา</div>
        <div class="ttp-text-xs">(รายวัน)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">{{ dailyChannel["1155"] }}</div>
        <div class="ttp-text-xs">จำนวนการรับแจ้งเหตุผ่านสายด่วน 1155</div>
        <div class="ttp-text-xs">(รายวัน)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">{{ dailyChannel.application }}</div>
        <div class="ttp-text-xs">จำนวนการรับแจ้งเหตุผ่าน Chatbot</div>
        <div class="ttp-text-xs">(รายวัน)</div>
      </div>
    </div>
  </div>
  <div class="ttp-layout px-4 pb-4">
    <div class="ttp-page-header-1">
      <h2 class="ttp-text-sm mt-4">ประเภทการแจ้งเหตุ</h2>
    </div>
    <div>
      <div class="ttp-between mb-4">
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 w-full">
          <div>
            <VueDatePicker :model-value="date" @update:model-value="handleDate" range :format="dateRangeFormat"
              class="w-full text-left"></VueDatePicker>
          </div>
          <div>
            <component :is="defaultFormComponents.form.select.categoryCode.component"
              v-bind="defaultFormComponents.form.select.categoryCode.props"
              @updateValue="defaultFormComponents.form.select.categoryCode.onUpdateValue" />
          </div>
          <div>
            <component :is="defaultFormComponents.form.select.topicCode.component"
              v-bind="defaultFormComponents.form.select.topicCode.props"
              @updateValue="defaultFormComponents.form.select.topicCode.onUpdateValue" />
          </div>
          <div>
            <component :is="defaultFormComponents.form.select.channel.component"
              v-bind="defaultFormComponents.form.select.channel.props"
              @updateValue="defaultFormComponents.form.select.channel.onUpdateValue" />
          </div>
        </div>
        <div>
          <component :is="defaultFormComponents.form.button.btnReset.component"
            v-bind="defaultFormComponents.form.button.btnReset.props"
            @click="defaultFormComponents.form.button.btnReset.click" />
        </div>
        <div>
          <component :is="defaultFormComponents.form.button.btnSearch.component"
            v-bind="defaultFormComponents.form.button.btnSearch.props"
            @click="defaultFormComponents.form.button.btnSearch.click" />
        </div>
      </div>
    </div>
    <hr class="mt-4" />
    <h2 class="ttp-text-xl text-black font-medium p-4 mt-4">รายงานข้อมูลสถิติจำนวนชาวต่างชาติเดินทางเข้าประเทศไทย
      ที่ลงทะเบียนผ่าน TPB App</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามสัญชาติ</h2>
        </div>
        <canvas v-if="!loading()" ref="touristNationalityResults"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามเพศ</h2>
        </div>
        <canvas v-if="!loading()" ref="touristGenderResults"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>
    <hr class="mt-4" />
    <h2 class="ttp-text-xl text-black font-medium p-4 mt-4">รายงานข้อมูลสถิติจำนวนการรับแจ้งเหตุผ่านสายด่วน 1155</h2>
    <div class="grid grid-cols-1 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">การรับแจ้งเหตุผ่านสายด่วน 1155 แยกตามเดือน ( 12 เดือนย้อนหลัง รวมเดือนปัจจุบัน )</h2>
        </div>
        <canvas v-if="!loading()" ref="barIncident_Month_1155_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามสัญชาติ</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentNationality_Month_1155_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามเพศ</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentGender_Month_1155_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">รายงานข้อมูลสถิติจำนวนการรับแจ้งเหตุผ่าน Chatbot (12 เดือนย้อนหลัง รวมเดือนปัจจุบัน)
          </h2>
        </div>
        <canvas v-if="!loading()" ref="barIncident_Month_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">จำนวนการรับแจ้งเหตุ incident ผ่าน Chatbot แยกตามสถานะ (รายวัน)</h2>
        </div>
        <canvas v-if="!loading()" ref="barIncident_Day_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามสัญชาติ</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentNationality_Day_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามเพศ</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentGender_Day_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>
  </div>
</template>

<style style="scss"></style>
