<script lang="ts">
import { defineComponent } from "vue";

const MobileHeaderComponent = defineComponent({
  name: "MobileHeaderComponent",
});
export default MobileHeaderComponent;
</script>

<template>
  <svg class="absolute left-0 z-0 w-full" viewBox="0 0 390 229" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="202" cy="-3.5" rx="280" ry="232.5" fill="url(#paint0_linear_128_7932)" />
    <path d="M503 -16.5C503 108.04 381.669 209 232 209C82.3308 209 -39 108.04 -39 -16.5C-39 -141.04 82.3308 -242 232 -242C381.669 -242 503 -141.04 503 -16.5Z" fill="url(#paint1_linear_128_7932)" />
    <defs>
      <linearGradient id="paint0_linear_128_7932" x1="2.0738" y1="98.5732" x2="387.418" y2="162.76" gradientUnits="userSpaceOnUse">
        <stop offset="0.332729" stop-color="#ECD51F" />
        <stop offset="1" stop-color="#CE2834" />
      </linearGradient>
      <linearGradient id="paint1_linear_128_7932" x1="173.5" y1="-2.00001" x2="244.5" y2="209" gradientUnits="userSpaceOnUse">
        <stop offset="0.078125" stop-color="#212A83" />
        <stop offset="1" stop-color="#151B47" />
      </linearGradient>
    </defs>
  </svg>
</template>
