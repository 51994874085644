import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import axios, { type AxiosInstance } from "axios";
import { getAuth, type User } from "firebase/auth";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

export const axiosClient = (params: { isFile: boolean }, type: ENUM_APP_NAME, cat?: ENUM_APP_CATEGORY_NAME): AxiosInstance => {
  const baseURL = type == ENUM_APP_NAME.POLICE_COMMAND_CENTER ? import.meta.env.VITE_TTP_COMMAND_CENTER_API : cat == ENUM_APP_CATEGORY_NAME.AGENT ? import.meta.env.VITE_TTP_POLICE_API : import.meta.env.VITE_TTP_TOURIST_API;
  const instance = axios.create({
    baseURL,
    ...(params.isFile
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      : {}),
  });

  instance.interceptors.request.use(async (config) => {
    try {
      await getCurrentUser();
      const { token } = await FirebaseAuthentication.getIdToken();
      config.headers.Authorization = token ? `Bearer ${token}` : null;
    } catch (_e) {}

    return config;
  });

  return instance;
};

const getCurrentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};
