import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetNews } from "@/configs/types/News/typeNews";
import type { typeGetPagination } from "@/configs/types/Shared/typePagination";

export class fetchNews {
  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetPagination<typeGetNews>>("/v1/news", { params });
    return data;
  }

  async Get(id: string): Promise<typeGetNews> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<typeGetNews>(`/v1/news/${id}`);
    return data;
  }
}
