<script lang="ts">
import { defineComponent } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useHistorySearchStore } from "../History/stores/useHistorySearchStore";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { fetchAlert } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAlert";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useChatFormStore } from "../Chat/stores/useChatFormStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import { usePopularPlaceStore } from "@/stores/PoliceTouristAndAgent/usePopularPlaceStore";
import { groupNewsByContentDate, setLocalStorage } from "@/configs/helpers/MainHelper";
import { useNewsSearchStore } from "../News/stores/useNewsSearchStore";
import { cloneDeep } from "lodash";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import { Capacitor } from "@capacitor/core";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { PushNotifications } from "@capacitor/push-notifications";

const WORLD_CIRCUMFERENCE = 40075 * 1000;

export default defineComponent({
  name: "HomeView",
  components: {
    LoadingFormComponent,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      componentName: this.$options.name,
      profile_image: "",
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href as any,
      popularPlace: null,
      roomId: "" as any,
    } as any;
  },
  computed: {
    defaultFormComponents() {
      return {
        alert: {
          backgroundCheck: {
            component: PopupSuccessComponent,
            props: {
              isOpen: this.historySearchStore.isBackgroundCheck,
              textTitle: this.$t("popup.success.tel1155_title"),
              textDetail: this.$t("popup.success.tel1155_detail"),
              isIcon: true,
              htmlIcon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" /</svg>`,
              classEnumName: this.$enums.ENUM_COLOR.YELLOW,
              class: "text-[#FB923C]",
              functionProp: () => {
                this.historySearchStore.isBackgroundCheck = false;
                this.$window.location.href = "tel:1155";
              },
              functionCancelProp: () => {
                this.historySearchStore.isBackgroundCheck = false;
              },
            } as typeof PopupSuccessComponent.props,
          },
        },
        form: {
          button: {
            btnSOS: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.sharedStore.isLoadingPermission,
                isDisable: this.sharedStore.isLoadingPermission,
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("page.HomeView.sos_btn"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
              } as typeof ButtonDefaultComponent.props,
              click: async (_e: Event) => {
                this.makeSOSCall();
              },
            },
            btn1155: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.sharedStore.isLoadingPermission,
                isDisable: this.sharedStore.isLoadingPermission,
                htmlIcon: "",
                isSubmit: true,
                title: "1155",
                classEnumName: this.$enums.ENUM_COLOR.RED,
              } as typeof ButtonDefaultComponent.props,
              click: async (_e: Event) => {
                this.telSOS1155();
              },
            },
          },
        },
      };
    },
    coordinations() {
      return this.sharedStore?.position?.coords;
    },
  },
  methods: {
    async popularPlaceFetch() {
      const delay = (d: number) => new Promise((resolve) => setTimeout(resolve, d));

      await Promise.all([this.historySearchStore.onUpdateCurrentPage()]);

      const recursivePopularPlace = async (distance: number) => {
        let d = 0;
        try {
          this.popularPlace = await this.popularPlaceStore.list(
            {
              latitude: this.coordinations?.latitude,
              longitude: this.coordinations?.longitude,
              distance,
            },
            this.sharedStore?.locale?.toUpperCase()
          );
          distance = distance * 2;
        } catch (e) {
          d = 5 * 1000; // delay 5 seconds
        } finally {
          await delay(d);
          if (!this.popularPlace?.total && distance < WORLD_CIRCUMFERENCE) {
            await recursivePopularPlace(distance * 2);
          }
        }
      };

      await recursivePopularPlace(5000);
    },
    async newsFetch() {
      if (!this.newsSearchStore.loadingScroll) {
        this.newsSearchStore.currentPage = this.newsSearchStore.currentPage + 1;

        let results = (await this.newsSearchStore.onUpdateListCurrentPage(this.newsSearchStore.currentPage)) as any;

        if (!this.newsSearchStore.tableScrollResults) {
          this.newsSearchStore.tableListResults = results;
          this.newsSearchStore.tableScrollResults = cloneDeep(results);
          this.newsSearchStore.totalPage = results?.totalPages;
        } else {
          this.newsSearchStore.tableListResults.items.push(...results?.items);
          this.newsSearchStore.tableListResults.totalPage = this.newsSearchStore.tableListResults.totalPages;
          results = this.newsSearchStore.tableListResults;
        }

        if (results?.items.length) {
          results.items = await Promise.all(
            results.items.map(async (item: any) => {
              if (typeof item.thumbnail !== 'string') {
                return item;
              }

              try {
                const [thumbnail] = await this.newsSearchStore?.fetchFile.Get([item.thumbnail]);
                item.thumbnail = thumbnail;
              } catch (e) {
                item.thumbnail = null;
              }

              return item;
            })
          );
        }

        this.newsSearchStore.tableScrollResults.items = await groupNewsByContentDate(results?.items);
        this.newsSearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
        this.newsSearchStore.loadingScroll = false;
      }
    },
    loading() {
      return this.historySearchStore.loading || this.chatFormStore.loading;
    },
    async setting() {
      this.newsFetch();

      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }

      this.$configLightbox();
    },
    async telSOS1155() {
      this.historySearchStore.isBackgroundCheck = !this.historySearchStore.isBackgroundCheck;
    },
    async getPhotoLibraryPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          try {
            await this.$PermissionsPlugin.requestPhoto();
          } catch (_error) {}

          const result = await this.$PermissionsPlugin
            .checkPermissionPhoto()
            .then(async (_result: any) => {
              await setLocalStorage(this.sharedStore.is_photo, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Photo");
              else this.$pushUnique(this.sharedStore.permissionResults, "Photo");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              await setLocalStorage(this.sharedStore.is_photo, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Photo");
              return false;
            });

          return result;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_photo, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Photo");
          return false;
        }
      } else {
        await setLocalStorage(this.sharedStore.is_photo, "granted");
        this.$popUnique(this.sharedStore.permissionResults, "Photo");
        return true;
      }
    },
    async getNotificationsPermission() {
      if (Capacitor.isNativePlatform()) {
        try {
          try {
            await this.$PermissionsPlugin.requestNotification();
          } catch (_error) {}

          const result = await this.$PermissionsPlugin
            .checkPermissionNotification()
            .then(async (_result: any) => {
              await setLocalStorage(this.sharedStore.is_push_notification, _result.value);
              if (_result.value == "granted") this.$popUnique(this.sharedStore.permissionResults, "Notification");
              else this.$pushUnique(this.sharedStore.permissionResults, "Notification");
              return _result.value == "granted" ? true : false;
            })
            .catch(async (_error: any) => {
              await setLocalStorage(this.sharedStore.is_push_notification, "denied");
              this.$pushUnique(this.sharedStore.permissionResults, "Notification");
              return false;
            });
          return result;
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_push_notification, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Notification");
          return false;
        }
      } else {
        try {
          const permission = (await PushNotifications.requestPermissions()) as any;
          if (permission === "granted") {
            await setLocalStorage(this.sharedStore.is_push_notification, "granted");
            this.$popUnique(this.sharedStore.permissionResults, "Notification");
            return true;
          } else {
            await setLocalStorage(this.sharedStore.is_push_notification, "denied");
            this.$pushUnique(this.sharedStore.permissionResults, "Notification");
            return false;
          }
        } catch (_error) {
          await setLocalStorage(this.sharedStore.is_push_notification, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Notification");
          return false;
        }
      }
    },
    resetPermission() {
      this.sharedStore.permissionResults = [];
      this.sharedStore.isLoadingPermission = false;
      this.sharedStore.isShowPermission = false;
    },
    trimName(name: string): string {
      if (name.length <= 20) {
        return name;
      }

      return `${name.slice(0, 17)}...`;
    },
    async alertFetch() {
      if (this.sharedStore?.position?.coords) {
        const alertFetch = new fetchAlert();
        this.sharedStore.alertResult = await alertFetch.Get({
          latitude: this.sharedStore?.position?.coords?.latitude,
          longitude: this.sharedStore?.position?.coords?.longitude,
        });

        try {
          const [thumbnail] = await this.newsSearchStore?.fetchFile.Get([this.sharedStore.alertResult.thumbnail]);
          this.sharedStore.alertResult.thumbnail = thumbnail;
        } catch (e) {
          this.sharedStore.alertResult.thumbnail = null;
        }

        this.sharedStore.isAlert = Boolean(this.sharedStore.alertResult);
      }
    },
  },
  async mounted() {
    // Setting Page
    await this.setting();
    if (this.coordinations) {
      this.popularPlaceFetch()
    }

    if (Capacitor.isNativePlatform()) {
      this.$PermissionsPlugin.addListener("locationPermissionChange", async (_data: any) => {
        if (!this.sharedStore.isLoadingPermission) this.resetPermission();
      });
      this.$PermissionsPlugin.addListener("photoPermissionChange", async (_data: any) => {
        if (!this.sharedStore.isLoadingPermission) this.resetPermission();
      });
      this.$PermissionsPlugin.addListener("microphonePermissionChange", async (_data: any) => {
        if (!this.sharedStore.isLoadingPermission) this.resetPermission();
      });
      this.$PermissionsPlugin.addListener("cameraPermissionChange", async (_data: any) => {
        if (!this.sharedStore.isLoadingPermission) this.resetPermission();
      });
      await this.$PermissionsPlugin.addListener("notificationPermissionChange", async (_data: any) => {
        if (!this.sharedStore.isLoadingPermission) this.resetPermission();
      });
    }
  },
  async unmounted() {
    if (Capacitor.isNativePlatform()) {
      this.$PermissionsPlugin.removeListener("locationPermissionChange");
      this.$PermissionsPlugin.removeListener("photoPermissionChange");
      this.$PermissionsPlugin.removeListener("microphonePermissionChange");
      this.$PermissionsPlugin.removeListener("cameraPermissionChange");
      this.$PermissionsPlugin.removeListener("notificationPermissionChange");
    }
  },
  setup() {
    const authStore = useAuthStore();
    const historySearchStore = useHistorySearchStore();
    const masterDataStore = useMasterDataStore();
    const chatFormStore = useChatFormStore();
    const sharedStore = useSharedStore();
    const popularPlaceStore = usePopularPlaceStore();
    const newsSearchStore = useNewsSearchStore();

    return {
      chatFormStore,
      sharedStore,
      authStore,
      historySearchStore,
      masterDataStore,
      modules: [Pagination, Navigation],
      popularPlaceStore,
      newsSearchStore,
    };
  },
  watch: {
    coordinations(to) {
      if (to) {
        this.popularPlaceFetch();
        this.alertFetch();
      }
    },
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-start sm:items-center justify-center mx-2 mt-5">
            <div :class="{ 'w-full': true, 'mb-4': !sharedStore.isAlert }">
              <div class="flex sm:my-4">
                <div class="w-full">
                  <div class="flex w-full items-center gap-2 lg:gap-6 ml-4">
                    <div class="swipe-photo inline-flex ttp-icon-box-01 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500 overflow-hidden">
                      <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
                      <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center rounded-full float-right w-16 h-16 lg:w-24 lg:h-24" :src="profile_image" alt="" /></a>
                    </div>
                    <div class="flex-col justify-start items-start gap-0 inline-flex">
                      <div class="ttp-text-md sm:ttp-text-xl text-white font-extralight">
                        {{ $t("page.HomeView.hello_txt") }}, <span class="font-medium">{{ trimName(authStore?.user?.name) }}</span>
                      </div>
                      <div class="ttp-text-sm sm:ttp-text-xl text-white">
                        <p>{{ $t("page.HomeView.welcome_txt") }}</p>
                        <p>{{ $t("page.HomeView.welcome_detail_txt") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-10 text-white text-right">
              <router-link :to="{ name: 'MessageView' }"
                ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                </svg>
              </router-link>
            </div>
            <div class="w-10 text-white text-right">
              <router-link :to="{ name: 'NotificationView' }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
              </router-link>
            </div>
          </div>
          <router-link v-if="sharedStore.isAlert" :to="{ name: 'AlertDetailView', params: { id: sharedStore?.alertResult?.id } }" :class="{ 'px-2': !sharedStore?.alertResult?.thumbnail?.url }" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden bg-yellow-50 my-4">
            <div class="relative w-full overflow-hidden">
              <div v-if="sharedStore?.alertResult?.thumbnail?.url" class="absolute inset-0 w-full h-36">
                <img :src="sharedStore?.alertResult?.thumbnail?.url" alt="รูปภาพ" class="object-cover w-full h-full" />
              </div>
            </div>
            <div class="flex flex-col w-full justify-between py-2">
              <div class="w-full ttp-text-sm">{{ $convertString($filterNewsByLocale(sharedStore?.alertResult?.translations, masterDataStore.lang())?.title) }}</div>
            </div>
          </router-link>

          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto pb-20">
            <div class="flex">
              <div class="w-full text-neutral-700 ttp-text-lg font-bold">{{ $t("page.HomeView.popular_places") }}</div>
              <router-link :to="{ name: 'PopularPlaceView' }" class="w-full ttp-text-sm text-right text-[#2C3483]">{{ $t("page.HomeView.see_more") }}</router-link>
            </div>
            <div class="swiper-container w-full">
              <div v-if="!popularPlace?.items" class="flex flex-col justify-center items-center w-full">
                <div><LoadingFormComponent /></div>
                <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
              </div>
              <Swiper v-else :slidesPerView="2.75" :loop="true" :spaceBetween="5" :modules="modules" class="swiper-wrapper">
                <SwiperSlide v-for="(result, number) in popularPlace?.items" :key="number" class="swiper-slide">
                  <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: result?.id } }" class="relative flex gap-2 h-36 rounded-[21px] overflow-hidden">
                    <div class="relative w-full">
                      <div class="absolute w-full h-36 inset-0">
                        <img v-if="result.images" :src="result?.images[0]" alt="รูปภาพ" class="object-cover w-full h-full" />
                      </div>
                    </div>
                    <div class="absolute w-full text-white bottom-0 p-4 ttp-text-md leading-none font-bold">{{ $convertString(result?.name) }}</div>
                  </router-link>
                </SwiperSlide>
              </Swiper>
            </div>

            <hr />
            <div class="flex">
              <div class="w-full text-neutral-700 ttp-text-lg font-bold">{{ $t("page.HomeView.news") }}</div>
              <router-link :to="{ name: 'NewsView' }" class="w-full ttp-text-sm text-right text-[#2C3483]">{{ $t("page.HomeView.see_more") }}</router-link>
            </div>

            <div v-if="!(JSON.stringify(newsSearchStore?.tableScrollResults?.items) === '{}')" v-for="(result, date) in newsSearchStore?.tableScrollResults?.items" :key="date">
              <div v-if="result.length" class="grid md:grid-cols-2 grid-cols-1 gap-2">
                <router-link :to="{ name: 'NewsDetailView', params: { id: data?.id } }" v-for="data in result" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                  <div class="relative w-full overflow-hidden">
                    <div :class="{ 'absolute inset-0': true, 'w-[90px] m-auto': !data?.thumbnail?.url, 'w-full h-36': data?.thumbnail?.url }">
                      <img v-if="data?.thumbnail?.url" :src="data?.thumbnail?.url" alt="รูปภาพ" class="object-cover w-full h-full" />
                      <img v-else="image_url" :src="image_url" alt="รูปภาพ" class="object-cover w-full h-full" />
                    </div>
                  </div>
                  <div class="flex flex-col w-full justify-between py-2">
                    <time class="w-full ttp-text-xs text-[#4E4E4E] font-bold">{{ $dayjs(new Date(data?.contentAt)).format("DD MMMM YYYY") }}</time>
                    <div class="w-full ttp-text-sm">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.title) }}</div>
                  </div>
                </router-link>
              </div>
            </div>
            <div v-else-if="newsSearchStore.currentPage == 1 && !newsSearchStore.loadingScroll" class="flex flex-col h-full justify-center items-center">
              <div class="text-center">
                <component :is="$outlineIcons.NewspaperIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.NewsView.not_found") }}</div>
              </div>
            </div>
            <div v-if="newsSearchStore.loadingScroll" class="flex flex-col justify-center items-center w-full">
              <div><LoadingFormComponent /></div>
              <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="historySearchStore.isBackgroundCheck" :is="defaultFormComponents.alert.backgroundCheck.component" v-bind="defaultFormComponents.alert.backgroundCheck.props" />
</template>
