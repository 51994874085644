import { defineStore } from "pinia";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues } from "@/configs/helpers/MainHelper";
import { fetchIncident } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchIncident";

const storeName = `useNotificationSearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {};
export const useNotificationSearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    scrollLoading: false,
    hasMore: false,
    isRequired,
    currentPage: 0,
    totalPage: 1,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    tableScrollResults: null as any,
    tableSearchResults: null as any,
    getResult: null as any,
    NewsRole: "",
    listType: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async onUpdateCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;

        const results = (await this.keyword({
          ...this.result,
          page: this.currentPage,
          size: Number(import.meta.env.VITE_PAGE_SIZE),
        })) as any;

        if (!results.items.length) this.loading = false;

        return results;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    keyword(results: typeGetQuery) {
      const payload = {
        ...results,
        size: Number(import.meta.env.VITE_PAGE_SIZE),
      };

      return this.fetchIncident.NotificationList(payload);
    },
  },
});
