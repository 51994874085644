export enum ENUM_APP_NAME {
  POLICE_COMMAND_CENTER = "police-command-center",
  TOURIST_POLICE = "police-tourist-and-agent",
}

export enum ENUM_APP_CATEGORY_NAME {
  AGENT = "agent",
  TOURIST = "tourist",
  COMMAND = "command",
}

export enum ENUM_FIELD_ROLE {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SUPERVISOR = "SUPERVISOR",
  INTEPETOR = "INTEPETOR",
  SUPPRESSOR = "SUPPRESSOR",
  INVESTIGATOR = "INVESTIGATOR",
  DUTY_OFFICER = "DUTY_OFFICER",
  AGENT = "AGENT",

  // Legacy
  CENTRAL_OFFICER = "CENTRAL_OFFICER",
  OFFICER = "OFFICER",
  REPORTER = "REPORTER",
}

export enum ENUM_CHAT_TYPE {
  TEXT = "TEXT",
  IMAGES = "IMAGES",
  LOCATION = "LOCATION",
  SELECT = "SELECT",
  SELECTED = "SELECTED",
  ANSWER_A_CALL = "ANSWER_A_CALL",
  MAKE_A_CALL = "MAKE_A_CALL",
  HANG_UP = "HANG_UP",
}
export enum ENUM_VOICE_TYPE {
  TOURIST_MAKE_A_CALL_CREATE = "TOURIST_MAKE_A_CALL_CREATE",
  TOURIST_WAITING = "TOURIST_WAITING",
  TOURIST_HANG_UP = "TOURIST_HANG_UP",
  TOURIST_ANSWER_A_CALL_SEND_TO = "TOURIST_ANSWER_A_CALL_SEND_TO",
  TOURIST_ANSWER_A_CALL_START_SUBMIT = "TOURIST_ANSWER_A_CALL_START_SUBMIT",
  TOURIST_ANSWER_A_CALL_SEND = "TOURIST_ANSWER_A_CALL_SEND",
  TOURIST_ANSWER_A_CALL_JOIN_SUBMIT = "TOURIST_ANSWER_A_CALL_JOIN_SUBMIT",
  TOURIST_SHUTDOWN = "TOURIST_SHUTDOWN",

  COMMAND_CENTER_MAKE_A_CALL_CREATE = "COMMAND_CENTER_MAKE_A_CALL_CREATE",
  COMMAND_CENTER_WAITING = "COMMAND_CENTER_WAITING",
  COMMAND_CENTER_ANSWER_A_CALL_START_SUBMIT = "COMMAND_CENTER_ANSWER_A_CALL_START_SUBMIT",
  COMMAND_CENTER_ANSWER_A_CALL_SEND = "COMMAND_CENTER_ANSWER_A_CALL_SEND",
  COMMAND_CENTER_HANG_UP = "COMMAND_CENTER_HANG_UP",
  COMMAND_CENTER_ANSWER_A_CALL_JOIN_SUBMIT = "COMMAND_CENTER_ANSWER_A_CALL_JOIN_SUBMIT",
  COMMAND_CENTER_ANSWER_A_CALL_SEND_TO = "COMMAND_CENTER_ANSWER_A_CALL_SEND_TO",
  COMMAND_CENTER_SHUTDOWN = "COMMAND_CENTER_SHUTDOWN",
}

export enum ENUM_FIELD_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ENUM_FIELD_LOCALE {
  TH = "TH",
  EN = "EN",
  CN = "CN",
  JP = "JP",
  KR = "KR",
  RU = "RU",
}

export enum ENUM_FIELD_LANGUAGE {
  TH = "TH",
  EN = "EN",
  CN = "CN",
  JP = "JP",
  KR = "KR",
  RU = "RU",
}

export enum ENUM_STEP_FORGOT_PASSWORD {
  STEP_1 = "EMAIL",
  STEP_2 = "OTP",
  STEP_3 = "newPassword",
}

export enum ENUM_SIZE {
  SMALL = "SMALL",
  NORMAL = "NORMAL",
  LARGE = "LARGE",
}

export enum ENUM_COLOR {
  NONE = "NONE",
  DEFAULT_1 = "DEFAULT_1",
  DEFAULT_2 = "DEFAULT_2",
  DARK = "DARK",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
  GRAY = "GRAY",
  BLACK = "BLACK",
  OPACITY = "OPACITY",
  NEW = "NEW",
  ACCEPTED = "ACCEPTED",
  WAITING = "WAITING",
  FORWARDED = "FORWARDED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  IN_REVIEW = "IN_REVIEW",
  REPORTED = "REPORTED",
  CLOSED = "CLOSED",
  REJECTED = "REJECTED",
}

export enum ENUM_TOGGLE_CLASS {
  PRIMARY = "peer-checked:bg-ttp-primary",
  SECONDARY = "peer-checked:bg-ttp-secondary",
  ERROR = "peer-checked:bg-ttp-error",
  WARNING = "peer-checked:bg-ttp-warning",
  INFO = "peer-checked:bg-ttp-info",
  SUCCESS = "peer-checked:bg-ttp-success",
  NEW = "peer-checked:bg-ttp-new",
  WAITING = "peer-checked:bg-ttp-waiting",
  FORWARDED = "peer-checked:bg-ttp-warning",
  IN_REVIEW = "peer-checked:bg-ttp-in-review",
  REPORTED = "peer-checked:bg-ttp-reported",
  CLOSED = "peer-checked:bg-ttp-close",
  REJECTED = "peer-checked:bg-ttp-close",
}

export enum ENUM_INFORMER_TYPE {
  GUEST = "GUEST",
  TOURIST = "TOURIST",
  ANONYMOUS = "ANONYMOUS",
}

export enum ENUM_CHAT_SENDER {
  TOURIST = "TOURIST",
  USER = "USER",
  SYSTEM = "SYSTEM",
}

export enum ENUM_GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NONE = "NONE",
}

export enum ENUM_PRIORITY {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}
export enum ENUM_INCIDENT_STATUS {
  NEW = "NEW",
  ACCEPTED = "ACCEPTED",
  WAITING = "WAITING",
  FORWARDED = "FORWARDED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  IN_REVIEW = "IN_REVIEW",
  REPORTED = "REPORTED",
  CLOSED = "CLOSED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}
export enum ENUM_TAB_INCIDENT {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESSFUL = "SUCCESSFUL",
}
export enum ENUM_INCIDENT_TYPE {
  DEFAULT = "DEFAULT",
  SOS = "SOS",
}
export enum ENUM_CHANNEL {
  APPLICATION = "APPLICATION",
  _1155 = "1155",
  WALK_IN = "WALK_IN",
}
export enum ENUM_MUTATION_STATUS {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
export enum ENUM_MUTATION {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  SELECT = "SELECT",
  RECEIVE = "RECEIVE",
  CANCEL = "CANCEL",
  CLOSE = "CLOSE",
  REJECT = "REJECT",
  ACCEPT = "ACCEPT",
}
export enum ENUM_IDENTIFICATION_TYPE {
  ID_CARD = "ID_CARD",
  PASSPORT = "PASSPORT",
}

export enum ENUM_BUTTON_STATE {
  IN_REVIEW = "IN_REVIEW",
  REPORTED = "REPORTED",
  SAVE = "SAVE",
}

export const enums = {
  ENUM_APP_NAME: ENUM_APP_NAME,
  ENUM_APP_CATEGORY_NAME: ENUM_APP_CATEGORY_NAME,
  ENUM_FIELD_ROLE: ENUM_FIELD_ROLE,
  ENUM_CHAT_TYPE: ENUM_CHAT_TYPE,
  ENUM_FIELD_STATUS: ENUM_FIELD_STATUS,
  ENUM_FIELD_LOCALE: ENUM_FIELD_LOCALE,
  ENUM_FIELD_LANGUAGE: ENUM_FIELD_LANGUAGE,
  ENUM_STEP_FORGOT_PASSWORD: ENUM_STEP_FORGOT_PASSWORD,
  ENUM_SIZE: ENUM_SIZE,
  ENUM_COLOR: ENUM_COLOR,
  ENUM_TOGGLE_CLASS: ENUM_TOGGLE_CLASS,
  ENUM_INFORMER_TYPE: ENUM_INFORMER_TYPE,
  ENUM_CHAT_SENDER: ENUM_CHAT_SENDER,
  ENUM_GENDER: ENUM_GENDER,
  ENUM_PRIORITY: ENUM_PRIORITY,
  ENUM_INCIDENT_STATUS: ENUM_INCIDENT_STATUS,
  ENUM_TAB_INCIDENT: ENUM_TAB_INCIDENT,
  ENUM_INCIDENT_TYPE: ENUM_INCIDENT_TYPE,
  ENUM_CHANNEL: ENUM_CHANNEL,
  ENUM_MUTATION_STATUS: ENUM_MUTATION_STATUS,
  ENUM_MUTATION: ENUM_MUTATION,
  ENUM_IDENTIFICATION_TYPE: ENUM_IDENTIFICATION_TYPE,
  ENUM_BUTTON_STATE: ENUM_BUTTON_STATE,
  ENUM_VOICE_TYPE: ENUM_VOICE_TYPE,
};
