<script lang="ts">
import { defineComponent } from "vue";
import { useProfileUnlinkStore } from "./stores/useProfileUnlinkStore";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";

export default defineComponent({
  name: "ProfileUnlinkView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    components() {
      return {};
    },
  },
  methods: {
    loading() {
      return false;
    },
    unlink() {
      switch (this.$route.params.socialType) {
        case "google":
          break;
        case "facebook":
          break;
        case "apple":
          break;
      }
    },
  },
  setup() {
    const profileUnlinkStore = useProfileUnlinkStore();
    const authStore = useAuthStore();

    return {
      profileUnlinkStore,
      authStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'ProfileSocialMediaAccountView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.ProfileSocialMediaAccountView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 items-center justify-center w-full px-4 md:px-10 h-full">
            <svg v-if="$route.params.socialType == 'google'" class="ttp-icon-box-01" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Frame" clip-path="url(#clip0_459_5101)">
                <path id="Vector" d="M23.5078 13.1668C23.5078 12.1836 23.4295 11.466 23.2598 10.722H12.2295V15.1599H18.7041C18.5736 16.2628 17.8687 17.9238 16.3022 19.0399L16.2802 19.1885L19.7678 21.9387L20.0095 21.9632C22.2285 19.877 23.5078 16.8076 23.5078 13.1668Z" fill="#4285F4"></path>
                <path id="Vector_2" d="M12.2296 24.8599C15.4016 24.8599 18.0645 23.7968 20.0095 21.9632L16.3023 19.0399C15.3102 19.7441 13.9787 20.2358 12.2296 20.2358C9.1228 20.2358 6.48598 18.1497 5.54603 15.2663L5.40825 15.2782L1.7818 18.135L1.73438 18.2692C3.6663 22.1757 7.63462 24.8599 12.2296 24.8599Z" fill="#34A853"></path>
                <path id="Vector_3" d="M5.54621 15.2663C5.2982 14.5222 5.15466 13.7249 5.15466 12.9011C5.15466 12.0772 5.2982 11.28 5.53316 10.5359L5.52659 10.3774L1.8547 7.47473L1.73457 7.5329C0.938328 9.154 0.481445 10.9744 0.481445 12.9011C0.481445 14.8278 0.938328 16.6481 1.73457 18.2692L5.54621 15.2663Z" fill="#FBBC05"></path>
                <path id="Vector_4" d="M12.2295 5.56635C14.4356 5.56635 15.9237 6.53634 16.7722 7.34693L20.0878 4.0516C18.0515 2.12492 15.4015 0.942322 12.2295 0.942322C7.63462 0.942322 3.6663 3.62638 1.73438 7.53289L5.53297 10.5359C6.48597 7.65252 9.12279 5.56635 12.2295 5.56635Z" fill="#EB4335"></path>
              </g>
              <defs>
                <clipPath id="clip0_459_5101"><rect width="23.04" height="24" fill="white" transform="translate(0.47998 0.942322)"></rect></clipPath>
              </defs>
            </svg>
            <svg v-else-if="$route.params.socialType == 'facebook'" class="ttp-icon-box-01" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_76_25335)">
                <path d="M40 0C17.908 0 0 17.908 0 40C0 62.092 17.908 80 40 80C62.092 80 80 62.092 80 40C80 17.908 62.092 0 40 0Z" fill="url(#paint0_linear_76_25335)" />
                <path d="M45.7618 56.1928H56.6171L58.3221 45.1656H45.7618V39.1381C45.7618 34.5578 47.2592 30.4955 51.5439 30.4955H58.4291V20.8734C57.219 20.7098 54.6603 20.3533 49.8262 20.3533C39.7302 20.3533 33.8118 25.6844 33.8118 37.8316V45.1677H23.4326V56.1949H33.8097V86.504C35.865 86.8102 37.9475 87.0199 40.0846 87.0199C42.0162 87.0199 43.9016 86.8438 45.7618 86.5921V56.1928Z" fill="white" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_76_25335" x1="11.986" y1="11.986" x2="73.23" y2="73.23" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#2AA4F4" />
                  <stop offset="1" stop-color="#007AD9" />
                </linearGradient>
                <clipPath id="clip0_76_25335">
                  <rect width="80" height="80" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg v-else-if="$route.params.socialType == 'apple'" class="ttp-icon-box-01" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Frame" clip-path="url(#clip0_459_5110)">
                <g id="Group">
                  <path id="Vector" d="M17.2729 0.396624C17.2219 0.339624 15.3844 0.419124 13.7854 2.15462C12.1864 3.88862 12.4324 5.87762 12.4684 5.92862C12.5044 5.97962 14.7484 6.05912 16.1809 4.04162C17.6134 2.02412 17.3239 0.455124 17.2729 0.396624ZM22.2439 17.9961C22.1719 17.8521 18.7564 16.1451 19.0744 12.8631C19.3924 9.57962 21.5869 8.67962 21.6214 8.58212C21.6559 8.48462 20.7259 7.39712 19.7404 6.84662C19.0168 6.4585 18.216 6.23614 17.3959 6.19562C17.2339 6.19112 16.6714 6.05312 15.5149 6.36962C14.7529 6.57812 13.0354 7.25312 12.5629 7.28012C12.0889 7.30712 10.6789 6.49712 9.16239 6.28262C8.19189 6.09512 7.16289 6.47912 6.42639 6.77462C5.69139 7.06862 4.29339 7.90562 3.31539 10.1301C2.33739 12.3531 2.84889 15.8751 3.21489 16.9701C3.58089 18.0636 4.15239 19.8561 5.12439 21.1641C5.98839 22.6401 7.13439 23.6646 7.61289 24.0126C8.09139 24.3606 9.44139 24.5916 10.3774 24.1131C11.1304 23.6511 12.4894 23.3856 13.0264 23.4051C13.5619 23.4246 14.6179 23.6361 15.6994 24.2136C16.5559 24.5091 17.3659 24.3861 18.1774 24.0561C18.9889 23.7246 20.1634 22.4676 21.5344 19.9191C22.0549 18.7341 22.2919 18.0936 22.2439 17.9961Z" fill="black"></path>
                  <path id="Vector_2" d="M17.2729 0.396624C17.2219 0.339624 15.3844 0.419124 13.7854 2.15462C12.1864 3.88862 12.4324 5.87762 12.4684 5.92862C12.5044 5.97962 14.7484 6.05912 16.1809 4.04162C17.6134 2.02412 17.3239 0.455124 17.2729 0.396624ZM22.2439 17.9961C22.1719 17.8521 18.7564 16.1451 19.0744 12.8631C19.3924 9.57962 21.5869 8.67962 21.6214 8.58212C21.6559 8.48462 20.7259 7.39712 19.7404 6.84662C19.0168 6.4585 18.216 6.23614 17.3959 6.19562C17.2339 6.19112 16.6714 6.05312 15.5149 6.36962C14.7529 6.57812 13.0354 7.25312 12.5629 7.28012C12.0889 7.30712 10.6789 6.49712 9.16239 6.28262C8.19189 6.09512 7.16289 6.47912 6.42639 6.77462C5.69139 7.06862 4.29339 7.90562 3.31539 10.1301C2.33739 12.3531 2.84889 15.8751 3.21489 16.9701C3.58089 18.0636 4.15239 19.8561 5.12439 21.1641C5.98839 22.6401 7.13439 23.6646 7.61289 24.0126C8.09139 24.3606 9.44139 24.5916 10.3774 24.1131C11.1304 23.6511 12.4894 23.3856 13.0264 23.4051C13.5619 23.4246 14.6179 23.6361 15.6994 24.2136C16.5559 24.5091 17.3659 24.3861 18.1774 24.0561C18.9889 23.7246 20.1634 22.4676 21.5344 19.9191C22.0549 18.7341 22.2919 18.0936 22.2439 17.9961Z" fill="black"></path>
                </g>
              </g>
              <defs>
                <clipPath id="clip0_459_5110"><rect width="24" height="24" fill="black" transform="translate(0.5 0.384613)"></rect></clipPath>
              </defs>
            </svg>
            <div class="ttp-text-2xl text-[#4E4E4E] mt-6">{{ $route.params.socialType == "google" ? $t("page.ProfileUnlinkView.link_google_txt") : $route.params.socialType == "facebook" ? $t("page.ProfileUnlinkView.link_fb_txt") : $t("page.ProfileUnlinkView.link_apple_txt") }}</div>
            <div class="text-xl text-black font-bold mb-10">{{ $convertString("-") }}</div>
            <button @click="unlink" class="w-full px-4 bg-indigo-900 rounded-[10px] justify-center items-center gap-2 inline-flex mt-2">
              <div class="grow shrink basis-0 text-center text-white ttp-text-sm font-bold py-2">{{ $t("btn.btn_unlink") }}</div>
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
