import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useRegisterOTPStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  number_1: {
    is: true,
    name: `number_1`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_2: {
    is: true,
    name: `number_2`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_3: {
    is: true,
    name: `number_3`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
  number_4: {
    is: true,
    name: `number_4`,
    label: `form.numberic.label`,
    placeholder: `form.numberic.placeholder`,
    value: null,
  },
};

export const useRegisterOTPStore = defineStore(storeName, {
  state: () => ({
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as any,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      number_1: isRequired.number_1.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_2: isRequired.number_2.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_3: isRequired.number_3.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      number_4: isRequired.number_4.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        return await this.verifyOTP();
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return false;
      }
    },
    async verifyOTP() {
      return true;
    },
  },
});
