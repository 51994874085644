<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MaintenanceView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-h-screen min-w-[320px] bg-gray-100 dark:text-gray-100 dark:bg-gray-900">
    <main id="page-content" class="flex flex-auto flex-col max-w-full">
      <div class="min-h-screen flex items-center justify-center relative overflow-hidden max-w-10xl mx-auto p-4 lg:p-8 w-full bg-white dark:bg-gray-800">
        <div class="py-6 w-full xl:w-8/12">
          <div class="grid grid-cols-1 lg:grid-cols-2">
            <div class="p-12 lg:p-20 text-teal-500">
              <svg class="w-48 lg:w-full mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 581.048 526.161" data-name="Layer 1">
                <path fill="#ffb6b6" d="m408.662 514.39-9.901-.001-4.71-38.19 14.613.001-.002 38.19z" />
                <path fill="#2f2e41" d="m409.01 524.72-30.446-.002v-.385a11.851 11.851 0 0 1 11.85-11.85h.001l5.562-4.22 10.376 4.22h2.658Z" />
                <path fill="#ffb6b6" d="m537.03 460.1-6.395 7.558-32.199-21.068 9.438-11.156L537.03 460.1z" />
                <path fill="#2f2e41" d="m545.141 466.504-19.665 23.245-.293-.248a11.851 11.851 0 0 1-1.395-16.702l.371-6.971 9.924-5.197 1.716-2.03Z" />
                <path fill="#e6e6e6" d="M55.502 10h-27a5 5 0 0 1 0-10h27a5 5 0 0 1 0 10Z" />
                <circle cx="232.502" cy="5" r="4.5" fill="#e6e6e6" />
                <circle cx="246.502" cy="5" r="4.5" fill="#e6e6e6" />
                <circle cx="260.502" cy="5" r="4.5" fill="#e6e6e6" />
                <path fill="#f2f2f2" d="M84 141.99H36a12.514 12.514 0 0 1-12.5-12.5v-48A12.514 12.514 0 0 1 36 68.99h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM253.002 141.99h-48a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM168 141.99h-48a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM36 174.99a11.513 11.513 0 0 0-11.5 11.5v48a11.513 11.513 0 0 0 11.5 11.5h48a11.513 11.513 0 0 0 11.5-11.5v-48a11.513 11.513 0 0 0-11.5-11.5ZM253.002 246.99h-48a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM168 246.99h-48a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM84 351.99H36a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM253.002 351.99h-48a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM168 351.99h-48a12.514 12.514 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5Z" />
                <path fill="#fff" d="M264.866 325.49H18.134A18.154 18.154 0 0 1 0 307.358v-97.733a18.154 18.154 0 0 1 18.134-18.133h246.732A18.154 18.154 0 0 1 283 209.624v97.733a18.154 18.154 0 0 1-18.134 18.134Z" />
                <path fill="#e6e6e6" d="M264.866 325.49H18.134A18.154 18.154 0 0 1 0 307.358v-97.733a18.154 18.154 0 0 1 18.134-18.133h246.732A18.154 18.154 0 0 1 283 209.624v97.733a18.154 18.154 0 0 1-18.134 18.134Zm-199.047-132A63.819 63.819 0 0 0 2 257.31v50.047a16.134 16.134 0 0 0 16.134 16.134h246.732A16.134 16.134 0 0 0 281 307.357v-97.733a16.134 16.134 0 0 0-16.134-16.133Z" />
                <path fill="#2f2e41" d="m457 233.491-53.079 6.158L383 275.491l-3 12 1.358 8.25-3.358 5.75 2 11-8 87 22 98h20.5l-6.5-91 17-78 15 76 69.37 60.412 16.158-23.164L472 396.491l-5-16 6.737-13.528L479 277.491l-22-44z" />
                <path fill="currentColor" d="M434.5 270.99s35.008 1.798 35.5-10.5-7.384-25.326-11-26-2.303-11.5-.402-19 .423-10.979-1.838-11.239-54.76-71.76-54.76-71.76l-11.5.5-11.5-8.5-18 7-4 16-11 10 6.3 43.962s6.975 17.744 16.564 35.835c2.984 5.629 6.22 11.291 9.578 16.463 7.909 12.182 16.491 21.644 24.03 21.537 21.028-.297 32.028-4.297 32.028-4.297Z" />
                <path fill="#ffb6b6" d="m304.67 224.277-6.304 2.91-1.46.671-2.335 1.088-48.652 22.463a7.256 7.256 0 0 1-8.741 1.495 6.959 6.959 0 0 1-2.806-9.428c.031-.058.068-.106.1-.163a7.297 7.297 0 0 1 9.755-2.87 7.394 7.394 0 0 1 .96.646l1.263-.803 3.962-2.513 2.993-1.897 5.952-3.788 2.31-1.464 7.485-4.752 7.22-4.58 8.748-5.555 6.724-4.273 2.094 2.092Z" />
                <path fill="currentColor" d="M317.263 223.253a12.535 12.535 0 0 1-2.67.815c-2.906.04-.334-2.523-5.726 1.612-4.38 3.35-5.757-.306-6.055 1.48-.496 2.952-2.84.682-4.446.027-.713-.296-1.27-.265-1.46.671a4.771 4.771 0 0 0-.054 1.315c.093 1.698-.862 1.258-2.281-.227-2.344-2.466-5.945-7.796-8.053-11.057-.612-.918-1.091-1.68-1.398-2.152-.222-.352-.343-.555-.343-.555a4.508 4.508 0 0 1 1.72-2.632 15.664 15.664 0 0 1 6.132-3.044 24.139 24.139 0 0 1 4.746-.689 3.184 3.184 0 0 0 1.684-.556l.01-.006a2.48 2.48 0 0 0 .77-1.963c.093-1.822 2.351-2.183 4.676-2.191 2.189.007 4.45.31 5.01-.023.808-.48 18.7-26.832 30.116-43.718a17.196 17.196 0 0 1 23.483-4.875l7.993 5.09a12.347 12.347 0 0 1 6.723 6.445 13.26 13.26 0 0 1 .522 7.92 31.311 31.311 0 0 1-4.617 10.347c-11.045 17.14-46.233 34.122-56.482 37.966Z" />
                <path fill="#ffb6b6" d="m462.75 190.946 4.048 5.64.934 1.306 1.51 2.089 31.245 43.535a7.256 7.256 0 0 1 3.118 8.302 6.959 6.959 0 0 1-8.728 4.535c-.063-.02-.117-.047-.18-.066a7.297 7.297 0 0 1-4.66-9.039 7.396 7.396 0 0 1 .454-1.065l-1.027-1.088-3.216-3.416-2.429-2.582-4.843-5.13-1.874-1.99-6.08-6.454-5.861-6.226-7.107-7.542-5.465-5.795 1.659-2.452Z" />
                <path fill="currentColor" d="M459.366 178.772a12.535 12.535 0 0 1 1.304 2.467c.589 2.847-2.413.806 2.665 5.32 4.117 3.669.786 5.71 2.597 5.666 2.992-.07 1.205 2.66.866 4.361-.156.756-.021 1.298.934 1.306a4.77 4.77 0 0 0 1.302-.194c1.65-.412 1.398.609.208 2.283-1.98 2.767-6.534 7.31-9.338 9.996-.787.774-1.444 1.388-1.85 1.779-.303.284-.48.441-.48.441a4.508 4.508 0 0 1-2.909-1.191 15.664 15.664 0 0 1-4.147-5.448 24.139 24.139 0 0 1-1.573-4.53 3.184 3.184 0 0 0-.864-1.549l-.008-.008a2.48 2.48 0 0 0-2.072-.385c-1.807.251-2.589-1.898-3.035-4.179-.407-2.151-.535-4.43-.968-4.917-.92-1.034-27.44-16.797-27.44-16.797l-26.91-35.472a12.347 12.347 0 0 1 5.059-7.819 13.26 13.26 0 0 1 7.679-2.008 31.311 31.311 0 0 1 11.032 2.58c18.917 7.61 42.238 38.959 47.948 48.298Z" />
                <circle cx="359.468" cy="100.473" r="23.056" fill="#ffb6b6" />
                <path fill="#2f2e41" d="M385.73 105.33s-4.76 6.75-7.73 9.16c.03-3.51-.55-9.36-.52-12.86a17.207 17.207 0 0 0-.55-5.46 5.763 5.763 0 0 0-3.61-3.87 4.235 4.235 0 0 0-4.54 6.63c-1.5.31-2.8-1.14-3.39-2.56-.59-1.42-.9-3.06-2.06-4.08a4.411 4.411 0 0 0-4.25-.64 10.29 10.29 0 0 0-3.72 2.46c-3.15 2.93-5.76 6.49-9.37 8.85a13.86 13.86 0 0 1-2.29 1.21 10.858 10.858 0 0 0-4.09-5.98 18.724 18.724 0 0 1-.07 6.92 7.945 7.945 0 0 1-5.59-1.71 9.299 9.299 0 0 1-3-5.92 15.389 15.389 0 0 1-.09-3.11 9.489 9.489 0 0 0 1.53-1.06 10.326 10.326 0 0 0 2.57-3.12 16.925 16.925 0 0 1-3.73.76l.03-.23a10.223 10.223 0 0 1 1.66-4.82c1.06-1.35 3.07-2.12 4.56-1.25a21.94 21.94 0 0 1 27.74-14.5 10.428 10.428 0 0 0-1.01 3.91 17.783 17.783 0 0 1 3.73-2.65 4.824 4.824 0 0 1 2.48 3.56c1.91.35 5.57.8 4.27 2.13a22.485 22.485 0 0 1 11.04 28.23Z" />
                <path fill="#ccc" d="m124.729 238.796-89.89-1.05a.984.984 0 1 1 .007-1.966l89.89 1.049a.984.984 0 0 1-.007 1.967ZM170.729 254.796l-135.89-1.05a.984.984 0 1 1 .007-1.966l135.89 1.049a.984.984 0 0 1-.007 1.967Z" />
                <circle cx="225.641" cy="246.241" r="21" fill="currentColor" />
                <path fill="#fff" d="m233.048 251.336-7.407-17.556-7.406 17.556 6.406-2.721v10.626h2v-10.626l6.407 2.721z" />
                <path fill="#f2f2f2" d="M246.641 286.74h-210a2.5 2.5 0 0 0 0 5h210a2.5 2.5 0 0 0 0-5Z" />
                <path fill="currentColor" d="M156.641 286.74v5h-120a2.5 2.5 0 0 1 0-5Z" />
                <path fill="#f2f2f2" d="M524.312 508.828a2.27 2.27 0 0 0 1.645-3.934l-.155-.618.061-.148a6.094 6.094 0 0 1 11.242.041c1.838 4.429 4.18 8.864 4.755 13.546a18.029 18.029 0 0 1-.316 6.2 72.281 72.281 0 0 0 6.575-30.02 69.766 69.766 0 0 0-.433-7.783q-.358-3.178-.994-6.31a73.119 73.119 0 0 0-14.5-30.99 19.459 19.459 0 0 1-8.093-8.396 14.843 14.843 0 0 1-1.35-4.056c.394.051 1.485-5.95 1.188-6.317.55-.833 1.532-1.248 2.132-2.06 2.982-4.044 7.09-3.338 9.236 2.157 4.582 2.312 4.626 6.148 1.815 9.836-1.79 2.347-2.035 5.523-3.604 8.035.161.207.33.407.49.614a73.591 73.591 0 0 1 7.682 12.169 30.592 30.592 0 0 1 1.826-14.21c1.748-4.217 5.025-7.77 7.91-11.415a6.28 6.28 0 0 1 11.184 3.083q.01.08.018.161-.643.363-1.259.77a3.078 3.078 0 0 0 1.241 5.602l.063.01a30.628 30.628 0 0 1-.807 4.58c3.701 14.315-4.29 19.529-15.702 19.763-.252.13-.497.259-.75.381a75.115 75.115 0 0 1 4.044 19.028 71.243 71.243 0 0 1-.052 11.503l.02-.135a18.826 18.826 0 0 1 6.426-10.87c4.946-4.063 11.933-5.56 17.269-8.825a3.533 3.533 0 0 1 5.41 3.437l-.021.143a20.677 20.677 0 0 0-2.319 1.117q-.643.363-1.258.77a3.078 3.078 0 0 0 1.24 5.602l.064.01.129.019a30.654 30.654 0 0 1-5.639 8.83c-2.314 12.496-12.256 13.682-22.89 10.043h-.007a75.093 75.093 0 0 1-5.044 14.726h-18.02c-.065-.2-.123-.407-.181-.607a20.475 20.475 0 0 0 4.986-.297c-1.337-1.64-2.674-3.294-4.01-4.935a1.12 1.12 0 0 1-.085-.097c-.678-.84-1.363-1.673-2.04-2.512l-.001-.001a29.99 29.99 0 0 1 .879-7.64Z" />
                <path fill="#ccc" d="M325.378 524.97a1.186 1.186 0 0 0 1.19 1.19h253.29a1.19 1.19 0 1 0 0-2.38h-253.29a1.187 1.187 0 0 0-1.19 1.19Z" />
              </svg>
            </div>
            <div class="lg:pl-20 lg:flex lg:items-center lg:relative text-center lg:text-left">
              <div class="hidden lg:block absolute top-0 bottom-0 left-0 w-px bg-gradient-to-b from-transparent via-gray-300 to-transparent dark:via-gray-600"></div>
              <div class="w-full">
                <h1 class="text-xl font-bold mb-2">
                  {{ $t("page.MaintenanceView.title") }}
                </h1>
                <h2 class="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400 mb-6">
                  {{ $t("page.MaintenanceView.detail") }}
                </h2>
                <div class="text-sm text-gray-500 mt-20 dark:text-gray-400">
                  {{ $t("layout.by") }}
                  <a href="#" class="font-medium text-teal-600 hover:text-teal-400 dark:text-teal-400 dark:hover:text-teal-300">Tourist Police Management</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
