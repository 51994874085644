import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import type { typeGetInformer } from "@/configs/types/Informer/typeInformer";

const storeName = `useSettingLanguageStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  locale: {
    is: false,
    name: `form.locale.name`,
    label: `form.locale.label`,
    placeholder: `form.locale.placeholder`,
    value: null,
  },
};

export const useSettingLanguageStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    isRequired,
    result: convertToValues(isRequired) as any,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({}),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        await this.update(this.result);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update(result: typeGetInformer): Promise<typeGetInformer> {
      try {
        this.loading = true;

        const data = await this.fetchAuth.UpdateProfile({
          ...result,
        });

        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
