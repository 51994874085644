import { defineStore } from "pinia";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues, groupNewsByContentDate } from "@/configs/helpers/MainHelper";

import { fetchNews } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchNews";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchFile";

const storeName = `useNewsInternalSearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  startAt: {
    is: false,
    name: `startAt`,
    label: `form.startAt.label`,
    placeholder: `form.startAt.placeholder`,
    value: null,
  },
  endAt: {
    is: false,
    name: `endAt`,
    label: `form.endAt.label`,
    placeholder: `form.endAt.placeholder`,
    value: new Date(),
  },
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: null,
  },
};
export const useNewsInternalSearchStore = defineStore(storeName, {
  state: () => ({
    fetchNews: new fetchNews(),
    fetchFile: new fetchFile(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    currentPage: 0,
    totalPage: 1,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    tableScrollResults: null as any,
    tableSearchResults: null as any,
    getResult: null as any,
    NewsRole: "",
    listType: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async onUpdateCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;

        delete this.result.endAt;
        delete this.result.startAt;

        const results = await this.keyword({
          ...this.result,
          page: this.currentPage,
          size: Number(import.meta.env.VITE_PAGE_SIZE),
        });

        if (!results.items.length) this.loading = false;
        return results;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async onUpdateSearchCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;

        delete this.result.endAt;
        delete this.result.startAt;

        this.tableResults = await this.keyword({
          ...this.result,
          page: this.currentPage,
        });

        const files = await this.fetchFile.Get([this.tableResults.items.map((i: any) => i.thumbnail)]);

        this.tableResults.items = (this.tableResults.items || []).map((item: any) => {
          if (item.thumbnail) {
            item.thumbnail = files.find((f: any) => f.id === item.thumbnail);
          }

          return item;
        });

        this.tableResults.items = groupNewsByContentDate(this.tableResults?.items);

        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    keyword(results: typeGetQuery) {
      const payload = {
        ...results,
      };

      return this.fetchNews.List(payload);
    },
  },
});
