<script lang="ts" setup>
import sha1 from "crypto-js/sha1";
import { computed } from "vue";
import { useRoute, useRouter, type RouteRecordRaw } from "vue-router";
import { useI18n } from "vue-i18n";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { Capacitor } from "@capacitor/core";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useAuthStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { getProjectType } from "@/routers";
import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";
import { outlineIcons, solidIcons } from "@/configs/icons/icon";

const sharedStore = useSharedStore();
const authStore = useAuthStore();
const router = useRouter();
const currentRoute = useRoute()
const { t } = useI18n();

const permission = computed(() => getProjectType());

const switchGDCCApp = () => {
  const secret = process.env.VITE_GDCC_APP_SSO_SECRET || "emobile7";
  const [address, domain] = (authStore.user?.email || "").split("@");
  const loginid = address?.length && domain?.length ? `${encodeURIComponent(address)}@${domain}` : "";
  const stamp = new Date().getTime();
  const time = stamp.toString();
  const server = process.env.VITE_GDCC_APP_SSO_SERVER || "https://appuat.gdccspace.com";
  const token = sha1(`${secret}${authStore.user?.email || ""}${time}`).toString();

  const url = `emobile10://open?identity=token&loginid=${loginid}&server=${server}&timestamp=${time}&loginTokenFromThird=${token}`;

  InAppBrowser.create(url, "_system");
};

const logout = async () => {
  await authStore.logout();
  const name = [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission.value) ? "LoginPoliceCommandCenterView" : "LoginView";
  router.push({ name });
};

const isMenuActive = (route: RouteRecordRaw) => {
  const activeMenu = (route?.meta?.activeMenu || []) as string[]
  return currentRoute.name && activeMenu.includes(currentRoute.name as string)
}

const isActive = (route: RouteRecordRaw): boolean => {
  if (!route || !currentRoute.matched.length) {
    return false
  }

  return route.name === currentRoute.matched[0].name
}

</script>

<template>
  <div class="space-y-1" v-for="route in router.options.routes" :key="route.name">
    <button
      type="button"
      v-if="route?.meta?.isMenu && route?.meta?.isDropdown && permission === route?.meta?.getProjectType"
      :to="route?.path"
      :active="isActive(route)"
      @click.stop="
        () => {
          if (sharedStore.submenuOpen !== String(route.name)) {
            sharedStore.setSubmenuOpen(route.name);
          } else {
            sharedStore.setSubmenuOpen(null);
          }
        }
      "
      class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
      :class="{
        'text-white bg-ttp-error hover:bg-ttp-error focus:bg-ttp-error-20': isActive(route) && [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
        'text-ttp-default bg-ttp-primary hover:bg-ttp-primary focus:bg-ttp-primary-20': isActive(route) && [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
      }"
    >
      <span v-if="route?.meta?.isIcon" v-html="route?.meta?.icon" class="flex-none flex items-center" />
      <span v-if="route?.meta?.i18n" class="py-2 grow text-left ttp-text-xs">{{ t((route?.meta as any).i18n) }}</span>
      <span
        v-if="route.children?.length"
        class="flex-none opacity-75"
        :class="{
          'rotate-180': sharedStore.submenuOpen == String(route.name),
        }"
      >
        <svg class="hi-mini hi-chevron-down inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>
    <div
      v-if="route?.meta?.isMenu && !route?.meta?.isDropdown && permission === route?.meta?.getProjectType"
      :to="route?.path"
      :active="isActive(route)"
      @click.stop="
        () => {
          if (sharedStore.submenuOpen !== String(route.name)) {
            sharedStore.setSubmenuOpen(route.name);
          } else {
            sharedStore.setSubmenuOpen(null);
          }
        }
      "
      class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
      :class="{
        'text-white': [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
        'text-ttp-default': [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
      }"
    >
      <span v-if="route?.meta?.isIcon" v-html="route?.meta?.icon" class="flex-none flex items-center" />
      <span v-if="route?.meta?.i18n" class="py-2 grow text-left ttp-text-xs">
        {{ t((route?.meta as any).i18n) }}
      </span>
      <span
        v-if="route.children?.length"
        class="flex-none opacity-75"
        :class="{
          'rotate-180': sharedStore.submenuOpen == String(route.name),
        }"
      />
    </div>
    <Transition
      v-if="route?.meta?.isMenu && route?.meta?.isDropdown && permission === route?.meta?.getProjectType"
      enter-active-class="transition ease-out duration-100"
      enter-from-class="-translate-y-5 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="-translate-y-5 opacity-0"
      v-for="subRoute in route.children"
      :key="subRoute.name"
    >
      <router-link
        v-if="subRoute?.meta?.isMenu"
        :to="subRoute.path ? `/${route.path}${subRoute.path}` : route.path"
        @click="
          () => {
            sharedStore.mobileSidebarOpen = !sharedStore.mobileSidebarOpen;
          }
        "
      >
        <div v-if="sharedStore.submenuOpen == String(route.name)" class="relative z-0 ml-7 mt-1">
          <button
            type="button"
            class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
            :class="{
              'text-ttp-default text-white bg-ttp-error hover:bg-ttp-error focus:bg-ttp-error-20': subRoute?.name === route.name && [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
              'text-ttp-default text-white hover:bg-ttp-primary': !(subRoute?.name === route.name) && [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
              'text-white bg-ttp-primary hover:bg-ttp-primary focus:bg-ttp-primary-20': subRoute?.name === route.name && [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
            }"
          >
            <span v-if="subRoute?.meta?.isMenu && route?.meta?.isIcon" v-html="subRoute?.meta?.icon" class="flex-none flex items-center" />
            <span v-if="subRoute?.meta?.isMenu && (subRoute?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs"> {{ t((subRoute?.meta as any).i18n) }}</span>
          </button>
        </div>
      </router-link>
    </Transition>
    <Transition v-if="route?.meta?.isMenu && !route?.meta?.isDropdown && permission === route?.meta?.getProjectType" enter-active-class="transition ease-out duration-100" enter-from-class="-translate-y-5 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition ease-in duration-100" leave-from-class="translate-y-0 opacity-100" leave-to-class="-translate-y-5 opacity-0" v-for="subRoute in route.children" :key="subRoute.name">
      <router-link
        v-if="subRoute?.meta?.isMenu"
        :to="`${route.path}${subRoute.path ? '/' + subRoute.path : ''}`"
        @click="
          () => {
            sharedStore.mobileSidebarOpen = !sharedStore.mobileSidebarOpen;
          }
        "
      >
        <div class="relative z-0 mt-1">
          <button
            type="button"
            class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
            :class="{
              'text-ttp-default text-white bg-ttp-error hover:bg-ttp-error focus:bg-ttp-error-20': isMenuActive(subRoute) && [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
              'text-ttp-default text-white hover:bg-ttp-primary': !isMenuActive(subRoute) && [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
              'text-white bg-ttp-primary hover:bg-ttp-primary focus:bg-ttp-primary-20': isMenuActive(subRoute) && [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
              'text-ttp-default hover:text-white focus:text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': !isMenuActive(subRoute) && [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
            }"
          >
            <span v-if="subRoute?.meta?.isMenu && subRoute?.meta?.isIcon" v-html="subRoute?.meta?.icon" class="flex-none flex items-center" />
            <span v-if="subRoute?.meta?.isMenu && (subRoute?.meta as any).i18n" class="py-2 grow text-left ttp-text-xs"> {{ t((subRoute?.meta as any).i18n) }}</span>
          </button>
        </div>
      </router-link>
    </Transition>
  </div>
  <div
    v-if="Capacitor.isNativePlatform()"
    class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
    :class="{
      'text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
      'text-ttp-default hover:text-white focus:text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': [ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
    }"
  >
    <span class="py-2 grow text-left ttp-text-xs">{{ t("layout.menu_head.SupportTitle") }}</span>
    <span class="flex-none opacity-75"> </span>
  </div>
  <a
    v-if="[ENUM_APP_CATEGORY_NAME.AGENT].includes(permission)"
    class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
    :class="{
      'text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
      'text-ttp-default hover:text-white focus:text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': ![ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
    }"
    href="https://meeting.ntconference.com/"
    target="_blank"
  >
    <span class="flex-none flex items-center">
      <component :is="outlineIcons.UserGroupIcon" class="inline-block ttp-icon-inside-box-03" />
    </span>
    <span class="py-2 grow text-left ttp-text-xs">NT Conference</span>
  </a>
  <button
    v-if="Capacitor.isNativePlatform() && [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission)"
    @click="switchGDCCApp"
    class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
    :class="{
      'text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
      'text-ttp-default hover:text-white focus:text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': ![ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
    }"
  >
    <span class="flex-none flex items-center">
      <component :is="outlineIcons.BriefcaseIcon" class="inline-block ttp-icon-inside-box-03" />
    </span>
    <span class="py-2 grow text-left ttp-text-xs">GDCC Space</span>
  </button>
  <button
    v-if="[ENUM_APP_CATEGORY_NAME.AGENT].includes(permission)"
    @click="logout"
    class="w-full group flex items-center space-x-2 px-2.5 ttp-text-xs rounded-md"
    :class="{
      'text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': [ENUM_APP_CATEGORY_NAME.AGENT].includes(permission),
      'text-ttp-default hover:text-white focus:text-white hover:bg-ttp-primary focus:bg-ttp-primary-20': ![ENUM_APP_CATEGORY_NAME.TOURIST].includes(permission),
    }"
  >
    <span class="flex-none flex items-center">
      <component :is="solidIcons.LockClosedIcon" class="inline-block ttp-icon-inside-box-03" />
    </span>
    <span class="py-2 grow text-left ttp-text-xs">{{ t("layout.menu.LogoutView") }}</span>
  </button>
</template>
