<template>
  <div v-show="callStatus !== CALL_STATUS.FREE && display" class="voice-dialog">
    <div class="flex flex-col items-center justify-center gap-2 w-full h-full px-10 bg-image">
      <audio ref="localAudio" id="localAudio" autoplay />
      <audio ref="remoteAudio" id="remoteAudio" autoplay />

      <h1 v-if="false" class="text-black text-center ttp-text-xl font-bold">
        {{ $t("page.VoiceCallView.call_ended") }}
      </h1>
      <div class="flex flex-col relative items-center justify-center">
        <IconVoiceCallComponent />
      </div>

      <h1 class="text-black text-center ttp-text-xl font-bold">Thai Tourist Police</h1>
      <div class="text-black text-center" :class="{ 'mb-36': callStatus !== CALL_STATUS.ON_CALL }">
        <template v-if="callStatus === CALL_STATUS.RINGING">Ringing...</template>
        <template v-else-if="callStatus === CALL_STATUS.CONNECTING">Connecting...</template>
        <template v-else-if="callStatus === CALL_STATUS.ON_CALL">{{ timer }}</template>
      </div>
      <div class="flex gap-4 mb-4 relative">
        <button @click="toggleMicrophone" class="flex flex-col gap-2 relative">
          <div class="w-full text-center">
            <component :is="$solidIcons.MicrophoneIcon" class="inline-block ttp-icon-inside-box-02 m-auto" />
          </div>
          <div v-if="isMicrophoneMuted" class="w-full text-center absolute">
            <component :is="$solidIcons.XMarkIcon" class="inline-block ttp-icon-inside-box-01" />
          </div>
          <div class="w-full text-center ttp-text-xs">
            {{ isMicrophoneMuted ? "Unmute Microphone" : "Mute Microphone" }}
          </div>
        </button>
      </div>
      <div class="flex w-full">
        <div class="flex w-full">
          <button @click.prevent="hangup()" class="flex m-auto text-center items-center justify-center rounded-full bg-[#CC2B34] w-28 h-28 drop-shadow-lg">
            <svg class="m-auto" width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0117 27.1143C16.2615 26.9581 21.6152 35.1604 14.1353 35.0626C6.92258 34.9667 4.10897 36.2797 4.23301 27.1413C4.35928 26.1104 2.58494 16.9136 28.144 17.2534C53.7062 17.5933 51.6924 26.7397 51.7906 27.7736C51.6679 36.9357 48.8913 35.5231 41.6786 35.4272C34.1971 35.3277 39.762 27.2705 28.0117 27.1143Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>

      <div class="mt-10 flex content-center w-full">
        <ButtonDefaultComponent :is-visible="true" :title="$t('go_to_chat')" :class-enum-name="ENUM_COLOR.DEFAULT_2" class-tag-name="w-full m-auto" @click="openIncident()" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ENUM_APP_NAME, ENUM_COLOR } from "@/configs/enums/enum";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";
import { io } from "socket.io-client";
import { onMounted, onBeforeUnmount, ref, computed } from "vue";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";
import { useRouter } from "vue-router";
import ButtonDefaultComponent from "../Forms/Button/ButtonDefaultComponent.vue";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { Capacitor, registerPlugin } from "@capacitor/core";

const AudioRoutePlugin = registerPlugin("AudioRoutePlugin") as any;

enum CALL_STATUS {
  FREE,
  RINGING,
  CONNECTING,
  ON_CALL,
}

const router = useRouter();
const sharedStore = useSharedStore();

// temporary solution
const getIce = async (): Promise<RTCIceServer[]> => {
  const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST).get<RTCIceServer[]>("v1/config/ice");
  return data;
};

const socket = io(import.meta.env.VITE_VOICE_SOCKET_URL, {
  path: "/voice",
  secure: true,
  transports: ["websocket", "polling"],
  forceNew: true,
  upgrade: true,
});

const callStatus = ref<CALL_STATUS>(CALL_STATUS.FREE);
const roomId = ref<string | null>(null);
const receiver = ref<string | null>(null);
const incident = ref<typeGetIncident | null>(null);
const localStream = ref<MediaStream | null>(null);
const remoteStream = ref<MediaStream | null>(null);
const localAudio = ref<HTMLAudioElement>();
const remoteAudio = ref<HTMLAudioElement>();
const counter = ref<Number>(0);
const candidateQueue: RTCIceCandidate[] = [];
const display = ref<boolean>(true);

let hasRemote: boolean = false;
let incidentId: string;

let pc: RTCPeerConnection | null;
const counterIntervalId = ref<NodeJS.Timeout | undefined>();

const isMicrophoneMuted = ref<boolean>(false);
const isSpeakerphoneOn = ref<boolean>(true);

const intervalCounter = () => {
  counter.value = counter.value.valueOf() + 1;
};

const timer = computed((): string => {
  const h: number = Math.floor(counter.value.valueOf() / 3600);
  const m: number = Math.floor((counter.value.valueOf() % 3600) / 60);
  const s: number = counter.value.valueOf() % 60;
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")} น.`;
});

const start = async (id: string) => {
  console.log("starting");
  console.log({ id });
  if (id === incidentId) {
    show();
    return;
  }

  // set display as default
  display.value = true;
  callStatus.value = CALL_STATUS.RINGING;
  incidentId = id;

  try {
    localStream.value = await navigator.mediaDevices.getUserMedia({
      audio: {
        noiseSuppression: true,
        echoCancellation: true,
      },
    });
  } catch (e) {
    console.log("mediaDevices.getUserMedia failed", e);
    callStatus.value = CALL_STATUS.FREE;
  }

  socket.emit("create", id, (r: string) => {
    roomId.value = r;
  });

  sharedStore.startCall();
};

const hide = () => {
  display.value = false;
};

const show = async () => {
  display.value = true;
};

const createPeerConnection = (iceServers: RTCIceServer[]) => {
  console.log(`[${new Date().toISOString()}] createPeerConnection`);
  console.log(`[${new Date().toISOString()}] iceServers`, iceServers);
  pc = new RTCPeerConnection({
    iceServers,
    rtcpMuxPolicy: "require",
    iceCandidatePoolSize: 2,
  });
  pc.onicecandidate = async (e: RTCPeerConnectionIceEvent) => {
    console.log("onicecandidate", e);
    if (e.candidate) {
      socket.emit("candidate", {
        candidate: e.candidate,
        sendTo: receiver.value,
      });
    }
  };

  pc.onicecandidateerror = (e: RTCPeerConnectionIceErrorEvent) => {
    console.log(`[${new Date().toISOString()}] onicecandidateerror`, e);
  };

  pc.onicegatheringstatechange = (e: Event) => {
    console.log(`[${new Date().toISOString()}] onicegatheringstatechange`, e, { iceGatheringState: pc?.iceGatheringState });
    if (pc?.iceGatheringState === "complete") {
      callStatus.value = CALL_STATUS.ON_CALL;
      counterIntervalId.value = setInterval(intervalCounter, 1000);
    }
  };

  pc.ontrack = (event: RTCTrackEvent) => {
    const [stream] = event.streams;
    remoteStream.value = stream;

    if (localStream.value && localAudio.value) {
      console.log(`[${new Date().toISOString()}] addLocalStream`, localStream.value);

      localAudio.value.srcObject = localStream.value as MediaStream;
      localAudio.value.volume = 0;
    }

    if (remoteStream.value && remoteAudio.value) {
      console.log(`[${new Date().toISOString()}] onAddRemoteStream`, remoteStream.value);
      remoteAudio.value.srcObject = remoteStream.value as MediaStream;
      remoteAudio.value.volume = 1;

      if (!Capacitor.isNativePlatform()) {
        navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
          const audioDevices = devices.filter(({ kind }) => kind === "audiooutput");
          console.log({ audioDevices });
          // remoteAudio.value?.setSinkId()
        });
      }
    }
    sharedStore.stopCall();
  };
};

const hangup = async () => {
  onHang({
    incidentId: incident.value ? (incident.value.id as string) : undefined,
  });

  // emit
  socket.emit("hangup", { code: receiver.value });
};

const onConnected = () => {
  console.log("connected on voice dialog");
};

const onHang = async ({ incidentId }: { incidentId: string | undefined }) => {
  if (incidentId?.length && incidentId !== incident.value?.id) {
    console.log("still on call");
    return;
  }
  console.log("hangup");

  incident.value = null;

  callStatus.value = CALL_STATUS.FREE;

  // unset local stream
  localStream.value?.getAudioTracks()?.forEach((track) => (track.enabled = false));
  localStream.value?.getTracks()?.forEach((track) => track.stop());
  localStream.value = null;

  // unset remote stream
  if (remoteAudio.value) {
    remoteAudio.value?.pause();
    remoteAudio.value.srcObject = null;
  }

  // unset RTC connector
  pc?.close();
  pc = null;
  hasRemote = false;

  // clean up counter
  if (counterIntervalId.value) {
    clearInterval(counterIntervalId.value);
  }
  counter.value = 0;
  incidentId = undefined;
  console.log({ incidentId });
  sharedStore.endCall();
};

const onReady = async (code: string) => {
  console.log(`[${new Date().toISOString()}] on ready`, code);
  receiver.value = code;
  console.log(`[${new Date().toISOString()}] getting ice`);
  const iceServers = await getIce();
  await createPeerConnection(iceServers);

  if (pc) {
    pc.onnegotiationneeded = async () => {
      const sdp = await pc?.createOffer({ offerToReceiveAudio: true });
      await pc?.setLocalDescription(sdp);

      console.log(`[${new Date().toISOString()}] offering`, code);
      socket.emit("offer", {
        type: "offer",
        description: pc?.localDescription,
        iceServers,
        receiver: receiver.value,
      });
    };

    console.log(`[${new Date().toISOString()}] addLocalTrack`, localStream.value);
    localStream.value?.getAudioTracks().forEach((track) => {
      pc?.addTrack(track, localStream.value as MediaStream);
    });
  }

  callStatus.value = CALL_STATUS.CONNECTING;
};

const onCandidate = async (event: any) => {
  console.log(`[${new Date().toISOString()}] on candidate, ${hasRemote ? "adding" : "queueing"}`);
  candidateQueue.push(event.candidate);

  if (hasRemote) {
    manageCandidates();
  }
};

const manageCandidates = async () => {
  if (candidateQueue.length > 0) {
    const candidate = candidateQueue.shift();
    try {
      console.log(`[${new Date().toISOString()}] adding candidate`, candidate);
      await pc?.addIceCandidate(new RTCIceCandidate(candidate));
    } catch (e) {
      console.log(`[${new Date().toISOString()}] failed to add candidate`, e);
    }

    await manageCandidates();
  }
};

const onAnswer = async (description: RTCSessionDescription) => {
  console.log(`[${new Date().toISOString()}] on answer`, description, pc);
  await pc?.setRemoteDescription(description);

  hasRemote = true;
  await manageCandidates();
};

const openIncident = () => {
  hide();

  router.push({
    name: "ChatManageView",
    params: { id: incidentId },
  });
};

const toggleMicrophone = () => {
  if (localStream.value) {
    const audioTracks = localStream.value.getAudioTracks();
    audioTracks.forEach((track) => {
      track.enabled = !track.enabled;
    });
    isMicrophoneMuted.value = !audioTracks[0].enabled;
  }
};

const switchSpeakerphone = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      if (isSpeakerphoneOn.value) {
        await AudioRoutePlugin.setVolume({ volume: 1 });
      } else {
        await AudioRoutePlugin.setVolume({ volume: 1 });
      }
      isSpeakerphoneOn.value = !isSpeakerphoneOn.value;
    } catch (error) {
      alert("Error switching speakerphone mode:" + error);
    }
  }
};

const autoSwitchMicrophone = () => {
  if (Capacitor.isNativePlatform()) {
    navigator.mediaDevices.addEventListener("devicechange", () => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const hasHeadset = devices.some((device) => device.kind === "audioinput" && device.label.toLowerCase().includes("headset"));
        if (localStream.value) {
          localStream.value.getAudioTracks().forEach((track) => {
            track.enabled = hasHeadset;
          });
        }
      });
    });
  }
};

onMounted(async () => {
  socket.connect();

  socket.on("connect", onConnected);
  socket.on("ready", onReady);
  socket.on("hangup", onHang);
  socket.on("candidate", onCandidate);
  socket.on("answer", onAnswer);

  autoSwitchMicrophone();
});

onBeforeUnmount(() => {
  socket.off("connect");
  socket.off("ready");
  socket.off("hangup");
  socket.off("candidate");
  socket.off("answer");

  socket.disconnect();
});

defineExpose({ start, show, hide, toggleMicrophone, switchSpeakerphone });
</script>
<style scoped>
.voice-dialog {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
}
</style>
