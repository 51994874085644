<script lang="ts">
import type { AllowedTypes } from "@/configs/types/Shared/typeShare";
import { defineComponent, ref, watch, type PropType } from "vue";

const FormInputLargeComponent = defineComponent({
  name: "FormInputLargeComponent",
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isStartIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    componentIcon_2: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    classInputName: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null] as PropType<AllowedTypes>,
      required: false,
      default: null,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
    ref: {
      type: String,
      required: false,
      default: "",
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("updateValue", target.value);
    };
    const handleBlur = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("blur", target.value);
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
      handleBlur,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormInputLargeComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label v-if="isLabel && !isSpecial" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired" class="text-ttp-error">*</span></label>
    <div class="relative">
      <div v-if="isIcon" class="absolute inset-y-0 flex items-center pointer-events-none" :class="isStartIcon ? 'start-2' : 'end-2'">
        <span v-if="htmlIcon" v-html="htmlIcon"></span>
        <component v-if="!$isObjectEmpty(componentIcon)" :is="componentIcon" :class="classIcon" />
      </div>
      <input :el="name" :maxlength="maxLength" :ref="name" :id="name" :type="type" :name="name" :value="value" @input="handleInput" @blur="handleBlur" :placeholder="isLabel ? placeholder : title" :disabled="isDisable" :readonly="isReadonly" autocomplete="off" :class="[{ 'border-ttp-error': isError }, { 'pl-10': isIcon && isStartIcon }, { 'pr-10': isIcon && !isStartIcon }, classInputName]" class="z-0 bg-gray-50 border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-lg text-center rounded-lg disabled:cursor-not-allowed disabled:bg-ttp-new block w-full p-2.5 h-20" />
      <div v-if="isIcon" class="absolute inset-y-0 flex items-center pointer-events-none z-50" :class="!isStartIcon ? 'start-2' : 'end-2'">
        <span v-if="htmlIcon" v-html="htmlIcon"></span>
        <component v-if="!$isObjectEmpty(componentIcon_2)" :is="componentIcon_2" :class="classIcon" />
      </div>
    </div>
    <small v-if="isError && isLabel" class="relative text-ttp-text-error z-0">{{ textError }}</small>
  </div>
</template>
