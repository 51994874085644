import type { typeGetUser } from "@/configs/types/User/typeUser";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";

export class fetchAuth {
  async Get(): Promise<typeGetUser> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).get<typeGetUser>("/v1/me");
    return data;
  }
  async PasswordUpdate(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).patch(`/v1/me:password`, body);
    return data;
  }
  async AddDevice(body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).post(`/v1/me/devices`, body);
    return data;
  }
  async DeleteDevice(id: string, body: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.AGENT).post(`/v1/me/devices/${id}`, body);
    return data;
  }
}
