<template>
  <div class="pt-16 md:pt-24 dark:text-gray-100">
    <div v-if="!total" class="flex flex-col h-full justify-center items-center mt-8">
      <div class="text-center text-gray-500">
        <BookOpenIcon class="inline-block m-auto ttp-icon-inside-box-05" />
        <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
      </div>
    </div>
    <ul v-else class="pb-12 divide-y divide-gray-200 rounded-lg bg-ttp-bg-theme dark:divide-gray-700 dark:bg-gray-900 dark:text-gray-100">
      <li v-for="item in items" :key="item.id" class="grid grid-cols-3 gap-2 p-4 w-full justify-between items-center border-b-2 bg-white">
        <div class="col-span-2">
          <p class="text-indigo-800 ttp-text-base font-semibold text-left mb-2">{{ item.incidentCategory ? contentToTitle(item.incidentCategory.content, "TH") : "-" }}</p>
          <p class="ttp-text-xs font-medium mb-2">หมายเลขรายงาน {{ item.incidentNumber }}</p>
          <p class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left mb-2">
            <UserCircleIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary mr-1" />
            <span class="ttp-text-xs font-medium">{{ item.responsible?.content[0]?.shortName || "-" }}</span>
          </p>
          <p class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left mb-2">
            <ClockIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary mr-1" />
            <span class="ttp-text-xs font-medium">{{ dayjs(item.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
          </p>
          <BadgeDefaultComponent :size="ENUM_SIZE.NORMAL" :title="$formattedStatus($classStatus(item.status))" :classEnumName="$classStatus(item.status)" is-visible is-icon />
        </div>
        <div>
          <ButtonDefaultComponent
            tag="router-link"
            :to="{
              name: 'IncidentDetailView',
              params: { id: item.id },
            }"
            :component-icon="EyeIcon"
            :class-enum-name="ENUM_COLOR.DEFAULT_1"
            :size="ENUM_SIZE.SMALL"
            class-tag-name="w-full"
            class-icon="ttp-icon-inside-box-03 inline-block"
            title="ใบแจ้งเหตุ"
            is-visible
            is-icon
          />
        </div>
      </li>
    </ul>
    <div class="h-12 flex w-full gap-2 justify-between items-center px-4 py-2 text-left fixed bottom-0 bg-white dark:bg-gray-900 border-t border-gray-200/75 dark:border-gray-700">
      <div class="w-full text-gray-500 ttp-text-xs font-semibold">
        ทั้งหมด <span>{{ total }}</span> เคส
      </div>
      <div class="flex justify-end gap-3 items-center w-full text-gray-500 ttp-text-xs font-semibold">
        <p>หน้า {{ currentPage }} จาก {{ totalPage }}</p>
        <div class="text-center dark:text-gray-100">
          <nav class="flex">
            <button @click="currentPage > 1 ? fetch(currentPage - 1) : null" class="inline-flex items-center justify-center gap-2 rounded-l-lg border border-gray-200 bg-white px-4 py-2 font-semibold leading-6 text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:z-1 focus:ring focus:ring-gray-300/25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
              <svg class="hi-mini hi-chevron-left -mx-1.5 inline-block size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
              </svg>
            </button>
            <button @click="currentPage < totalPage ? fetch(currentPage + 1) : null" class="inline-flex items-center justify-center gap-2 rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-semibold leading-6 text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:z-1 focus:ring focus:ring-gray-300/25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
              <svg class="hi-mini hi-chevron-right -mx-1.5 inline-block size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ENUM_COLOR, ENUM_SIZE } from "@/configs/enums/enum";
import { BookOpenIcon } from "@heroicons/vue/24/outline";
import { ClockIcon, EyeIcon, UserCircleIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { contentToTitle, useIncidentStore, type Incident } from "@/stores/PoliceTouristAndAgent/useIncidentStore";
import dayjs from "dayjs";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";

const incidentStore = useIncidentStore();

const loading = ref<boolean>(false);
const currentPage = ref<number>(1);
const totalPage = ref<number>(1);
const total = ref<number>(0);
const items = ref<Incident[]>([]);

const fetch = async (page: number) => {
  loading.value = true;
  try {
    const result = await incidentStore.getIncidents({
      page,
    });
    items.value = result.items;
    total.value = result.total;
    currentPage.value = result.page || 1;
    totalPage.value = result.totalPages || 1;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetch(1);
});
</script>
