<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useNewsSearchStore } from "./stores/useNewsSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import type { $string } from "@/configs/types/Shared/typeShare";
import dayjs from "dayjs";

import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import { useNewsDeleteStore } from "./stores/useNewsDeleteStore";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import PopupDeleteComponent from "@/components/Popups/PopupDeleteComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import { PERMISSION_NEWS } from "@/configs/permission";

export default defineComponent({
  name: "NewsView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      headers: [
        {
          key: "thumbnail",
          label: this.$t("form.thumbnail.label"),
          parser: (v: any) => {
            return this.$convertString(v[this.$findTranslationIndexByLocale(v, "TH")]?.url);
          },
        },
        {
          key: "translations",
          label: this.$t("form.translations.label"),
          parser: (v: any) => {
            return this.$convertString(v[this.$findTranslationIndexByLocale(v, "TH")]?.title);
          },
        },
        {
          key: "contentAt",
          label: this.$t("form.contentAt.label"),
          parser: (v: string) => this.$dayjs(v).format("DD/MM/YYYY HH:mm"),
        },
        {
          key: "publishedAt",
          label: this.$t("form.publishedAt.label"),
          parser: (v: string) => this.$dayjs(v).format("DD/MM/YYYY HH:mm"),
        },
        { key: "published", label: this.$t("form.published.label") },
        { key: "isInternal", label: this.$t("form.isInternal.label") },
        { key: "action", label: "" },
      ],
      date: ref([null, dayjs().endOf("d").toDate()]) as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.newsSearchStore.error.keyword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsSearchStore.isRequired.keyword.is,
                textError: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.newsSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                value: this.newsSearchStore.result.keyword,
                name: this.newsSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsSearchStore.result.keyword = value;
              },
            },
          },
          button: {
            btnCreate: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                title: this.$t("btn.btn_news"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnReset: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_reset"),
                classEnumName: this.$enums.ENUM_COLOR.YELLOW,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.newsSearchStore.result.type = null;
                this.newsSearchStore.result.keyword = null;
                this.resetDate();
              },
            },
            btnEdit: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$outlineIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block text-ttp-primary",
                title: "",
                classEnumName: this.$enums.ENUM_COLOR.NONE,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnDelete: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$outlineIcons.TrashIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block text-ttp-error",
                title: "",
                classEnumName: this.$enums.ENUM_COLOR.NONE,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: (props: { item: { id: string } }) => {
                this.newsDeleteStore.isDelete = !this.newsDeleteStore.isDelete;
                this.newsDeleteStore.result.id = props?.item?.id as string;
              },
            },
          },
          popup: {
            editCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.newsSearchStore.isOpenComplete,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.completed_detail"),
                functionProp: () => {
                  this.newsSearchStore.isOpenComplete = false;
                },
                functionCancelProp: () => {
                  this.newsSearchStore.isOpenComplete = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            deletePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.newsDeleteStore.isDelete,
                textTitle: this.$t("popup.error.delete_title"),
                textDetail: this.$t("popup.error.delete_detail"),
                functionProp: async (e: Event) => {
                  this.newsDeleteStore.isDelete = !this.newsDeleteStore.isDelete;
                  await this.newsDeleteStore.onSubmit(e);
                },
                functionCancelProp: () => {
                  this.newsDeleteStore.isDelete = false;
                },
              } as typeof PopupDeleteComponent.props,
            },
            deleteSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.newsDeleteStore.isSuccess,
                textTitle: this.$t("popup.success.delete_title"),
                textDetail: this.$t("popup.success.delete_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.newsDeleteStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.newsDeleteStore.isSuccess,
                textTitle: this.$t("popup.caution.delete_title"),
                textDetail: this.$t("popup.caution.delete_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.newsDeleteStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
          badge: {
            published: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
            isInternal: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
    hasCreatePermission() {
      return this.$inArray(this.authStore.displayRole, PERMISSION_NEWS)
    }
  },
  methods: {
    async fetch() {
      await this.newsSearchStore.onUpdateCurrentPage(Number(this.$route.query.page) || 1);
    },
    loading() {
      return this.newsSearchStore.loading;
    },
    async reset() {
      await this.newsSearchStore.$reset();
      await this.newsDeleteStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.resetDate();
      await this.fetch();
      await this.$configLightbox();
    },
    resetDate() {
      this.newsSearchStore.result.startAt = dayjs().startOf("d").toISOString();
      this.newsSearchStore.result.endAt = dayjs().endOf("d").toISOString();

      this.date = ref([this.newsSearchStore.result.startAt, this.newsSearchStore.result.endAt]);
    },
    handleDate(modelData: any) {
      this.date.value = modelData;
      if (!this.date?.value?.length) {
        this.newsSearchStore.result.startAt = dayjs().startOf("d").toISOString();
        this.newsSearchStore.result.endAt = dayjs().endOf("d").toISOString();
      } else {
        const [startAt, endAt] = this.date.value;

        if (startAt) this.newsSearchStore.result.startAt = dayjs(startAt).startOf("d").toISOString();
        else this.newsSearchStore.result.startAt = null;
        if (endAt) this.newsSearchStore.result.endAt = dayjs(endAt).endOf("d").toISOString();
        else this.newsSearchStore.result.endAt = null;
      }
      this.date = ref([this.newsSearchStore.result.startAt, this.newsSearchStore.result.endAt]);
    },
    dateRangeFormat(dates: Date[]) {
      const [start, end] = dates;

      const startString = !start || !dayjs(start).isValid() ? "เริ่มต้น" : dayjs(start).startOf("d").format("DD/MM/YYYY");
      const endString = !end || !dayjs(end).isValid() ? "ปัจจุบัน" : dayjs(end).startOf("d").format("DD/MM/YYYY");

      return `${startString} - ${endString}`;
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  created() {
    watch(
      () => this.$route.query.page,
      () => {
        this.fetch();
      }
    );
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const newsDeleteStore = useNewsDeleteStore();
    const newsSearchStore = useNewsSearchStore();
    const authStore = useAuthStore();

    return {
      masterDataStore,
      newsSearchStore,
      newsDeleteStore,
      authStore,
    };
  },
});
</script>

<template>
  <div class="ttp-form">
    <div class="flex items-center justify-end grid grid-cols-6 gap-2">
      <router-link
        v-if="hasCreatePermission"
        :to="{ name: 'NewsManageView' }"
        class="col-end-0 col-span-3 md:col-end-7 md:col-span-2 lg:col-end-7 lg:col-span-1"
      >
        <component classTagName="w-full" :is="defaultFormComponents.form.button.btnCreate.component" v-bind="defaultFormComponents.form.button.btnCreate.props" />
      </router-link>
    </div>
    <form @submit="newsSearchStore.onSubmit">
      <div class="w-full gap-2">
        <div class="ttp-between">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 w-full">
            <VueDatePicker :model-value="date" @update:model-value="handleDate" range :format="dateRangeFormat" class="w-full text-left"></VueDatePicker>
            <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
          </div>
          <div>
            <component :is="defaultFormComponents.form.button.btnReset.component" v-bind="defaultFormComponents.form.button.btnReset.props" @click="defaultFormComponents.form.button.btnReset.click" />
          </div>
          <div>
            <component :is="defaultFormComponents.form.button.btnSearch.component" v-bind="defaultFormComponents.form.button.btnSearch.props" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <TableComponent :headers="headers" :content="newsSearchStore.tableResults?.items" :loading="loading()" counter :counter-label="$t('form.number.label')">
    <template v-slot:body-thumbnail="props">
      <div class="swipe-photo">
        <a v-if="props.value[0]" :href="props.value[0]?.url" target="_blank"><img :src="props.value[0]?.url" class="max-w-[120px]" /></a>
        <template v-else>-</template>
      </div>
    </template>
    <template v-slot:body-published="props">
      <component :is="defaultFormComponents.form.badge.published.component" v-bind="defaultFormComponents.form.badge.published.props" :title="props?.value == true ? 'แสดง' : 'ไม่แสดง'" :classEnumName="props?.value == true ? $enums.ENUM_COLOR.GREEN : $enums.ENUM_COLOR.RED" />
    </template>
    <template v-slot:body-isInternal="props">
      <component :is="defaultFormComponents.form.badge.isInternal.component" v-bind="defaultFormComponents.form.badge.isInternal.props" :title="props?.value == true ? 'แสดง' : 'ไม่แสดง'" :classEnumName="props?.value == true ? $enums.ENUM_COLOR.GREEN : $enums.ENUM_COLOR.RED" />
    </template>
    <template v-slot:body-action="props">
      <router-link
        :to="{
          name: 'NewsManageView',
          params: { id: props?.item?.id },
        }"
      >
        <component :is="defaultFormComponents.form.button.btnEdit.component" v-bind="defaultFormComponents.form.button.btnEdit.props" />
      </router-link>
      <component :is="defaultFormComponents.form.button.btnDelete.component" v-bind="defaultFormComponents.form.button.btnDelete.props" @click="defaultFormComponents.form.button.btnDelete.click(props)" />
    </template>
  </TableComponent>
  <PaginationComponent
    :currentPage="Number($route.query.page) || 1"
    :totalPage="newsSearchStore.totalPage"
    :gotoPage="newsSearchStore.gotoPage"
    :nextPage="newsSearchStore.nextPage"
    :prevPage="newsSearchStore.prevPage"
    :onUpdateCurrentPage="
      () => {
        fetch();
      }
    "
  />

  <component :is="defaultFormComponents.form.popup.editCaution.component" v-bind="defaultFormComponents.form.popup.editCaution.props" />

  <!-- Delete -->
  <component :is="defaultFormComponents.form.popup.deletePopup.component" v-bind="defaultFormComponents.form.popup.deletePopup.props" />
  <component v-if="newsDeleteStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.popup.deleteSuccess.component" v-bind="defaultFormComponents.form.popup.deleteSuccess.props" />
  <component v-else-if="newsDeleteStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.popup.deleteCaution.component" v-bind="defaultFormComponents.form.popup.deleteCaution.props" />
</template>

<style style="scss"></style>
