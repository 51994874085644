<script lang="ts">
import { defineComponent } from "vue";
import { useProfileEditTouristStore } from "./stores/useProfileEditTouristStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import type { $enum, $string } from "@/configs/types/Shared/typeShare";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import type { ENUM_GENDER } from "@/configs/enums/enum";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { useRegisterInformationStore } from "../../Authentication/stores/useRegisterInformationStore";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";

export default defineComponent({
  name: "ProfileEditTouristView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          popup: {
            success: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.profileEditTouristStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.confirm_detail"),
                functionProp: () => {
                  this.profileEditTouristStore.isSuccess = false;
                },
                functionCancelProp: () => {
                  this.profileEditTouristStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
          },
          input: {
            name: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.name.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.profileEditTouristStore.isRequired.name.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.name.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.name.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.name.placeholder),
                value: this.profileEditTouristStore?.result?.name,
                name: this.profileEditTouristStore?.isRequired?.name?.name,
                isKeydownText: true,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.profileEditTouristStore.result.name = value;
              },
            },
            contactNumber: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.contactNumber.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.profileEditTouristStore.isRequired.contactNumber.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.contactNumber.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.contactNumber.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.contactNumber.placeholder),
                value: this.profileEditTouristStore?.result?.contactNumber,
                name: this.profileEditTouristStore.isRequired.contactNumber.name,
                isKeydownNumber: true,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.profileEditTouristStore.result.contactNumber = value;
              },
            },
            email: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.email.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.profileEditTouristStore.isRequired.email.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.email.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.email.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.email.placeholder),
                value: this.profileEditTouristStore?.result?.email,
                name: this.profileEditTouristStore.isRequired.email.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.profileEditTouristStore.result.email = value;
              },
            },
            identificationNumber: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: !!this.registerInformationStore.registerForm["identificationNumber"],
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.identificationNumber.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: !!this.registerInformationStore.registerForm["identificationNumber"] && this.profileEditTouristStore.isRequired.identificationNumber.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.identificationNumber.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.identificationNumber.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.identificationNumber.placeholder),
                value: this.profileEditTouristStore?.result?.identificationNumber,
                name: this.profileEditTouristStore.isRequired.identificationNumber.name,
                isKeydownNumber: true,
                maxLength: 25,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.profileEditTouristStore.result.identificationNumber = value;
              },
            },
          },
          select: {
            nationality: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.nationality.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.profileEditTouristStore.isRequired.nationality.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.nationality.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.nationality.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.nationality.placeholder),
                value: this.profileEditTouristStore?.result?.nationality,
                items: this.masterDataStore.itemNationalities(),
                name: this.profileEditTouristStore.isRequired.nationality.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.profileEditTouristStore.result.nationality = value;
              },
            },
            country: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.country.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.profileEditTouristStore.isRequired.country.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.country.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.country.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.country.placeholder),
                value: this.profileEditTouristStore?.result?.country,
                items: this.masterDataStore.itemNationalities(),
                name: this.profileEditTouristStore.isRequired.country.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.profileEditTouristStore.result.country = value;
              },
            },
            gender: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.profileEditTouristStore.error[this.profileEditTouristStore.isRequired.gender.name] || "").length > 0,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isRequired: this.profileEditTouristStore.isRequired.gender.is,
                textError: this.$t(this.profileEditTouristStore.isRequired.gender.placeholder),
                title: this.$t(this.profileEditTouristStore.isRequired.gender.label),
                placeholder: this.$t(this.profileEditTouristStore.isRequired.gender.placeholder),
                value: this.profileEditTouristStore?.result?.gender,
                items: this.masterDataStore.itemGenders(),
                name: this.profileEditTouristStore.isRequired.gender.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: ENUM_GENDER | $enum) => {
                this.profileEditTouristStore.result.gender = value;
              },
            },
          },
          button: {
            save: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: (this.$route.params.id && this.profileEditTouristStore?.result?.responsibleID === null) || this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: (e: Event) => {
                this.profileEditTouristStore.onSubmit(e);
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.profileEditTouristStore.loading;
    },
    async fetch() {
      this.profileEditTouristStore.loading = true;

      await Promise.all([
        (async () => {
          await this.registerInformationStore.getRegisterForm();
          const fetch = new fetchAuth();
          const user = await fetch.Get();
          this.profileEditTouristStore.result = user;
        })(),
      ]);
      this.profileEditTouristStore.loading = false;
    },
    async reset() {
      await this.profileEditTouristStore.$reset();
      await this.authStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const profileEditTouristStore = useProfileEditTouristStore();
    const registerInformationStore = useRegisterInformationStore();
    const masterDataStore = useMasterDataStore();
    const authStore = useAuthStore();
    return {
      profileEditTouristStore,
      registerInformationStore,
      masterDataStore,
      authStore,
    };
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px] bg-white">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center h-screen ttp-fixed-tourist-mobile-header">
        <div class="flex flex-col w-full max-w-5xl pb-28 overflow-auto">
          <div class="flex gap-2 mx-2 my-5">
            <button
              class="text-black text-left"
              @click="
                () => {
                  $router.push({ name: 'ProfileMenuView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-black font-bold">
              <h1 class="ttp-text-xl">{{ $t("page.ProfileEditTouristView.title") }}</h1>
            </div>
          </div>

          <div class="flex flex-col relative gap-4 w-full px-4 md:px-10 h-full">
            <div class="w-full mt-0 p-4">
              <div>
                <div class="grid grid-cols-12 gap-2 py-4">
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.name.component" v-bind="defaultFormComponents.form.input.name.props" @updateValue="defaultFormComponents.form.input.name.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.nationality.component" v-bind="defaultFormComponents.form.select.nationality.props" @updateValue="defaultFormComponents.form.select.nationality.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.country.component" v-bind="defaultFormComponents.form.select.country.props" @updateValue="defaultFormComponents.form.select.country.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.contactNumber.component" v-bind="defaultFormComponents.form.input.contactNumber.props" @updateValue="defaultFormComponents.form.input.contactNumber.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.email.props" @updateValue="defaultFormComponents.form.input.email.onUpdateValue" />

                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.select.gender.component" v-bind="defaultFormComponents.form.select.gender.props" @updateValue="defaultFormComponents.form.select.gender.onUpdateValue" />
                  <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13'" :is="defaultFormComponents.form.input.identificationNumber.component" v-bind="defaultFormComponents.form.input.identificationNumber.props" @updateValue="defaultFormComponents.form.input.identificationNumber.onUpdateValue" />
                </div>
                <div class="ttp-between border-t-2 pt-4">
                  <div class="flex gap-2 w-full">
                    <component :is="defaultFormComponents.form.button.save.component" v-bind="defaultFormComponents.form.button.save.props" @click="defaultFormComponents.form.button.save.click" classTagName="w-full m-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="profileEditTouristStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.popup.success.component" v-bind="defaultFormComponents.form.popup.success.props" />
</template>

<style style="scss"></style>
