import { ENUM_GENDER } from "@/configs/enums/enum";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { convertToValues, convertToNumber, convertEnumToArray } from "@/configs/helpers/MainHelper";
import type { typeGetRegisterUser } from "@/configs/types/User/typeRegisterUser";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useRegisterInformationStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  name: {
    is: true,
    name: `name`,
    label: `form.name.label`,
    placeholder: `form.name.placeholder`,
    value: null,
  },
  contactNumberCountryCode: {
    is: true,
    name: `name`,
    label: `form.contactNumberCountryCode.label`,
    placeholder: `form.contactNumberCountryCode.placeholder`,
    value: null,
  },
  nationality: {
    is: true,
    name: `name`,
    label: `form.nationality.label`,
    placeholder: `form.nationality.placeholder`,
    value: null,
  },
  country: {
    is: true,
    name: `name`,
    label: `form.country.label`,
    placeholder: `form.country.placeholder`,
    value: null,
  },
  gender: {
    is: true,
    name: `gender`,
    label: `form.gender.label`,
    placeholder: `form.gender.placeholder`,
    value: null,
  },
  identificationType: {
    is: true,
    name: `identificationType`,
    label: `form.identificationType.label`,
    placeholder: `form.identificationType.placeholder`,
    value: null,
  },
  identificationNumber: {
    is: true,
    name: `identificationNumber`,
    label: `form.passport_number.label`,
    placeholder: `form.passport_number.placeholder`,
    value: null,
  },
};

export const useRegisterInformationStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    hiddenFields: {
      identificationNumber: false,
    } as any,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as typeGetRegisterUser,
    registerForm: {} as { [key: string]: boolean },
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      name: isRequired.name.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      nationality: isRequired.nationality.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      country: isRequired.country.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      gender: isRequired.gender.is ? yup.string().nullable().oneOf(convertEnumToArray(ENUM_GENDER)).required() : yup.string().oneOf(convertEnumToArray(ENUM_GENDER)).nullable().notRequired(),
      // identificationNumber: isRequired.identificationNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      identificationNumber: isRequired.identificationNumber.is
        ? yup
            .string()
            .nullable()
            .test((value: any) => {
              const fetch = new fetchAuth();
              return fetch.Hidden().then((results) => (results.identificationNumber ? value : true));
            })
        : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async getRegisterForm() {
      const fetch = new fetchAuth();
      this.registerForm = await fetch.GetRegisterForm();
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
        return await this.update();
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update() {
      return true;
    },
    async getHiddenFields() {
      this.hiddenFields = await this.fetchAuth.Hidden();
    },
  },
});
