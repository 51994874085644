<script lang="ts">
import { defineComponent } from "vue";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";

import { useAuthStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { useHistorySearchStore } from "@/views/PoliceTouristAndAgent/Tourist/History/stores/useHistorySearchStore";
import { useChatFormStore } from "@/views/PoliceTouristAndAgent/Tourist/Chat/stores/useChatFormStore";
import { getLocalStorage, setLocalStorage } from "@/configs/helpers/MainHelper";

import VoiceDialog from "@/components/Tourist/VoiceDialog.vue";

export default defineComponent({
  name: "PageFooterComponent",
  components: {
    VoiceDialog,
  },
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  methods: {
    async fetch() {
      if (this.$route.params.id) {
        const result = await this.historySearchStore.fetchIncident.Get(this.$route.params.id as string);
        this.historySearchStore.result = result;
      }

      this.historySearchStore.loading = false;
    },
    loading() {
      return this.historySearchStore.loading;
    },
    async reset() {
      await this.historySearchStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.getLocationPermission();
    },
    async makeSOSCall() {
      this.sharedStore.permissionResults = [];
      this.sharedStore.isLoadingPermission = true;
      let allowLocation = (await getLocalStorage(this.sharedStore.is_track_location)) === "granted";
      if (!allowLocation) {
        allowLocation = await this.getLocationPermission();
      }

      if (!allowLocation) {
        this.sharedStore.isShowPermission = true;
        this.sharedStore.isLoadingPermission = false;
        return
      }

      try {
        const result = await this.chatFormStore.fetchIncident.CreateIncident({
          type: "SOS",
          information: {
            latitude: this.sharedStore?.position?.coords?.latitude,
            longitude: this.sharedStore?.position?.coords?.longitude,
          },
        });

        (this.$refs as any).voice.start(result.id);

        this.sharedStore.isShowPermission = false;
      } catch (e) {
      } finally {
        this.sharedStore.isLoadingPermission = false;
      }
    },
    async getLocationPermission(): Promise<boolean> {
      try {
        this.sharedStore.position = Capacitor.isNativePlatform() ? await Geolocation.getCurrentPosition() : navigator.geolocation.getCurrentPosition(Promise.resolve, Promise.reject)
      } catch (e) {
        this.sharedStore.position = null

        await setLocalStorage(this.sharedStore.is_track_location, "denied");
        this.$pushUnique(this.sharedStore.permissionResults, "Location");
        return false
      }

      if (Capacitor.isNativePlatform()) {
        try {
          const { value: permission } = await (this.$PermissionsPlugin as any).checkPermissionLocation()

          if (permission == "granted") {
            this.$popUnique(this.sharedStore.permissionResults, "Location");
            return true
          }

          this.$pushUnique(this.sharedStore.permissionResults, "Location");
          return false
        } catch (e) {
          await setLocalStorage(this.sharedStore.is_track_location, "denied");
          this.$pushUnique(this.sharedStore.permissionResults, "Location");

          return false;
        }
      } else {
        await setLocalStorage(this.sharedStore.is_track_location, "granted");
        this.$popUnique(this.sharedStore.permissionResults, "Location");

        return true
      }
    },
  },
  async mounted() {
    await this.setting();
  },
  setup() {
    const sharedStore = useSharedStore();
    const chatFormStore = useChatFormStore();
    const authStore = useAuthStore();
    const historySearchStore = useHistorySearchStore();

    return {
      sharedStore,
      chatFormStore,
      authStore,
      historySearchStore,
    };
  },
});
</script>

<template>
  <footer v-if="$inArray($getProjectType(), [$enums.ENUM_APP_CATEGORY_NAME.TOURIST]) && !$route?.meta?.hideFooter" id="page-footer" class="fixed w-full z-10 bottom-0 flex flex-none items-center lg:hidden shadow-md drop-shadow-md">
    <ul class="flex bg-gray-100 w-full text-center items-end justify-center">
      <li class="w-full py-3">
        <router-link
          :to="{ name: 'HomeView' }"
          :class="{
            'text-ttp-primary': $route.name == 'HomeView',
            'text-[#4E4E4E]': $route.name != 'HomeView',
          }"
        >
          <component v-if="$route.name != 'HomeView'" :is="$outlineIcons.HomeIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <component v-else :is="$solidIcons.HomeIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $route.name == 'HomeView',
            }"
          >
            {{ $t("layout.menu_bottom.home_title") }}
          </div>
        </router-link>
      </li>
      <li
        class="w-full py-3"
        :class="{
          'bg-ttp-primary': $route.name == 'PreChatView',
          'text-[#FFFFFF]': $route.name == 'PreChatView',
          'text-[#4E4E4E]': $route.name != 'PreChatView',
        }"
      >
        <router-link :to="{ name: 'PreChatView' }" class="flex flex-col justify-center items-center">
          <svg class="ttp-icon-inside-box-02" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_117_15553" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="78" height="78">
              <rect width="78" height="78" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_117_15553)">
              <path
              :fill="$route.name == 'PreChatView' ? '#FFFFFF': '#4E4E4E'"
                d="M39 71.5005L38.1875 61.7505H37.375C29.6833 61.7505 23.1562 59.0692 17.7938 53.7067C12.4313 48.3442 9.75 41.8172 9.75 34.1255C9.75 26.4338 12.4313 19.9067 17.7938 14.5442C23.1562 9.18174 29.6833 6.50049 37.375 6.50049C41.2208 6.50049 44.8094 7.2182 48.1406 8.65361C51.4719 10.089 54.3969 12.0661 56.9156 14.5849C59.4344 17.1036 61.4115 20.0286 62.8469 23.3599C64.2823 26.6911 65 30.2797 65 34.1255C65 38.188 64.3365 42.088 63.0094 45.8255C61.6823 49.563 59.8677 53.0297 57.5656 56.2255C55.2635 59.4213 52.5281 62.3192 49.3594 64.9192C46.1906 67.5192 42.7375 69.713 39 71.5005ZM37.2938 51.9192C38.2146 51.9192 39 51.5942 39.65 50.9442C40.3 50.2942 40.625 49.5088 40.625 48.588C40.625 47.6672 40.3 46.8817 39.65 46.2317C39 45.5817 38.2146 45.2567 37.2938 45.2567C36.3729 45.2567 35.5875 45.5817 34.9375 46.2317C34.2875 46.8817 33.9625 47.6672 33.9625 48.588C33.9625 49.5088 34.2875 50.2942 34.9375 50.9442C35.5875 51.5942 36.3729 51.9192 37.2938 51.9192ZM34.9375 41.6005H39.8125C39.8125 39.9755 39.975 38.838 40.3 38.188C40.625 37.538 41.6542 36.3463 43.3875 34.613C44.3625 33.638 45.175 32.5817 45.825 31.4442C46.475 30.3067 46.8 29.088 46.8 27.788C46.8 25.0255 45.8656 22.9536 43.9969 21.5724C42.1281 20.1911 39.9208 19.5005 37.375 19.5005C34.9917 19.5005 32.9875 20.164 31.3625 21.4911C29.7375 22.8182 28.6 24.4297 27.95 26.3255L32.5 28.113C32.7708 27.1922 33.2854 26.2849 34.0437 25.3911C34.8021 24.4974 35.9125 24.0505 37.375 24.0505C38.8375 24.0505 39.9344 24.4567 40.6656 25.2692C41.3969 26.0817 41.7625 26.9755 41.7625 27.9505C41.7625 28.8713 41.4917 29.6974 40.95 30.4286C40.4083 31.1599 39.7583 31.9047 39 32.663C37.1042 34.288 35.9531 35.5744 35.5469 36.5224C35.1406 37.4703 34.9375 39.163 34.9375 41.6005Z"
              />
            </g>
          </svg>
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $route.name == 'PreChatView',
            }"
          >
            {{ $t("layout.menu_bottom.report_title") }}
          </div>
        </router-link>
      </li>
      <li class="w-full py-3">
        <button :style="{ height: '20vmin', ['margin-top']: '-20vmin' }" class="w-full rounded-full flex flex-col justify-center items-center bg-[#FFD1D3] text-[#CC2B34]" @click.prevent="makeSOSCall">
          <svg class="ttp-icon-inside-box-02" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#CC2B34">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
          </svg>
          <div
            class="ttp-text-xs font-medium"
          >
            {{ $t("page.HomeView.sos_btn") }}
          </div>
        </button>
      </li>
      <li class="w-full py-3">
        <router-link
          :to="{ name: 'HistoryPoliceTouristAndAgentView' }"
          :class="{
            'text-ttp-primary': $inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView']),
            'text-[#4E4E4E]': !$inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView']),
          }"
        >
          <component v-if="!$inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView'])" :is="$outlineIcons.ClockIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <component v-else :is="$solidIcons.ClockIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $inArray($route.name, ['HistoryPoliceTouristAndAgentView', 'HistoryDetailPoliceTouristAndAgentView']),
            }"
          >
            {{ $t("layout.menu_bottom.history_title") }}
          </div>
        </router-link>
      </li>
      <li class="w-full py-3">
        <router-link
          :to="{ name: 'SettingMenuView' }"
          :class="{
            'text-ttp-primary': $inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileEmergencyContactView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView']),
            'text-[#4E4E4E]': !$inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileEmergencyContactView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView']),
          }"
        >
          <component v-if="!$inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileEmergencyContactView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView'])" :is="$outlineIcons.UserIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <component v-else :is="$solidIcons.UserIcon" class="inline-block m-auto ttp-icon-inside-box-02" />
          <div
            class="ttp-text-xs font-medium"
            :class="{
              'font-bold': $inArray($route.name, ['SettingContactUsView', 'SettingLanguageView', 'SettingMenuView', 'SettingNotificationView', 'SettingSecurityPrivacyChangePasswordView', 'SettingSecurityPrivacyView', 'ProfileEditTouristView', 'ProfileEmergencyContactView', 'ProfileMenuView', 'ProfileSocialMediaAccountView', 'ProfileUnlinkView']),
            }"
          >
            {{ $t("layout.menu_bottom.profile_title") }}
          </div>
        </router-link>
      </li>
    </ul>
  </footer>
  <VoiceDialog ref="voice" />
</template>
