<script lang="ts">
import { defineComponent } from "vue";

import { useForgotPasswordStore } from "@/views/PoliceCommandCenter/Authentication/stores/useForgotPasswordStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { EnvelopeIcon, LockClosedIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "ForgotPasswordPoliceCommandCenterView",
  components: {
    FormInputDefaultComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
      inputs: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            email: {
              component: FormInputDefaultComponent,
              props: {
                name: "email",
                isSpecial: false,
                isVisible: true,
                // isError: this.formError["email"]?.length > 0,
                isIcon: true,
                // isDisable: this.loading,
                isRequired: true,
                // textError: this.formError["email"],
                componentIcon: this.icons.EnvelopeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                title: this.$t("form.email.label"),
                placeholder: this.$t("form.email.placeholder"),
                // value: this.form.email,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: () => {
                // this.form.email = value.trim();
              },
            },
            newPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                // isError: (this.registerStore.error.newPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                // isDisable: this.loading(),
                // isRequired: this.registerStore.isRequired.newPassword.is,
                // textError: this.$t(this.registerStore.isRequired.newPassword.placeholder),
                title: this.$t("form.newPassword.label"),
                placeholder: this.$t("form.newPassword.placeholder"),
                // value: this.registerStore.result.newPassword,
                // name: this.registerStore.isRequired.newPassword.name,
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: () => {
                // this.registerStore.result.newPassword = value.trim();
              },
            },
            repeatPassword: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                // isError: (this.registerStore.error.repeatPassword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                componentIcon: this.$solidIcons.LockClosedIcon,
                componentIcon_2: this.$solidIcons.EyeSlashIcon,
                componentIcon_3: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 text-ttp-placeholder",
                // isDisable: this.loading(),
                // isRequired: this.registerStore.isRequired.repeatPassword.is,
                // textError: this.$t(this.registerStore.isRequired.repeatPassword.placeholder),
                title: this.$t("form.repeatPassword.label"),
                placeholder: this.$t("form.repeatPassword.placeholder"),
                // value: this.registerStore.result.repeatPassword,
                // name: this.registerStore.isRequired.repeatPassword.name,
                type: "password",
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: () => {
                // this.registerStore.result.repeatPassword = value.trim();
              },
            },
          },
        },
      };
    },
  },
  methods: {
    moveToNextField(value: any, index: number) {
      const nextIndex = index + 1;
      if (nextIndex < this.inputs.length && value.length == 1) {
        (this.$refs as any).inputs[nextIndex].focus();
      }
    },
  },
  setup() {
    const forgotPasswordStore = useForgotPasswordStore();
    return {
      forgotPasswordStore,
      icons: { EnvelopeIcon, LockClosedIcon },
    };
  },
});
</script>

<template>
  <div id="page-container" class="mx-auto flex min-h-screen w-full min-w-[320px] flex-col bg-gray-100 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex min-h-screen w-full max-w-10xl items-center justify-center bg-gray-200/50 p-4">
        <div class="w-full max-w-5xl">
          <div class="flex flex-col overflow-hidden border rounded-lg bg-white shadow-md md:flex-row drop-shadow-md">
            <section class="relative flex items-center justify-center bg-cover md:w-1/2 md:px-10 lg:p-16 bg-ttp-text-primary">
              <div class="relative text-center sm:text-left">
                <img src="@/assets/images/ttp-logo.png" class="ttp-icon-inside-box-05" />
              </div>
            </section>
            <section class="md:w-1/2 p-4 md:p-10 lg:p-14 lg:p-24">
              <div>
                <h1 class="mb-1 ttp-text-xl font-bold">
                  {{ $t("page.ForgotPasswordView.title") }}
                </h1>
                <h2 v-if="forgotPasswordStore.step == forgotPasswordStore.enumStep.STEP_1" class="ttp-text-xs font-medium text-ttp-text-secondary mb-6">
                  {{ $t("page.ForgotPasswordView.detail_step_1") }}
                </h2>
                <h2 v-if="forgotPasswordStore.step == forgotPasswordStore.enumStep.STEP_2" class="ttp-text-xs font-medium text-ttp-text-secondary mb-6">
                  {{ $t("page.ForgotPasswordView.detail_step_2") }}
                </h2>
                <h2 v-if="forgotPasswordStore.step == forgotPasswordStore.enumStep.STEP_3" class="ttp-text-xs font-medium text-ttp-text-secondary mb-6">
                  {{ $t("page.ForgotPasswordView.detail_step_3") }}
                </h2>
              </div>
              <form v-if="forgotPasswordStore.step == forgotPasswordStore.enumStep.STEP_1" class="space-y-6">
                <div class="grid grid-cols-12 gap-2">
                  <component :classTagName="'col-start-1 col-end-13'" :is="defaultFormComponents.form.input.email.component" v-bind="defaultFormComponents.form.input.email.props" @updateValue="defaultFormComponents.form.input.email.onUpdateValue" />
                </div>
                <div class="flex items-center justify-between space-x-2 mb-5">
                  <a
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                        $router.push({ name: 'LoginPoliceCommandCenterView' });
                      }
                    "
                    href="#"
                    class="ttp-text-xs font-medium inline-block text-ttp-primary hover:text-ttp-primary-dark"
                    >{{ $t("btn.btn_back_dashboard") }}</a
                  >
                </div>
                <div>
                  <button
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                        forgotPasswordStore.step = forgotPasswordStore.enumStep.STEP_2;
                      }
                    "
                    type="submit"
                    class="w-full inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-6 py-3 leading-6 border-ttp-primary bg-ttp-primary text-white hover:text-white hover:bg-ttp-primary-light hover:border-ttp-primary-light focus:ring focus:ring-ttp-primary-lighter focus:ring-opacity-50 active:bg-ttp-primary active:border-ttp-primary ttp-text-xs"
                  >
                    <span>{{ $t("btn.btn_change_password") }}</span>
                  </button>
                </div>
              </form>
              <form v-else-if="forgotPasswordStore.step == forgotPasswordStore.enumStep.STEP_2" class="space-y-6">
                <div class="grid grid-cols-6 gap-4">
                  <input v-for="(input, index) in inputs" :key="index" v-model="input.value" type="number" ref="inputs" @input="(e: any)=>{moveToNextField(e.target.value, index)}" maxlength="1" class="text-center ttp-icon-inside-box-02 block border placeholder-ttp-text-secondary leading-6 rounded-lg border-ttp-outline focus:border-ttp-primary-light focus:ring focus:ring-ttp-primary-light focus:ring-opacity-50 w-10 h-10" />
                </div>
                <div class="flex items-center justify-between space-x-2 mb-5">
                  <a
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                        $router.push({ name: 'LoginPoliceCommandCenterView' });
                      }
                    "
                    href="#"
                    class="ttp-text-xs font-medium inline-block text-ttp-primary hover:text-ttp-primary-dark"
                    >{{ $t("btn.btn_back_dashboard") }}</a
                  ><a
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                      }
                    "
                    href="#"
                    class="ttp-text-xs font-medium inline-block text-ttp-primary hover:text-ttp-primary-dark"
                  >
                    <span class="mr-2 text-ttp-text-secondary">{{ $t("btn.btn_not_receive") }}</span>
                    <span>{{ $t("btn.btn_resend_code") }}</span>
                  </a>
                </div>
                <div>
                  <button
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                        forgotPasswordStore.step = forgotPasswordStore.enumStep.STEP_3;
                      }
                    "
                    type="submit"
                    class="w-full inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-6 py-3 leading-6 border-ttp-primary bg-ttp-primary text-white hover:text-white hover:bg-ttp-primary-light hover:border-ttp-primary-light focus:ring focus:ring-ttp-primary-lighter focus:ring-opacity-50 active:bg-ttp-primary active:border-ttp-primary ttp-text-xs"
                  >
                    <span>{{ $t("btn.btn_submit") }}</span>
                  </button>
                </div>
              </form>
              <form v-else-if="forgotPasswordStore.step == forgotPasswordStore.enumStep.STEP_3" class="space-y-6">
                <div class="grid grid-cols-12 gap-2 py-4">
                  <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.newPassword.component" v-bind="defaultFormComponents.form.input.newPassword.props" @updateValue="defaultFormComponents.form.input.newPassword.onUpdateValue" />
                  <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.input.repeatPassword.component" v-bind="defaultFormComponents.form.input.repeatPassword.props" @updateValue="defaultFormComponents.form.input.repeatPassword.onUpdateValue" />
                  <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13 ttp-text-xs">* {{ $t("caution.password_1") }}</small>
                </div>
                <div class="flex items-center justify-between space-x-2 mb-5">
                  <a
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                        $router.push({ name: 'LoginPoliceCommandCenterView' });
                      }
                    "
                    href="#"
                    class="ttp-text-xs font-medium inline-block text-ttp-primary hover:text-ttp-primary-dark"
                    >{{ $t("btn.btn_back_dashboard") }}</a
                  >
                </div>
                <div>
                  <button
                    @click="
                      (e) => {
                        if (e) e.preventDefault();
                        forgotPasswordStore.step = forgotPasswordStore.enumStep.STEP_2;
                      }
                    "
                    type="submit"
                    class="w-full inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-6 py-3 leading-6 border-ttp-primary bg-ttp-primary text-white hover:text-white hover:bg-ttp-primary-light hover:border-ttp-primary-light focus:ring focus:ring-ttp-primary-lighter focus:ring-opacity-50 active:bg-ttp-primary active:border-ttp-primary ttp-text-xs"
                  >
                    <span>{{ $t("btn.btn_change_password") }}</span>
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
