export default {
  layout: {
    appName: `Tourist Police`,
    menu: {
      PreviewView: `Preview`,
      HomeView: `Home`,
      LogoutView: `Logout`,
      NotFoundView: `Not Found`,
      MaintenanceView: `Maintenance`,
      LoginView: `Login`,
      ForgotPasswordView: `Forgot Password`,
      IncidentView: `Incident`,
      NewIncidentView: `New Incident`,
      AcceptedIncidentView: `Accepted Incident`,
      WaitingIncidentView: `Waiting Incident`,
      CurrentIncidentView: `Current Incident`,
      ReportingIncidentView: `Reported Incident`,
      ClosingIncidentView: `Closing Incident`,
      ClosedIncidentView: `Closed Incident`,
      RejectedIncidentView: `Rejected Incident`,
      ConferenceView: `NT Conference`,
      OverviewView: `Overview`,
      DashboardView: `Dashboard`,
      DashboardIncidentTypeView: `Incident Type`,
      ProfileView: `Profile`,
      SettingMenuView: `Profile Setting`,
      ProfileEditView: `Profile Edit`,
      PasswordSettingView: `Password Setting`,
      UserView: `User Management`,
      UserManageView: `User Management`,
      TouristView: `Tourist Management`,
      TouristManageView: `Tourist Management`,
      ChatView: `Chat`,
      IncidentManageView: `Incident Management`,
      IncidentNoticeView: `Incident Notice`,
      IncidentReportView: `Incident Report`,
      HistoryView: `History`,
      HistoryNoticeView: `History Notice`,
      HistoryReportView: `History Report`,
      ReportIncidentView: `Incident Report`,
      VoiceCallView: `Voice Call`,
      OverviewReportView: `Report`,
      NewsView: "News Management",
      NewsManageView: "News",
      PopularPlaceView: "Popular Place",
    },
    menu_head: {
      PreviewTitle: `Preview`,
      AccountTitle: `Account`,
      UserTitle: `Member`,
      TouristTitle: `Tourist`,
      IncidentTitle: `Incident Report`,
      SupportTitle: `Support Program`,
      ProfileTitle: `Profile`,
      HistoryTitle: `Incident Report`,
      DashboardTitle: `Dashboard`,
      NewsTitle: "News",
    },
    menu_bottom: {
      home_title: `Home`,
      report_title: `Chat`,
      history_title: `History`,
      profile_title: `Profile`,
    },
    by: `Powered by`,
    create: `Create`,
    update: `Edit`,
    edit_address: `Edit Address`,
    edit_address_placeholder: `Please Enter Address`,
    please_input: `Please Enter`,
    not_found: `Not Found`,
    not_found_image: `Image Not Found`,
    loading: `Loading`,
    all: `All`,
    case: `Case`,
    non: `Not Specified`,
    non_image: `No, I don't`,
    non_location: `Don't want to share location`,
    select_picture: `Select picture`,
    result: `Result`,
    anonymous: `Anonymous`,
    gender: {
      male: `Male`,
      female: `Female`,
    },
    date: `Date`,
    error_detail: `An error has occurred. Please contact the public relations department.`,
  },
  divider: {
    or_login_with: `or login with`,
  },
  page: {
    AllIncidentView: {
      title_1: `Time for the staff to arrive:`,
      title_2: `Estimated arrival time:`,
      title_3: `Staff arrival time at the incident:`,
      title_4: `Time spent at the incident:`,
      waiting_1: `Staff is on the way`,
      waiting_2: `Please wait a moment...`,
      status_1: `Incident successfully resolved`,
      status_2: `Work completed`,
      status_3: `Report canceled`,
    },
    PermissionView: {
      app_detail: `In order to access help, please enable application access rights at all times as follows.`,
      web_detail: `<hr class="my-2" />
      <h2 class="ttp-text-lg font-medium text-center">Steps to Enable Permissions in Browser</h2>
      <hr class="my-2" />
      <div>1. Open the browser</div>
      <div>2. Click the three dots in the upper right corner to open the menu</div>
      <div>3. Select "Settings"</div>
      <div>4. Scroll down and click "Privacy and security"</div>
      <div>5. Select "Site settings"</div>
      <hr class="my-2" />
      <h3 class="ttp-text-base font-medium">Permissions:</h3>
      <div>- Location: Click "Location" and turn on "Sites can ask for your location"</div>
      <div>- Camera: Click "Camera" and turn on "Sites can ask to use your camera"</div>
      <div>- Microphone: Click "Microphone" and turn on "Sites can ask to use your microphone"</div>
      <div>- Notifications: Click "Notifications" and turn on "Sites can ask to send notifications"</div>
      <div>- Photos: If a site requests access to files, manage it through file access permissions which can be set per site</div>
    `,
    },
    NewsInternalDetailView: {
      not_found_gallery: `Album not found`,
      gallery: `Gallery`,
    },
    NewsDetailView: {
      not_found_gallery: `Album not found`,
    },
    NewsInternalView: {
      gallery: `Gallery`,
    },
    NewsView: {
      title: `News`,
      not_found: `News not found`,
    },
    PreviewView: {
      title: `Preview`,
    },
    HomeView: {
      title: `Home`,
      hello_txt: `Hello`,
      welcome_txt: `Welcome to Thailand.`,
      welcome_detail_txt: `Tourist Police, Your first friends.`,
      incident_status: `Incident Status`,
      popular_places: `Popular Places`,
      news: `News`,
      box_title: `Need help from tourist police`,
      box_detail: `Please click the button below to contact us`,
      sos_title: `Emergency help needed?`,
      sos_detail: `Please the button to emergency call.`,
      sos_btn: `1155`,
      see_more: `See More`,
    },
    LoginView: {
      title: `Login`,
      subtitle: `Welcome to Command Center`,
      agent_subtitle: `Welcome to Agent`,
      lang: `Select Language`,
    },
    ForgotPasswordView: {
      title: `Forgot Password`,
      detail_step_1: `Don't worry, we'll get you back! Your email.`,
      detail_step_2: `Please enter the OTP sent to your email.`,
      detail_step_3: `Set your new password now!`,
      detail_step_verify: `Verify your identity`,
      create: `Create a new password`,
      desc: `Enter your email for the verification process.`,
      email: `We'll send a 6-digit code to your email.`,
      otp: `Code verification OTP`,
      otp_send_to: `Code has been send to`,
      otp_resend: `Send the code again in`,
    },
    ResetPasswordView: {
      title: `Password changed`,
      detail: `Your password has been changed successfully`,
    },
    NotFoundView: {
      status_number: `404`,
      title: `This is awkward...`,
      detail: `It looks like we couldn't find the page you were looking for. It may have been moved or deleted. Sorry about that! Please try searching our web application`,
    },
    MaintenanceView: {
      title: `Improvement in progress`,
      detail: `Hold on, our website is being improved and will be back soon, looking hotter than a freshly toasted bagel. Thank you for your patience. We'll be bringing you something really cool soon!`,
    },
    TouristManageView: {
      record_title: `Contact history (latest)`,
    },
    OverviewView: {
      title_left: `Notification`,
      title_right: `Urgent SOS Notification`,
      tab_left: `New Incident Report`,
      tab_right: `Managed`,
      not_found: `No New Events`,
      tab_new: `New`,
      tab_in_progress: `Progress`,
      tab_successful: `Edit Successful`,
    },
    IncidentManageView: {
      title: `Incident Manage`,
      document_number: `Document Number`,
      receive_date: `Receive Date`,
      information: `Information`,
      history: `Manage History`,
      emergency_contact: `Emergency Contact`,
    },
    IncidentNoticeView: {
      history: `History`,
      number_code: `Number Case`,
    },
    RegisterView: {
      title: "Register",
      desc: "With Email",
      create: "Create an account",
      login: "Do you already have an account?",
    },
    ChatView: {
      title: "Guardian",
      location_title: "Location",
      reply_message: `Reply to message`,
      make_a_call: `Make a call`,
      hang_up: `Hang up`,
      answer_a_call: `Answer the call`,
      response_voice_call: `Response by Voice Call`,
      response_bot: `Response by Guardian (Bot)`,
      response_police: `Response by Police`,
      end_conversation: `End of conversation`,
      detail: `If there is a new incident report please get in touch with us`,
    },
    ChatManageView: {
      title: `Guardian`,
      location_title: `Locations`,
    },
    MessageView: {
      title: `Message`,
    },
    PreChatView: {
      title: `Report Incident`,
      sub_title: `Report Incident`,
      detail: `Report the incident to request assistance from the Thailand Tourist Police. It is not an inform.`,
    },
    VoiceCallView: {
      call_ended: `The call has ended.`,
    },
    HistoryPoliceTouristAndAgentView: {
      title: `Incident History`,
    },
    HistoryDetailPoliceTouristAndAgentView: {
      title: `Incident History`,
      report_no: `Report No.`,
      report_info: `Report Information`,
      not_found_detail: `You don’t have any incident history here.`,
    },
    ReportIncidentView: {
      title: `Report`,
      guardian_chatbot: `Guardian Chatbot`,
      report_incident_with_chatbot: `Report incident with Chatbot`,
      sos_report: `SOS Report`,
      share_location: `Share location right now to tourist police`,
    },
    ProfileMenuView: {
      title: `Profile`,
    },
    ProfileEditTouristView: {
      title: `Edit Profile`,
    },
    ProfileEmergencyContactView: {
      title: `Emergency contact`,
    },
    ProfileSocialMediaAccountView: {
      title: `Social Media Account`,
      link_txt: `Link`,
    },
    ProfileUnlinkView: {
      link_google_txt: `Linked Google account`,
      link_fb_txt: `Linked Facebook account`,
      link_apple_txt: `Linked Apple account`,
    },
    SettingContactUsView: {
      title: `Contact Us`,
    },
    SettingLanguageView: {
      title: `Language`,
    },
    SettingNotificationView: {
      title: `Notification`,
      push_notifications_txt: `Push Notifications`,
      chat_txt: `Chat Notifications`,
    },
    SettingSecurityPrivacyChangePasswordView: {
      title: `Change password`,
    },
    SettingSecurityPrivacyView: {
      title: `Security & Privacy`,
      allow_location: `Allow tracking my location`,
      allow_camera: `Allow to access camera`,
      allow_photo: `Allow to access photo album`,
      allow_mic: `Allow to access microphone`,
    },
    PopularPlaceView: {
      not_found: `No Place`,
    },
    RegisterEmergencyView: {
      title: `Person of notify in case of emergency`,
      detail: `We will use this information to contact you. In the event that we are unable to contact you`,
    },
    RegisterSuccessView: {
      head: `Success`,
      title: `Verification Success!`,
      welcome: `Welcome`,
      detail_1: `We will help you to the best of our ability. Thank you for trusting us to look after you.`,
      detail_2: `Your account is ready to use. You will be redirected to the Home page when you click.`,
    },
  },
  btn: {
    btn_permission: `Privacy settings`,
    btn_readmore: `Read more`,
    btn_skip: `Skip`,
    btn_go_home: `Return to home page`,
    btn_background_check: `Background Check`,
    btn_unlink: `Unconnect`,
    btn_destination_stoped_incident: `Arrived at the destination and stopped the incident`,
    btn_repair_report: `Edit`,
    btn_google_map: `Go to Google Map`,
    btn_reset: `Reset`,
    btn_receieve_report: `Close task`,
    btn_map: `Map`,
    btn_share: `Share`,
    btn_register: `Register`,
    btn_create_user: `Create user`,
    btn_submit: `Submit`,
    btn_confirm: `Confirm`,
    btn_select: `Select file`,
    btn_delete: `Delete this data`,
    btn_delete_file: `Delete file`,
    btn_delete_image: `Delete image`,
    btn_delete_account: `Delete account`,
    btn_send_email: `Send invitation email`,
    btn_cancel: `Cancel`,
    btn_save: `Save`,
    btn_next: `Next`,
    btn_accept: `Accept`,
    btn_confirm_save: `Confirm save`,
    btn_incident: `Incident`,
    btn_record: `Management history`,
    btn_assign: `Assign`,
    btn_report: `Submit report`,
    btn_edit: `Edit`,
    btn_back: `Back`,
    btn_manage: `Manage`,
    btn_export: `Export data`,
    btn_new_case: `Create incident`,
    btn_view_report: `View report`,
    btn_back_dashboard: `Back to dashboard`,
    btn_forgot_password: `Forgot password`,
    btn_sign_in: `Sign in`,
    btn_change_password: `Change password`,
    btn_back_login: `Back to login page`,
    btn_not_receive: `Didn’t receive the code?`,
    btn_resend_code: `Resend code`,
    btn_search: `Search`,
    btn_reject: `Reject`,
    btn_all: `View all`,
    btn_receive: `Accept`,
    btn_view_notification: `View notification`,
    btn_manage_history: `Management history`,
    btn_chat: `Go to chat room`,
    btn_login_with_phone: `Continue with Phone number`,
    btn_fb: `Continue with Facebook`,
    btn_google: `Continue with Google`,
    btn_apple: `Continue with Apple`,
    btn_successful: `Write incident report`,
    btn_edit_completed: `Save operation results`,
    btn_location_completed: `Arrived at the destination and stopped the incident`,
    btn_edit_report: `Submit report`,
    btn_approve_report: `Approve report`,
    btn_cancel_report: `Cancel report`,
    btn_select_default: `Select`,
    btn_non: `Not specified`,
    btn_no: `No`,
    btn_fill_in_manually: `Fill in manually`,
    btn_start_report: `Start report`,
    btn_get_report: `Report urgent incident`,
    btn_logout: `Yes, log out`,
    btn_news: `Create news`,
    btn_submit_work: `Save operation results`,
    btn_alert: `Create alert`,
  },
  popup: {
    cancel: {
      cancel_title: `Cancel`,
      cancel_detail: `Are you sure? To cancel this update?`,
    },
    reject: {
      reject_title: `Confirm report cancellation`,
      reject_detail: `Are you sure? to cancel this report?`,
    },
    error: {
      delete_title: `Confirm deletion`,
      delete_detail: `Are you sure? to delete this data?`,
      delete_file_image_title: `Confirm image deletion`,
      delete_file_image_detail: `Are you sure? to delete this picture?`,
    },
    success: {
      no_criminal: `No criminal record found`,
      title: `Saved successfully`,
      detail: `You can proceed to the next step`,
      history_detail: `You can review the incident reports retrospectively`,
      receive_title: `Received successfully`,
      receive_detail: `You can proceed to the next step`,
      edit_receive_title: `Successfully fixed the responsible person`,
      edit_receive_detail: `The responsible person can proceed to the next step`,
      reject_title: `Report rejected successfully`,
      email_title: `Email edited successfully`,
      email_detail: `You can log in with your new email, please log in again`,
      delete_title: `Data deleted successfully`,
      delete_detail: `This data has been deleted`,
      delete_user_title: `Account deleted successfully`,
      delete_user_detail: `The account has been deleted`,
      delete_image_title: `Image deleted successfully`,
      delete_image_detail: `The image has been deleted`,
      close_title: `Work closed successfully`,
      close_detail: ``,
      edit_title: `Edited successfully`,
      edit_detail: ``,
      send_title: `Report sent successfully`,
      send_detail: ``,
      waiting_title: `Submitted successfully`,
      closed_title: `Work closed successfully`,
      closed_detail: `You can proceed to the next step`,
      confirm_title: `Report approved successfully`,
      confirm_detail: ``,
      receive_suppressed_title: `Incident suppressed successfully`,
      receive_suppressed_detail: `You can proceed to the next step`,
      submit_title: `Received successfully`,
      submit_detail: `You can proceed to the next step`,
      copy_title: `Copied successfully`,
      send_wait_title: `Saved successfully`,
      save_receive_title: `Work saved successfully`,
      tel1155_title: `Notification`,
      tel1155_detail: `This call will be charged. Do you accept or not?`,
    },
    caution: {
      default_title: `Sorry`,
      delete_title: `Sorry`,
      default_detail: `No reports at this time`,
      recipient_detail: `The subject already exists. or information has changed`,
      completed_detail: `Cannot be completed because this item\nhas already been edited`,
      delete_image_detail: `Unable to delete image`,
      delete_detail: `Cannot delete`,
      delete_user_detail: `Cannot delete this account`,
      error_detail: `Encountered an error unable to complete the transaction`,
      duplicate_detail: `Data already exists in the system`,
    },
    textarea: {
      process_title: `Processing results`,
      process_placeholder: `Please enter process results`,
      reject_title: `Reject the results of the operation`,
    },
    logout: {
      title: `Logout`,
      detail: `Are you sure you want to log out?`,
    },
  },
  form: {
    address: {
      label: "Address",
      placeholder: "Please enter address",
    },
    opening_hours: {
      label: "Opening hours",
      placeholder: "Please enter opening hours",
    },
    tourist_types: {
      label: "Tourist types",
      placeholder: "Please enter tourist types",
    },
    types: {
      label: "Types",
      placeholder: "Please enter types",
    },
    map: {
      label: "Map",
      placeholder: "Please enter map",
    },
    latitude: {
      label: "Latitude",
      placeholder: "Please enter latitude",
    },
    longitude: {
      label: "Longitude",
      placeholder: "Please enter longitude",
    },
    area: {
      label: "Area (Meter)",
      placeholder: "Please enter area",
    },
    metadata: {
      label: "How many minutes to the incident site (enter the number in minutes)",
      placeholder: "Please enter how many minutes to the incident site",
    },
    contentAt: {
      label: "News date",
      placeholder: "Please select news date",
    },
    publishedAt: {
      label: "Display date",
      placeholder: "Please select display date",
    },
    thumbnail: {
      label: "Image",
      placeholder: "Please select an image",
    },
    gallery: {
      label: "Photo album",
      placeholder: "Please select an image",
    },
    published: {
      label: "Display status",
      placeholder: "Please select display status",
    },
    isInternal: {
      label: "Internal news status",
      placeholder: "Please select internal news status",
    },
    translations: {
      label: "Thai title",
      placeholder: "Please enter Thai title",
    },
    translations_cn: {
      label: "Chinese article",
      placeholder: "Please enter Chinese article",
    },
    translations_en: {
      label: "English article",
      placeholder: "Please enter English article",
    },
    translations_jp: {
      label: "Japanese article",
      placeholder: "Please enter Japanese article",
    },
    translations_kr: {
      label: "Korean article",
      placeholder: "Please enter Korean article",
    },
    translations_ru: {
      label: "Russian article",
      placeholder: "Please enter Russian article",
    },
    translations_th: {
      label: "Thai article",
      placeholder: "Please enter Thai article",
    },
    channel: {
      label: `Channel`,
      placeholder: `Please select`,
    },
    is_track_location: {
      label: `Allow to access Track Location`,
      placeholder: `Please select`,
    },
    is_camera: {
      label: `Allow to access Camera`,
      placeholder: `Please select`,
    },
    is_photo: {
      label: `Allow to access Photo`,
      placeholder: `Please select`,
    },
    is_microphone: {
      label: `Allow to access Microphone`,
      placeholder: `Please select`,
    },
    is_push_notification: {
      label: `Allow to access Push Notification`,
      placeholder: `Please select`,
    },
    is_chat: {
      label: `Allow to access Chat`,
      placeholder: `Please select`,
    },
    emergency_contact: {
      label: `Emergency contact`,
      placeholder: `Please enter emergency contact`,
    },
    images: {
      label: `Image`,
      placeholder: `Please select an image`,
    },
    region: {
      label: `Region`,
      placeholder: `Please select a region`,
    },
    message: {
      label: `Aa`,
      placeholder: `Aa`,
    },
    numberic: {
      label: ``,
      placeholder: ``,
    },
    location_search: {
      label: `Incident location`,
      placeholder: `Search for incident location`,
    },
    notification_date: {
      label: `Notification date`,
      placeholder: `Please select notification date`,
    },
    case_number: {
      label: `Report number`,
      placeholder: `Please enter report number`,
    },
    friendly_category: {
      label: `Incident type from tourist`,
      placeholder: `-`,
    },
    story_type: {
      label: `Incident type`,
      placeholder: `Please select incident type`,
    },
    province_area: {
      label: `Province area`,
      placeholder: `Please select province area`,
    },
    recipient: {
      label: `Recipient`,
      placeholder: `Please select recipient`,
    },
    passport_number: {
      label: `Passport number`,
      placeholder: `Please enter passport number`,
    },
    number: {
      label: `Number`,
      placeholder: `Please enter number`,
    },
    gender: {
      label: `Gender`,
      placeholder: `Please select gender`,
    },
    nationality: {
      label: `Nationality`,
      placeholder: `Please select nationality`,
    },
    country: {
      label: `Country`,
      placeholder: `Please select country`,
    },
    app_channel: {
      label: `Registration channel`,
      placeholder: `Please select registration channel`,
    },
    notification_channel: {
      label: `Notification channel`,
      placeholder: `Please select notification channel`,
    },
    phone: {
      label: `Phone number`,
      placeholder: `Please enter phone number`,
    },
    informer_phone: {
      label: `Informer phone number`,
      placeholder: `Please enter informer phone number`,
    },
    identification_type: {
      label: `Identification type`,
      placeholder: `Please select identification type`,
    },
    type_code: {
      label: ``,
      placeholder: ``,
    },
    id: {
      label: `ID`,
      placeholder: `Please enter ID`,
    },
    email: {
      label: `Email`,
      placeholder: `Please enter email`,
    },
    nowPassword: {
      label: `Current password`,
      placeholder: `Please enter current password`,
    },
    password: {
      label: `Password`,
      placeholder: `Please enter password`,
    },
    newPassword: {
      label: `New password`,
      placeholder: `Please enter new password`,
    },
    repeatPassword: {
      label: `Confirm new password`,
      placeholder: `Please confirm new password`,
    },
    remember_me: {
      label: `Remember me`,
      placeholder: ``,
    },
    status: {
      label: `Status`,
      placeholder: `Please select status`,
    },
    role: {
      label: `Role`,
      placeholder: `Please select role`,
    },
    division: {
      label: "Division",
      placeholder: "Please select division",
    },
    subdivision: {
      placeholder: "Please select subdivision",
    },
    station: {
      placeholder: "Please select station",
    },
    responsibleArea: {
      label: "Responsible area",
      placeholder: "Please select responsible area",
    },
    keyword: {
      label: `Search...`,
      placeholder: ``,
    },
    image_name: {
      label: `Image`,
      placeholder: `Please enter image name`,
    },
    informer_name: {
      label: `Informer name`,
      placeholder: `Please enter informer name`,
    },
    informer_type: {
      label: `Informer type`,
      placeholder: `Please select informer type`,
    },
    name: {
      label: `Name`,
      placeholder: `Please enter name`,
    },
    title: {
      label: `Title`,
      placeholder: `Please enter title`,
    },
    title_news: {
      label: `Title`,
      placeholder: `Please enter text`,
    },
    title_alert: {
      label: `Title`,
      placeholder: `Please enter text`,
    },
    description: {
      label: `Description`,
      placeholder: `Please enter text`,
    },
    detail: {
      label: `Article`,
      placeholder: `Please enter text`,
    },
    firstName: {
      label: `First name`,
      placeholder: `Please enter first name`,
    },
    lastName: {
      label: `Last name`,
      placeholder: `Please enter last name`,
    },
    idCard: {
      label: `ID card number`,
      placeholder: `Please enter ID card number`,
    },
    isOpenAgent: {
      label: `Enable Agent Platform`,
      placeholder: ``,
    },
    touristDetail: {
      label: `Incident details (from tourist)`,
      placeholder: `Please enter incident details (from tourist)`,
    },
    notificationChannel: {
      label: `Notification channel`,
      placeholder: ``,
    },
    officerDetail: {
      label: `Incident details (for officers)`,
      placeholder: `Please enter details`,
    },
    no: {
      label: `Code`,
      placeholder: `Please enter code`,
    },
    subject: {
      label: `Subject`,
      placeholder: `Please select subject`,
    },
    important: {
      label: `Importance`,
      placeholder: `Please select importance`,
    },
    district: {
      label: `District`,
      placeholder: `Please select district`,
    },
    sub_district: {
      label: `Sub-district`,
      placeholder: `Please select sub-district`,
    },
    location_detail: {
      label: `Location details`,
      placeholder: `Please enter location details`,
    },
    pin_location: {
      label: `Pin location`,
      placeholder: ``,
    },
    submit_story: {
      label: `Submit story`,
      placeholder: ``,
    },
    close_case: {
      label: `Close case`,
      placeholder: ``,
    },
    send_responsibility: {
      label: `Send to responsible area`,
      placeholder: `Please select responsible area`,
    },
    agency: {
      label: `Agency`,
      placeholder: ``,
    },
    police_inspector: {
      label: `Police inspector`,
      placeholder: ``,
    },
    result: {
      label: `Result`,
      placeholder: ``,
    },
    responsible_person: {
      label: `Responsible person`,
      placeholder: `Please select responsible person`,
    },
    updated_date: {
      label: `Last updated`,
      placeholder: ``,
    },
    estimate_time: {
      label: `Estimated time`,
      placeholder: ``,
    },
    subject_user: {
      label: `Subject user`,
      placeholder: ``,
    },
    assign_user: {
      label: `Assign user`,
      placeholder: ``,
    },
    inspector_user: {
      label: `Inspector user`,
      placeholder: ``,
    },
    type: {
      label: `Incident type`,
      placeholder: `Please select incident type`,
    },
    startAt: {
      label: `Start date`,
      placeholder: `Please select start date`,
    },
    endAt: {
      label: `End date`,
      placeholder: `Please select end date`,
    },
    information_user: {
      label: `Informer information`,
      placeholder: `Please select informer information`,
    },
    admin_user: {
      label: `Admin user`,
      placeholder: `Please select admin user`,
    },
    control_user: {
      label: `Control user`,
      placeholder: `Please select control user`,
    },
    responsibleAreaCodes: {
      label: "Responsible area",
      placeholder: "Please select responsible area",
    },
    locales: {
      label: "Language",
      placeholder: "Please select language",
    },
    mobile: {
      label: "Phone number",
      placeholder: "",
    },
  },
  error: {
    password: "Password should be at least 6 characters",
    invalid_credential: "Unable to login. Please try again",
  },
  caution: {
    user_1: `Please note: If the operator system is enabled It is necessary to choose an area of ​​responsibility`,
    password_1: `Your password must be at least 6 characters, including uppercase letters, lowercase letters, and numbers.`,
    picture_1: `Recommendation: Image size 200 x 200 pixels`,
    picture_2: `(Maximum 5 MB) File type: JPG (JPEG), PNG`,
    picture_1_2: `คำแนะนำ: ขนาดรูปภาพ 490 x 385 pixels`,
    picture_2_2: `(สูงสุด 5 MB) ประเภทไฟล์: JPG (JPEG), PNG`,
    delete_title: ``,
    email_1: `We will use this email to verify your account.`,
  },
  role: {
    ADMIN: `Administrator`,
    CENTRAL_OFFICER: `Central Officer`,
    OFFICER: `Officer`,
    REPORTER: `Reporter`,
    AGENT: `Agent`,
  },
  incident_status: {
    waiting: `Waiting`,
    in_progress: `In Progress`,
    canceled: `Completed`,
  },
  lang: {
    th: `ไทย`,
    en: `English`,
    cn: `中国大陆`,
    ru: `Россия`,
    kr: `대한민국`,
    jp: `日本`,
  },
  progress: {
    processing_time: `Processing Time`,
    period_time: `Period Time`,
  },
  go_to_chat: "Go back to chat"
};
