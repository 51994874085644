<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageContentBreadcrumbComponent",
  components: {},
});
</script>

<template>
  <div v-if="$route?.meta?.isHeader" class="space-y-1">
    <h2 class="flex flex-col sm:flex-row sm:items-end sm:space-x-2 ttp-text-lg py-2 lg:mb-0 font-bold">
      <div class="inline-flex items-center space-x-2">
        <span v-html="$route?.meta?.icon" class="flex-none flex items-center"></span>
        <span v-if="$route?.meta?.isSpecial">
          {{ !$route.params.id ? $t("layout.create") + $t(($route?.meta as any).i18n) : $t("layout.update") + $t(($route?.meta as any).i18n) }}
        </span>
        <span v-else>
          {{ $t(($route?.meta as any).i18n) }}
        </span>
      </div>
    </h2>
  </div>
  <nav v-if="$route?.meta?.isHeader" class="rounded px-0 py-2 ttp-text-xs font-normal">
    <ol class="flex items-center">
      <li v-for="(_item, _index) in $route.matched">
        <router-link v-if="!_item.children.length" :to="{ name: _item.name, params: { id: $route.params.id } }">
          <div class="text-ttp-primary">
            {{ $route?.meta?.isSpecial ? (!$route.params.id ? $t("layout.create") + $t((_item?.meta as any).i18n) : $t("layout.update") + $t((_item?.meta as any).i18n)) : $t((_item?.meta as any).i18n) }}
          </div>
        </router-link>
        <span v-if="_item.children.length" class="opacity-50">
          {{ $t((_item?.meta as any).i18n) }}
        </span>
        <span v-if="_index !== $route.matched.length - 1" class="px-2 opacity-25"> <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03" /></span>
      </li>
    </ol>
  </nav>
</template>
