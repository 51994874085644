import { defineStore } from "pinia";
import { fetchMasterData } from "@/configs/fetches/PoliceCommandCenter/fetchMasterData";
import { fetchMasterData as fetchMasterAgentData } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchMasterData";
import { fetchMasterData as fetchMasterTouristData } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchMasterData";
import { contentToSelect, contentToSelectIncident, contentToSelectNationality, contentToSelectCountry, contentToSelectExternalDepartments } from "@/configs/helpers/FormatHelper";
import { ENUM_APP_CATEGORY_NAME, ENUM_CHANNEL, ENUM_FIELD_LANGUAGE, ENUM_FIELD_LOCALE, ENUM_FIELD_ROLE, ENUM_GENDER, ENUM_INCIDENT_STATUS, ENUM_INFORMER_TYPE, ENUM_PRIORITY } from "@/configs/enums/enum";
import type { typeGetLocale } from "@/configs/types/MasterData/typeLocale";
import type { typeGetStation } from "@/configs/types/MasterData/typeStation";
import type { typeGetRole } from "@/configs/types/MasterData/typeRole";
import type { typeGetTitle } from "@/configs/types/MasterData/typeTitle";
import type { typeGetNationality } from "@/configs/types/MasterData/typeNationality";
import type { typeGetIncidentCategory } from "@/configs/types/Incident/typeIncidentCategory";
import type { typeGetIncidentTopic } from "@/configs/types/Incident/typeIncidentTopic";
import type { typeGetInformer } from "@/configs/types/Informer/typeInformer";
import { isCommandCenterApp, transformData } from "@/configs/helpers/MainHelper";
import type { $enum, $number, $string } from "@/configs/types/Shared/typeShare";
import type { typeGetOrganization } from "@/configs/types/MasterData/typeOrganization";

import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import type { typeGetCountry } from "@/configs/types/MasterData/typeCountry";
import { getProjectType } from "@/routers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import type { typeGetIncidentFriendlyCategory } from "@/configs/types/Incident/typeIncidentFriendlyCategory";
import type { typeGetExternalDepartment } from "@/configs/types/MasterData/typeExternalDepartment";

dayjs.extend(utc);
dayjs.extend(timezone);

type State = {
  fetchMasterData: fetchMasterData;
  fetchMasterAgentData: fetchMasterAgentData;
  fetchMasterTouristData: fetchMasterTouristData;
  locales: typeGetLocale[];
  stations: typeGetStation[];
  externalDepartments: typeGetExternalDepartment[];
  station: any;
  roles: typeGetRole[];
  titles: typeGetTitle[];
  nationalities: typeGetNationality[];
  countries: typeGetCountry[];
  incidentFriendlyCategories: typeGetIncidentFriendlyCategory[];
  incidentCategories: typeGetIncidentCategory[];
  incidentTopics: typeGetIncidentTopic[];
  informers: typeGetInformer[];
  provinces: any[];
  districts: any[];
  subDistricts: any[];
  organizations: typeGetOrganization[];
  provinceID: $number;
  districtID: $number;
  subDistrictID: $number;
  categoryCode: $string;
  topicCode: $number;
  loading: boolean;
  loadingProvince: boolean;
  loadingDistrict: boolean;
  loadingSubDistrict: boolean;
  loadingCategoryCode: boolean;
  loadingTopicCode: boolean;
  loadingStations: boolean;
};

const storeName = `useMasterDataStore-${import.meta.env.VITE_APP_VERSION}`;

export const useMasterDataStore = defineStore(storeName, {
  state: (): State => ({
    fetchMasterData: new fetchMasterData(),
    fetchMasterAgentData: new fetchMasterAgentData(),
    fetchMasterTouristData: new fetchMasterTouristData(),
    locales: [],
    stations: [],
    station: "" as string,
    roles: [],
    titles: [],
    nationalities: [],
    countries: [],
    incidentFriendlyCategories: [],
    incidentCategories: [],
    incidentTopics: [],
    informers: [],
    provinces: [],
    districts: [],
    subDistricts: [],
    organizations: [],
    externalDepartments: [],
    provinceID: null,
    districtID: null,
    subDistrictID: null,
    categoryCode: null,
    topicCode: null,
    loading: true,
    loadingProvince: false,
    loadingDistrict: true,
    loadingSubDistrict: true,
    loadingCategoryCode: false,
    loadingTopicCode: true,
    loadingStations: false,
  }),

  actions: {
    lang() {
      return isCommandCenterApp ? useSharedPoliceCommandCenterStore().locale : useSharedPoliceTouristAndAgentStore().locale;
    },
    async listTitle() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.titles = await this.fetchMasterData.listTitle();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.titles = await this.fetchMasterAgentData.listTitle();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.titles = await this.fetchMasterTouristData.listTitle();
          break;
      }
    },
    async listStations(province_id: number = 0, district_id: number = 0) {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.stations = await this.fetchMasterData.listStations(province_id, district_id);
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.stations = await this.fetchMasterAgentData.listStations(province_id, district_id);
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.stations = await this.fetchMasterTouristData.listStations(province_id, district_id);
          break;
      }
    },
    async listLocale() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.locales = await this.fetchMasterData.listLocale();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.locales = await this.fetchMasterAgentData.listLocale();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.locales = await this.fetchMasterTouristData.listLocale();
          break;
      }
    },
    async listRole() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.roles = await this.fetchMasterData.listRole();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.roles = await this.fetchMasterAgentData.listRole();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.roles = await this.fetchMasterTouristData.listRole();
          break;
      }
    },
    async listNationality() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.nationalities = await this.fetchMasterData.listNationality();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.nationalities = await this.fetchMasterAgentData.listNationality();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.nationalities = await this.fetchMasterTouristData.listNationality();
          break;
      }
    },
    async listCountry() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.countries = await this.fetchMasterData.listCountry();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.countries = await this.fetchMasterAgentData.listCountry();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.countries = await this.fetchMasterTouristData.listCountry();
          break;
      }
    },
    async listExternalDepartments(province_id: number = 0) {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.externalDepartments = await this.fetchMasterData.listExternalDepartments(province_id);
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.externalDepartments = await this.fetchMasterAgentData.listExternalDepartments(province_id);
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.externalDepartments = await this.fetchMasterTouristData.listExternalDepartments(province_id);
          break;
      }
    },
    async listIncidentFriendlyCategory() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.incidentFriendlyCategories = await this.fetchMasterData.listIncidentFriendlyCategory();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.incidentFriendlyCategories = await this.fetchMasterAgentData.listIncidentFriendlyCategory();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.incidentFriendlyCategories = await this.fetchMasterTouristData.listIncidentFriendlyCategory();
          break;
      }
    },
    async listIncidentCategory() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.incidentCategories = await this.fetchMasterData.listIncidentCategory();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.incidentCategories = await this.fetchMasterAgentData.listIncidentCategory();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.incidentCategories = await this.fetchMasterTouristData.listIncidentCategory();
          break;
      }
    },
    async listIncidentTopic(categoryCode: string) {
      const foundItem = this.incidentCategories.find((item) => {
        return item?.code?.includes(categoryCode);
      });
      if (foundItem) this.incidentTopics = foundItem.topics;
    },
    async listInformer(params: object) {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.informers = await this.fetchMasterData.listInformer(params);
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.informers = await this.fetchMasterAgentData.listInformer(params);
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.informers = await this.fetchMasterTouristData.listInformer(params);
          break;
      }
    },
    async listProvince() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.provinces = await this.fetchMasterData.listProvince();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.provinces = await this.fetchMasterAgentData.listProvince();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.provinces = await this.fetchMasterTouristData.listProvince();
          break;
      }
    },
    async listDistrict(province_id: number) {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.districts = await this.fetchMasterData.listDistrict(province_id);
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.districts = await this.fetchMasterAgentData.listDistrict(province_id);
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.districts = await this.fetchMasterTouristData.listDistrict(province_id);
          break;
      }
    },
    async listSubDistrict(province_id: number, district_id: number) {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.subDistricts = await this.fetchMasterData.listSubDistrict(province_id, district_id);
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.subDistricts = await this.fetchMasterAgentData.listSubDistrict(province_id, district_id);
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.subDistricts = await this.fetchMasterTouristData.listSubDistrict(province_id, district_id);
          break;
      }
    },
    async listOrganizations() {
      switch (getProjectType()) {
        case ENUM_APP_CATEGORY_NAME.COMMAND:
          this.organizations = await this.fetchMasterData.listOrganizations();
          break;
        case ENUM_APP_CATEGORY_NAME.AGENT:
          this.organizations = await this.fetchMasterAgentData.listOrganizations();
          break;
        case ENUM_APP_CATEGORY_NAME.TOURIST:
          this.organizations = await this.fetchMasterTouristData.listOrganizations();
          break;
      }
    },
    itemTitles(lang: ENUM_FIELD_LOCALE | $enum) {
      switch (lang) {
        case ENUM_FIELD_LOCALE.TH:
          return contentToSelect(this.titles, ENUM_FIELD_LOCALE.TH);
        case ENUM_FIELD_LOCALE.EN:
          return contentToSelect(this.titles, ENUM_FIELD_LOCALE.EN);
      }
    },
    itemStations() {
      return contentToSelect(this.stations, ENUM_FIELD_LOCALE.TH, 'shortName');
    },
    itemExternalDepartments() {
      return contentToSelectExternalDepartments(this.externalDepartments, ENUM_FIELD_LOCALE.TH);
    },
    itemOrganizations(level: number, parentCode: $string = null) {
      if (level == 0) {
        return contentToSelect(this.organizations, ENUM_FIELD_LOCALE.TH).sort((a, b) => a.value?.localeCompare(b.value || "") || 0);
      } else {
        const organizations = this.organizations.filter((o) => o.level === level && (parentCode || "") === o.parentCode);
        return contentToSelect(organizations, ENUM_FIELD_LOCALE.TH).sort((a, b) => a.value?.localeCompare(b.value || "") || 0);
      }
    },
    itemStatus(isReject: boolean) {
      const statusArray = [
        { label: 'ส่งเรื่องไปยังพื้นที่ีรับผิดชอบ', value: ENUM_INCIDENT_STATUS.WAITING },
        { label: 'ส่งเรื่องไปหน่วยงานภายนอก', value: ENUM_INCIDENT_STATUS.FORWARDED },
        { label: 'ปิดเคส', value: ENUM_INCIDENT_STATUS.CLOSED },
      ];

      if (isReject) {
        statusArray.push({
          label: this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ปฎิเสธ" : "Refused",
          value: ENUM_INCIDENT_STATUS.REJECTED,
        });
      }

      return statusArray;
    },
    itemLocales() {
      return this.locales;
    },
    itemRoles() {
      return this.roles.map(({ code: value, title: label }) => ({
        label,
        value,
      }));
    },
    itemNationalities() {
      return contentToSelectNationality(this.nationalities, this.lang());
    },
    itemCountries() {
      return contentToSelectCountry(this.countries, this.lang());
    },
    itemIncidentFriendlyCategories() {
      return contentToSelectIncident(this.incidentFriendlyCategories, this.lang());
    },
    itemIncidentCategories() {
      return contentToSelectIncident(this.incidentCategories, this.lang());
    },
    itemIncidentTopics() {
      return contentToSelectIncident(this.incidentTopics, this.lang());
    },
    itemInformers() {
      return Object.keys(ENUM_INFORMER_TYPE).map((key) => ({
        label: key,
        value: ENUM_INFORMER_TYPE[key as keyof typeof ENUM_INFORMER_TYPE],
      })) as { label: $string; value: $string }[];
    },
    itemGenders() {
      return Object.keys(ENUM_GENDER).map((key) => ({
        label: this.getGenderName(key as any),
        value: ENUM_GENDER[key as keyof typeof ENUM_GENDER],
      })) as { label: $string; value: $string }[];
    },
    itemLanguages() {
      return Object.keys(ENUM_FIELD_LANGUAGE).map((key) => ({
        label: this.getLanguageName(key as any),
        value: ENUM_FIELD_LANGUAGE[key as keyof typeof ENUM_FIELD_LANGUAGE],
      })) as { label: $string; value: $string }[];
    },
    itemInformationPriority() {
      return Object.keys(ENUM_PRIORITY).map((key) => ({
        label: this.getInformationPriorityName(key as any),
        value: ENUM_PRIORITY[key as keyof typeof ENUM_PRIORITY],
      })) as { label: $string; value: $string }[];
    },
    itemChannels() {
      return Object.keys(ENUM_CHANNEL).map((key) => ({
        label: ENUM_CHANNEL[key as keyof typeof ENUM_CHANNEL],
        value: ENUM_CHANNEL[key as keyof typeof ENUM_CHANNEL],
      })) as { label: $string; value: $string }[];
    },
    itemProvinces() {
      return transformData(this.provinces);
    },
    itemDistricts() {
      return transformData(this.districts);
    },
    itemSubDistricts() {
      return transformData(this.subDistricts);
    },
    itemDivisions() {
      return this.itemOrganizations(1);
    },
    itemSubDivisions() {
      return this.itemOrganizations(2, null);
    },
    resetItems() {
      // Clear
      this.incidentTopics = [];
      this.districts = [];
      this.subDistricts = [];
      this.loadingTopicCode = true;
      this.loadingDistrict = true;
      this.loadingSubDistrict = true;

      //
    },
    getOrganization(code: any, type: "shortname" | "fullname") {
      const foundItem = this.organizations.find((item) => item.code === code);
      if (this.lang() == ENUM_FIELD_LANGUAGE.TH) return foundItem ? (type == "shortname" ? foundItem?.content[this.lang() == ENUM_FIELD_LANGUAGE.TH ? 0 : 1].shortName : foundItem?.content[this.lang() == ENUM_FIELD_LANGUAGE.TH ? 0 : 1].fullName) : null;
      else return foundItem ? (type == "shortname" ? foundItem?.content[1].shortName : foundItem?.content[1].fullName) : null;
    },
    getStation(code: string) {
      for (let i = 0; i < this.stations.length; i++) {
        if (this.stations[i].code === code) {
          for (let j = 0; j < this.stations[i].content.length; j++) {
            if (this.stations[i].content[j].locale === "TH") {
              return this.stations[i].content[j];
            }
          }
          return this.stations[i].content.find((content: any) => content.locale === "EN");
        }
      }

      return null;
    },
    getGenderName(data: ENUM_GENDER) {
      switch (data) {
        case ENUM_GENDER.MALE:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ชาย" : "Male";
        case ENUM_GENDER.FEMALE:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "หญิง" : "Female";
        case ENUM_GENDER.NONE:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ไม่ระบุเพศ" : "Not Specific";
      }
    },
    getLanguageName(data: any) {
      switch (data) {
        case ENUM_FIELD_LANGUAGE.TH:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ไทย" : "Thai";
        case ENUM_FIELD_LANGUAGE.EN:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "อังกฤษ" : "English";
      }
    },
    getInformationPriorityName(data: ENUM_PRIORITY | $enum) {
      switch (data) {
        case ENUM_PRIORITY.CRITICAL:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "มากที่สุด" : "Most";
        case ENUM_PRIORITY.HIGH:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "มาก" : "A Lot";
        case ENUM_PRIORITY.LOW:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "น้อย" : "Little";
        case ENUM_PRIORITY.MEDIUM:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ปานกลาง" : "Moderate";
        default:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ไม่ระบุ" : "Not Specified";
      }
    },
    getRegionByCode(code: any, type: "shortname" | "fullname") {
      const foundItem = this.stations.find((item) => item.code === code);
      if (this.lang() == ENUM_FIELD_LANGUAGE.TH) return foundItem ? (type == "shortname" ? foundItem?.content[this.lang() == ENUM_FIELD_LANGUAGE.TH ? 0 : 1].shortName : foundItem?.content[this.lang() == ENUM_FIELD_LANGUAGE.TH ? 0 : 1].fullName) : null;
      else return foundItem ? (type == "shortname" ? foundItem?.content[1].shortName : foundItem?.content[1].fullName) : null;
    },
    getRoleByCode(code: any) {
      const foundItem = this.roles.find((item) => item.code === code);
      if (!foundItem) {
        return null;
      }

      if (this.lang() == ENUM_FIELD_LANGUAGE.TH) {
        return foundItem.title;
      }

      switch (foundItem.code) {
        case ENUM_FIELD_ROLE.ADMIN:
          return "Administrator";
        case ENUM_FIELD_ROLE.AGENT:
          return "Agent";
        case ENUM_FIELD_ROLE.MANAGER:
          return "บริหาร";
        case ENUM_FIELD_ROLE.INTEPETOR:
          return "ล่ามแปลภาษา";
        case ENUM_FIELD_ROLE.SUPPRESSOR:
          return "งานป้องกันปราบปราม";
        case ENUM_FIELD_ROLE.INVESTIGATOR:
          return "สืบสวน";
        case ENUM_FIELD_ROLE.DUTY_OFFICER:
          return "นายเวร";

        // Legacy
        case ENUM_FIELD_ROLE.CENTRAL_OFFICER:
          return "Central Officer";
        case ENUM_FIELD_ROLE.OFFICER:
          return "Officer";
        case ENUM_FIELD_ROLE.REPORTER:
          return "Reporter";
        default:
          return null;
      }
    },
    getStatusByCode(code: any, isCheck: boolean) {
      switch (code) {
        case ENUM_INCIDENT_STATUS.ACCEPTED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "รับแจ้งเหตุแล้ว" : "The incident has been reported";
        case ENUM_INCIDENT_STATUS.WAITING:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "รอดำเนินการ" : "Waiting";
        case ENUM_INCIDENT_STATUS.FORWARDED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ส่งต่อไปยังหน่วยงานที่เกี่ยวข้อง" : "Forwarded to responsible unit";
        case ENUM_INCIDENT_STATUS.CLOSED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ปิดงาน" : "Closing work";
        case ENUM_INCIDENT_STATUS.COMPLETED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "เข้าระงับเหตุสำเร็จ" : "Completely Fixed";
        case ENUM_INCIDENT_STATUS.IN_PROGRESS:
          if (isCheck) return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "อยู่ระหว่างเดินทางเข้าระงับเหตุ" : "On the way to stop the incident";
          else return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "อยู่ระหว่างดำเนินการเข้าระงับเหตุ" : "Proceed to stop the incident";
        case ENUM_INCIDENT_STATUS.REJECTED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ปฎิเสธ" : "Refuse";
        case ENUM_INCIDENT_STATUS.IN_REVIEW:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ตรวจสอบรายงาน" : "Reviewing Report";
        case ENUM_INCIDENT_STATUS.REPORTED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ส่งรายงาน" : "Send Report";
        case ENUM_INCIDENT_STATUS.NEW:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "รอดำเนินการ" : "Pending";
        default:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "กำลังดำเนินการ" : "In Progress";
      }
    },
    getStatusDetailByCode(code: any) {
      switch (code) {
        case ENUM_INCIDENT_STATUS.ACCEPTED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ดำเนินการ" : "Responsible Person";
        case ENUM_INCIDENT_STATUS.CLOSED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ปิดงาน" : "Closing Person";
        case ENUM_INCIDENT_STATUS.COMPLETED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ดำเนินการ" : "Responsible Person";
        case ENUM_INCIDENT_STATUS.IN_PROGRESS:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ดำเนินการ" : "Responsible Person";
        case ENUM_INCIDENT_STATUS.REJECTED:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ปฎิเสธ" : "Denier";
        case ENUM_INCIDENT_STATUS.REPORTED:
        case ENUM_INCIDENT_STATUS.IN_REVIEW:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ส่งรายงาน" : "Reporter";
        case ENUM_INCIDENT_STATUS.NEW:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "ผู้ดำเนินการ" : "Operator";
        default:
          return this.lang() == ENUM_FIELD_LANGUAGE.TH ? "กำลังดำเนินการ" : "In Progress";
      }
    },
    getIncidentFriendlyCategoryByCodeAndLocale(code: $string, locale: string) {
      const friendlyCategory = this.incidentFriendlyCategories.find((item) => item.code === code);

      if (friendlyCategory) {
        const { title } = friendlyCategory.content.find((c) => c.locale === locale) || friendlyCategory.content[0];
        return title;
      }
      return null;
    },
    getIncidentCategoryByCodeAndLocale(code: $string, locale: string, category?: typeGetIncidentCategory) {
      if (!category) {
        category = this.incidentCategories.find((item) => item.code === code);
      }

      if (category) {
        const { title } = category.content.find((c) => c.locale === locale) || category.content[0];
        return title;
      }
      return null;
    },
    getIncidentTopicByCodeAndLocale(code: $string, locale: string, topic?: typeGetIncidentTopic) {
      if (!topic) {
        topic = this.incidentTopics.find((item) => item.code === code);
      }

      if (topic) {
        const { title } = topic.content.find((t) => t.locale === locale) || topic.content[0];
        return title;
      }
      return null;
    },
    getOrganizationByCode(data: any) {
      if (data) {
        if (data.content && data.content.length > 0) {
          return data.content[this.lang() == ENUM_FIELD_LANGUAGE.TH ? 0 : 0].fullName;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    getAllResponsibleAreaFullNames(data: any) {
      if (data) {
        const fullNames = data.map((item: any) => {
          if (item.content && item.content.length > 0) {
            return `- ${item.content[this.lang() == ENUM_FIELD_LANGUAGE.TH ? 0 : 1].fullName}`;
          } else {
            return "-";
          }
        });
        return fullNames.join("<br/>");
      } else {
        return "-";
      }
    },
    async transformTouristReportNationalities(data: any) {
      const transformed: any = { label: [], value: [] };
      await this.listNationality();

      this.itemNationalities().forEach((country: any) => {
        if (data[country.value]) {
          transformed.label.push(country.label);
          transformed.value.push(data[country.value]);
        }
      });

      return transformed;
    },
    async transformTouristReportGenders(data: any) {
      const transformed: any = { label: [], value: [] };

      await this.itemGenders().forEach((result: any) => {
        if (data[result.value]) {
          transformed.label.push(result.label);
          transformed.value.push(data[result.value]);
        }
      });

      return transformed;
    },
    async transformTouristReportOrganizations(data: any) {
      const transformed: any = { label: [], value: [] };

      await this.itemOrganizations(0, null).forEach((result: any) => {
        if (data[result.value]) {
          transformed.label.push(result.label);
          transformed.value.push(data[result.value]);
        }
      });

      return transformed;
    },
    async transformIncidentReportProvinces(data: any) {
      const totals: Record<string, number> = {};
      await this.listCountry();

      Object.values(data).forEach((countries: any) => {
        Object.entries(countries).forEach(([countryCode, number]: any) => {
          totals[countryCode] = (totals[countryCode] || 0) + number;
        });
      });

      const result: any = { label: [], value: [] };
      await this.itemProvinces().forEach((province: any) => {
        if (totals[province.value]) {
          result.label.push(province.label);
          result.value.push(totals[province.value]);
        }
      });

      return result;
    },
    async transformIncidentReportCountries(data: any) {
      const totals: Record<string, number> = {};
      await this.listCountry();

      Object.values(data).forEach((countries: any) => {
        Object.entries(countries).forEach(([countryCode, number]: any) => {
          totals[countryCode] = (totals[countryCode] || 0) + number;
        });
      });

      const result: any = { label: [], value: [] };
      await this.itemCountries().forEach((nationality: any) => {
        if (totals[nationality.value]) {
          result.label.push(nationality.label);
          result.value.push(totals[nationality.value]);
        }
      });

      return result;
    },
    async transformIncidentReportDate(data: any, format = "day") {
      const report: { labels: string[]; datasets: { label: string; data: number[] }[] } = {
        labels: [],
        datasets: [],
      };

      for (const dayIndex in Object.keys(data)) {
        const day = dayjs(Object.keys(data)[dayIndex])
          .tz("Asia/Bangkok")
          .format(format === "month" ? "MMM YYYY" : "d MMM YYYY");
        if (!report.labels.includes(day)) {
          report.labels.push(day);
        }

        for (const status in data[Object.keys(data)[dayIndex]]) {
          if (!report.datasets.length) {
            report.datasets.push({ label: "จำนวน", data: [] });
          }
          if (!report.datasets[0].data[dayIndex]) {
            report.datasets[0].data[dayIndex] = 0;
          }

          report.datasets[0].data[dayIndex] += data[Object.keys(data)[dayIndex]][status];
        }
      }

      return report;
    },
    async transformIncidentReportStatus(data: any) {
      const report: { labels: string[]; datasets: { label: string; data: number[]; backgroundColor: string }[] } = {
        labels: [],
        datasets: [
          { label: "NEW", data: [], backgroundColor: "#E5E7EB" },
          { label: "ACCEPTED", data: [], backgroundColor: "#2C3483" },
          { label: "WAITING", data: [], backgroundColor: "#2C3483" },
          { label: "IN_PROGRESS", data: [], backgroundColor: "#FB923C" },
          { label: "COMPLETED", data: [], backgroundColor: "#16A34A" },
          { label: "IN_REVIEW", data: [], backgroundColor: "#11e0f7" },
          { label: "REPORTED", data: [], backgroundColor: "#1f2937" },
          { label: "CLOSED", data: [], backgroundColor: "#6B7280" },
          { label: "REJECTED", data: [], backgroundColor: "#DC2626" },
        ],
      };

      for (const dayIndex in Object.keys(data)) {
        const day = dayjs(Object.keys(data)[dayIndex]).tz("Asia/Bangkok").format("d MMM YYYY");
        if (!report.labels.includes(day)) {
          report.labels.push(day);
        }

        for (const status in data[Object.keys(data)[dayIndex]]) {
          let dataset = report.datasets.find((d) => d.label === status);
          if (!dataset) {
            dataset = { label: status, data: [], backgroundColor: "" };
            report.datasets.push(dataset);
          }

          dataset.data[dayIndex] = data[Object.keys(data)[dayIndex]][status];
        }
      }

      return report;
    },
    async transformIncidentReportNationalities(data: any) {
      const totals: Record<string, number> = {};
      await this.listNationality();

      Object.values(data).forEach((countries: any) => {
        Object.entries(countries).forEach(([countryCode, number]: any) => {
          totals[countryCode] = (totals[countryCode] || 0) + number;
        });
      });

      const result: any = { label: [], value: [] };
      await this.itemNationalities().forEach((nationality: any) => {
        if (totals[nationality.value]) {
          result.label.push(nationality.label);
          result.value.push(totals[nationality.value]);
        }
      });

      return result;
    },
    async transformIncidentReportGenders(data: any) {
      const totals: Record<string, number> = {};

      Object.values(data).forEach((countries: any) => {
        Object.entries(countries).forEach(([countryCode, number]: any) => {
          totals[countryCode] = (totals[countryCode] || 0) + number;
        });
      });

      const result: any = { label: [], value: [] };
      await this.itemGenders().forEach((nationality: any) => {
        if (totals[nationality.value]) {
          result.label.push(nationality.label);
          result.value.push(totals[nationality.value]);
        }
      });

      return result;
    },
    async transformIncident12Month1155Results(_data: any) { },
    async transformIncidentReportOrganizations(data: any) {
      const totals: Record<string, number> = {};

      Object.values(data).forEach((countries: any) => {
        Object.entries(countries).forEach(([countryCode, number]: any) => {
          totals[countryCode] = (totals[countryCode] || 0) + number;
        });
      });

      const result: any = { label: [], value: [] };
      await this.itemOrganizations(0, null).forEach((nationality: any) => {
        if (totals[nationality.value]) {
          result.label.push(nationality.label);
          result.value.push(totals[nationality.value]);
        }
      });

      return result;
    },
    async transformIncidentCategoryReport(data: { [key: string]: { [key: string]: number } }) {
      const report: { labels: string[]; datasets: { label: string; data: number[] }[] } = {
        labels: [],
        datasets: [],
      };

      for (const dayIndex in Object.keys(data)) {
        const day = dayjs(Object.keys(data)[dayIndex]).tz("Asia/Bangkok").format("d MMM YYYY");
        if (!report.labels.includes(day)) {
          report.labels.push(day);
        }

        for (const category in data[Object.keys(data)[dayIndex]]) {
          const label = this.itemIncidentCategories().find(({ value }) => value === category)?.label as string;
          let dataset = report.datasets.find((d) => d.label === label);
          if (!dataset) {
            dataset = { label, data: [] };
            report.datasets.push(dataset);
          }

          dataset.data[dayIndex] = data[Object.keys(data)[dayIndex]][category];
        }
      }

      return report;
    },
    async transformCombineReport(data1: any, data2: any) {
      const dateKeys = Object.keys(data1);
      const dataEntries = await Promise.all(
        dateKeys.map(async (date) => {
          const formattedDate = new Date(date)
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, "-");

          return [
            formattedDate,
            {
              nationalities: await this.transformTouristReportNationalities(data1[date]),
              genders: await this.transformTouristReportGenders(data2[date]),
            },
          ];
        })
      );

      return {
        date: dateKeys.map((date) =>
          new Date(date)
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, "-")
        ),
        data: Object.fromEntries(dataEntries),
      };
    },
  },
});
