import { ENUM_FIELD_LOCALE, ENUM_GENDER, ENUM_IDENTIFICATION_TYPE, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { convertToValues, convertToNumber, convertEnumToArray } from "@/configs/helpers/MainHelper";
import type { typeGetInformer } from "@/configs/types/Informer/typeInformer";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useProfileEditTouristStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  name: {
    is: true,
    name: `name`,
    label: `form.name.label`,
    placeholder: `form.name.placeholder`,
    value: null,
  },
  nationality: {
    is: false,
    name: `nationality`,
    label: `form.nationality.label`,
    placeholder: `form.nationality.placeholder`,
    value: null,
  },
  country: {
    is: false,
    name: `country`,
    label: `form.country.label`,
    placeholder: `form.country.placeholder`,
    value: null,
  },
  contactNumber: {
    is: true,
    name: `contactNumber`,
    label: `form.phone.label`,
    placeholder: `form.phone.placeholder`,
    value: null,
  },
  contactNumberCountryCode: {
    is: true,
    name: `name`,
    label: `form.contactNumberCountryCode.label`,
    placeholder: `form.contactNumberCountryCode.placeholder`,
    value: null,
  },
  email: {
    is: true,
    name: `email`,
    label: `form.email.label`,
    placeholder: `form.email.placeholder`,
    value: null,
  },
  gender: {
    is: false,
    name: `gender`,
    label: `form.gender.label`,
    placeholder: `form.gender.placeholder`,
    value: null,
  },
  identificationType: {
    is: true,
    name: `identificationType`,
    label: `form.identificationType.label`,
    placeholder: `form.identificationType.placeholder`,
    value: null,
  },
  identificationNumber: {
    is: false,
    name: `identificationNumber`,
    label: `form.passport_number.label`,
    placeholder: `form.passport_number.placeholder`,
    value: null,
  },
};

export const useProfileEditTouristStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    isRequired,
    result: convertToValues(isRequired) as any,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      name: isRequired.name.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      nationality: isRequired.nationality.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      country: isRequired.country.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      contactNumber: isRequired.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      email: isRequired.email.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      gender: isRequired.gender.is ? yup.string().nullable().oneOf(convertEnumToArray(ENUM_GENDER)).required() : yup.string().oneOf(convertEnumToArray(ENUM_GENDER)).nullable().notRequired(),
      identificationNumber: isRequired.identificationNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        await this.update(this.result);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update(result: typeGetInformer): Promise<typeGetInformer> {
      try {
        this.loading = true;
        const data = await this.fetchAuth.UpdateProfile({
          ...result,
          contactNumberCountryCode: ENUM_FIELD_LOCALE.TH,
          identificationType: ENUM_IDENTIFICATION_TYPE.PASSPORT,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
