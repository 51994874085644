import { defineStore } from "pinia";
import * as yup from "yup";

import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { enums, type ENUM_MUTATION, ENUM_MUTATION_STATUS, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { convertToValues } from "@/configs/helpers/MainHelper";
import { fetchReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

const storeName = `useOverviewSearchStore-${import.meta.env.VITE_APP_VERSION}`;

const notNew = [
  ENUM_INCIDENT_STATUS.ACCEPTED,
  ENUM_INCIDENT_STATUS.WAITING,
  ENUM_INCIDENT_STATUS.FORWARDED,
  ENUM_INCIDENT_STATUS.IN_PROGRESS,
  ENUM_INCIDENT_STATUS.COMPLETED,
  ENUM_INCIDENT_STATUS.IN_REVIEW,
  ENUM_INCIDENT_STATUS.REPORTED,
  ENUM_INCIDENT_STATUS.CLOSED,
  ENUM_INCIDENT_STATUS.REJECTED,
]

const isRequired = {};
export const useOverviewSearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    fetchReport: new fetchReport(),
    tableDefaultResults: null as any,
    tableDefaultAcceptResults: null as any,
    tableSOSResults: null as any,
    tableSOSAcceptResults: null as any,
    reportResult: null as any,
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    isDefaultTab: true,
    isSOSTab: true,
    loadingDefault: true,
    loadingSOS: true,
    loadingReport: true,
    isRequired,
    currentDefaultPage: 1,
    totalDefaultPage: 1,
    currentDefaultAcceptPage: 1,
    totalDefaultAcceptPage: 1,
    currentSOSPage: 1,
    totalSOSPage: 1,
    currentSOSAcceptPage: 1,
    totalSOSAcceptPage: 1,
    actionDefault: null as ENUM_MUTATION | null,
    actionDefaultAccept: null as ENUM_MUTATION | null,
    actionSOS: null as ENUM_MUTATION | null,
    actionSOSAccept: null as ENUM_MUTATION | null,
    actionReport: null as ENUM_MUTATION | null,
    actionDefaultStatus: null as ENUM_MUTATION_STATUS | null,
    actionDefaultAcceptStatus: null as ENUM_MUTATION_STATUS | null,
    actionSOSStatus: null as ENUM_MUTATION_STATUS | null,
    actionSOSAcceptStatus: null as ENUM_MUTATION_STATUS | null,
    actionReportStatus: null as ENUM_MUTATION_STATUS | null,
    result: convertToValues(isRequired) as typeGetQuery,
    validationSchema: yup.object({}),
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async getDefaultPage(page: number, e?: MouseEvent) {
      e?.preventDefault();
      try {
        this.loadingDefault = true;
        this.currentDefaultPage = page;

        this.tableDefaultResults = await this.keyword({
          status: ENUM_INCIDENT_STATUS.NEW,
          type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
          page: this.currentDefaultPage,
        });

        this.totalDefaultPage = this.tableDefaultResults.totalPages;
        this.actionDefaultStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingDefault = false;
      } catch (error: any) {
        this.actionDefaultStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingDefault = false;
      }
    },
    async nextDefaultPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentDefaultPage < this.totalDefaultPage) {
        try {
          this.loadingDefault = true;
          this.currentDefaultPage++;
          this.tableDefaultResults = await this.keyword({
            status: ENUM_INCIDENT_STATUS.NEW,
            type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
            page: this.currentDefaultPage,
          });
          this.totalDefaultPage = this.tableDefaultResults.totalPages;
          this.actionDefaultStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingDefault = false;
        } catch (error: any) {
          this.actionDefaultStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingDefault = false;
        }
      }
    },
    async prevDefaultPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentDefaultPage > 1) {
        try {
          this.loadingDefault = true;
          this.currentDefaultPage--;
          this.tableDefaultResults = await this.keyword({
            status: ENUM_INCIDENT_STATUS.NEW,
            type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
            page: this.currentDefaultPage,
          });
          this.totalDefaultPage = this.tableDefaultResults.totalPages;
          this.actionDefaultStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingDefault = false;
        } catch (error: any) {
          this.actionDefaultStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingDefault = false;
        }
      }
    },
    async onUpdateDefaultCurrentPage(currentPage: number) {
      try {
        this.loadingDefault = true;
        this.currentDefaultPage = currentPage;
        this.tableDefaultResults = await this.keyword({
          status: enums.ENUM_INCIDENT_STATUS.NEW,
          type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
          page: this.currentDefaultPage,
        });
        this.totalDefaultPage = this.tableDefaultResults.totalPages;
        this.actionDefaultStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingDefault = false;
      } catch (error: any) {
        this.actionDefaultStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingDefault = false;
      }
    },

    async getDefaultAcceptPage(page: number, e?: MouseEvent) {
      e?.preventDefault();
      try {
        this.loadingDefault = true;
        this.currentDefaultAcceptPage = page;
        this.tableDefaultAcceptResults = await this.keyword({
          status: notNew.join(','),
          type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
          startAt: dayjs().tz().startOf('day').toISOString(),
          endAt: dayjs().tz().endOf('day').toISOString(),
          page: this.currentDefaultAcceptPage,
        });

        this.totalDefaultAcceptPage = this.tableDefaultAcceptResults.totalPages;
        this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingDefault = false;
      } catch (error: any) {
        this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingDefault = false;
      }
    },
    async nextDefaultAcceptPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentDefaultAcceptPage < this.totalDefaultAcceptPage) {
        try {
          this.loadingDefault = true;
          this.currentDefaultAcceptPage++;
          this.tableDefaultAcceptResults = await this.keyword({
            status: notNew.join(','),
            type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
            startAt: dayjs().tz().startOf('day').toISOString(),
            endAt: dayjs().tz().endOf('day').toISOString(),
            page: this.currentDefaultAcceptPage,
          });
          this.totalDefaultAcceptPage = this.tableDefaultAcceptResults.totalPages;
          this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingDefault = false;
        } catch (error: any) {
          this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingDefault = false;
        }
      }
    },
    async prevDefaultAcceptPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentDefaultAcceptPage > 1) {
        try {
          this.loadingDefault = true;
          this.currentDefaultAcceptPage--;
          this.tableDefaultAcceptResults = await this.keyword({
            status: notNew.join(','),
            type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
            startAt: dayjs().tz().startOf('day').toISOString(),
            endAt: dayjs().tz().endOf('day').toISOString(),
            page: this.currentDefaultAcceptPage,
          });
          this.totalDefaultAcceptPage = this.tableDefaultAcceptResults.totalPages;
          this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingDefault = false;
        } catch (error: any) {
          this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingDefault = false;
        }
      }
    },
    async onUpdateDefaultAcceptCurrentPage(currentPage: number) {
      try {
        this.loadingDefault = true;
        this.currentDefaultAcceptPage = currentPage;
        this.tableDefaultAcceptResults = await this.keyword({
          status: ENUM_INCIDENT_STATUS.ACCEPTED,
          type: enums.ENUM_INCIDENT_TYPE.DEFAULT,
          startAt: dayjs().tz().startOf('day').toISOString(),
          endAt: dayjs().tz().endOf('day').toISOString(),
          page: this.currentDefaultAcceptPage,
        });
        this.totalDefaultAcceptPage = this.tableDefaultAcceptResults.totalPages;
        this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingDefault = false;
      } catch (error: any) {
        this.actionDefaultAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingDefault = false;
      }
    },

    async getSOSPage(page: number, e?: MouseEvent) {
      e?.preventDefault();
      try {
        this.loadingSOS = true;
        this.currentSOSPage = page;
        this.tableSOSResults = await this.keyword({
          status: enums.ENUM_INCIDENT_STATUS.NEW,
          type: enums.ENUM_INCIDENT_TYPE.SOS,
          page: this.currentSOSPage,
        });
        this.totalSOSPage = this.tableSOSResults.totalPages;
        this.actionSOSStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingSOS = false;
      } catch (error: any) {
        this.actionSOSStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingSOS = false;
      }
    },
    async nextSOSPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentSOSPage < this.totalSOSPage) {
        try {
          this.loadingSOS = true;
          this.currentSOSPage++;
          this.tableSOSResults = await this.keyword({
            status: enums.ENUM_INCIDENT_STATUS.NEW,
            type: enums.ENUM_INCIDENT_TYPE.SOS,
            page: this.currentSOSPage,
          });
          this.totalSOSPage = this.tableSOSResults.totalPages;
          this.actionSOSStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingSOS = false;
        } catch (error: any) {
          this.actionSOSStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingSOS = false;
        }
      }
    },
    async prevSOSPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentSOSPage > 1) {
        try {
          this.loadingSOS = true;
          this.currentSOSPage--;
          this.tableSOSResults = await this.keyword({
            status: enums.ENUM_INCIDENT_STATUS.NEW,
            type: enums.ENUM_INCIDENT_TYPE.SOS,
            page: this.currentSOSPage,
          });
          this.totalSOSPage = this.tableSOSResults.totalPages;
          this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingSOS = false;
        } catch (error: any) {
          this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingSOS = false;
        }
      }
    },
    async onUpdateSOSCurrentPage(currentPage: number) {
      try {
        this.loadingSOS = true;
        this.currentSOSPage = currentPage;
        this.tableSOSResults = await this.keyword({
          type: enums.ENUM_INCIDENT_TYPE.SOS,
          status: enums.ENUM_INCIDENT_STATUS.NEW,
          page: this.currentSOSPage,
        });
        this.totalSOSPage = this.tableSOSResults.totalPages;
        this.actionSOSStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingSOS = false;
      } catch (error: any) {
        this.actionSOSStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingSOS = false;
      }
    },

    async getSOSAcceptPage(page: number, e: any) {
      if (e) e.preventDefault();
      try {
        this.loadingSOS = true;
        this.currentSOSAcceptPage = page;

        this.tableSOSAcceptResults = await this.keyword({
          status: notNew.join(','),
          type: enums.ENUM_INCIDENT_TYPE.SOS,
          startAt: dayjs().tz().startOf('day').toISOString(),
          endAt: dayjs().tz().endOf('day').toISOString(),
          page: this.currentSOSAcceptPage,
        });
        this.totalSOSAcceptPage = this.tableSOSAcceptResults.totalPages;
        this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingSOS = false;
      } catch (error: any) {
        this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingSOS = false;
      }
    },
    async nextSOSAcceptPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentSOSAcceptPage < this.totalSOSAcceptPage) {
        try {
          this.loadingSOS = true;
          this.currentSOSAcceptPage++;
          this.tableSOSAcceptResults = await this.keyword({
            status: ENUM_INCIDENT_STATUS.ACCEPTED,
            type: enums.ENUM_INCIDENT_TYPE.SOS,
            startAt: dayjs().tz().startOf('day').toISOString(),
            endAt: dayjs().tz().endOf('day').toISOString(),
            page: this.currentSOSAcceptPage,
          });
          this.totalSOSAcceptPage = this.tableSOSResults.totalPages;
          this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingSOS = false;
        } catch (error: any) {
          this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingSOS = false;
        }
      }
    },
    async prevSOSAcceptPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentSOSAcceptPage > 1) {
        try {
          this.loadingSOS = true;
          this.currentSOSAcceptPage--;
          this.tableSOSAcceptResults = await this.keyword({
            status: ENUM_INCIDENT_STATUS.ACCEPTED,
            type: enums.ENUM_INCIDENT_TYPE.SOS,
            startAt: dayjs().tz().startOf('day').toISOString(),
            endAt: dayjs().tz().endOf('day').toISOString(),
            page: this.currentSOSAcceptPage,
          });
          this.totalSOSAcceptPage = this.tableSOSAcceptResults.totalPages;
          this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.loadingSOS = false;
        } catch (error: any) {
          this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loadingSOS = false;
        }
      }
    },
    async onUpdateSOSAcceptCurrentPage(currentPage: number) {
      try {
        this.loadingSOS = true;
        this.currentSOSAcceptPage = currentPage;
        this.tableSOSAcceptResults = await this.keyword({
          status: ENUM_INCIDENT_STATUS.ACCEPTED,
          type: enums.ENUM_INCIDENT_TYPE.SOS,
          startAt: dayjs().tz().startOf('day').toISOString(),
          endAt: dayjs().tz().endOf('day').toISOString(),
          page: this.currentSOSAcceptPage,
        });
        this.totalSOSAcceptPage = this.tableSOSAcceptResults.totalPages;
        this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingSOS = false;
      } catch (error: any) {
        this.actionSOSAcceptStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingSOS = false;
      }
    },
    async onUpdateReport() {
      try {
        this.loadingReport = true;
        this.reportResult = await this.report();
        this.actionReportStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loadingReport = false;
      } catch (error: any) {
        this.actionReportStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loadingReport = false;
      }
    },
    keyword(results: typeGetQuery) {
      return this.fetchIncident.List({
        ...results,
        size: Number(import.meta.env.VITE_PAGE_SIZE),
      });
    },
    report() {
      return this.fetchReport.SimpleIncidentReport();
    },
  },
});
