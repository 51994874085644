export default {
  layout: {
    appName: `ตำรวจท่องเที่ยว`,
    menu: {
      AlertView: `จัดการแจ้งเตือน`,
      AlertManageView: `แจ้งเตือน`,
      PreviewView: `Preview`,
      HomeView: `หน้าแรก`,
      LogoutView: `ออกจากระบบ`,
      NotFoundView: `ไม่พบหน้า`,
      MaintenanceView: `ซ่อมบำรุง`,
      LoginView: `เข้าสู่ระบบ`,
      ForgotPasswordView: `เปลี่ยนรหัสผ่าน`,
      MyIncidentView: `งานของฉัน`,
      AllIncidentView: `เหตุการณ์ทั้งหมด`,
      IncidentView: `จัดการเหตุการณ์`,
      IncidentDetailView: `จัดการเหตุการณ์`,
      NewIncidentView: `เหตุการณ์ใหม่`,
      AcceptedIncidentView: `รับเรื่อง`,
      ForwardedIncidentView: `ส่งต่อหน่วยงานภายนอก`,
      WaitingIncidentView: `รอดำเนินการ`,
      CurrentIncidentView: `กำลังดำเนินการ`,
      ReportingIncidentView: `รออนุมัติ`,
      ClosingIncidentView: `รอปิดงาน`,
      ClosedIncidentView: `ปิดงาน`,
      RejectedIncidentView: `ยกเลิก`,
      ConferenceView: `NT Conference`,
      OverviewView: `ภาพรวม`,
      VideoWallView: `Video Wall`,
      DashboardView: `แดชบอร์ด`,
      DashboardIncidentTypeView: `ประเภทการแจ้งเหตุ`,
      ProfileView: `บัญชีของฉัน`,
      SettingMenuView: `บัญชีของฉัน`,
      ProfileEditView: `บัญชีผู้ใช้งาน`,
      PasswordSettingView: `ตั้งค่ารหัสผ่าน`,
      UserView: `จัดการผู้ใช้งาน`,
      UserManageView: `บัญชีผู้ใช้งาน`,
      TouristView: `จัดการนักท่องเที่ยว`,
      TouristManageView: `บัญชีนักท่องเที่ยว`,
      ChatView: `ห้องสนทนา`,
      IncidentManageView: `จัดการแจ้งเหตุุ`,
      IncidentNoticeView: `ใบแจ้งเหตุ`,
      IncidentReportView: `รายงาน`,
      HistoryView: `ประวัติ`,
      HistoryNoticeView: `ใบแจ้งเหตุ`,
      HistoryReportView: `แบบรายงานเหตุเบื้องต้น จากเคสที่ได้รับแจ้งในระบบออนไลน์`,
      ReportIncidentView: `รายงาน`,
      VoiceCallView: `สายสนทนา`,
      OverviewReportView: `รีพอร์ท`,
      NewsView: "จัดการข่าวสาร",
      NewsManageView: "ข่าวสาร",
      NewsInternalView: "ข่าวสารองค์กร",
      PopularPlaceView: "สถานที่น่าสนใจ",
    },
    menu_head: {
      PreviewTitle: `ตัวอย่าง`,
      AccountTitle: `แอคเคาท์`,
      UserTitle: `สมาชิก`,
      TouristTitle: `นักท่องเที่ยว`,
      IncidentTitle: `การแจ้งเหตุ`,
      SupportTitle: `โปรแกรมสนับสนุน`,
      ProfileTitle: `บัญชีผู้ใช้งาน`,
      HistoryTitle: `การแจ้งเหตุ`,
      OtherTitle: `อื่นๆ`,
      DashboardTitle: `แดชบอร์ด`,
      NewsTitle: "ข่าวสาร",
      AlertTitle: "การแจ้งเตือน",
      NewsInternalTitle: "ข่าวสารองค์กร",
    },
    menu_bottom: {
      home_title: `หน้าแรก`,
      report_title: `แจ้งเหตุ`,
      history_title: `ประวัติ`,
      profile_title: `โปรไฟล์`,
    },
    by: `ขับเคลื่อนโดย`,
    create: `สร้าง`,
    update: `แก้ไข`,
    edit_address: `แก้ไขที่อยู่`,
    edit_address_placeholder: `กรุณากรอกที่อยู่`,
    please_input: `กรุณาใส่`,
    not_found: `ไม่พบข้อมูล`,
    not_found_image: `ไม่พบรูปภาพ`,
    loading: `กำลังโหลดข้อมูล`,
    all: `ทั้งหมด`,
    case: `เคส`,
    non: `ไม่ระบุ`,
    non_image: `ไม่ ฉันไม่เลือก`,
    non_location: `ไม่ต้องการแชร์ตำแหน่ง`,
    select_picture: `เลือกรูปภาพ`,
    result: `ผลการดำเนินการ`,
    anonymous: `ไม่ประสงค์ออกนาม`,
    gender: {
      male: `ผู้ชาย`,
      female: `ผู้หญิง`,
    },
    date: `วันที่`,
    error_detail: `เกิดข้อผิดพลาดกรุณาติดต่อฝ่ายประชาสัมพันธ์`,
  },
  divider: {
    or_login_with: `or login with`,
  },
  page: {
    AllIncidentView: {
      title_1: "เจ้าหน้าที่ใช้เวลาเดินทางประมาณ:",
      title_2: "เจ้าหน้าที่จะถึงเวลาประมาณ:",
      title_3: "เจ้าหน้าที่ถึงจุดเกิดเหตุเวลา:",
      title_4: "ถึงที่เกิดเหตุแล้วใช้เวลา:",
      waiting_1: "เจ้าหน้าที่กำลังเดินทางมาถึง",
      waiting_2: "กรุณารอสักครู่...",
      status_1: "เข้าระงับเหตุสำเร็จ",
      status_2: "ปิดงาน",
      status_3: "ยกเลิกรายงาน",
    },
    PermissionView: {
      app_detail: `เพื่อให้เข้าถึงความช่วยเหลือรบกวนเปิดสิทธิ์การเข้าถึง Application ไว้ตลอดเวลา มีดังนี้`,
      web_detail: `<hr class="my-2" />
      <h2 class="ttp-text-lg font-medium text-center">ขั้นตอนการเปิด Permission ใน Browser</h2>
      <hr class="my-2" />
      <div>1. เปิด Browser</div>
      <div>2. คลิกที่จุดสามจุดที่มุมขวาบนของหน้าจอเพื่อเปิดเมนู</div>
      <div>3. เลือก "Settings" (การตั้งค่า)</div>
      <div>4. เลื่อนลงมาและคลิกที่ "Privacy and security" (ความเป็นส่วนตัวและความปลอดภัย)</div>
      <div>5. เลือก "Site settings" (การตั้งค่าเว็บไซต์)</div>
      <hr class="my-2" />
      <h3 class="ttp-text-base font-medium">Permission:</h3>
      <div>- Location: คลิก "Location" แล้วเปิด "Sites can ask for your location"</div>
      <div>- Camera: คลิก "Camera" แล้วเปิด "Sites can ask to use your camera"</div>
      <div>- Microphone: คลิก "Microphone" แล้วเปิด "Sites can ask to use your microphone"</div>
      <div>- Notifications: คลิก "Notifications" แล้วเปิด "Sites can ask to send notifications"</div>
      <div>- Photos: หากเว็บไซต์ขอสิทธิ์เข้าถึงไฟล์ รูปภาพจะถูกจัดการผ่านสิทธิ์ในการเข้าถึงไฟล์ ซึ่งสามารถตั้งค่าได้ตามเว็บไซต์ที่กำหนด</div>`,
    },
    NewsInternalDetailView: {
      not_found_gallery: `ไม่พบอัลบั้มภาพ`,
      gallery: `อัลบั้มภาพ`,
    },
    NewsDetailView: {
      not_found_gallery: `ไม่พบอัลบั้มภาพ`,
    },
    NewsInternalView: {
      gallery: `อัลบั้มภาพ`,
    },
    NewsView: {
      title: `News`,
      not_found: `ไม่พบข่าวสาร`,
    },
    PreviewView: {
      title: `Preview`,
    },
    HomeView: {
      title: `หน้าแรก`,
      hello_txt: `สวัสดี`,
      welcome_txt: `ยินดีต้อนรับสู่ประเทศไทย.`,
      welcome_detail_txt: `ตำรวจท่องเที่ยว เพื่อนคนแรกของคุณ`,
      incident_status: `สถานะเหตุการณ์`,
      popular_places: `สถานที่น่าสนใจ`,
      news: `ข่าวสาร`,
      box_title: `ต้องการแจ้งเหตุด่วนกับตำรวจท่องเที่ยว`,
      box_detail: `กรุณากดปุ่มเพื่อตอบคำถามกับตำรวจท่องเที่ยว`,
      sos_title: `ต้องการความช่วยเหลือฉุกเฉิน ?`,
      sos_detail: `กรุณากดปุ่มโทรฉุกเฉิน`,
      sos_btn: `1155`,
      see_more: `เพิ่มเติม`,
    },
    LoginView: {
      title: `เข้าสู่ระบบ`,
      subtitle: `ยินดีต้อนรับเข้าสู่ระบบ Command Center`,
      agent_subtitle: `ยินดีต้อนรับเข้าสู่ระบบ Agent`,
      lang: `เลือกภาษา`,
    },
    ForgotPasswordView: {
      title: `ลืมรหัสผ่าน`,
      detail_step_1: `ไม่ต้องกังวล เราจะนำคุณกลับมา!`,
      detail_step_2: `กรุณากรอกรหัส OTP ที่ส่งไปยังอีเมลของคุณ`,
      detail_step_3: `ตั้งรหัสผ่านใหม่ของคุณได้เลย!`,
      detail_step_verify: `ยืนยันตัวตน`,
      create: `สร้างรหัสผ่านใหม่`,
      desc: `กรอกอีเมลของคุณสำหรับขั้นตอนการตรวจสอบ`,
      email: `เราจะส่งรหัส 4 หลักไปที่อีเมลของคุณ`,
      otp: `การตรวจสอบรหัส OTP`,
      otp_send_to: `รหัสถูกส่งไปที่`,
      otp_resend: `ส่งรหัสอีกครั้งใน`,
    },
    ResetPasswordView: {
      title: `Password changed`,
      detail: `Your password has been changed successfully`,
    },
    NotFoundView: {
      status_number: `404`,
      title: `นี่มันน่าอึดอัดใจนะ...`,
      detail: `ดูเหมือนว่าเราไม่พบหน้าที่คุณกำลังมองหา บางทีอาจถูกย้ายหรือลบไปแล้ว ขอโทษด้วยกับเรื่องนั้น! โปรดลองค้นหาเว็บแอปพลิเคชันของเรา`,
    },
    MaintenanceView: {
      title: `การปรับปรุงอยู่ในระหว่างดำเนินการ`,
      detail: `เดี๋ยวก่อน เว็บไซต์ของเรากำลังได้รับการปรับปรุงและจะกลับมาอีกครั้งในเร็วๆ นี้ ดูร้อนแรงยิ่งกว่าเบเกิลปิ้งใหม่ๆ ขอขอบคุณที่อดทนรอ เราจะนำเสนอสิ่งที่สุดยอดจริงๆ เร็วๆ นี้!`,
    },
    TouristManageView: {
      record_title: `ประวัติการติดต่อ (ล่าสุด)`,
    },
    OverviewView: {
      title_left: `การแจ้งเหตุ`,
      title_right: `การแจ้งเหตุด่วน SOS`,
      tab_left: `การแจ้งเหตุใหม่`,
      tab_right: `จัดการแล้ว`,
      not_found: `ไม่มีเหตุการณ์ใหม่`,
      tab_new: `ใหม่`,
      tab_in_progress: `กำลังดำเนินการ`,
      tab_successful: `รอส่งรายงาน`,
    },
    IncidentManageView: {
      title: `รายละเอียดเหตุ`,
      document_number: `หมายเลขรายงาน`,
      receive_date: `วันที่แจ้ง`,
      information: `ข้อมูลผู้แจ้ง`,
      history: `ประวัติการจัดการ`,
      emergency_contact: `ผู้ติดต่อในกรณีฉุกเฉิน`,
    },
    IncidentNoticeView: {
      history: `ประวัติการจัดการ`,
      number_code: `หมายเลขรายงาน`,
    },
    RegisterView: {
      title: "สมัครสมาชิก",
      desc: "ด้วย Email",
      create: "สร้างบัญชี",
      login: "คุณมีบัญชีอยู่แล้วหรือไม่?",
    },
    ChatView: {
      title: `Guardian`,
      location_title: `Locations`,
      reply_message: `Reply to message`,
      make_a_call: `โทรออก`,
      hang_up: `วางสาย`,
      answer_a_call: `รับสาย`,
      response_voice_call: `Response by Voice Call`,
      response_bot: `Response by Guardian (Bot)`,
      response_police: `Response by Police`,
      end_conversation: `End of conversation`,
      detail: `If there is a new incident report please get in touch with us`,
    },
    ChatManageView: {
      title: `Guardian`,
      location_title: `Locations`,
    },
    MessageView: {
      title: `ข้อความ`,
    },
    PreChatView: {
      title: `Report Incident`,
      sub_title: `Report Incident`,
      detail: `แจ้งเหตุเพื่อขอความช่วยเหลือจากตำรวจท่องเที่ยว ไม่ได้เป็นการแจ้งความ`,
    },
    VoiceCallView: {
      call_ended: `การโทรสิ้นสุดลง`,
    },
    HistoryPoliceTouristAndAgentView: {
      title: `ประวัติเหตุการณ์`,
    },
    HistoryDetailPoliceTouristAndAgentView: {
      title: `ประวัติเหตุการณ์`,
      report_no: `หมายเลขรายงาน`,
      report_info: `ข้อมูลรายงาน`,
      not_found_detail: `คุณไม่มีประวัติเหตุการณ์ที่นี่`,
    },
    ReportIncidentView: {
      title: `รายงาน`,
      guardian_chatbot: `แชทบอทผู้พิทักษ์`,
      report_incident_with_chatbot: `รายงานเหตุการณ์ด้วย แชทบอท`,
      sos_report: `รายงาน SOS`,
      share_location: `แชร์ตำแหน่งตอนนี้กับตำรวจท่องเที่ยว`,
    },
    ProfileMenuView: {
      title: `โปรไฟล์`,
    },
    ProfileEditTouristView: {
      title: `แก้ไขโปรไฟล์`,
    },
    ProfileEmergencyContactView: {
      title: `ติดต่อในกรณีฉุกเฉิน`,
    },
    ProfileSocialMediaAccountView: {
      title: `บัญชีโซเชียลมีเดีย`,
      link_txt: `ลิ้ง`,
    },
    ProfileUnlinkView: {
      link_google_txt: `คุณกำลังเชื่อมต่อกับ Google แอคเคาท์`,
      link_fb_txt: `คุณกำลังเชื่อมต่อกับ Facebook แอคเคาท์`,
      link_apple_txt: `คุณกำลังเชื่อมต่อกับ Apple แอคเคาท์`,
    },
    SettingContactUsView: {
      title: `ติดต่อเรา`,
    },
    SettingLanguageView: {
      title: `ภาษา`,
    },
    SettingNotificationView: {
      title: `การแจ้งเตือน`,
      push_notifications_txt: `การแจ้งเตือนแบบพุช`,
      chat_txt: `แจ้งเตือนแชท`,
    },
    SettingSecurityPrivacyChangePasswordView: {
      title: `เปลี่ยนรหัสผ่าน`,
    },
    SettingSecurityPrivacyView: {
      title: `ความปลอดภัยและความเป็นส่วนตัว`,
      allow_location: `อนุญาตให้ติดตามตำแหน่งของฉัน`,
      allow_camera: `อนุญาตให้เข้าถึงกล้อง`,
      allow_photo: `อนุญาตให้เข้าถึงอัลบั้มรูป`,
      allow_mic: `อนุญาตให้เข้าถึงไมโครโฟน`,
    },
    PopularPlaceView: {
      not_found: `ไม่พบสถานที่`,
    },
    RegisterEmergencyView: {
      title: `ผู้แจ้งเหตุฉุกเฉิน`,
      detail: `เราจะใช้ข้อมูลนี้เพื่อติดต่อคุณ ในกรณีที่เราไม่สามารถติดต่อคุณได้`,
    },
    RegisterSuccessView: {
      head: `สมัครสำเร็จ!`,
      title: `การยืนยันสำเร็จ!`,
      welcome: `ยินดีต้อนรับ`,
      detail_1: `เราจะช่วยคุณอย่างสุดความสามารถ ขอบคุณที่ไว้วางใจให้เราดูแลคุณ`,
      detail_2: `บัญชีของคุณพร้อมใช้งานแล้ว คุณจะถูกนำไปที่หน้าแรกเมื่อคุณคลิก`,
    },
  },
  btn: {
    btn_permission: `ตั้งค่าความเป็นส่วนตัว`,
    btn_readmore: `อ่านต่อ`,
    btn_skip: `ข้าม`,
    btn_go_home: `กลับสู่หน้าหลัก`,
    btn_background_check: `ตรวจสอบประวัติ`,
    btn_unlink: `ยกเลิกการเชื่อมต่อ`,
    btn_destination_stoped_incident: `ถึงที่หมาย เข้าระงับเหตุ`,
    btn_repair_report: `แก้ไข`,
    btn_google_map: `ไปยังแผนที่ Google`,
    btn_reset: `รีเซ็ต`,
    btn_receieve_report: `ปิดงาน`,
    btn_map: `แผนที่`,
    btn_share: `แชร์`,
    btn_register: `สมัครสมาชิก`,
    btn_create_user: `สร้างผู้ใช้งาน`,
    btn_submit: `ตกลง`,
    btn_confirm: `ยืนยัน`,
    btn_select: `เลือกไฟล์`,
    btn_delete: `ลบข้อมูลนี้`,
    btn_delete_file: `ลบไฟล์`,
    btn_delete_image: `ลบภาพ`,
    btn_delete_account: `ลบบัญชี`,
    btn_send_email: `ส่งอีเมลเชิญ`,
    btn_cancel: `ยกเลิก`,
    btn_save: `บันทึก`,
    btn_next: `ถัดไป`,
    btn_accept: `ตกลง`,
    btn_confirm_save: `ยืนยันการบันทึก`,
    btn_incident: `ใบแจ้งเหตุ`,
    btn_record: `ประวัติการจัดการ`,
    btn_assign: `มอบหมาย`,
    btn_report: `ส่งรายงาน`,
    btn_edit: `แก้ไข`,
    btn_back: `ย้อนกลับ`,
    btn_manage: `จัดการ`,
    btn_export: `ส่งออกข้อมูล`,
    btn_new_case: `สร้างใบแจ้งเหตุ`,
    btn_view_report: `ดูรายงาน`,
    btn_back_dashboard: `กลับไปที่แดชบอร์ด`,
    btn_forgot_password: `ลืมรหัสผ่าน`,
    btn_sign_in: `เข้าสู่ระบบ`,
    btn_change_password: `เปลี่ยนรหัสผ่าน`,
    btn_back_login: `กลับสู่หน้าเข้าสู่ระบบ`,
    btn_not_receive: `คุณยังไม่ได้รับรหัส?`,
    btn_resend_code: `ส่งรหัสอีกครั้ง`,
    btn_search: `ค้นหา`,
    btn_reject: `ปฎิเสธ`,
    btn_all: `ดูทั้งหมด`,
    btn_receive: `รับเรื่อง`,
    btn_view_notification: `ดูใบแจ้งเหตุ`,
    btn_manage_history: `ประวัติการจัดการ`,
    btn_chat: `ไปยังห้องสนทนา`,
    btn_login_with_phone: `Continue with Phone number`,
    btn_fb: `Continue with Facebook`,
    btn_google: `Continue with Google`,
    btn_apple: `Continue with Apple`,
    btn_successful: `เขียนรายงานเหตุการณ์`,
    btn_edit_completed: `บันทึกผลการดำเนินงาน`,
    btn_location_completed: `ถึงที่หมายเข้าระงับเหตุ`,
    btn_edit_report: `ส่งรายงาน`,
    btn_approve_report: `อนุมัติรายงาน`,
    btn_cancel_report: `ยกเลิกรายงาน`,
    btn_select_default: `เลือก`,
    btn_non: `ไม่ระบุ`,
    btn_no: `ไม่ ไม่ใช่`,
    btn_fill_in_manually: `กรอกข้อมูลด้วยตนเอง`,
    btn_start_report: `เริ่มแจ้งเหตุ`,
    btn_get_report: `แจ้งเหตุด่วน`,
    btn_logout: `ใช่, ต้องการออกจากระบบ`,
    btn_news: `สร้างข่าวสาร`,
    btn_submit_work: `บันทึกผลดำเนินงาน`,
    btn_alert: `สร้างการแจ้งเตือน`,
  },
  popup: {
    cancel: {
      cancel_title: `ยืนยันการยกเลิก`,
      cancel_detail: `คุณแน่ใจหรือไม่ ที่จะยกเลิกอัพเดทข้อมูลนี้?`,
    },
    reject: {
      reject_title: `ยืนยันการยกเลิกรายงาน`,
      reject_detail: `คุณแน่ใจหรือไม่ ที่จะยกเลิกรายงานนี้?`,
    },
    error: {
      delete_title: `ยืนยันการลบ`,
      delete_detail: `คุณแน่ใจหรือไม่ ที่จะลบข้อมูลนี้?`,
      delete_file_image_title: `ยืนยันการลบรูปภาพ`,
      delete_file_image_detail: `คุณแน่ใจหรือไม่ ที่จะลบรูปภาพนี้?`,
    },
    success: {
      no_criminal: `ไม่พบข้อมูลประวัติอาชญากรรมข้ามชาติ`,
      title: `บันทึกข้อมูลสำเร็จ`,
      detail: `คุณสามารถดำเนินการในขั้นตอนต่อไปได้`,
      history_detail: `คุณสามารถย้อนดูใบแจ้งเหตุย้อนหลังได้`,
      receive_title: `รับเรื่องสำเร็จ`,
      receive_detail: `คุณสามารถดำเนินการในขั้นตอนต่อไปได้`,
      edit_receive_title: `แก้ไขผู้ดำเนินการ`,
      edit_receive_detail: `ผู้ดำเนินการสามารถดำเนินการในขั้นตอนต่อไปได้`,
      reject_title: `ปฎิเสธการแจ้งเหตุสำเร็จ`,
      email_title: `อีเมลถูกแก้ไขแล้ว`,
      email_detail: `คุณสามารถใช้อีเมลใหม่ในการเข้าสู่ระบบได้ กรุณาเข้าสู่ระบบใหม่อีกครั้ง`,
      delete_title: `ลบข้อมูลสำเร็จ`,
      delete_detail: `ข้อมูลได้ถูกลบออกแล้ว`,
      delete_user_title: `ลบบัญชีสำเร็จ`,
      delete_user_detail: `บัญชีได้ถูกลบออกแล้ว`,
      delete_image_title: `ลบรูปภาพสำเร็จ`,
      delete_image_detail: `รูปภาพได้ถูกลบออกแล้ว`,
      close_title: `ปิดงานสำเร็จ`,
      close_detail: ``,
      edit_title: `แก้ไขสำเร็จ`,
      edit_detail: ``,
      send_title: `ส่งรายงานสำเร็จ`,
      send_detail: ``,
      waiting_title: `ส่งเรื่องสำเร็จ`,
      closed_title: `ปิดงานสำเร็จ`,
      closed_detail: `คุณสามารถดำเนินการในขั้นตอนต่อไปได้`,
      confirm_title: `อนุมัติรายงานสำเร็จ`,
      confirm_detail: ``,
      receive_suppressed_title: `ระงับเหตุสำเร็จ`,
      receive_suppressed_detail: `คุณสามารถดำเนินการในขั้นตอนต่อไปได้`,
      submit_title: `รับเรื่องสำเร็จ`,
      submit_detail: `คุณสามารถดำเนินการในขั้นตอนต่อไปได้`,
      copy_title: `คัดลอกสำเร็จ`,
      send_wait_title: `บันทึกข้อมูลสำเร็จ`,
      save_receive_title: `บันทึกผลดำเนินงานสำเร็จ`,
      tel1155_title: `แจ้งเตือน`,
      tel1155_detail: `มีค่าใช้จ่ายในการโทรครั้งนี้ ท่านยอมรับหรือไม่`,
    },
    caution: {
      default_title: `ขออภัย`,
      delete_title: `ขออภัย`,
      default_detail: `ยังไม่มีรายงานในขณะนี้`,
      recipient_detail: `มีผู้รับเรื่องแล้ว หรือข้อมูลมีการเปลี่ยนแปลง`,
      completed_detail: `ไม่สามารถจัดการได้เนื่องจากรายการนี้\nถูกแก้ไขเรียบร้อยแล้ว`,
      delete_image_detail: `ไม่สามารถลบรูปภาพได้`,
      delete_detail: `ไม่สามารถลบได้`,
      delete_user_detail: `ไม่สามารถลบบัญชีนี้ได้ได้`,
      error_detail: `พบข้อผิดพลาดไม่สามารถทำรายการได้`,
      duplicate_detail: `มีข้อมูลอยู่ในระบบแล้ว`,
    },
    textarea: {
      process_title: `ผลการดำเนินการ`,
      process_placeholder: `กรุณากรอกผลการดำเนินการ`,
      reject_title: `ปฎิเสธผลการดำเนินการ`,
      close_title: `ปิดเคสงาน`,
      status_title: `ส่งเรื่องการแจ้งเหตุ`,
      assignee_title: `แก้ไขผู้ดำเนินการ`,
      receive_title: `รายละเอียดรับเรื่อง`,
      detail_incident_title: `รายละเอียดระงับเหตุ`,
      process_incident_title: `รายละเอียดผลดำเนินงาน`,
      activity_incident_title: `รายละเอียดเหตุการณ์`,
    },
    logout: {
      title: `ออกจากระบบ`,
      detail: `คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ?`,
    },
  },
  form: {
    address: {
      label: "Address",
      placeholder: "กรุณากรอก Address",
    },
    opening_hours: {
      label: "Opening hours",
      placeholder: "กรุณากรอก Opening hours",
    },
    tourist_types: {
      label: "Tourist types",
      placeholder: "กรุณากรอก Tourist types",
    },
    types: {
      label: "Types",
      placeholder: "กรุณากรอก Types",
    },
    map: {
      label: "แผนที่",
      placeholder: "กรุณากรอก แผนที่",
    },
    latitude: {
      label: "Latitude",
      placeholder: "กรุณากรอก Latitude",
    },
    longitude: {
      label: "Longitude",
      placeholder: "กรุณากรอก Longitude",
    },
    area: {
      label: "Area (เมตร)",
      placeholder: "กรุณากรอก Area",
    },
    metadata: {
      label: "กี่นาทีถึงจุดเกิดเหตุ (ตัวเลขหน่วยเป็นนาที)",
      placeholder: "กรุณากรอก กี่นาทีถึงจุดเกิดเหตุ",
    },
    contentAt: {
      label: "วันที่ข่าวสาร",
      placeholder: "กรุณาเลือก วันที่ข่าวสาร",
    },
    publishedAt: {
      label: "วันที่แสดงผล",
      placeholder: "กรุณาเลือก วันที่แสดงผล",
    },
    expiryAt: {
      label: "วันที่สิ้นสุดการแสดงผล",
      placeholder: "กรุณาเลือก วันที่สิ้นสุดการแสดงผล",
    },
    thumbnail: {
      label: "รูปภาพ",
      placeholder: "กรุณาเลือกรูปภาพ",
    },
    gallery: {
      label: "อัลบั้มภาพ",
      placeholder: "กรุณาเลือกรูปภาพ",
    },
    published: {
      label: "สถานะแสดงผล",
      placeholder: "กรุณาเลือก ภาษาสถานะแสดงผล",
    },
    isInternal: {
      label: "สถานะข่าวสารภายใน",
      placeholder: "กรุณาเลือก สถานะข่าวสารภายใน",
    },
    translations: {
      label: "หัวข้อภาษาไทย",
      placeholder: "กรุณากรอก หัวข้อภาษาไทย",
    },
    translations_cn: {
      label: "บทความภาษาจีน",
      placeholder: "กรุณากรอก บทความภาษาจีน",
    },
    translations_en: {
      label: "บทความภาษาอังกฤษ",
      placeholder: "กรุณากรอก บทความภาษาจีน",
    },
    translations_jp: {
      label: "บทความภาษาญี่ปุ่น",
      placeholder: "กรุณากรอก บทความภาษาญี่ปุ่น",
    },
    translations_kr: {
      label: "บทความภาษาเกาหลี",
      placeholder: "กรุณากรอก บทความภาษาเกาหลี",
    },
    translations_ru: {
      label: "บทความภาษารัสเซีย",
      placeholder: "กรุณากรอก บทความภาษารัสเซีย",
    },
    translations_th: {
      label: "บทความภาษาไทย",
      placeholder: "กรุณากรอก บทความภาษาไทย",
    },
    channel: {
      label: `ช่องทาง`,
      placeholder: `กรุณาเลือก`,
    },
    is_track_location: {
      label: `Allow to access Track Location`,
      placeholder: `กรุณาเลือก`,
    },
    is_camera: {
      label: `Allow to access Camera`,
      placeholder: `กรุณาเลือก`,
    },
    is_photo: {
      label: `Allow to access Photo`,
      placeholder: `กรุณาเลือก`,
    },
    is_microphone: {
      label: `Allow to access Microphone`,
      placeholder: `กรุณาเลือก`,
    },
    is_push_notification: {
      label: `Allow to access Push Notification`,
      placeholder: `กรุณาเลือก`,
    },
    is_chat: {
      label: `Allow to access Chat`,
      placeholder: `กรุณาเลือก`,
    },
    emergency_contact: {
      label: `ผู้ติดต่อในกรณีฉุกเฉิน`,
      placeholder: `กรุณาผู้ติดต่อในกรณีฉุกเฉิน`,
    },
    images: {
      label: `รูปภาพ`,
      placeholder: `กรุณาเลือกรูปภาพ`,
    },
    region: {
      label: `ภูมิภาค`,
      placeholder: `กรุณาเลือกภูมิภาค`,
    },
    message: {
      label: `Aa`,
      placeholder: `Aa`,
    },
    numberic: {
      label: ``,
      placeholder: ``,
    },
    location_search: {
      label: `สถานที่เกิดเหตุ`,
      placeholder: `ค้นหาสถานที่เกิดเหตุ`,
    },
    notification_date: {
      label: `วันที่แจ้ง`,
      placeholder: `กรุณาเลือกวันที่แจ้ง`,
    },
    case_number: {
      label: `หมายเลขรายงาน`,
      placeholder: `กรุณากรอกหมายเลขรายงาน`,
    },
    friendly_category: {
      label: `ประเภทเรื่องจากนักท่องเที่ยว`,
      placeholder: `-`,
    },
    story_type: {
      label: `ประเภทเรื่อง`,
      placeholder: `กรุณาเลือกประเภทเรื่อง`,
    },
    province_area: {
      label: `เขตพื้นที่จังหวัด`,
      placeholder: `กรุณาเลือกเขตพื้นที่จังหวัด`,
    },
    recipient: {
      label: `ผู้รับเรื่อง`,
      placeholder: `กรุณาเลือกผู้รับเรื่อง`,
    },
    passport_number: {
      label: `เลขที่พาสปอร์ต`,
      placeholder: `กรุณากรอกเลขที่พาสปอร์ต`,
    },
    number: {
      label: `ลำดับ`,
      placeholder: `กรุณากรอกลำดับ`,
    },
    gender: {
      label: `เพศ`,
      placeholder: `กรุณากรอกเพศ`,
    },
    nationality: {
      label: `สัญชาติ`,
      placeholder: `กรุณาเลือกสัญชาติ`,
    },
    country: {
      label: `ประเทศ`,
      placeholder: `กรุณาเลือกประเทศ`,
    },
    app_channel: {
      label: `ช่องทางสมัคร`,
      placeholder: `กรุณาเลือกช่องทางสมัคร`,
    },
    notification_channel: {
      label: `ช่องทางรับแจ้ง`,
      placeholder: `กรุณาเลือกช่องทางรับแจ้ง`,
    },
    phone: {
      label: `เบอร์โทรศัพท์`,
      placeholder: `กรุณากรอกเบอร์โทรศัพท์`,
    },
    informer_phone: {
      label: `เบอร์โทรผู้แจ้ง`,
      placeholder: `กรุณากรอกเบอร์โทรผู้แจ้ง`,
    },
    identification_type: {
      label: `ประเภทยืนยันตัวตน`,
      placeholder: `กรุณากรอกประเภทยืนยันตัวตน`,
    },
    type_code: {
      label: ``,
      placeholder: ``,
    },
    id: {
      label: `ไอดี`,
      placeholder: `กรุณากรอกไอดี`,
    },
    email: {
      label: `อีเมล`,
      placeholder: `กรุณากรอกอีเมล`,
    },
    nowPassword: {
      label: `รหัสผ่านปัจุบัน`,
      placeholder: `กรุณากรอกรหัสผ่านปัจุบัน`,
    },
    password: {
      label: `รหัสผ่าน`,
      placeholder: `กรุณากรอกรหัสผ่าน`,
    },
    newPassword: {
      label: `รหัสผ่านใหม่`,
      placeholder: `กรุณากรอกรหัสผ่านใหม่`,
    },
    repeatPassword: {
      label: `ยืนยันรหัสผ่านใหม่`,
      placeholder: `กรุณายืนยันรหัสผ่านใหม่`,
    },
    remember_me: {
      label: `จำฉันไว้ในระบบ`,
      placeholder: ``,
    },
    status: {
      label: `สถานะ`,
      placeholder: `กรุณาเลือกสถานะ`,
    },
    role: {
      label: `สิทธิและบทบาท`,
      placeholder: `กรุณาเลือกสิทธิและบทบาท`,
    },
    division: {
      label: "สังกัดกอง",
      placeholder: "กรุณาเลือกกองบังคับการ",
    },
    subdivision: {
      placeholder: "กรุณาเลือกกองกำกับการ",
    },
    station: {
      placeholder: "กรุณาเลือกสถานี",
    },
    responsibleArea: {
      label: "พื้นที่รับผิดชอบ",
      placeholder: "กรุณาเลือกพื้นที่รับผิดชอบ",
    },
    externalDepartment: {
      label: "หน่วยงานภายนอก",
      placeholder: "กรุณาเลือกหน่วยงานภายนอก",
    },
    keyword: {
      label: `ค้นหา...`,
      placeholder: ``,
    },
    image_name: {
      label: `รูปภาพ`,
      placeholder: `กรุณาใส่รูปภาพ`,
    },
    informer_name: {
      label: `ชื่อผู้แจ้ง`,
      placeholder: `กรุณากรอกชื่อผู้แจ้ง`,
    },
    informer_type: {
      label: `ประเภทผู้แจ้ง`,
      placeholder: `กรุณาเลือกประเภทผู้แจ้ง`,
    },
    name: {
      label: `ชื่อ`,
      placeholder: `กรุณากรอกชื่อ`,
    },
    title: {
      label: `คำนำหน้าชื่อ`,
      placeholder: `คำนำหน้าชื่อ`,
    },
    title_news: {
      label: `หัวข้อ`,
      placeholder: `กรอกข้อความ`,
    },
    title_alert: {
      label: `หัวข้อ`,
      placeholder: `กรอกข้อความ`,
    },
    description: {
      label: `คำอธิบาย`,
      placeholder: `กรอกข้อความ`,
    },
    detail: {
      label: `บทความ`,
      placeholder: `กรอกข้อความ`,
    },
    firstName: {
      label: `ชื่อ`,
      placeholder: `กรุณากรอกชื่อ`,
    },
    lastName: {
      label: `นามสกุล`,
      placeholder: `กรุณากรอกนามสกุล`,
    },
    idCard: {
      label: `เลขบัตรประจำตัวประชาชน`,
      placeholder: `กรุณากรอกเลขบัตรประจำตัวประชาชน`,
    },
    isOpenAgent: {
      label: `เปิดใช้งานระบบเจ้าหน้าที่ปฎิบัติการ Agent Platform`,
      placeholder: ``,
    },
    touristDetail: {
      label: `รายละเอียดเหตุ (จากนักท่องเที่ยว)`,
      placeholder: `กรุณากรอก รายละเอียดเหตุ (จากนักท่องเที่ยว)`,
    },
    notificationChannel: {
      label: `ช่องทางการรับแจ้ง`,
      placeholder: ``,
    },
    officerDetail: {
      label: `รายละเอียดเหตุ (สำหรับเจ้าหน้าที่) `,
      placeholder: `กรุณากรอกรายละเอียด`,
    },
    no: {
      label: `รหัส`,
      placeholder: `กรอกรหัส`,
    },
    subject: {
      label: `หัวเรื่อง`,
      placeholder: `กรุณาเลือกหัวเรื่อง`,
    },
    important: {
      label: `ความสำคัญ`,
      placeholder: `กรุณาเลือกความสำคัญ`,
    },
    district: {
      label: `เขต/อำเภอ`,
      placeholder: `กรุณาเลือกเขต/อำเภอ`,
    },
    sub_district: {
      label: `แขวง/ตำบล`,
      placeholder: `กรุณาเลือกแขวง/ตำบล`,
    },
    location_detail: {
      label: `รายละเอียดสถานที่`,
      placeholder: `กรุณากรอกรายละเอียด`,
    },
    pin_location: {
      label: `หมุดสถานที่`,
      placeholder: ``,
    },
    submit_story: {
      label: `ส่งเรื่อง`,
      placeholder: ``,
    },
    close_case: {
      label: `ปิดเคส`,
      placeholder: ``,
    },
    send_responsibility: {
      label: `ส่งเรื่องให้พื้นที่รับผิดชอบ`,
      placeholder: `กรุณาเลือกพื้นที่รับผิดชอบ`,
    },
    agency: {
      label: `หน่วยงานดูแล`,
      placeholder: ``,
    },
    police_inspector: {
      label: `สารวัตรสถาน`,
      placeholder: ``,
    },
    result: {
      label: `ผล`,
      placeholder: ``,
    },
    responsible_person: {
      label: `ผู้ดำเนินการ`,
      placeholder: `กรุณาเลือกผู้ดำเนินการ`,
    },
    updated_date: {
      label: `อัปเดตล่าสุด`,
      placeholder: ``,
    },
    estimate_time: {
      label: `ใช้เวลาประมาณ`,
      placeholder: ``,
    },
    subject_user: {
      label: `ผู้รับเรื่อง`,
      placeholder: ``,
    },
    assign_user: {
      label: `ผู้มอบหมาย`,
      placeholder: ``,
    },
    inspector_user: {
      label: `สารวัตรสถานี`,
      placeholder: ``,
    },
    category: {
      label: `หัวเรื่อง`,
      placeholder: `กรุณาเลือก หัวเรื่อง`,
    },
    type: {
      label: `การแจ้งเหตุ`,
      placeholder: `กรุณาเลือก การแจ้งเหตุ`,
    },
    startAt: {
      label: `วันเริ่มต้น`,
      placeholder: `กรุณาเลือก วันเริ่มต้น`,
    },
    endAt: {
      label: `วันสิ้นสุด`,
      placeholder: `กรุณาเลือก วันสิ้นสุด`,
    },
    information_user: {
      label: `ข้อมูลผู้แจ้ง`,
      placeholder: `กรุณาเลือกข้อมูลผู้แจ้ง`,
    },
    admin_user: {
      label: `เจ้าหน้าที่ผู้ดูแล`,
      placeholder: `กรุณาเลือกเจ้าหน้าที่ผู้ดูแล`,
    },
    control_user: {
      label: `ผู้ควบคุม`,
      placeholder: `กรุณาเลือกผู้ควบคุม`,
    },
    responsibleAreaCodes: {
      label: "พื้นที่รับผิดชอบ",
      placeholder: "กรุณาเลือกพื้นที่รับผิดชอบ",
    },
    locales: {
      label: "ภาษา",
      placeholder: "กรุณาเลือกภาษา",
    },
    mobile: {
      label: "เบอร์โทรศัพท์",
      placeholder: "กรุณากรอกเบอร์โทรศัพท์",
    },
  },
  error: {
    password: "รหัสผ่านควรมีอย่างน้อย 6 ตัวอักษร",
    invalid_credential: "ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง",
  },
  caution: {
    user_1: `โปรดทราบว่า: หากเปิดใช้งานระบบเจ้าหน้าที่ปฎิบัติการ จำเป็นต้องเลือกพื้นที่รับผิดชอบ`,
    password_1: `ต้องมีรหัสผ่านอย่างน้อย 6 ตัว มีตัวพิมพ์ใหญ่ ตัวพิมเล็ก และตัวเลข`,
    picture_1: `คำแนะนำ: ขนาดรูปภาพ 200 x 200 pixels`,
    picture_2: `(สูงสุด 5 MB) ประเภทไฟล์: JPG (JPEG), PNG`,
    picture_1_2: `คำแนะนำ: ขนาดรูปภาพ 490 x 385 pixels`,
    picture_2_2: `(สูงสุด 5 MB) ประเภทไฟล์: JPG (JPEG), PNG`,
    delete_title: ``,
    email_1: `เราจะใช้อีเมลนี้เพื่อตรวจสอบบัญชีของคุณ`,
    gdcc_account: `มี GDCC Account แล้ว`,
  },
  role: {
    ADMIN: `ผู้ดูแลระบบ`,
    CENTRAL_OFFICER: `เจ้าหน้าที่คอลเซนเตอร์ 1155`,
    OFFICER: `หัวหน้าสถานี`,
    REPORTER: `ผู้บังคับบัญชา`,
    AGENT: `เจ้าหน้าที่สายตรวจ`,
    MANAGER: `บริหาร`,
    INTEPETOR: `เจ้าหน้าที่ศูนย์ 1155`,
    SUPERVISOR: `หัวหน้าสถานี / ผู้ช่วย`,
    SUPPRESSOR: `งานป้องกันปราบปราม`,
    INVESTIGATOR: `สืบสวน`,
    DUTY_OFFICER: `นายร้อยตำรวจเวร`,
  },
  incident_status: {
    waiting: `รอดำเนินการ`,
    in_progress: `กำลังดำเนินการ`,
    canceled: `ปิดงาน`,
  },
  lang: {
    th: `ไทย`,
    en: `English`,
    cn: `中国大陆`,
    ru: `Россия`,
    kr: `대한민국`,
    jp: `日本`,
  },
  progress: {
    processing_time: `เวลาดำเนินการ`,
    period_time: `ใช้ระยะเวลา`,
  },
  go_to_chat: "กลับไปยังหน้าสนทนา"
};
