import { ENUM_STEP_FORGOT_PASSWORD } from "@/configs/enums/enum";
import { defineStore } from "pinia";

const storeName = `useForgotPasswordStore-${import.meta.env.VITE_APP_VERSION}`;
export const useForgotPasswordStore = defineStore(storeName, {
  state: () => ({
    enumStep: ENUM_STEP_FORGOT_PASSWORD,
    step: ENUM_STEP_FORGOT_PASSWORD.STEP_1,
  }),
  actions: {},
});
