import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetUser } from "@/configs/types/User/typeUser";

export class fetchUser {
  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get("/v1/users", {
      params,
    });
    return data;
  }
  async Create(body: object): Promise<typeGetUser> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).post<typeGetUser>("/v1/users", body);
    return data;
  }
  async Get(id: string): Promise<typeGetUser> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetUser>(`/v1/users/${id}`);
    return data;
  }
  async SyncGDCC(id: string): Promise<void> {
    await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).patch(`/v1/users/${id}/gdcc`);
    return
  }
  async Update(id: string, body: object): Promise<typeGetUser> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).put<typeGetUser>(`/v1/users/${id}`, body);
    return data;
  }
  async Delete(id: string): Promise<boolean> {
    try {
      await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).delete(`/v1/users/${id}`);
      return true;
    } catch (error) {
      return false;
    }
  }
}
