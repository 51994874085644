import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";

const storeName = `useUserDeleteStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  id: {
    is: false,
    name: `id`,
    label: `form.id.label`,
    placeholder: `form.id.placeholder`,
    value: null,
  },
};
export const useUserDeleteStore = defineStore(storeName, {
  state: () => ({
    fetchUser: new fetchUser(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    fetch: (() => {}) as VoidFunction,
    reset: (() => {}) as VoidFunction,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    result: convertToValues(isRequired) as Pick<typeGetUser, "id">,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      id: yup
        .mixed()
        .nullable()
        .required(isRequired.id.placeholder)
        .test("numberCheck", isRequired.id.placeholder, (value: any) => {
          return value !== 0 && value !== null && value !== "" && value !== undefined && value !== "0";
        }),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);
      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        this.delete(this.result);
      } catch (error: any) {
        this.loading = false;
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async delete(values: any) {
      try {
        this.loading = true;
        await this.fetchUser.Delete(values.id);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
      }
    },
  },
});
