import { defineStore } from "pinia";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues } from "@/configs/helpers/MainHelper";

import { fetchAlert } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAlert";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";

const storeName = `useAlertSearchStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {};
export const useAlertSearchStore = defineStore(storeName, {
  state: () => ({
    fetchAlert: new fetchAlert(),
    fetchFile: new fetchFile(),
    sharedStore: useSharedStore(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    loadingList: false,
    loadingScroll: false,
    loadingSearch: false,
    isRequired,
    currentPage: 0,
    totalPage: 1,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    tableListResults: null as any,
    tableScrollResults: null as any,
    tableSearchResults: null as any,
    getResult: null as any,
    AlertRole: "",
    listType: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    error: {} as { [key: string]: string | null },
  }),
  actions: {},
});
