<script lang="ts">
import { defineComponent } from "vue";
import { useSharedStore as useSharedPoliceCommandCenterStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { useSharedStore as useSharedPoliceTouristAndAgentStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { isCommandCenterApp } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "MainLayoutView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  async created() {
    this.masterDataStore.listNationality();
  },
  setup() {
    const sharedStore = isCommandCenterApp ? useSharedPoliceCommandCenterStore() : useSharedPoliceTouristAndAgentStore();
    const masterDataStore = useMasterDataStore();
    return {
      sharedStore,
      masterDataStore,
    };
  },
});
</script>

<template>
  <router-view></router-view>
</template>
