<script lang="ts">
import { defineComponent, reactive } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useNewsSearchStore } from "./stores/useNewsSearchStore";
import type { $string } from "@/configs/types/Shared/typeShare";
import { useInfiniteScroll } from "@vueuse/core";
import { groupNewsByContentDate } from "@/configs/helpers/MainHelper";
import _ from "lodash";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";

export default defineComponent({
  name: "NewsView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href,
      profile_image: "" as any,
      isVisibleSearch: false,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.newsSearchStore.error.keyword || "").length > 0,
                isIcon: true,
                isStartIcon: true,
                classIcon: "ttp-icon-inside-box-03 text-[#2C3483]",
                componentIcon: this.$solidIcons.NewspaperIcon,
                isDisable: this.loading(),
                isRequired: this.newsSearchStore.isRequired.keyword.is,
                textError: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.newsSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.newsSearchStore.isRequired.keyword.placeholder),
                value: this.newsSearchStore.result.keyword,
                name: this.newsSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: async (value: $string) => {
                this.newsSearchStore.result.keyword = value;
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async newsListFetch() {
      if (!this.newsSearchStore.loadingScroll) {
        this.newsSearchStore.currentPage = this.newsSearchStore.currentPage + 1;

        let results = (await this.newsSearchStore.onUpdateListCurrentPage(this.newsSearchStore.currentPage)) as any;

        if (!this.newsSearchStore.tableScrollResults) {
          this.newsSearchStore.tableListResults = results;
          this.newsSearchStore.tableScrollResults = _.cloneDeep(results);
          this.newsSearchStore.totalPage = results?.totalPages;
        } else {
          this.newsSearchStore.tableListResults.items.push(...results?.items);
          this.newsSearchStore.tableListResults.totalPage = this.newsSearchStore.tableListResults.totalPages;
          results = this.newsSearchStore.tableListResults;
        }

        await results?.items.reduce(async (_acc: any, item: any) => {
          if (item.thumbnail) {
            item.thumbnail = await Promise.all(await this.newsSearchStore?.fetchFile.Get([item.thumbnail]));
          }
          return item;
        }, []);

        this.newsSearchStore.tableScrollResults.items = await groupNewsByContentDate(results?.items);
        this.newsSearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
        this.newsSearchStore.loadingScroll = false;
      }
    },
    async newsScrollFetch() {
      useInfiniteScroll(
        this.el,
        () => {
          if (this.newsSearchStore.totalPage > this.newsSearchStore.currentPage) this.newsListFetch();
        },
        { distance: 10 }
      );
      return stop;
    },
    async newsSearchFetch() {
      if (!this.newsSearchStore.loadingSearch) await this.newsSearchStore.onUpdateSearchCurrentPage(Number(this.$route.query.page) || 1);
    },
    loading() {
      return this.newsSearchStore.loading;
    },
    async reset() {
      this.newsSearchStore.$reset();
    },
    async setting() {
      await this.newsListFetch();
      await this.newsScrollFetch();
      await this.$configLightbox();

      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }
    },
  },
  async mounted() {
    await this.reset();
    await this.setting();
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();
    const newsSearchStore = useNewsSearchStore();

    var state = reactive({
      el: null,
    });

    return {
      authStore,
      masterDataStore,
      newsSearchStore,
      el: state.el,
      state,
    };
  },
});
</script>

<template>
  <div
    v-if="isVisibleSearch"
    @click="
      () => {
        isVisibleSearch = !isVisibleSearch;
      }
    "
    class="absolute top-0 left-0 bg-black flex w-full h-full z-[99999] opacity-50"
  ></div>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen ttp-fixed-tourist-mobile-header">
          <div class="flex items-center justify-center mx-2 my-5">
            <button
              class="w-10 text-white text-left z-[99999]"
              @click="
                () => {
                  $router.push({ name: 'HomeView' });
                }
              "
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div v-if="isVisibleSearch" class="relative w-full text-white text-right z-[99999]">
              <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
              <div v-if="newsSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <router-link :to="{ name: 'NewsDetailView', params: { id: data?.id } }" v-for="(data, key) in newsSearchStore?.tableSearchResults?.items" :key="key" class="flex gap-2 mb-2 w-full">
                  <div class="inline-flex w-10 h-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 overflow-hidden">
                    <img v-if="data?.thumbnail[0]" :src="data?.thumbnail[0]?.url" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                    <img v-else="image_url" :src="image_url" alt="รูปภาพ" lass="object-cover object-center w-10 h-10" />
                  </div>
                  <div class="flex flex-col w-full">
                    <div class="ttp-text-md text-black text-left font-bold">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.title) }}</div>
                    <time class="ttp-text-xs text-black text-left">{{ $dayjs(new Date(data?.contentAt)).format("DD MMMM YYYY") }}</time>
                  </div>
                </router-link>
              </div>
              <div v-else-if="loading()" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <div class="m-auto text-center"><LoadingFormComponent class="text-black" /></div>
                <div class="ttp-text-xs text-black text-center">{{ $t("layout.loading") }}</div>
              </div>
              <div v-else-if="newsSearchStore?.tableSearchResults != null && !newsSearchStore?.tableSearchResults?.items?.length" class="absolute top-12 bg-white flex flex-col gap-1 w-full rounded-lg p-3 z-[99999]">
                <div class="ttp-text-xs text-black text-center">{{ $t("layout.not_found") }}</div>
              </div>
            </div>
            <div v-if="!isVisibleSearch" class="w-full"></div>
            <button
              @click="
                () => {
                  if (!isVisibleSearch) isVisibleSearch = !isVisibleSearch;
                  else newsSearchFetch();
                }
              "
              class="w-10 text-white text-right z-[99999]"
            >
              <div>
                <component :is="$solidIcons.MagnifyingGlassIcon" class="flex-none inline-block ttp-icon-inside-box-02 mx-1" />
              </div>
            </button>
            <div v-if="!isVisibleSearch" class="w-10 text-white text-right">
              <router-link :to="{ name: 'MessageView' }"
                ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                </svg>
              </router-link>
            </div>
            <div v-if="!isVisibleSearch" class="w-10 text-white text-right">
              <router-link :to="{ name: 'NotificationView' }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ttp-icon-inside-box-02 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
              </router-link>
            </div>
          </div>
          <div v-if="true" class="flex my-4">
            <div class="w-full">
              <div class="flex w-full items-center gap-6 ml-4">
                <div class="swipe-photo inline-flex ttp-icon-box-01 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500 overflow-hidden">
                  <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
                  <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center rounded-full float-right w-16 h-16 lg:w-24 lg:h-24" :src="profile_image" alt="" /></a>
                </div>
                <div class="flex-col justify-start items-start gap-0 inline-flex">
                  <div class="ttp-text-md sm:ttp-text-xl text-white font-extralight">
                    {{ $t("page.HomeView.hello_txt") }}, <span class="text-white-300">{{ $t("page.HomeView.welcome_txt") }}</span>
                  </div>
                  <div class="text-white font-medium ttp-text-3xl">
                    <div>{{ authStore?.user?.name }}</div>
                    <div class="ttp-text-sm sm:ttp-text-xl text-white-300">{{ $t("page.HomeView.welcome_detail_txt") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="el" class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full overflow-auto pb-20 h-full">
            <div class="grid grid-cols-1">
              <component
                :classTagName="'w-full text-left'"
                :is="defaultFormComponents.form.input.keyword.component"
                v-bind="defaultFormComponents.form.input.keyword.props"
                @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue"
                @click="
                  () => {
                    isVisibleSearch = !isVisibleSearch;
                  }
                "
              />
            </div>

            <div v-if="!(JSON.stringify(newsSearchStore?.tableScrollResults?.items) === '{}')" v-for="(result, date) in newsSearchStore?.tableScrollResults?.items" :key="date">
              <template v-if="result.length">
                <div class="flex w-full gap-2 mb-2">
                  <span class="ttp-text-md font-bold">Date</span><time>{{ $dayjs(date).format("DD MMMM YYYY") }}</time>
                </div>
                <div class="grid md:grid-cols-2 grid-cols-1 gap-2">
                  <router-link :to="{ name: 'NewsDetailView', params: { id: data?.id } }" v-for="data in result" class="relative flex gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden">
                    <div class="relative w-full overflow-hidden">
                      <div :class="{ 'absolute inset-0': true, 'w-[90px] m-auto': !data?.thumbnail[0]?.url, 'w-full h-36': data?.thumbnail[0]?.url }">
                        <img v-if="data?.thumbnail[0]?.url" :src="data?.thumbnail[0]?.url" alt="รูปภาพ" class="object-cover w-full h-full" />
                        <img v-else="image_url" :src="image_url" alt="รูปภาพ" class="object-cover w-full h-full" />
                      </div>
                    </div>
                    <div class="flex flex-col w-full justify-between py-2">
                      <time class="w-full ttp-text-xs text-[#4E4E4E] font-bold">{{ $dayjs(data?.contentAt).format("DD MMMM YYYY") }}</time>
                      <div class="w-full ttp-text-sm">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.title) }}</div>
                    </div>
                  </router-link>
                </div>
              </template>
            </div>
            <div v-else-if="newsSearchStore.currentPage == 1 && !newsSearchStore.loadingScroll" class="flex flex-col h-full justify-center items-center">
              <div class="text-center">
                <component :is="$outlineIcons.NewspaperIcon" class="inline-block m-auto ttp-icon-box-01 text-[#2c3483]" />
                <div class="ttp-text-xs text-[#2c3483]">{{ $t("page.NewsView.not_found") }}</div>
              </div>
            </div>
            <div v-if="newsSearchStore.loadingScroll" class="flex flex-col justify-center items-center w-full">
              <div><LoadingFormComponent /></div>
              <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style style="scss"></style>
