<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { ENUM_SIZE, ENUM_COLOR } from "@/configs/enums/enum";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
const ButtonStyle2Component = defineComponent({
  name: "ButtonStyle2Component",
  components: {
    LoadingFormComponent,
  },
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    classEnumName: {
      type: String,
      required: false,
      default: ENUM_COLOR.DEFAULT_1,
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: ENUM_SIZE.NORMAL,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const classEnumName = computed(() => {
      if (props.classEnumName == ENUM_COLOR.DEFAULT_1) {
        return "flex justify-center items-center text-ttp-primary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-primary hover:bg-ttp-primary rounded-lg ttp-text-xs";
      } else if (props.classEnumName == ENUM_COLOR.DEFAULT_2) {
        return "flex justify-center items-center text-ttp-info disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-info hover:bg-ttp-info rounded-lg ttp-text-xs";
      } else if (props.classEnumName == ENUM_COLOR.DARK) {
        return "flex justify-center items-center text-ttp-secondary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-secondary hover:bg-ttp-secondary rounded-lg ttp-text-xs";
      } else if (props.classEnumName == ENUM_COLOR.GREEN) {
        return "flex justify-center items-center text-ttp-success disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-success hover:bg-ttp-success rounded-lg ttp-text-xs";
      } else if (props.classEnumName == ENUM_COLOR.RED) {
        return "flex justify-center items-center text-ttp-error disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-error hover:bg-ttp-error rounded-lg ttp-text-xs";
      } else if (props.classEnumName == ENUM_COLOR.YELLOW) {
        return "flex justify-center items-center text-ttp-warning disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-warning hover:bg-ttp-warning rounded-lg ttp-text-xs";
      } else if (props.classEnumName == ENUM_COLOR.GRAY) {
        return "flex justify-center items-center text-ttp-text-secondary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-ttp-text-secondary border border-ttp-new hover:bg-ttp-new rounded-lg ttp-text-xs";
      } else {
        return "flex justify-center items-center text-ttp-primary disabled:cursor-not-allowed disabled:bg-ttp-disabled disabled:cursor-not-allowed disabled:text-ttp-text-disabled disabled:cursor-not-allowed disabled:border-0 hover:text-white border border-ttp-primary hover:bg-ttp-primary rounded-lg ttp-text-xs";
      }
    });

    const size = computed(() => {
      if (props.size == ENUM_SIZE.SMALL) {
        return "ttp-text-xs px-2.5 py-2.5 text-center";
      } else if (props.size == ENUM_SIZE.NORMAL) {
        return "ttp-text-sm px-2.5 py-2.5 text-center";
      } else if (props.size == ENUM_SIZE.LARGE) {
        return "ttp-text-md px-2.5 py-2.5 text-center";
      } else {
        return "ttp-text-base px-2.5 py-2.5 text-center";
      }
    });

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      classEnumName,
      size,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
      }
    },
  },
});
export default ButtonStyle2Component;
</script>

<template>
  <button v-if="isVisible" type="button" :disabled="isDisable" :class="`${[classEnumName, classTagName, size, 'z-0'].join(' ')}`" @click="handleClick" @touchstart="handleClick">
    <div v-if="isLoading"><LoadingFormComponent /></div>
    <span v-else-if="htmlIcon && isIcon" v-html="htmlIcon"></span>
    <span v-else><component v-if="!$isObjectEmpty(componentIcon) && isIcon" :is="componentIcon" :class="classIcon" /></span>
    <span v-if="title" class="w-full">{{ title }}</span>
  </button>
</template>
