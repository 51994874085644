import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";
import { getAuth, type Auth, initializeAuth, indexedDBLocalPersistence } from "firebase/auth";
import { getMessaging as _getMessaging, isSupported as _isSupported, type Messaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
let auth: Auth;
let messaging: Messaging | null;

if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(firebaseApp, {
    persistence: indexedDBLocalPersistence,
  });
} else {
  auth = getAuth(firebaseApp);
}

export const getMessaging = async (): Promise<Messaging | null> => {
  if (messaging) {
    return messaging;
  }

  const isSupported = Capacitor.isNativePlatform() ? await _isSupported() : false;

  messaging = isSupported ? _getMessaging(firebaseApp) : null;

  return messaging;
};

export { auth };
