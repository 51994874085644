<template>
  <div class="pt-16 md:pt-24 dark:text-gray-100">
    <TabGroup @change="changeTab">
      <TabList class="flex items-center border-b border-gray-200/75 text-sm dark:border-gray-700">
        <Tab v-for="tab in tabs" :key="`tab-${tab.key}`" as="template" v-slot="{ selected }">
          <button
            class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-semibold focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
            :class="{
              'border-indigo-900 text-indigo-900 dark:border-indigo-900 dark:text-indigo-900 bg-white dark:bg-gray-900 rounded-t-lg': selected,
              'border-transparent text-gray-500 hover:border-indigo-900 hover:text-indigo-900 active:text-indigo-900 dark:text-gray-500 dark:hover:border-indigo-900 dark:hover:text-indigo-900': !selected,
            }"
            @click="router.push({ query: { tab: tab.key } })"
          >
            {{ tab.name }}&nbsp;
            <span v-if="!tab.loading">({{ tab.total }})</span>
            <ArrowPathIcon v-else class="animate-spin size-4" />
          </button>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel v-for="tab in tabs" :key="`tab-panel-${tab.key}`">
          <div v-if="!tab.total" class="flex flex-col h-full justify-center items-center mt-8">
            <div class="text-center text-gray-500">
              <BookOpenIcon class="inline-block m-auto ttp-icon-inside-box-05" />
              <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
            </div>
          </div>
          <ul v-else class="pb-12 divide-y divide-gray-200 rounded-lg bg-ttp-bg-theme dark:divide-gray-700 dark:bg-gray-900 dark:text-gray-100">
            <li v-for="item in tab.items" :key="item.id" class="grid grid-cols-3 gap-2 p-4 w-full justify-between items-center border-b-2 bg-white">
              <div class="col-span-2">
                <p class="text-indigo-800 ttp-text-base font-semibold text-left mb-2">{{ item.incidentCategory ? contentToTitle(item.incidentCategory.content, "TH") : "-" }}</p>
                <p class="ttp-text-xs font-medium mb-2">หมายเลขรายงาน {{ item.incidentNumber }}</p>
                <p class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left mb-2">
                  <UserCircleIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary mr-1" />
                  <span class="ttp-text-xs font-medium">{{ item.responsible?.content[0]?.shortName || "-" }}</span>
                </p>
                <p class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left mb-2">
                  <ClockIcon class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary mr-1" />
                  <span class="ttp-text-xs font-medium">{{ dayjs(item.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
                </p>
                <BadgeDefaultComponent :size="ENUM_SIZE.NORMAL" :title="$formattedStatus($classStatus(item.status))" :classEnumName="$classStatus(item.status)" is-visible is-icon />
              </div>
              <div>
                <ButtonDefaultComponent
                  tag="router-link"
                  :to="{
                    name: 'IncidentDetailView',
                    params: { id: item.id },
                  }"
                  :component-icon="EyeIcon"
                  :class-enum-name="ENUM_COLOR.DEFAULT_1"
                  :size="ENUM_SIZE.SMALL"
                  class-tag-name="w-full"
                  class-icon="ttp-icon-inside-box-03 inline-block"
                  title="ใบแจ้งเหตุ"
                  is-visible
                  is-icon
                />
              </div>
            </li>
          </ul>
          <div class="h-12 flex w-full gap-2 justify-between items-center px-4 py-2 text-left fixed bottom-0 bg-white dark:bg-gray-900 border-t border-gray-200/75 dark:border-gray-700">
            <div class="w-full text-gray-500 ttp-text-xs font-semibold">
              ทั้งหมด <span>{{ tab.total }}</span> เคส
            </div>
            <div class="flex justify-end gap-3 items-center w-full text-gray-500 ttp-text-xs font-semibold">
              <p>หน้า {{ tab.currentPage }} จาก {{ tab.totalPage }}</p>
              <div class="text-center dark:text-gray-100">
                <nav class="flex">
                  <button @click="tab.currentPage > 1 ? fetch(tab, tab.currentPage - 1) : null" class="inline-flex items-center justify-center gap-2 rounded-l-lg border border-gray-200 bg-white px-4 py-2 font-semibold leading-6 text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:z-1 focus:ring focus:ring-gray-300/25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
                    <svg class="hi-mini hi-chevron-left -mx-1.5 inline-block size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                    </svg>
                  </button>
                  <button @click="tab.currentPage < tab.totalPage ? fetch(tab, tab.currentPage + 1) : null" class="inline-flex items-center justify-center gap-2 rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-semibold leading-6 text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:z-1 focus:ring focus:ring-gray-300/25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
                    <svg class="hi-mini hi-chevron-right -mx-1.5 inline-block size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup lang="ts">
import { ENUM_COLOR, ENUM_INCIDENT_STATUS, ENUM_SIZE } from "@/configs/enums/enum";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { BookOpenIcon, ArrowPathIcon } from "@heroicons/vue/24/outline";
import { ClockIcon, EyeIcon, UserCircleIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { contentToTitle, useIncidentStore, type GetIncidentQuery, type Incident } from "@/stores/PoliceTouristAndAgent/useIncidentStore";
import dayjs from "dayjs";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";

type Tab = {
  key: string;
  name: string;
  loading: boolean;
  currentPage: number;
  totalPage: number;
  total: number;
  query: GetIncidentQuery;
  items: Incident[];
};

const router = useRouter();
const incidentStore = useIncidentStore();

const tabs = ref<Tab[]>([
  {
    key: "new",
    name: "ใหม่",
    loading: false,
    currentPage: 1,
    totalPage: 1,
    total: 0,
    query: {
      status: ENUM_INCIDENT_STATUS.WAITING,
    },
    items: [],
  },
  {
    key: "in_progress",
    name: "กำลังดำเนินการ",
    loading: false,
    currentPage: 1,
    totalPage: 1,
    total: 0,
    query: {
      status: ENUM_INCIDENT_STATUS.IN_PROGRESS,
    },
    items: [],
  },
  {
    key: "report",
    name: "รอส่งรายงาน",
    loading: false,
    currentPage: 1,
    totalPage: 1,
    total: 0,
    query: {
      status: ENUM_INCIDENT_STATUS.COMPLETED,
    },
    items: [],
  },
]);

const fetch = async (tab: Tab, page: number) => {
  tab.query.page = page;

  tab.loading = true;
  tab.items = [];
  tab.currentPage = 1;
  tab.totalPage = 1;
  tab.total = 0;

  try {
    const result = await incidentStore.getIncidents(tab.query);
    tab.items = result.items;
    tab.total = result.total;
    tab.currentPage = result.page || 1;
    tab.totalPage = result.totalPages || 1;
  } catch (e) {
  } finally {
    tab.loading = false;
  }
};

const changeTab = (index: number) => fetch(tabs.value[index], 1)

onMounted(() => {
  tabs.value.forEach((tab) => fetch(tab, 1));
});
</script>
