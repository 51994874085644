<script setup lang="ts">
import { onMounted, ref } from "vue";
import type { Dayjs } from "dayjs";
import { fetchReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import dayjs from "dayjs";

type Report = { title: string; total: number };

const provinceMap: { [key: number]: string } = {
  1: "กรุงเทพมหานคร",
  2: "สมุทรปราการ",
  3: "นนทบุรี",
  4: "ปทุมธานี",
  5: "พระนครศรีอยุธยา",
  6: "อ่างทอง",
  7: "ลพบุรี",
  8: "สิงห์บุรี",
  9: "ชัยนาท",
  10: "สระบุรี",
  11: "ชลบุรี",
  12: "ระยอง",
  13: "จันทบุรี",
  14: "ตราด",
  15: "ฉะเชิงเทรา",
  16: "ปราจีนบุรี",
  17: "นครนายก",
  18: "สระแก้ว",
  19: "นครราชสีมา",
  20: "บุรีรัมย์",
  21: "สุรินทร์",
  22: "ศรีสะเกษ",
  23: "อุบลราชธานี",
  24: "ยโสธร",
  25: "ชัยภูมิ",
  26: "อำนาจเจริญ",
  27: "หนองบัวลำภู",
  28: "ขอนแก่น",
  29: "อุดรธานี",
  30: "เลย",
  31: "หนองคาย",
  32: "มหาสารคาม",
  33: "ร้อยเอ็ด",
  34: "กาฬสินธุ์",
  35: "สกลนคร",
  36: "นครพนม",
  37: "มุกดาหาร",
  38: "เชียงใหม่",
  39: "ลำพูน",
  40: "ลำปาง",
  41: "อุตรดิตถ์",
  42: "แพร่",
  43: "น่าน",
  44: "พะเยา",
  45: "เชียงราย",
  46: "แม่ฮ่องสอน",
  47: "นครสวรรค์",
  48: "อุทัยธานี",
  49: "กำแพงเพชร",
  50: "ตาก",
  51: "สุโขทัย",
  52: "พิษณุโลก",
  53: "พิจิตร",
  54: "เพชรบูรณ์",
  55: "ราชบุรี",
  56: "กาญจนบุรี",
  57: "สุพรรณบุรี",
  58: "นครปฐม",
  59: "สมุทรสาคร",
  60: "สมุทรสงคราม",
  61: "เพชรบุรี",
  62: "ประจวบคีรีขันธ์",
  63: "นครศรีธรรมราช",
  64: "กระบี่",
  65: "พังงา",
  66: "ภูเก็ต",
  67: "สุราษฎร์ธานี",
  68: "ระนอง",
  69: "ชุมพร",
  70: "สงขลา",
  71: "สตูล",
  72: "ตรัง",
  73: "พัทลุง",
  74: "ปัตตานี",
  75: "ยะลา",
  76: "นราธิวาส",
  77: "บึงกาฬ",
};

const { from, to } = defineProps<{
  from: Dayjs;
  to: Dayjs;
}>();

const fetch = new fetchReport();
const report = ref<Report[]>([]);
const lastUpdate = ref<Dayjs>();

const reload = async () => {
  const result = await fetch.IncidentReport(
    {
      startDate: from.format("YYYY-MM-DD"),
      endDate: to.format("YYYY-MM-DD"),
    },
    "province_id",
    "month"
  );

  lastUpdate.value = dayjs();

  report.value = Object.values(result)
    .reduce(
      (x, item) =>
        Object.keys(item).reduce((y, k) => {
          let i = y.find(([key, _]) => key === k);
          if (!i) {
            i = [k, 0];
            y.push(i);
          }

          i[1] += item[k];
          return y;
        }, x),
      [] as Array<[string, number]>
    )
    .sort(([, a], [, b]) => b - a)
    .reduce((item, [key, total]) => {
      item.push({
        title: provinceMap[parseInt(key)],
        total,
      });
      return item;
    }, [] as Report[])
    .slice(0, 5);
};

onMounted(reload);
defineExpose({ reload });
</script>

<template>
  <!-- Card Headings: Title with Subtitle -->
  <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100 h-full">
    <!-- Card Header -->
    <div class="card-title bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
      <h3 class="mb-1 font-semibold">
        <slot name="title">จำนวนผู้ใช้งานใหม่</slot>
      </h3>
    </div>
    <!-- END Card Header -->

    <!-- Card Body -->
    <div class="chart-wrap grow p-5">
      <table class="min-w-full whitespace-nowrap align-middle text-sm">
        <thead>
          <tr class="border-b border-gray-100 dark:border-gray-700/50">
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">จังหวัด</th>
            <th class="bg-gray-100/75 px-3 py-4 text-center font-semibold text-gray-900 dark:bg-gray-700/25 dark:text-gray-50">จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in report" class="border-b border-gray-100 dark:border-gray-700/50">
            <td class="p-3">{{ item.title }}</td>
            <td class="p-3">{{ item.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Card Body -->
  </div>
  <!-- END Card Headings: Title with Subtitle -->
</template>

<style scoped>
.chart-wrap {
  min-height: 0;
}
</style>
