import { ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import { defineStore } from "pinia";
import * as yup from "yup";

const storeName = `useResetPasswordStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  newPassword: {
    is: true,
    name: `newPassword`,
    type: "password",
    label: `form.newPassword.label`,
    placeholder: `form.newPassword.placeholder`,
    value: null,
  },
  repeatPassword: {
    is: true,
    name: `repeatPassword`,
    type: "password",
    label: `form.repeatPassword.label`,
    placeholder: `form.repeatPassword.placeholder`,
    value: null,
  },
};

export const useResetPasswordStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    isRequired,
    result: convertToValues(isRequired) as any,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object().shape({
      newPassword: yup.string().min(8, "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร").minLowercase(1).minUppercase(1).minNumbers(1),
      repeatPassword: yup.string().when(["newPassword"], (newPassword, schema) => {
        return newPassword[0] !== undefined ? schema.oneOf([yup.ref("newPassword")], "รหัสผ่านไม่ตรงกัน").required("กรุณายืนยันรหัสผ่าน") : schema.notRequired();
      }),
    }),
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });
        return await this.update(this.result);
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    async update(result: any) {
      try {
        this.loading = true;
        const data = await this.fetchAuth.PasswordUpdate({
          password: result?.newPassword,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
