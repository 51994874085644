<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import type { Dayjs } from "dayjs";
import Thailand from "@svg-maps/thailand";
import { fetchReport } from "@/configs/fetches/PoliceCommandCenter/fetchReport";
import SvgMap, { type Location } from "@/components/SvgMap/SvgMap.vue";
import dayjs from "dayjs";

const provinceMap: { [key: number]: string } = {
  1: "bkk",
  2: "spk",
  3: "nbi",
  4: "pte",
  5: "aya",
  6: "atg",
  7: "lri",
  8: "sbr",
  9: "cnt",
  10: "sri",
  11: "cbi",
  12: "ryg",
  13: "cti",
  14: "trt",
  15: "cco",
  16: "pri",
  17: "nyk",
  18: "skw",
  19: "nma",
  20: "brm",
  21: "srn",
  22: "ssk",
  23: "ubn",
  24: "yst",
  25: "cpm",
  26: "acr",
  27: "nbp",
  28: "kkn",
  29: "udn",
  30: "lei",
  31: "nki",
  32: "mkm",
  33: "ret",
  34: "ksn",
  35: "snk",
  36: "npm",
  37: "mdh",
  38: "cmi",
  39: "lpn",
  40: "lpg",
  41: "utd",
  42: "pre",
  43: "nan",
  44: "pyo",
  45: "cri",
  46: "msn",
  47: "nsn",
  48: "uti",
  49: "kpt",
  50: "tak",
  51: "sti",
  52: "plk",
  53: "pct",
  54: "pnb",
  55: "rbr",
  56: "kri",
  57: "spb",
  58: "npt",
  59: "skn",
  60: "skm",
  61: "pbi",
  62: "pkn",
  63: "nrt",
  64: "kbi",
  65: "pna",
  66: "pkt",
  67: "sni",
  68: "rng",
  69: "cpn",
  70: "ska",
  71: "stn",
  72: "trg",
  73: "plg",
  74: "ptn",
  75: "yla",
  76: "nwt",
  77: "bkn",
};

const { from, to } = defineProps<{
  from: Dayjs;
  to: Dayjs;
}>();

const fetch = new fetchReport();
const report = ref<{ [key: string]: number }>({});
const lastUpdate = ref<Dayjs>();

const reload = async () => {
  const result = await fetch.IncidentReport(
    {
      startDate: from.format("YYYY-MM-DD"),
      endDate: to.format("YYYY-MM-DD"),
    },
    "province_id",
    "month"
  );

  lastUpdate.value = dayjs();

  report.value = Object.values(result).reduce((x, item) => {
    return Object.keys(item).reduce((y, k) => {
      if (!y[provinceMap[parseInt(k)]]) {
        y[provinceMap[parseInt(k)]] = 0;
      }

      y[provinceMap[parseInt(k)]] += item[k];
      return y;
    }, x);
  }, {} as { [key: string]: number });
};

const total = computed<number>((): number => Object.values(report.value).reduce((a, b) => a + b, 0));

const locationClass = (location: Location): string => {
  const level = total.value ? Math.ceil((report.value[location.id] || 0) / total.value) : 0;
  return level ? `level-${level}` : "";
};

onMounted(reload);
defineExpose({ reload });
</script>

<template>
  <!-- Card Headings: Title with Subtitle -->
  <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100 h-full">
    <!-- Card Header -->
    <div class="bg-gray-50 px-5 py-4 dark:bg-gray-700/50">
      <h3 class="mb-1 font-semibold">
        <slot name="title">จำนวนผู้ใช้งานใหม่</slot>
      </h3>
    </div>
    <!-- END Card Header -->

    <!-- Card Body -->
    <div class="chart-wrap grow p-5">
      <SvgMap v-if="Thailand" :map="Thailand" :locationClass="locationClass" />
    </div>
    <!-- Card Body -->
  </div>
  <!-- END Card Headings: Title with Subtitle -->
</template>

<style scoped>
.chart-wrap {
  min-height: 0;
}
</style>
