import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetAlert } from "@/configs/types/Alert/typeAlert";

import type { typeGetPagination } from "@/configs/types/Shared/typePagination";

export class fetchAlert {
  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetPagination<typeGetAlert>>("/v1/alerts", { params });
    return data;
  }

  async Create(body: object): Promise<typeGetAlert> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).post<typeGetAlert>("/v1/alerts", body);
    return data;
  }

  async Get(id: string): Promise<typeGetAlert> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetAlert>(`/v1/alerts/${id}`);
    return data;
  }

  async Update(id: string, body: object): Promise<typeGetAlert> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).put<typeGetAlert>(`/v1/alerts/${id}`, body);
    return data;
  }

  async Delete(id: string): Promise<typeGetAlert> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).delete<typeGetAlert>(`/v1/alerts/${id}`);
    return data;
  }
}
