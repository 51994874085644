import { convertToValues, convertToNumber, removeLocalStorage } from "@/configs/helpers/MainHelper";
import type { typeGetRegisterUser } from "@/configs/types/User/typeRegisterUser";
import { defineStore } from "pinia";
import * as yup from "yup";
import YupPassword from "yup-password";
import { auth } from "@/configs/plugins/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { AUTH_TOKEN_KEY, useAuthStore } from "./useAuthStore";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";

YupPassword(yup);

const storeName = `useRegisterStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

const isRequired = {
  email: {
    is: true,
    name: `email`,
    label: `form.email.label`,
    placeholder: `form.email.placeholder`,
    value: null,
  },
  contactNumber: {
    is: true,
    name: `guest.contactNumber`,
    label: `form.phone.label`,
    placeholder: `form.phone.placeholder`,
    value: null,
  },
  newPassword: {
    is: true,
    type: "password",
    name: `newPassword`,
    label: `form.newPassword.label`,
    placeholder: `form.newPassword.placeholder`,
    value: null,
  },
  repeatPassword: {
    is: true,
    type: "password",
    name: `repeatPassword`,
    label: `form.repeatPassword.label`,
    placeholder: `form.repeatPassword.placeholder`,
    value: null,
  },
  locale: {
    is: true,
    name: `locale`,
    label: `form.locale.label`,
    placeholder: `form.locale.placeholder`,
    value: null,
  },
};

export const useRegisterStore = defineStore(storeName, {
  state: () => ({
    fetchAuth: new fetchAuth(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: false,
    isRequired,
    result: convertToValues(isRequired) as typeGetRegisterUser,
    provider: null,
    uid: null,
    error: {} as { [key: string]: string | null },
    validationSchema: {
      email: yup.object().shape({
        email: isRequired.email.is ? yup.string().nullable().required().email() : yup.string().nullable().notRequired().email(),
        contactNumber: isRequired.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
        newPassword: yup.string().min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร").minLowercase(1).minUppercase(1).minNumbers(1),
        repeatPassword: yup.string().when(["newPassword"], (newPassword, schema) => {
          return newPassword[0] !== undefined ? schema.oneOf([yup.ref("newPassword")], "รหัสผ่านไม่ตรงกัน").required("กรุณายืนยันรหัสผ่าน") : schema.notRequired();
        }),
      }),
      thirdParty: yup.object().shape({
        email: isRequired.email.is ? yup.string().nullable().required().email() : yup.string().nullable().notRequired().email(),
        contactNumber: isRequired.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      }),
    },
  }),
  actions: {
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      const schema = !this.provider || this.provider === "EMAIL" ? this.validationSchema.email : this.validationSchema.thirdParty;

      try {
        await schema.validate(this.result, {
          abortEarly: false,
        });

        // Login
        return {
          status: !this.provider || this.provider === "EMAIL" ? await this.loginFirebase(this.result) : true,
          isError: false,
        };
      } catch (e: any) {
        (e.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
        return {
          status: false,
          isError: true,
        };
      }
    },
    async loginFirebase(data: typeGetRegisterUser) {
      this.loading = true;
      if (data.email) {
        return await signInWithEmailAndPassword(auth, data.email, data.repeatPassword)
          .then(async () => {
            return await this.loginTourist(data, true);
          })
          .catch(async () => {
            return await this.registerFirebase(data);
          });
      } else {
        return false;
      }
    },
    async loginTourist(data: typeGetRegisterUser, isLastStep: boolean) {
      this.loading = true;

      const authStore = useAuthStore();

      try {
        if (data.email) {
          await authStore.login({
            email: data.email,
            password: data.repeatPassword,
            rememberMe: false,
          });
          return isLastStep;
        } else {
          return false;
        }
      } catch (e) {
        return true;
      }
    },
    async registerFirebase(data: typeGetRegisterUser) {
      if (data.email) {
        return await createUserWithEmailAndPassword(auth, data.email, data.repeatPassword)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else {
        return false;
      }
    },
    async logout() {
      const authStore = useAuthStore();
      await auth.signOut();

      authStore.token = null;
      authStore.user = null;

      removeLocalStorage(AUTH_TOKEN_KEY);

      window.location.href = window.location.href;
    },
  },
});
