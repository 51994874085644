<script lang="ts">
import { defineComponent } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";

export default defineComponent({
  name: "ProfileView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  setup() {
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();

    return {
      authStore,
      masterDataStore,
      profile_image: "" as any,
    };
  },
  methods: {
    async setting() {
      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {},
});
</script>

<template>
  <div class="flex flex-col md:flex-row gap-2">
    <div class="ttp-layout w-full my-0">
      <div class="ttp-page-header-1">
        <h2>{{ $t(($route?.meta as any).i18n) }}</h2>
      </div>

      <div class="flex items-center justify-center gap-2 ttp-fixed-agent-mobile-header">
        <div class="swipe-photo flex flex-col ttp-text-xs relative gap-4 items-center w-full px-4 pb-4 pt-10 h-full">
          <div class="relative inline-flex ttp-icon-box-04 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500 overflow-hidden">
            <component v-if="!profile_image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-01" />
            <a v-else :href="profile_image" target="_blank"><img class="object-cover object-center w-28 h-28 lg:w-36 lg:h-36 rounded-full" :src="profile_image" alt="" /></a>
          </div>
          <div class="text-black text-center ttp-text-lg font-bold">{{ $convertString(authStore?.user?.content[0]?.fullName) }}</div>
          <div class="text-[#6B7280] text-center">{{ $convertString(masterDataStore.getRoleByCode(authStore?.user?.role)) }}</div>
          <div class="flex flex-col w-full">
            <div class="text-black">{{ $t("form.name.label") }}(TH)</div>
            <div>{{ $convertString(authStore?.user?.content[0]?.fullName) }}</div>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-black">Name (EN)</div>
            <div>{{ $convertString(authStore?.user?.content[1]?.fullName) }}</div>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-black">{{ $t("form.mobile.label") }}</div>
            <div>{{ $convertString(authStore?.user?.contactNumber) }}</div>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-black">{{ $t("form.idCard.label") }}</div>
            <div>{{ $convertString(authStore?.user?.idCard) }}</div>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-black">{{ $t("form.email.label") }}</div>
            <div>{{ $convertString(authStore?.user?.email) }}</div>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-black">{{ $t("form.division.label") }}</div>
            <div>{{ masterDataStore.getOrganizationByCode(authStore?.user?.organization) }}</div>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-black">{{ $t("form.responsibleArea.label") }}</div>
            <div v-html="masterDataStore.getAllResponsibleAreaFullNames(authStore?.user?.responsibleAreas)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style style="scss"></style>
