import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";

export type TouristReport = {[key: string]: number}
export type IncidentReport = {[key: string]: number}
export type SimpleReport = {[key: string]: number}

export class fetchReport {
  async TouristReport(params: object, group: string): Promise<TouristReport> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<{[key: string]: number}>(`/v1/reports/tourists/${group}`, { params });
    return data;
  }

  async TouristDailyReport(params: object, group: string): Promise<{[key: string]: TouristReport}> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<{[key: string]: TouristReport}>(`/v1/reports/tourists/${group}/daily`, { params });
    return data;
  }

  async IncidentReport(params: object, group: string, timeGroup: string): Promise<{[key: string]: IncidentReport}> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<{[key: string]: IncidentReport}>(`/v1/reports/incidents/${group}/${timeGroup}`, { params });
    return data;
  }

  async SimpleIncidentReport(): Promise<SimpleReport> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<SimpleReport>('/v1/reports/simple-incident-report');
    return data;
  }
}
