import AdminLayoutView from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/AdminLayoutView.vue";

import HistoryPoliceTouristAndAgentView from "@/views/PoliceTouristAndAgent/Tourist/History/HistoryPoliceTouristAndAgentView.vue";
import HistoryDetailPoliceTouristAndAgentView from "@/views/PoliceTouristAndAgent/Tourist/History/HistoryDetailPoliceTouristAndAgentView.vue";
import HomeView from "@/views/PoliceTouristAndAgent/Tourist/Home/HomeView.vue";
import SettingLanguageView from "@/views/PoliceTouristAndAgent/Tourist/Setting/SettingLanguageView.vue";
import SettingSecurityPrivacyView from "@/views/PoliceTouristAndAgent/Tourist/Setting/SettingSecurityPrivacyView.vue";
import SettingSecurityPrivacyChangePasswordView from "@/views/PoliceTouristAndAgent/Tourist/Setting/SettingSecurityPrivacyChangePasswordView.vue";
import SettingMenuView from "@/views/PoliceTouristAndAgent/Tourist/Setting/SettingMenuView.vue";
import SettingContactUsView from "@/views/PoliceTouristAndAgent/Tourist/Setting/SettingContactUsView.vue";
import SettingNotificationView from "@/views/PoliceTouristAndAgent/Tourist/Setting/SettingNotificationView.vue";
import ProfileMenuView from "@/views/PoliceTouristAndAgent/Tourist/Profile/ProfileMenuView.vue";
import ProfileEditTouristView from "@/views/PoliceTouristAndAgent/Tourist/Profile/ProfileEditTouristView.vue";
import ProfileEmergencyContactView from "@/views/PoliceTouristAndAgent/Tourist/Profile/ProfileEmergencyContactView.vue";
import ProfileSocialMediaAccountView from "@/views/PoliceTouristAndAgent/Tourist/Profile/ProfileSocialMediaAccountView.vue";
import ProfileUnlinkView from "@/views/PoliceTouristAndAgent/Tourist/Profile/ProfileUnlinkView.vue";
import PreChatView from "@/views/PoliceTouristAndAgent/Tourist/Chat/PreChatView.vue";
import ChatView from "@/views/PoliceTouristAndAgent/Tourist/Chat/ChatView.vue";
import MessageView from "@/views/PoliceTouristAndAgent/Tourist/Chat/MessageView.vue";
import NotificationView from "@/views/PoliceTouristAndAgent/Tourist/Notification/NotificationView.vue";
import NewsView from "@/views/PoliceTouristAndAgent/Tourist/News/NewsView.vue";
import NewsDetailView from "@/views/PoliceTouristAndAgent/Tourist/News/NewsDetailView.vue";
import PopularPlaceView from "@/views/PoliceTouristAndAgent/Tourist/PopularPlace/PopularPlaceView.vue";
import PopularPlaceDetailView from "@/views/PoliceTouristAndAgent/Tourist/PopularPlace/PopularPlaceDetailView.vue";
import ActivityDetailView from "@/views/PoliceTouristAndAgent/Tourist/PopularPlace/ActivityDetailView.vue";
import AlertDetailView from "@/views/PoliceTouristAndAgent/Tourist/Alert/AlertDetailView.vue";

export const routePoliceTourist: any = [
  {
    path: "/tourist",
    name: "Tourist",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "Tourist",
      i18n: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "tourist",
      activeMenu: [],
    },
    children: [
      {
        path: "redirect",
        name: "RedirectView",
        redirect: (to: any) => {
          const redirect = { name: to.query?.view, params: JSON.parse(to.query?.params || "") };
          console.log({ redirect });
          return redirect;
        },
      },
      {
        path: "home",
        name: "HomeView",
        component: HomeView,
        meta: {
          requiresAuth: true,
          title: "Home",
          i18n: "layout.menu.HomeView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["HomeView"],
        },
      },
      {
        path: "news",
        name: "NewsView",
        component: NewsView,
        meta: {
          requiresAuth: true,
          title: "News",
          i18n: "layout.menu.NewsManageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["NewsView", "NewsDetailView"],
        },
      },
      {
        path: "news/:id",
        name: "NewsDetailView",
        component: NewsDetailView,
        meta: {
          requiresAuth: true,
          title: "News",
          i18n: "layout.menu.NewsDetailView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["NewsView", "NewsDetailView"],
        },
      },
      {
        path: "alert/:id",
        name: "AlertDetailView",
        component: AlertDetailView,
        meta: {
          requiresAuth: true,
          title: "Alert",
          i18n: "layout.menu.AlertDetailView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["AlertDetailView"],
        },
      },
      {
        path: "attractions",
        name: "PopularPlaceView",
        component: PopularPlaceView,
        meta: {
          requiresAuth: true,
          title: "Popular Places",
          i18n: "layout.menu.PopularPlaceView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["PopularPlaceView", "PopularPlaceDetailView"],
        },
      },
      {
        path: "attractions/:id",
        name: "PopularPlaceDetailView",
        component: PopularPlaceDetailView,
        meta: {
          requiresAuth: true,
          title: "Popular Places",
          i18n: "layout.menu.PopularPlaceDetailView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["PopularPlaceView"],
        },
      },
      {
        path: "activities/:id",
        name: "ActivityDetailView",
        component: ActivityDetailView,
        meta: {
          requiresAuth: true,
          title: "Activity",
          i18n: "layout.menu.PopularPlaceDetailView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["PopularPlaceView"],
        },
      },
      {
        path: "report/incident",
        name: "ReportIncidentView",
        component: PreChatView,
        meta: {
          requiresAuth: true,
          title: "Incident Report",
          i18n: "layout.menu.ReportIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["ReportIncidentView", "PreChatView", "ChatManageView"],
        },
      },
      {
        path: "report/history",
        name: "HistoryPoliceTouristAndAgentView",
        component: HistoryPoliceTouristAndAgentView,
        meta: {
          requiresAuth: true,
          title: "Incident Report",
          i18n: "layout.menu.HistoryView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["HistoryDetailPoliceTouristAndAgentView", "HistoryPoliceTouristAndAgentView", "NotificationView", "MessageView"],
        },
      },
      {
        path: "report/history/:id",
        name: "HistoryDetailPoliceTouristAndAgentView",
        component: HistoryDetailPoliceTouristAndAgentView,
        meta: {
          requiresAuth: true,
          title: "Incident Report : Detail",
          i18n: "layout.menu.HistoryDetailPoliceTouristAndAgentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["HistoryDetailPoliceTouristAndAgentView", "HistoryPoliceTouristAndAgentView", "NotificationView", "MessageView"],
        },
      },
      {
        path: "setting",
        name: "SettingMenuView",
        component: SettingMenuView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.SettingMenuView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/profile",
        name: "ProfileMenuView",
        component: ProfileMenuView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.ProfileMenuView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/profile/edit",
        name: "ProfileEditTouristView",
        component: ProfileEditTouristView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.ProfileEditTouristView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/profile/emergency-contact",
        name: "ProfileEmergencyContactView",
        component: ProfileEmergencyContactView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.ProfileEmergencyContactView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/profile/social-media-accounts",
        name: "ProfileSocialMediaAccountView",
        component: ProfileSocialMediaAccountView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.ProfileSocialMediaAccountView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/profile/unlink/:socialType",
        name: "ProfileUnlinkView",
        component: ProfileUnlinkView,
        beforeEnter: (to: any, _from: any, next: any) => {
          const validTypes = ["google", "facebook", "apple"];
          if (validTypes.includes(to.params.socialType)) {
            next();
          } else {
            next({ name: "HomeView" });
          }
        },
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.ProfileUnlinkView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/security-privacy",
        name: "SettingSecurityPrivacyView",
        component: SettingSecurityPrivacyView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.SettingSecurityPrivacyView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/security-privacy/change-password",
        name: "SettingSecurityPrivacyChangePasswordView",
        component: SettingSecurityPrivacyChangePasswordView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.SettingSecurityPrivacyChangePasswordView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/language",
        name: "SettingLanguageView",
        component: SettingLanguageView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.SettingLanguageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "setting/notification",
        name: "SettingNotificationView",
        component: SettingNotificationView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.SettingNotificationView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "contact-us",
        name: "SettingContactUsView",
        component: SettingContactUsView,
        meta: {
          requiresAuth: true,
          title: "Profile",
          i18n: "layout.menu.SettingContactUsView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["SettingMenuView", "ProfileMenuView", "ProfileEditTouristView", "ProfileEmergencyContactView", "ProfileSocialMediaAccountView", "ProfileUnlinkView", "SettingSecurityPrivacyView", "SettingSecurityPrivacyChangePasswordView", "SettingLanguageView", "SettingNotificationView", "SettingContactUsView"],
        },
      },
      {
        path: "chat/:id",
        name: "ChatManageView",
        component: ChatView,
        meta: {
          requiresAuth: true,
          title: "Chat",
          i18n: "layout.menu.ChatView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          hideFooter: true,
          activeMenu: ["ChatManageView"],
        },
      },
      {
        path: "chat/pre-chat",
        name: "PreChatView",
        component: PreChatView,
        meta: {
          requiresAuth: true,
          title: "Pre Chat",
          i18n: "layout.menu.PreChatView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          hideFooter: false,
          activeMenu: ["PreChatView", "ReportIncidentView"],
        },
      },
      {
        path: "chat/message",
        name: "MessageView",
        component: MessageView,
        meta: {
          requiresAuth: true,
          title: "Message",
          i18n: "layout.menu.MessageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["HistoryDetailPoliceTouristAndAgentView", "HistoryPoliceTouristAndAgentView", "NotificationView", "MessageView"],
        },
      },
      {
        path: "notification",
        name: "NotificationView",
        component: NotificationView,
        meta: {
          requiresAuth: true,
          title: "Notification",
          i18n: "layout.menu.NotificationView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: ["HistoryDetailPoliceTouristAndAgentView", "HistoryPoliceTouristAndAgentView", "NotificationView", "MessageView"],
        },
      },
    ],
  },
];
